<template>
  <div>
    <vg-modal
      v-show="!isModalConfirmDeleteOpened"
      :title="isAnUpdateUserForm?$t('user-form-update'):$t('user-form-add')+': '+$t('user-form-user')"
      id="user-form-modal"
      v-loading="isDisabledUi"
      @close="$emit('close')"
    >
      <template #body-left>
        <form
          ref="user-form"
          onSubmit="return false;"
          id="user-form"
        >
          <vg-input
            v-model="user.prenom"
            :title="$t('user-form-prenom')"
            input-type="text"
            :is-required-value="true"
          />
          <vg-input
            v-model="user.nom"
            :title="$t('user-form-nom')"
            input-type="text"
            :is-required-value="true"
          />
          <vg-input
            v-model="user.email"
            :title="$t('user-form-email')"
            input-type="text"
            :is-required-value="true"
            :autocomplete="'new-password'"
            :disabled="isAnUpdateUserForm"
          />
          <vg-input
            v-if='can("PARAMETRES.UPDATE_PASSWORD")'
            v-model="user.password"
            :title="$t('user-form-password')"
            input-type="password"
            :is-required-value="!isAnUpdateUserForm"
            :autocomplete="'new-password'"
          />
          <vg-input
            v-model="user.telephone"
            :title="$t('user-form-telephone')"
            input-type="text"
            :is-required-value="false"
          />
          <vg-input
            :title="$t('user-form-role')"
            :is-required-value="true"
          >
            <vg-select
              v-model="user.roles"
              :options="roles"
              :attributeLabel="'label'"
              :attributeValue="'value'"
              :clearable="false"
            />
        </vg-input>
          <vg-input v-if="user.roles=='ROLE_SOUS_TRAITANT'"
            :title="$t('user-form-tiers')">
            <vg-tiers-selector v-model="user.tiers_id">
            </vg-tiers-selector>
          </vg-input>

          <vg-input
            :title="$t('user-form-fonction')">
            <input v-model="user.fonction" list="userFonction" id="choixFonction" name="choixFonction" style="width: 100%;border: 1px solid #dcdfe6;border-radius: 0px;height: 30px !important;"/>
            <datalist id="userFonction" >
                <option v-for="(fonction, index) in fonctions" :key="index" :value="fonction.label"></option>
            </datalist>
          </vg-input>



          <vg-input
            :title="$t('restriction-site')"
            :is-required-value="false"
          >
            <vg-lieux-selector
                v-model="userLieux"
                :filters="lieuxFilters"
                @change="handleChangeLieuxRestriction"
                multiple
            >
            </vg-lieux-selector>
          </vg-input>
          <vg-input
              v-model="user.enabled"
              :title="$t('is-enabled')"
              v-if="isAnUpdateUserForm"
          >
              <vg-switch
                  :checked="parseInt(user.enabled)"
                  v-model="user.enabled">
              </vg-switch>
          </vg-input>
          <input
            v-show="false"
            ref="user-submit"
            type="submit"
          >
        </form>
      </template>
      <template #body-right v-if="isAnUpdateUserForm">
          <!-- personnal parameters -->
          <h5 style="color: #35b1ea;">{{$t("user-form-notifications-email")}}</h5>
          <div v-for="(group, groupIndex) in groupedParameters" :key="groupIndex">
              <vg-collapse 
                :title="$t(group[0].groupe)"
                :collapseByDefault="false"
                :type="'section'">
                <template v-slot:content>
                  <div v-for="(parameter,index) in group">
                    <vg-checkbox v-model="parameter.entry"
                      :key="'checkbox-'+parameter.name"
                      :inputValue="parameter.entry"
                      :defaultChecked="getDefaultChecked(parameter)"
                      :label="$t(parameter.name)"
                    />
                    <div v-if="parameter.groupe=='maintenance' && parameter.entry==1 && !isAnExcludedParameter(parameter.name)"
                      style="display: flex;justify-content: flex-start;align-items: center;gap:10px;width:100%;padding-left:35px;">
                      <vg-input :title="$t('restriction-services')">
                        <div v-for="(parameterService, indexService) in parameter.services">
                          <span>{{ parameterService.entry }}</span> 
                          <vg-button size="sm" type="danger" :key="indexService+'-'+parameterService.id" 
                            @click="onDeleteUserParemeter(parameterService)">
                            <i class="fa fa-times"></i>
                          </vg-button> | 
                        </div>
                        <vg-button v-if="showAddServiceIdParent!=parameter.id" size="sm" type="success" :key="'show-add-'+parameter.id" 
                          @click="showAddServiceIdParent=parameter.id;">
                          {{$t("add")}}
                        </vg-button>
                        <vg-service-selector v-if="showAddServiceIdParent==parameter.id" :key="'service-selector-'+parameter.name" 
                          :placeholder="$t('tous-services')" 
                          @selected="onAddService(parameter, $event)" />
                        <vg-button v-if="showAddServiceIdParent==parameter.id" size="sm" type="default-danger" :key="'cancel-show-add-'+parameter.id" 
                          @click="showAddServiceIdParent=null;">
                          <i class="fa fa-times"></i>
                        </vg-button>
                      </vg-input>
                      <vg-input :title="$t('restriction-typologies')">
                        <div v-for="(parameterTypologie, indexTypologie) in parameter.typologies">
                          <span>{{ parameterTypologie.entry }} </span>
                          <vg-button size="sm" type="danger" :key="indexTypologie+'-'+parameterTypologie.id" 
                            @click="onDeleteUserParemeter(parameterTypologie)">
                            <i class="fa fa-times"></i>
                          </vg-button> | 
                        </div>
                        <vg-button v-if="showAddTypologieIdParent!=parameter.id" size="sm" type="success" :key="'show-add-'+parameter.id" 
                          @click="showAddTypologieIdParent=parameter.id;">
                          {{$t("add")}}
                        </vg-button>
                        <vg-typologies-maintenance-selector v-if="showAddTypologieIdParent==parameter.id" :key="'typologies-selector-'+parameter.name" 
                          attributeValue="name"
                          :placeholder="$t('tous-typologies')" 
                          @selected="onAddTypologie(parameter, $event)" />
                        <vg-button v-if="showAddTypologieIdParent==parameter.id" size="sm" type="default-danger" :key="'cancel-show-add-'+parameter.id"
                          @click="showAddTypologieIdParent=null;">
                          <i class="fa fa-times"></i>
                        </vg-button>
                      </vg-input>
                    </div>
                    <br>
                  </div>
                </template>
              </vg-collapse>
          </div>
          
          <vg-input :title="$t('notifications-email-lang-selector')">
              <vg-lang-selector></vg-lang-selector>
          </vg-input>
          <vg-checkbox
              v-model="user.notifyByEmail"
              :key="index"
              :inputValue="user.notifyByEmail"
              :defaultChecked="user.notifyByEmail=='1' || user.notifyByEmail==true || user.notifyByEmail==1?true:false"
              :label="$t('notify-user-by-email')"
            />
      </template>
      <template #body-right v-else>
          <!-- personnal parameters -->
          <h5 style="color: #35b1ea;">{{$t("user-form-notifications-email")}}</h5>
          <div v-for="(group, groupIndex) in groupedParameters" :key="groupIndex">
              <vg-collapse 
                :title="$t(group[0].groupe)"
                :collapseByDefault="false"
                :type="'section'">
                <template v-slot:content>
                  <div v-for="(parameter,index) in group">
                    <vg-checkbox v-model="parameter.entry"
                      :key="'checkbox-'+parameter.name"
                      :inputValue="parameter.entry"
                      :defaultChecked="getDefaultChecked(parameter)"
                      :label="$t(parameter.name)"
                    />
                    <div v-if="parameter.groupe=='maintenance' && parameter.entry==1 && !isAnExcludedParameter(parameter.name)"
                      style="display: flex;justify-content: flex-start;align-items: center;gap:10px;width:100%;padding-left:35px;">
                      <vg-input :title="$t('restriction-services')" v-show="services && services.length!=0">
                        <vg-service-selector :key="'service-selector-'+parameter.name" 
                          v-model="parameter.services"
                          multiple 
                          :executeFetch="index==1" 
                          :placeholder="$t('tous-services')" 
                          @selected="onSelectedServices(parameter, $event)" />
                      </vg-input>
                      <vg-input :title="$t('restriction-typologies')" v-show="typologiesMaintenance && typologiesMaintenance.length!=0">
                        <vg-typologies-maintenance-selector :key="'typologies-selector-'+parameter.name" 
                          v-model="parameter.typologies"
                          multiple 
                          :attributeValue="'name'"
                          :executeFetch="index==1" 
                          :placeholder="$t('tous-typologies')"
                          @selected="onSelectedTypologies(parameter, $event)" />
                      </vg-input>
                    </div>
                    <br>
                  </div>
                </template>
              </vg-collapse>
          </div>
          
          <vg-input :title="$t('notifications-email-lang-selector')">
              <vg-lang-selector></vg-lang-selector>
          </vg-input>
          <vg-checkbox
              v-model="user.notifyByEmail"
              :key="index"
              :inputValue="user.notifyByEmail"
              :defaultChecked="user.notifyByEmail=='1' || user.notifyByEmail==true || user.notifyByEmail==1?true:false"
              :label="$t('notify-user-by-email')"
            />
      </template>
      <template v-slot:footer>
        <vg-button
          type="default"
          @click="$emit('close')"
        >
          {{ $t("user-form-cancel") }}
        </vg-button>

        <vg-button
          v-if="isAnUpdateUserForm"
          type="danger"
          @click="isModalConfirmDeleteOpened=true;"
        >
          {{ $t("user-form-delete") }}
        </vg-button>

        <vg-button
          v-if="isAnUpdateUserForm"
          type="default-danger"
          @click="submitForm"
        >
          {{ $t("user-form-save") }}
        </vg-button>

        <vg-button
          v-if="!isAnUpdateUserForm"
          type="success"
          @click="submitForm"
        >
          {{ $t("user-form-save") }}
        </vg-button>
      </template>
    </vg-modal>
    <dialog-secured-action
      v-if="isModalConfirmDeleteOpened"
      :valid-response="user.email"
      @save="handleDeleteUser"
      @close="isModalConfirmDeleteOpened = false"
    >
      <template v-slot:header-title>
        {{ $t("user-form-confirm-suppression") }} <b>{{ user.name }}</b> ?
      </template>
    </dialog-secured-action>
  </div>
</template>
<script>
import UserMixins from "src/mixins/UserMixins.js";
import UserParametersMixins from "src/mixins/UserParametersMixins.js";

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgLieuxSelector from "src/components/Vg/Selectors/VgLieuxSelector";
import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
import VgLangSelector from "src/components/Vg/Internationalisation/VgLangSelector.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import VgTypologiesMaintenanceSelector from "src/components/Vg/Selectors/VgTypologiesMaintenanceSelector.vue";
import VgSwitch from 'src/components/Vg/VgSwitch.vue';

import { mapGetters } from 'vuex';

export default {
    name: 'vg-user-form',
    components:{
        VgButton,
        VgInput,
        VgSelect,
        VgCollapse,
        VgCheckbox,
        DialogSecuredAction,
        VgLieuxSelector,
        VgTiersSelector,
        VgLangSelector,
        VgServiceSelector,
        VgTypologiesMaintenanceSelector,
        VgSwitch
    },
    mixins: [ UserMixins, UserParametersMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "user-form-cancel": "Annuler",
            "user-form-save": "Enregistrer",
            "user-form-update": "Modification utilisateur",
            "user-form-delete": "Supprimer",
            "user-form-add": "Création utilisateur",
            "user-form-user": "utilisateur",
            "user-form-nom": "Nom",
            "user-form-prenom": "Prénom",
            "user-form-email": "Email",
            "user-form-telephone": "Téléphone",
            "user-form-role": "Rôle",
            "user-form-tiers": "Tiers associé",
            "user-form-fonction": "Fonction",
            "user-form-password": "Mot de passe",
            "user-form-notifications-email": "Notifications email",
            "user-form-confirm-suppression": "Confirmer la suppression",
            "user-form-Restriction-lieux": "Attacher au site",
            "restriction-site": "Restriction site",
            "alerte_contrat_tacite_reconduction": "Alerte contrat tactite reconduction",
            "rapport_hebdomadaire_verification": "Rapport hebdomadaire préventif",
            "rapport_hebdomadaire_maintenance": "Rapport hebdomadaire curatif",
            "alerte_fin_contrat": "m'avertir quand des contrats arrivent à échéance",
            "alerte_echeance_intervention_arrivant_a_terme": "m'avertir quand des interventions arrivent à échéance",
            "emailing_ouverture_fm": "m'avertir quand une fiche curative est créée",
            "emailing_fermeture_fm": "m'avertir quand une fiche curative est clôturée",
            "emailing_affectation_fm": "m'avertir quand une fiche curative m'est affectée",
            "emailing_bc_validation_complete": "m'avertir quand le statut du bon de commande passe en validation complète",
            "emailing_bc_livraison_complete": "m'avertir quand le statut du bon de commande passe en livraison complète",
            "emailing_bc_demande_validation": "m'avertir quand une demande de validation est déclenchée",
            "emailing_ouverture_fdc": "m'avertir quand une demande consommable est créée",
            "emailing_fermeture_fdc": "m'avertir quand une demande consommable est clôturée",
            "alerte_stock_minimum": "Alerte stock minimum",
            "alerte_enregistrement_intervention_periodique": "Alerte enregistrement intervention périodique",
            "alerte_sotie_equipement": "Alerte sortie équipement",
            "alerte_deplacement_equipement": "Alerte déplacement équipement",
            "role-admin": "Administrateur",
            "role-technicien": "Technicien",
            "role-superviseur": "Superviseur",
            "role-basic": "Demandeur",
            "role-basic-verificateur": "Demandeur vérificateur (ex: Gouvernante)",
            "role-sous-traitant": "Sous-traitant",
            "fonction-technicien": "Technicien",
            "fonction-gouvernante": "Gouvernante",
            "fonction-manager": "Manager",
            "fonction-reception": "Réception",
            "fonction-referent-site": "Référent site",
            "fonction-autre": "Autre",
            "emailing_commentaire_fm": "M'avertir quand un commentaire est ajouté à une fiche curative",
            "emailing_equipements_a_retournes": "M'avertir quand des équipements ne sont pas retournés à temps",
            "is-enabled": "utilisateur actif ?",
            "notify-user-by-email": "Cocher pour transmettre les identifiants par email.",
            "notifications-email-lang-selector": "Langue des notifications emails",
            "emailing_relance_fm": "m'avertir quand une fiche curative est relancée",
            "receive_only_blocking_maintenance_email": "m'avertir uniquement quand une fiche curative est bloquante",
            "receive_only_urgent_maintenance_email": "m'avertir uniquement quand une fiche curative est urgente",
            "emailing_generation_auto_intervention_previsionnel": "m'avertir quand une intervention prévisionnelle est générée",
            "emailing_generation_auto_intervention_previsionnel_anomaly": "m'avertir quand une intervention prévisionnelle est générée avec anomalie",
            "maintenance": "Maintenance curative",
            "fiche-demande-consommable":"Fiche demande consommable",
            "bon-de-commande":"Bon de commande",
            "verification":"Maintenance préventive",
            "intervention":"Intervention",
            "equipement":"Equipement",
            "consommable":"Consommable",
            "contrat":"Contrat",
            "autre":"Autre",
            "restriction-services": "Restreindre aux services",
            "restriction-typologies": "Restreindre aux typologies",
            "tous-services": "Tous les services",
            "tous-typologies": "Toutes les typologies",
            "add": "Ajouter"
        },
        "en": {
            "user-form-cancel": "Cancel",
            "user-form-save": "Save",
            "user-form-update": "Update user",
            "user-form-delete": "Delete",
            "user-form-add": "Create user",
            "user-form-user": "User",
            "user-form-nom": "Last name",
            "user-form-prenom": "First name",
            "user-form-email": "Email",
            "user-form-telephone": "Phone",
            "user-form-role": "Role",
            "user-form-fonction": "Position",
            "user-form-password": "Password",
            "user-form-confirm-suppression": "Are you sure you want to permanently remove this item",
            "user-form-Restriction-lieux": "Restrict to this site",
            "restriction-site": "Site restriction",
            "alerte_contrat_tacite_reconduction": "Alert contract tacit agreement",
            "rapport_hebdomadaire_verification": "Weekly curative report",
            "rapport_hebdomadaire_maintenance": "Weekly curative report",
            "alerte_fin_contrat": "Notify me when upcomming contract's deadline",
            "alerte_echeance_intervention_arrivant_a_terme": "Notify me on upcomming intervention's deadline",
            "emailing_ouverture_fm": "Notify me on curative sheet creation",
            "emailing_fermeture_fm": "Notify me on curative sheet solved",
            "alerte_stock_minimum": "Minimal stock alert",
            "alerte_enregistrement_intervention_periodique": "New periodic intervention alert",
            "alerte_sotie_equipement": "Equipment release alert",
            "alerte_deplacement_equipement": "Equipment movement alert",
            "user-form-tiers": "Third party",
            "role-admin": "Administrator",
            "role-technicien": "Technician",
            "role-superviseur": "Supervisor",
            "role-basic": "Requester",
            "role-chef-equipe": "CE",
            "role-basic-verificateur": "Verifier applicant (ex: Housekeeper)",
            "role-sous-traitant": "Subcontractor",
            "fonction-technicien": "Technician",
            "fonction-gouvernante": "Governess",
            "fonction-manager": "Manager",
            "fonction-reception": "Reception",
            "fonction-referent-site": "Site referrer",
            "fonction-autre": "Others",
            "emailing_commentaire_fm": "Email me on curative sheet new comment",
            "emailing_bc_validation_complete": "Email me on purchase order complete validation",
            "emailing_bc_livraison_complete": "Email me on purchase order complete delivery",
            "emailing_bc_demande_validation": "Email me on purchase order validation request",
            "emailing_ouverture_fdc": "Email me when a consumable request is created",
            "emailing_fermeture_fdc": "Email me when a consumable request is closed",
            "emailing_equipements_a_retournes": "Email me when equipments are not back in time",
            "is-enabled": "Active user ?",
            "notify-user-by-email": "Tick to send an email with credentials.",
            "notifications-email-lang-selector": "Language of email notifications",
            "emailing_relance_fm": "Notify me on curative sheet reviving",
            "receive_only_blocking_maintenance_email": "Notify me only on a blocking curative sheet creation",
            "receive_only_urgent_maintenance_email": "Notify me only on an urgent curative sheet creation",
            "emailing_generation_auto_intervention_previsionnel": "Notify me on automatic generation of preventive intervention",
            "emailing_generation_auto_intervention_previsionnel_anomaly": "Notify me on automatic generation of preventive intervention with anomaly",
            "maintenance": "Curative maintenance",
            "fiche-demande-consommable":"Consumable request",
            "bon-de-commande":"Purchase order",
            "verification":"Preventif maintenance",
            "intervention":"Intervention",
            "equipement":"Equipment",
            "consommable":"Consumable",
            "contrat":"Contract",
            "autre":"Other",
            "voir-uniquement": "See only",
            "tous-services": "All services",
            "tous-typologies": "All typologies",
            "restriction-services": "Restrict to services",
            "restriction-typologies": "Restrict to typologies",
            "add": "Ajouter"
        }
    }
},
    props: {
        /**
            * @model
            */
        value:{
            type: Object,
            default:function(){
                return {};
            }
        },
        /**
            * A list of authorization roles.
            */
        roles:{
            type:Array,
            default:function(){
                return [
                    {label:this.$t("role-admin"),value:"ROLE_ADMIN"},
                    //{label:this.$t("role-superviseur"),value:"ROLE_SUPERVISEUR"},
                    {label:this.$t("role-technicien"),value:"ROLE_TECHNICIEN"},
                    {label:this.$t("role-sous-traitant"),value:"ROLE_SOUS_TRAITANT"},
                    //{label:this.$t("role-chef-equipe"),value:"ROLE_CHEF_EQUIPE"},
                    {label:this.$t("role-basic-verificateur"),value:"ROLE_BASIC_VERIFICATEUR"},
                    {label:this.$t("role-basic"),value:"ROLE_BASIC"},
                ];
            }
        },
        /**
            * Fonctions.
            */
        fonctions:{
            type:Array,
            default:function(){
                return [
                    {label:this.$t("fonction-technicien"),value:"Technicien"},
                    {label:this.$t("fonction-gouvernante"),value:"Gouvernante"},
                    {label:this.$t("fonction-manager"),value:"Manager"},
                    {label:this.$t("fonction-reception"),value:"Réception"},
                    {label:this.$t("fonction-referent-site"),value:"Référent site"},
                    {label:this.$t("fonction-autre"),value:"Autre"}
                ];
            }
        }
    },
    watch:{
      value:{
        handler: function(newvalue){
          console.log("WATCH VALUE-----------------", newvalue);
          this.user = Object.assign(this.user, newvalue);
          this.$forceUpdate();
        },
        deep: true
      }
    },
    data: function() {
        return {
            user:{
                email: "",
                username: "",
                nom: "",
                prenom: "",
                fonction: "",
                roles: "ROLE_BASIC",
                lieux:[],
                notifyByEmail:0,
                tiers_id: null,
                enabled:1,
                parameters: [
                    { name: "rapport_hebdomadaire_maintenance", type: "boolean", defaultValue: "1", entry: "1", groupe: "maintenance", services: [], typologies: []},
                    { name: "rapport_hebdomadaire_verification", type: "boolean", defaultValue: "1", entry: "1", groupe: "verification", services: [], typologies: []},
                    { name: "alerte_fin_contrat", type: "boolean", defaultValue: "1", entry: "1", groupe: "contrat", services: [], typologies: []},
                    { name: "alerte_stock_minimum", type: "boolean", defaultValue: "1", entry: "1", groupe: "consommable", services: [], typologies: []},
                    { name: "alerte_enregistrement_intervention_periodique", type: "boolean", defaultValue: "1", entry: "1", groupe: "intervention", services: [], typologies: []},
                    { name: "alerte_sotie_equipement", type: "boolean", defaultValue: "1", entry: "1", groupe: "equipement", services: [], typologies: []},
                    { name: "alerte_deplacement_equipement", type: "boolean", defaultValue: "1", entry: "1", groupe: "equipement", services: [], typologies: []},
                    { name: "alerte_contrat_tacite_reconduction", type: "boolean", defaultValue: "1", entry: "1", groupe: "contrat", services: [], typologies: []},
                    { name: "emailing_ouverture_fm", type: "boolean", defaultValue: "1", entry: "1", groupe: "maintenance", services: [], typologies: []},
                    { name: "receive_only_blocking_maintenance_email", type: "boolean", defaultValue: "0", entry: "0", groupe: "maintenance", services: [], typologies: []},
                    { name: "receive_only_urgent_maintenance_email", type: "boolean", defaultValue: "0", entry: "0", groupe: "maintenance", services: [], typologies: []},
                    { name: "emailing_fermeture_fm", type: "boolean", defaultValue: "1", entry: "1", groupe: "maintenance", services: [], typologies: []},
                    { name: "emailing_affectation_fm", type: "boolean", defaultValue: "1", entry: "1", groupe: "maintenance", services: [], typologies: []},
                    { name: "emailing_relance_fm", type: "boolean", defaultValue: "0", entry: "0", groupe: "maintenance", services: [], typologies: []},
                    { name: "alerte_echeance_intervention_arrivant_a_terme", type: "boolean", defaultValue: "0", entry: "0", groupe: "intervention", services: [], typologies: []},
                    { name: "emailing_commentaire_fm", type: "boolean", defaultValue: "0", entry: "0", groupe: "maintenance", services: [], typologies: []},
                    { name: "emailing_bc_validation_complete", type: "boolean", defaultValue: "0", entry: "0", groupe: "bon-de-commande", services: [], typologies: []},
                    { name: "emailing_bc_livraison_complete", type: "boolean", defaultValue: "0", entry: "0", groupe: "bon-de-commande", services: [], typologies: []},
                    { name: "emailing_bc_demande_validation", type: "boolean", defaultValue: "1", entry: "1", groupe: "bon-de-commande", services: [], typologies: []},
                    { name: "emailing_ouverture_fdc", type: "boolean", defaultValue: "0", entry: "0", groupe: "fiche-demande-consommable", services: [], typologies: []},
                    { name: "emailing_fermeture_fdc", type: "boolean", defaultValue: "0", entry: "0", groupe: "fiche-demande-consommable", services: [], typologies: []}
                ]
            },
            isModalConfirmDeleteOpened:false,
            isDisabledUi:false,
            isLoading:false,
            showAddServiceIdParent: null,
            showAddTypologieIdParent: null
        };
    },
    computed:{
        ...mapGetters({
            typologiesMaintenance: "TypologiesMaintenanceStore/getCollection",
            services: 'LibelServicesStore/getCollection'
        }),
        lieuxFilters: function(){
            return {
                type_lieu: {attr: "type_lieu", value: "Site", action: "equals"}
            };
        },
        isAnUpdateUserForm:function(){
            return this.value ? this.value.hasOwnProperty('id'):false;
        },
        userLieux: function(){
            return this.user.lieux?this.user.lieux.map((lieu)=>lieu.lieux_id):[];
        },
        parametresNotificationsEmail: function(){
            return ;
        },
        groupedParameters: function(){
            return this.user.parameters.reduce((acc, parameter) => {
                if (!acc[parameter.groupe]) {
                    acc[parameter.groupe] = [];
                }
                acc[parameter.groupe].push(parameter);
                return acc;
            }, {});
        }
    },
    created: function() {
        //console.log("USER", this.value);
        this.user = Object.assign(this.user, this.value);
        let appID = this.$app.appID;
        let idUser = this.$app.idUser;
        this.user.account_id = this.$app["account_id"];
        this.user.appID = appID;
        this.user.parameters = this.user.parameters.filter((parameter)=>parameter.type=="boolean");
        this.user.parameters.forEach((parameter, i) => {
            parameter["appId"] = appID;
            !this.isAnUpdateUserForm ? parameter["user_id"] = idUser : null;
            parameter["entry"] = parseInt(parameter["entry"]);
        });
        if(this.user.id && this.value.lieux){
            this.user.lieux = this.value.lieux.map((lieu)=>{
                return {user_id: this.user.id, lieux_id: lieu.lieux_id};
            });
        }
    },
    methods: {
      isAnExcludedParameter: function(parameterName){
        let excludedNameList = [
          "rapport_hebdomadaire_maintenance",
          "receive_only_blocking_maintenance_email",
          "receive_only_urgent_maintenance_email"
        ];
        return excludedNameList.includes(parameterName);
      },
      onAddService: function(userParameter, service){
        console.log("ADD SERVICE--------------", userParameter, service);
        if(service){
          this.isLoading = true;
          // save userParameter service
          let userParameters = [{
            user_id: this.user.id,
            name: userParameter.name+"_service",
            entry: service,
            appId: this.$app.appID,
            type: "string",
            groupe: userParameter.groupe,
            user_parameter_id: userParameter.id
          }];
          this.UserParametersMixins_create(userParameters).then((datas)=>{
            console.log("AFTER CREATE USER PARAMETERS", datas);
            this.showAddServiceIdParent = null;
            this.isLoading = false;
            this.$emit("refetch-user");
          });
        }
      },
      onAddTypologie: function(userParameter, typologie){
        console.log("ADD TYPOLOGIE--------------", userParameter, typologie);
        if(typologie){
          this.isLoading = true;
          // save userParameter service
          let userParameters = [{
            user_id: this.user.id,
            name: userParameter.name+"_typologie",
            entry: typologie,
            appId: this.$app.appID,
            type: "string",
            groupe: userParameter.groupe,
            user_parameter_id: userParameter.id
          }];
          this.UserParametersMixins_create(userParameters).then((datas)=>{
            console.log("AFTER CREATE USER PARAMETERS", datas);
            this.showAddTypologieIdParent = null;
            this.isLoading = false;
            this.$emit("refetch-user");
          });
        }
      },
      onDeleteUserParemeter: function(userParameter){
        this.isLoading = true;
        this.UserParametersMixins_delete(userParameter).then((userParameter)=>{
          this.isLoading = false;
          this.$emit("refetch-user");
        });
      },
        onSelectedServices: function(parameter, selectedServices){
          this.user.parameters.forEach((p, index)=>{
            if(p.name==parameter.name){
              this.user.parameters[index].services = selectedServices;
            }
          });
        },
        onSelectedTypologies: function(parameter, selectedTypologies){
          this.user.parameters.forEach((p, index)=>{
            if(p.name==parameter.name){
              this.user.parameters[index].typologies = selectedTypologies;
            }
          });
        },
        getDefaultChecked: function(parameter){
          if(this.isAnUpdateUserForm){
            return parameter.entry=='1' || parameter.entry==true || parameter.entry==1?true:false;
          }else{
            return parameter.defaultValue=='1'?true:false;
          }
        },
        /**
        *
        *
        */
        handleChangeLieuxRestriction:function(lieux_id){
            let user_id = this.user.id;
            let restrictionsSite = lieux_id.map((lieu_id) => {
                return {"user_id":user_id,"lieux_id":lieu_id};
            });
            this.user.lieux = restrictionsSite;
        },
        handleUpdateUser:function(){
            if(this.user["lieux"] && this.user["lieux"].length){ 
              this.user["lieux"].forEach((lieu)=>{
                lieu["user_id"] = this.user.id;
              });
            };
            this.UserMixins_update(this.user).then((user)=>{
                this.$emit("updated",user);
                this.$emit("close");
            });
        },
        handleCreateUser:function(){
            this.UserMixins_create(this.user).then((user)=>{
                this.$emit("created",user);
                this.$emit("close");
            });
        },
        handleDeleteUser:function(){
            this.isDisabledUi = true;
            this.UserMixins_delete(this.user).then((user)=>{
                this.$emit("deleted",user);
                this.$emit("close");
                isModalConfirmDeleteOpened = false;
            });
        },
        handleChecked: function(data){
            ////console.log("handleChecked", data);
            this.user.parameters[data.value].entry = 1;
            //this.newFournisseurs.push(this.tiers.find((tier)=>tier.id==data.value));
            this.$forceUpdate();
        },
        handleUnchecked: function(data){
            ////console.log("handleUnchecked", data);
            this.user.parameters[data.value].entry = 0;
            //if(this.newFournisseurs.findIndex((tier)=>tier.id==data.value)!=-1) this.newFournisseurs.splice(this.newFournisseurs.findIndex((tier)=>tier.id==data.value), 1);
            this.$forceUpdate();
        },
        submitForm:function(){
            this.$refs['user-submit'].click();
            this.isDisabledUi = true;
            let form = this.$refs['user-form'];
            if(form.checkValidity()){
                if(this.isAnUpdateUserForm){
                    this.handleUpdateUser();
                    //console.log(this.user);
                }
                else{
                    this.handleCreateUser();
                }
            }else{
                this.isDisabledUi = false;
            }
            return true;

        }
    }
};
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-user-form
    Create|Update|Delete user

    #### basic usage
    Comment here
    ```html static
        <vg-user-form v-if="user || isUserFormDisplayed"
            v-model="user"
            @close="isUserFormDisplayed=false;user=null;"
            @created="isUserFormDisplayed=false;fetch()"
            @updated="isUserFormDisplayed=false;fetch()"
            @deleted="isUserFormDisplayed=false;fetch()"
        >
        </vg-user-form>
    ```
    ### User object
    ```js
    var user = {
      "id": "891",
      "email": "brasseur.sabrina@gmail.com",
      "username": "brasseur.sabrina@gmail.com",
      "nom": "Dohring",
      "prenom": "Sabrina",
      "fonction": "Manager",
      "roles": "ROLE_SUPERVISEUR",
      "account_id": "151",
      "parameters": [
          {
              "id": "891",
              "name": "rapport_hebdomadaire_maintenance",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          },
          {
              "id": "891",
              "name": "rapport_hebdomadaire_verification",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          },
          {
              "id": "891",
              "name": "alerte_fin_contrat",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          },
          {
              "id": "891",
              "name": "alerte_stock_minimum",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          },
          {
              "id": "891",
              "name": "alerte_enregistrement_intervention_periodique",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          },
          {
              "id": "891",
              "name": "alerte_sotie_equipement",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          },
          {
              "id": "891",
              "name": "alerte_deplacement_equipement",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          },
          {
              "id": "891",
              "name": "alerte_contrat_tacite_reconduction",
              "type": "boolean",
              "defaultValue": "1",
              "entry": "1",
              "appId": "A7X1zGO9nK",
              "user_id": "891"
          }
      ]
    }
    ```
</docs>
