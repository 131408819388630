<template>
	<div class="vg-tiers-table">
		<tag-grid
			:idTableau="'tiers-tableau'"
			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="tiers"
			:isModalShowHideColumnsOpened="TagGridMixins.isModalShowHideColumnsOpened"
			:isExportXLS="TagGridMixins.isExportXLS"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
			@grid-feeded="setDefaultFilter()"
			@on-grid-ready="setDefaultFilter()"
			@ag-dbl-click="onDoubleClickRow"
			@ag-click="onClickRow">
		</tag-grid>
	</div>
</template>
<script>
	import TagGrid from 'src/components/Grid/TagGrid.vue';
	import TagGridMixins from 'src/mixins/TagGridMixins.js';
	import TiersMixins from 'src/mixins/TiersMixins.js';

	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import TiersMobileCellRender from "src/components/Vg/TagGrid/TiersMobileCellRender.vue";
	import TagCellRender from "src/components/Vg/TagGrid/TagCellRender.vue";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-tiers-table',
		components: {
			TagGrid,
			IconNameCellRender,
			TiersMobileCellRender,
			TagCellRender
		},
		mixins: [ TagGridMixins, TiersMixins ],
        props: {
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return ["id", "libel_equipement"];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* affiche le tableau en mode impression
			* default false
			*/
			showPrint : {
				type: Boolean,
				default: false
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			}
        },
        data: function() {
            return {
				showLoadingOverlay: false,
				metadatas: new Metadatas(),
				gridOptions: {},
				columnDefs: [
					{ field: "id", headerName:this.$t("header-id"), minWidth: 90,hide:true, cellClass: ["vg-cell-ballam-theme","vg-font-size-20", "vg-font-weight-bold"] },
					{ field: "name", headerName:this.$t("header-nom"), minWidth: 180, cellClass: ["vg-cell-ballam-theme"],
	                cellRenderer: (params) => new IconNameCellRender({propsData: {dataColumn: params.data.name, icon: "static/assets/icone/circle/tiers-cercle.png"}}).$mount().$el

	                },
					{ field: "type", headerName:this.$t("header-type"), minWidth: 180, cellClass: ["vg-cell-ballam-theme"], },
					{ field: "tags", headerName:this.$t("header-tags"), minWidth: 200, cellClass: [" vg-padding-3"],
	                    cellRenderer: (params) => new TagCellRender({propsData: {tags: params.data.tags}}).$mount().$el
					},
					{ field: "address", headerName : this.$t("header-adresse"), minWidth: 180, cellClass: ["vg-cell-ballam-theme"],},
					{ field: "contractor",cellClass: ["vg-cell-ballam-theme"], hide: true, isVisible: false},
					{ field: "defaultContractor",cellClass: ["vg-cell-ballam-theme"], hide: true, isVisible: false},
					{ field: "rate", headerName : this.$t("zoomTiers-details-label-appreciation"), hide: true,cellClass: ["vg-cell-ballam-theme"], isVisible: false}
				]
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
		"overlay-loading-text": "Chargement des tiers...",
		"overlay-no-rows-text": "Aucun tiers correspondant au filtrage",
		"header-id": "ID",
        "header-nom": "Nom",
        "header-type": "Type",
        "header-tags": "Tags",
        "header-adresse": "Adresse"
    },
	"en": {
		"overlay-loading-text": "Loading suppliers...",
		"overlay-no-rows-text": "No supplier matching filtering",
		"header-id": "ID",
        "header-nom": "Name",
        "header-type": "Type",
        "header-tags": "Tags",
        "header-adresse": "Address"
	},
	"th":{
		"header-id": "ID",
        "header-nom": "ชื่อ",
        "header-type": "ประเภท",
        "header-tags": "แท็ก",
        "header-adresse": "ที่อยู่"
	}
    }

},
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
			filters: {
				handler: function(newfilters){
					this.fetch();
				},
				deep: true
			},
			localFilters: {
				handler: function(newfilters){
					//console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
					if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
				},
				deep: true
			}
		},
		created: function(){
			if(this.$vgutils.isMobile()){
                this.columnDefs.map((columnDef)=>{
                    columnDef.hide = true;
                })
                this.columnDefs.unshift({
					headerName: "",
					field:"",
					cellClass: ["vg-cell-without-padding"],
                    cellRenderer: (params) => new TiersMobileCellRender({propsData: {tiers: params.data}}).$mount().$el
					//cellRenderer: (params) => this.$vgutils.formatFicheMaintenanceCell(params.data)
				});
            }
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			/**
			* column is hidden
			* @param String colId
			* @return Boolean
			*/
			columnIsHidden: function(colId){
				return this.hiddenColumns.indexOf(colId)!=-1;
			},
			/**
			* column is pinned to left
			* @param String colId
			* @return Boolean
			*/
			columnIsPinnedToLeft: function(colId){
				return this.pinnedColumns.indexOf(colId)!=-1;
			},
			/**
			* column is not showable
			* @param String colId
			* @return Boolean
			*/
			columnIsNotShowable: function(colId){
				return !(this.notShowableColumns.indexOf(colId)!=-1);
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				this.metadatas.setFilters(this.filters);
				this.TiersMixins_get(this.metadatas).then((datas)=>{
					//console.log("TIERS", datas);
					this.showLoadingOverlay = false;
					this.$emit("fetch-success", {equipements: datas});
				});
			},
			setDefaultFilter: function(){
				if(this.showPrint) this.gridOptions.domLayout= "forPrint";
				this.$emit("grid-feeded", this.gridOptions);
			},
			goToZoom: function(row){
                if(this.can("TIER.PAGE")) this.$router.push({name:"_tiers_id",params:{ id: row.data.uid }})
            },
			onClickRow: function(e){
				this.$emit("row-click", e.data);
				if(this.$vgutils.isMobile()) this.goToZoom(e);
			},
			onDoubleClickRow: function(row){
                this.goToZoom(row)
				//this.$emit("row-dbl-click", row);
			},
            handleMultipleSelectionRowChanged: function(gridOptions){
				var selectedNodes = gridOptions.api.getSelectedNodes();
                let equipements = selectedNodes.map((node)=>{
                    return node.data;
                });
                this.$store.dispatch("TiersStore/selectItems", equipements);
			},
		},
		computed: {
			...mapGetters({
              	tiers: 'TiersStore/getCollection'
            })
		}
    };

</script>
<style lang="scss" scoped>
.vg-tiers-table{
	height: 100%;
}
</style>
