<template>
    <div id='historique-grandeurs-physiques' class="full-height">
        <vg-app-layout v-show="!$vgutils.isMobile() && !isPieceTargeted "
    		:title="$t('titre')"
            :icon="'Entite/historique-grandeurs-physiques.png'"
            class="hidden-print"
            :activeFilters="activeFilters"
            @action-export="actionExport">
            <template v-slot:action-button-item >
                <li @click="showModalRapport=true;">{{$t("menu-more-rapport")}}</li>
            </template>
            <template v-slot:header-bottom-left >
                <vg-tabs
                    v-if="releves"
                    :tabs="tabs"
                    :color="'gris'"
                    @tabClicked="onTabClicked">
                </vg-tabs>
            </template>

            <template v-slot:search>
                <vg-text-filter
                    v-model="searchQuery">
                </vg-text-filter>
            </template>

            <template v-slot:primary>
                <tag-grid
                    :idTableau="'eqp-grandeurs_physiques-filters'"
                    :filters="getFilters"
                    v-model="gridOptions"
                    :columnDefs="columnDefs"
                    :rowData="releves"
        			:showLoadingOverlay="showLoadingOverlay"
        			:overlayLoadingText="$t('overlay-loading-text')"
        			:overlayNoRowsText="$t('overlay-no-rows-text')"
                    @grid-feeded="setDefaultFilter()"
                    @row-value-changed="onRowValueChanged" />
            </template>

            <template #secondary-filters>
                <vg-filter-date-intervalle :title="$t('filter-by-date')"
                    :defaultStartDate="agfilters.dateVerif.value[0]"
                    :defaultEndDate="agfilters.dateVerif.value[1]"
                    @change="handleChangeDateIntervalle"/>
                <vg-filter-site
                    v-model="agfilters.path.value" />
            </template>
            <template #secondary-widget>
                <vg-tree-lieux
                    v-model="agfilters.path.value"/>
            </template>

        </vg-app-layout>
        <fm-dialog-notif
                v-if="selectedReponse && displayDialogNotifier"
                :msg="'confirmation '+selectedReponse.question+' ?'"
                @fm-dialog-notifier-success="handleUpdateReponse"
                @close="displayDialogNotifier = false"
            >
          <div >
            <!--div>{{ propsData.div }}<div-->
            <div class="vg-margin-bottom-10">
                {{ selectedReponse.libel_equipement }} <br> <span v-html="$vgutils.formatPathCell(selectedReponse.path)" ></span>
            </div>
            <table class="table">
                <thead>
                    <th>Minimum</th>
                    <th>Valeur</th>
                    <th>Maximum</th>
                </thead>
                <tbody>
                    <tr>
                        <td><h3><small>{{ selectedReponse.minVal }}</small></h3></td>
                        <td><h3>{{selectedReponse.reponse}}</h3></td>
                        <td><h3><small>{{ selectedReponse.maxVal }}</small></h3> </td>
                    </tr>
                </tbody>

            </table><br>


          </div>
        </fm-dialog-notif>
        <vg-modal
            :width="'100%'"
            :height="'100%'"
            v-if="showModalRapport"
            @close="showModalRapport = false ">
            <template v-slot:body>
                <vg-reporting-prints
                    :data="filteredCompteur"
                    :idTableau="'grandeur-physique'"
                    :columnDefs="columnDefVisible"
                    :allTitle="$t('title-rapport')">
                </vg-reporting-prints>

            </template>


        </vg-modal>
    </div>
</template>

<script>


    
    import TagGridMixins from 'src/mixins/TagGridMixins.js';
    import ReponsesMixins from 'src/mixins/ReponsesMixins.js';
    import TagGrid from 'src/components/Grid/TagGrid.vue';
    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import fmDialogNotifier from 'src/views/Verification/Historique/fmDialogNotifier.vue';
    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgCollapse from 'src/components/Vg/VgCollapse.vue';
    import VgGroup from "src/components/Vg/VgGroup.vue";
	import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import VgFilterDateIntervalle from "src/components/Vg/Filters/VgFilterDateIntervalle.vue";
    import VgReportingPrints from 'src/components/Vg/TagGrid/VgReportingPrints.vue';

    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";

    import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
    import DatetimeCellRender from 'src/components/Vg/TagGrid/DatetimeCellRender.vue';
    import ThumbnailCellRender from 'src/components/Vg/TagGrid/ThumbnailCellRender.vue';

    export default {
        name: 'historiqueGrandeursPhysiques',
        i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des grandeurs physique...",
		"overlay-no-rows-text": "Aucun grandeur physique correspondant au filtrage",
        "titre": "Historique relevés grandeurs physiques",
        "date": "Date",
        "pointVerif": "Point de verification",
        "min": "Min",
        "valIndex": "Valeur",
        "max": "Maxi",
        "operateur": "Operateur",
        "path": "Emplacement",
        "equipement": "Equipement",
        "column-header-photo": "Photo",
        "filtre-title": "Filtres",
        "filtre-arborescence": "Arborescence",
        "collapse-filter": "Filtres complémentaires",
        "filter-tache": "Tache de vérification",
        "filter-by-date": "Filtre par date relevé",
        "tab-toutes": "toutes",
        "site": "Site",
        "batiment": "Batiment",
        "etage": "Etage",
        "piece": "piece",
        "menu-more-rapport": "Creer un rapport",
        "collapse-filter-lieux": "Filtrage par lieux",
        "title-rapport": "Rapport grandeurs physiques"
    },
    "en": {
        "overlay-loading-text": "Loading physical values...",
		"overlay-no-rows-text": "No physical value matching filtering",
        "titre": "Physical values history",
        "date": "Date",
        "pointVerif": "Checkpoint",
        "min": "Min",
        "valIndex": "Value",
        "max": "Max",
        "tab-toutes": "All",
        "site": "Site",
        "batiment": "Block",
        "etage": "Level",
        "piece": "Room",
        "filter-by-date": "Filter by date",
        "menu-more-rapport": "Create a Report",
        "collapse-filter-lieux": "Filtering by location",
        "title-rapport": "Report",
        "operateur": "Operator",
        "path": "Path",
        "equipement": "Equipment",
        "column-header-photo": "Photo",
        "filtre-title": "Filters",
        "filtre-arborescence": "Tree view",
        "collapse-filter": "Extra filters",
        "filter-tache": "Verification tasks"
    },
    "th": {
        "date": "วันที่",
        "pointVerif": "จุดตรวจสอบ",
        "min": "ต่ำสุด",
        "valIndex": "มูลค่าดัชนี",
        "max": "สูงสุด",
        "tab-toutes": "ทั้งหมด",
        "site": "หน้างาน",
        "batiment": "บล็อก",
        "etage": "ระดับ",
        "piece": "ห้อง",
        "menu-more-rapport": "สร้างรายงาน",
        "collapse-filter-lieux": "การกรองตามที่ตั้ง",
        "title-rapport": "รายงาน",
        "operateur": "ผู้ปฏิบัติการ",
        "path": "ทางเดิน",
        "equipement": "อุปกรณ์",
        "column-header-photo": "รูปภาพ",
        "filtre-title": "ตัวกรอง",
        "filtre-arborescence": "มุมมองต้นไม้",
        "collapse-filter": "ตัวกรองพิเศษ",
        "filter-tache": "ภารกิจการตรวจสอบ"
        }
    }
},
        mixins:[ TagGridMixins, ReponsesMixins],
        components: {

            TagGrid,
            VgTabs,
            "fm-dialog-notif":fmDialogNotifier,
			VgTextFilter,
            VgFilterSite,
            VgCollapse,
            VgGroup,
            VgFilterDateIntervalle,
            VgReportingPrints,
            PathCellRender,
            DatetimeCellRender,
            ThumbnailCellRender,

            VgTreeLieux
        },
        data : function () {
            return {
                showLoadingOverlay: false,
                headerDatas: {
                    title: this.$t("titre"),
                    imgvisible: false,
                    buttonvisible: false,
                    noBorder: true
                },
                selectedEquipment:{},
                gridOptions: {
            	    editType: 'fullRow'
            	},
            	columnDefs: [
                    {
                        headerName: this.$t('site'),
                        field: 'path',
                        colId:'paths',
                        tooltipField: 'path',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[1];
                        }

                    },
                    {
                        headerName: this.$t('batiment'),
                        field: 'path',
                        colId:'pathb',
                        tooltipField: 'path',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[2];
                        }

                    },
                    {
                        headerName: this.$t('etage'),
                        field: 'path',
                        colId:'pathe',
                        tooltipField: 'path',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[3];
                        }

                    },
                    {
                        headerName: this.$t('piece'),
                        field: 'path',
                        colId:'pathp',
                        tooltipField: 'path',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[4];
                        }

                    },
                    {
            			headerName:this.$t("date"),
            			field: "date_reponse",
            			colId: "date_reponse",
            			getQuickFilterText: function(params) {
            				return this.$vgutils.getDate(params.data.date_reponse);
            			},
            			cellClass: ["vg-cell-ballam-theme"],
            			width: 180, /*headerComponent: headerRenderer,*/
            			cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.date_reponse}}).$mount().$el
            		},
            		{
            			headerName:this.$t("equipement"),
            			field: "libel_equipement",
                        colId:"libel_equipement",
                        tooltipField: 'libel_equipement',
                        width: 360,
            			cellClass: "vg-cell-ballam-theme"
            		},
            		{
            			headerName:this.$t("path"),
            			field: "path",
                        colId:"path",
                        tooltipField: 'path',
                        width: 180,
            			cellClass: "vg-cell-ballam-theme",
                        cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path}}).$mount().$el,
            		},
            		{
            			headerName:this.$t("pointVerif"),
            			field: "question",
            			cellClass: ["vg-cell-ballam-theme"],
            			width: 300,
            			cellRenderer: (params) => {
            				var container = document.createElement('div');
            				container.innerHTML = '' +
            				"<div style='height: 100%; width: 230px; text-align: left;'>"+
            				"<span style='margin-right: 10px;'><i class='fa fa-check' style='font-size: 20px;'></i></span>"+
            				"<span style='position: relative; top: -2px;'>"+ params.data.question +"</span>"+
            				"</div>";
            				return container;
            			}
            		},
            		{
            			headerName:this.$t("min"),
            			field: "minVal",
            			width: 180,
            			cellClass: ["vg-cell-ballam-theme"]
            		},
            		{
            			headerName:this.$t("valIndex"),
            			field: "reponse",
            			cellClass: "vg-cell-ballam-theme vg-important-cell",
            			width: 180
            		},
            		{
            			headerName:this.$t("max"),
            			field: "maxVal",
            			width: 180,
            			cellClass: ["vg-cell-ballam-theme"]
            		},
            		{
            			field: "equipement_id" ,
            			headerName: "equipement_id",
            			hide:true,
            			filter:'agTextColumnFilter',
            			cellClass: ["vg-cell-ballam-theme"],
            			isColumnShowable:true,
            		},
            		{
            			field: "username" ,
            			headerName: this.$t("operateur"),
            			filter:'agTextColumnFilter',
            			cellClass: ["vg-cell-ballam-theme"]
            		},
                    {
    					headerName: this.$t("column-header-photo"),
    					field: "photos",
    					colId: "photos",
    					cellRenderer: (params) => new ThumbnailCellRender({propsData: {params: params, attributeName: "photos", attributeUid: "equipementTache_uid"}}).$mount().$el
    				}
            	],
            	releves: null,
                showModalRapport:false,
            	equipementsgrandeurphysique: [],
                displayDialogNotifier:false,
                fmSend:{id:1},
                msgSend:"Message Header",
                actionSend:{},
                selectedReponse:null,
                agfilters: {
                    "path": {"attr":"path", "colId": "path", "value": null, "action":"contains"},
                    "type_tache":{"attr":"t.type_tache","colId":"type_tache", "value":"Relever_Grandeur_physique","action":"equals"},
                    "dateVerif": {"attr":"dateVerif", "colId": "dateVerif", "value": [moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().add(1,'day').format("YYYY-MM-DD")], "action":"between"}
                    ///"libel_tache": {"attr":"libel_tache", "colId": "libel_tache", "value": null, "action":"contains"},
                    //"libel_equipement": {"attr":"libel_equipement", "colId": "libel_equipement", "value": null, "action":"contains"},
                    //"qrCode": {"attr":"qrCode", "colId": "qrCode", "value": null, "action":"contains"},
                    //"username": {"attr":"username", "colId": "username", "value": null, "action":"contains"}

                },
            	searchQuery: ''
            }
        },
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
            agfilters: {
                handler: function(nfilters) {
                    ////console.log("WATCH AG FILTERS", nfilters);
                    const agfilters = Object.values(nfilters);
                    this.TagGridMixins_setFilters(this.gridOptions, agfilters);
                    this.fetch([], this.getFilters);
                },
                deep: true
            }
		},
        methods: {
            actionExport: function(event){
                window.dispatchEvent(new CustomEvent(event.name, {
                    "detail": {"idTableau":"eqp-grandeurs_physiques-filters"}
                }));
            },
            setDefaultFilter: function(){
            	this.TagGridMixins_setFilters(this.gridOptions, this.tabs.length!=0?this.tabs[0].filters: []);
            },
            onTabClicked: function(e){
            	// //console.log("onTabClicked", e);
            	this.TagGridMixins_setFilters(this.gridOptions, e.filters);
            },
            fetch: function(directives, filters){
                this.showLoadingOverlay = true;
                this.ReponsesMixins_getReponses(filters, directives).then((releves)=>{
                    // //console.log(releves);
                    this.getEquipementsGrandeurPhysique(releves);
                    this.ReponsesMixins_setConsoJournaliere(releves);
                    this.releves = releves;
                    this.showLoadingOverlay = false;
                });
            },
            getEquipementsGrandeurPhysique: function(releves){
                var compteurs = [];
                //var releves = this.releves;
                releves.forEach(function(releve){
                	if( compteurs.findIndex(function(compteur){
                		return releve.equipement_id==compteur.equipement_id;
                	}) == -1){
                		compteurs.push(releve);
                	}
                });
                this.equipementsgrandeurphysique = compteurs;
            },
            onRowValueChanged: function(event){
                // //console.log(event.data);
                // //console.log("event");
                this.selectedReponse = event.data;
                this.displayDialogNotifier = true;
            },
            handlefilterByPath:function(lieu){
                this.agfilters.path.value = lieu.attrs.path;
            },
            handleUpdateReponse:function(){
                var reponse = {
                    "datas": {
                        "id":this.selectedReponse.idReponse,
                        "reponse":this.selectedReponse.reponse
                    }
                }
                this.ReponsesMixins_updateReponse(reponse);
            },
            handleChangeDateIntervalle: function(data){
                this.agfilters.dateVerif.value = [data.startDate, data.endDate];
            },
        },
        created: function () {
            var that = this;
            var directives = [{"name":"LIMIT","offset":0,"limit":1000}];
            this.fetch(directives, this.getFilters);
        },
        computed:{
            activeFilters: function(){
                let filtersNames = ["path", "dateVerif"];
                let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
                return activeFilters.length;
            },
			counters: function(){
				var counters = {};
				var compteurs = this.releves;
				compteurs.forEach(function(compteur){
					if(!counters[compteur.equipement_id]) counters[compteur.equipement_id] = 0;
					counters[compteur.equipement_id]++;
				});
				return counters;
			},
			tabs: function(){
				var counters = this.counters;
				var tabs = [];
				var compteurs = this.equipementsgrandeurphysique;
                tabs.push({
                    label:this.$t("tab-toutes"),
                    name:this.$t("tab-toutes"),
                    counter: this.releves.length,
                    filters:[{colId: "equipement_id", value: null}]
                });
				this.equipementsgrandeurphysique.forEach(function(compteur){
					tabs.push({
						label: compteur.libel_equipement,
						name: compteur.libel_equipement,
						counter: counters[compteur.equipement_id],
						filters:[{colId: "equipement_id", value: compteur.equipement_id}]
					});
				});
				return tabs;
			},
            getFilters: function(){
                return Object.values(this.agfilters);
            },
            filteredCompteur:function(){
                return this.gridOptions.api.getModel().rowsToDisplay.map((row)=>row.data);
            },
            columnDefVisible:function(){
                return this.TagGridMixins_columnVisibleTagGrid(this.gridOptions.api);
            }
        }
    }
</script>

<style lang="scss" scoped>
    // $properties
    // filters panel
    $filtersPanelHeader: 60px;

    #historique-grandeurs-physiques {
        #historique-grandeurs-physiques__tabs-container {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            padding-left: 20px;
            // &::-webkit-scrollbar {
            //     display: none;
            // }
            .tab {
                border: 1px solid gray;
                border-radius: 6px 6px 0px 0px;
                margin-right: 1px;
                min-width: fit-content;
                padding: 10px;
                &:last-child {
                    margin-right: inherit;
                }
                &.active {
                    background-color: #FF6927;
                    border: none;
                    color: white;
                    font-weight: bold;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        #filters-panel {
            border: 1px solid gray;
            height: calc(100vh - 203px);
            #filters-panel__header {
                background-color: #F2F2F2;
                border-bottom: 1px solid gray;
                color: #009eec;
                height: $filtersPanelHeader;
            }
            #filters-panel__body {
                height: calc(100% - #{$filtersPanelHeader});
                overflow: auto;
            }
        }
        #grid-container {
            height: calc(100vh - 203px);
            padding-left: 20px;
        }
    }
</style>
