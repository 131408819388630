<template>
	<vg-modal
		:title="$t('title', {numero: intervention.numeroIntervention})"
		@close="$emit('close')">
		<template #body>
			<vg-input :title="$t('upload-document')" v-if="VgFilesMixins.tags">
				<!-- uploader documents -->
				<vg-files-uploader
					:tagsList="VgFilesMixins.tags"
					@upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;isAddDocuments=false;$emit('after-upload-document', intervention);" />
				<hr>
				<vg-files-viewer-list-section
					:files="VgFilesMixins_filesListSection" />
			</vg-input>
		</template>
		<template #footer>
			<vg-button type="default-info"
			   @click="$emit('close')">
			   {{$t("cancel")}}
			</vg-button>
		</template>
	</vg-modal>
</template>
<script>
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
    import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";

    import Metadatas from "src/services/Metadatas.js";

    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-intervention-documents-modal',
    	props: {
			/**
			* @model intervention
			*/
			value: {
				type: Object
			}
        },
		mixins: [
			
		],
		components: {
			VgInput,
			VgButton,
			VgCheckbox,
			VgFilesUploader,
			VgFilesViewerListSection
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Ajouter un document à l'intervention #IE - {numero}",
			"cancel": "Fermer",
			"upload-document": "Ajouter un document"
        },
		"en": {
			"title": "Add a document to intervention #IE - {numero}",
			"cancel": "Close"
		}
    }
},
        data: function(){
            return {
				intervention: this.value
            };
        },
		watch: {
			
		},
		created: function(){
			this.VgFilesMixins_init(this.intervention.uid, "intervention");
		},
		mounted: function(){
			
		},
		methods: {
			
		},
        computed:{
			...mapGetters({
	            
	        }),
        }
    };
</script>
<style lang="scss" scoped>
.equipements-list{
	.equipement-row{
		.equipement{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
		}
	}
}
</style>