<template>
    <div v-if="params.data.nbDocuments && params.data.nbDocuments>0" class="intervention-nb-documents-cell-render">
        <i class="far fa-file existed-documents" ></i> x{{ params.data.nbDocuments }}
    </div>
    <div v-else-if="params.data.status=='intervention-realisee'" class="intervention-nb-documents-cell-render">
        <i class="far fa-file no-documents"></i> <span style="color: red;font-size:10px;">{{ $t("documents-manquants") }}</span>
        <vg-button type="default-danger" size="sm" :key="'btn-upload-documents-'+params.data.id" 
            @click="$emit('open-documents', params.data)"><i class="fas fa-upload"></i></vg-button>
    </div>
    <div v-else class="intervention-nb-documents-cell-render">
        
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    import VgButton from "src/components/Vg/VgButton.vue";
    export default Vue.extend({
        name: "intervention-nb-documents-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true
			}
		},
        components: {
            VgButton
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "documents-manquants": "Documents manquants"
        },
        "en": {
            "documents-manquants": "Missing documents"
        }
    }
},
        computed:{
            
        }
    }
);
</script>

<style lang="scss" scoped>
.intervention-nb-documents-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}
.existed-documents {
    color: #35b1EA;
    cursor: pointer;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.no-documents {
    color: red;
    cursor: pointer;
    display: inline-block;
    animation: pulse 1.5s infinite;
}
</style>