<template>
    <div>
        <div style="display: flex;justify-content: flex-start;gap:10px;align-items: center;">
            <span>{{ $t("numero") }} <b>{{ params.data.numero }}</b></span>
            <vg-button type="info" size="sm" @click="openFile">{{ $t("voir") }}</vg-button>
        </div>
        <div style="display: flex;justify-content: space-between;gap:10px;align-items: center;">
            <span>{{ $t("demande-par") }} <b>{{ params.data.ficheDemandeConsommable && params.data.ficheDemandeConsommable.nomDemandeur?params.data.ficheDemandeConsommable.nomDemandeur:params.data.createur.nom+" "+params.data.createur.prenom }}</b> {{ $t("date-le") }} <vg-datetime-viewer v-model="params.data.dateCreation" hideTime/></span>
            <vg-button v-if="!params.data.signataire" type="success" @click="$emit('open-signer', params.data)">{{ $t("signer") }}</vg-button>
        </div>
        <div v-if="params.data.ficheDemandeConsommable" style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
            <span>{{ $t("site") }}: <b>{{ params.data.ficheDemandeConsommable.site.libel_lieu }}</b> - {{ $t("service") }}: <b>{{ params.data.ficheDemandeConsommable.serviceDemandeur }}</b></span>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "bon-de-sortie-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isAutomaticOpenLink:{
                type: Boolean,
                default: true
            }
		},
        components:{
            VgOperationFlag,
            VgDatetimeViewer,
            VgButton
        },
        data: function() {
            return {
                showSignature: false
			};
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "numero": "Numéro",
            "voir": "Voir",
            "demande-par": "Demandé par",
            "date-le": "le",
            "site": "Site",
            "service": "Service",
            "signer": "Signer"
        },
        "en": {
            "numero": "Number",
            "voir": "Show",
            "demande-par": "Requested by",
            "date-le": "on",
            "site": "Site",
            "service": "Service",
            "signer": "Sign"
        }
    }
},
        methods:{
            onClickMaintenance: function(){
                this.$emit("go-to-maintenance",this.params.data.operation.ficheSav_id);
            },
            openFile: function(){
				if(this.params.data.document) this.VgFilesMixins_getDownloadFileLink(this.params.data.document).then((link)=>
                    this.isAutomaticOpenLink ? window.open(link, '_blank') : this.$emit("click-link", link)
                );
			},
            openFDC: function(){
                this.$emit("go-to-fdc",this.params.data.ficheDemandeConsommable_id);
            }
        }
    });
</script>

<style lang="scss" scoped>
.link{
    font-weight: bold;
    color:#3999ff;
}
.link:hover{
    cursor: pointer;
    text-decoration: underline dotted;
}
</style>