<template>
    <div class="tag-grid" :style="getDefaultHeight">
        <vg-modal v-show="showToggleAgGridColModal"
            :title="$t('modal-gestion-colonne-title')"
            @close="handleCloseAgGridColModal">
            <template #body>
                <div v-for="column in localeGridOptions.columnDefs">
                    <vg-checkbox v-if="!column.isColumnShowable && (column.isVisible || column.isVisible==undefined)"
                        :label="column.headerName"
                        :inputValue="!column.hide"
                        :defaultChecked="!column.hide"
                        @checked="showColumn(column)"
                        @unchecked="hideColumn(column)" />
                </div>
            </template>
            <template #footer>
                <vg-button @click="handleCloseAgGridColModal">{{$t("close")}}</vg-button>
            </template>
        </vg-modal>

        <div :id="getIdTableau" :ref="getIdTableau" :class="['ag-grid',{'ag-theme-balham':!$vgutils.isMobile()}]" ></div>
    </div>
</template>

<script>
//class="ag-material"

// var agGrid = require('ag-grid');

var agGrid = require("ag-grid-community");
/*var enterprise = require("ag-grid-enterprise");
agGrid = Object.assign(agGrid,enterprise);*/
//enterprise.LicenseManager.setLicenseKey("Evaluation_License-_Not_For_Production_Valid_Until_25_September_2019__MTU2OTM2NjAwMDAwMA==806589aafb83be498eba6b4bfd1789ec");
// //console.log(agGrid,enterprise);
import TagGridExceptions from 'src/Exceptions/components/Grid/TagGridExceptions.js';
import Moment from 'moment';

import VGCellRender from 'src/services/VGCellRender.js';
import TagGridMixins from "src/mixins/TagGridMixins.js";


import VgButton from "src/components/Vg/VgButton.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";

// import 'root/node_modules/ag-grid/dist/styles/theme-material.css'; // à charger depuis index.html

//import TestCell from "src/components/Cells/TestCell.vue";

export default {
    name: 'tag-grid',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-gestion-colonne-title": "Gestion des colonnes",
            "close": "Fermer",
            "site": "Site",
            "batiment": "Batiment",
            "etage": "Etage",
            "piece": "Pièce",
            "piece-generique": "Pièce générique",
            "batiment-generique": "Batiment générique",
            "etage-generique": "Etage générique"
        },
        "en": {
            "modal-gestion-colonne-title": "Show/hide columns",
            "close": "Close",
            "site": "Site",
            "batiment": "Block",
            "etage": "Level",
            "piece": "Room",
            "piece-generique": "Generic room",
            "batiment-generique": "Generic block",
            "etage-generique": "Generic level"
        },
        "th": {
            "modal-gestion-colonne-title": "แสดง/ซ่อนคอลัมน์"
        }
    }
} ,
	mixins: [TagGridMixins],
    props: {
        idTableau : String,
        filters: Array,
		ismobile: Boolean,	// useless
        defaultHeight: {
          type: String,
          default: "90%"
        },
        /**
         * Pour la fonction de diviser la colonne Path en site/bat/eta/piece
         * Si le path n'est pas la clé par default mais fm.equipement.path . mettre "fm.equipement.path"
         */
        pathSplitKey:{
            type:String,
            default:"path"
        },
		value: Object,
		columnDefs: {
			type: Array,
			default: [],
			required: true
		},
		rowData: {
			type: Array,
			required: true
		},
        isModalShowHideColumnsOpened: {
            type: Boolean,
            default: false
        },
        isExportXLS: {
            type: Boolean,
            default: false
        },
        exportFileName: {
            type: String,
            default: null
        },
        /**
        * @see https://stackoverflow.com/questions/49774251/ag-grid-cellrendering-with-deltarowdatamode
        */
        deltaRowDataMode:{
            type:Boolean,
            handler:function(){
                return false;
            }
        },
        showLoadingOverlay: {
            type: Boolean,
            default: false
        },
        overlayLoadingText: {
            type: String,
            default: "Chargement des données..."
        },
        overlayNoRowsText: {
            type: String,
            default: "Aucun élément correspondant au filtrage"
        }
    },
	components: {


        VgButton,
		VgCheckbox
	},
    data: function() {
        return {
            showToggleAgGridColModal: this.isModalShowHideColumnsOpened,
            localeGridOptions: null,
            agGridInstance:null,
			options: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?108:40,
				rowData: this.rowData,
                pagination:false,
				rowSelection: 'multiple',
                defaultColDef:{
                    sortable:true,
                    resizable:true,
                    filter:false,
                    // enableRowGroup:false ag-grid entreprise
                },
				enableRangeSelection: this.$vgutils.isMobile()?false:true,
				suppressRowClickSelection: this.$vgutils.isMobile()?false:true,
				suppressCellSelection: true,
                enableBrowserTooltips:true,
				animateRows: false,
				cacheQuickFilter: true,
				debug: false,
                /*sideBar: {
                    toolPanels: [{
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressPivots: true,
                            suppressPivotMode: true,
                        }
                    }],
                    defaultToolPanel: 'columns'
                },*/
                // domLayout: "forPrint",
				localeText: {
					equals: 'Egal à',
					notEqual: 'Différent de',
					lessThan: 'Inférieur à',
					greaterThan: 'Supérieur à',
					contains: 'Contient',
					notContains: 'Ne contient pas',
					startsWith: 'Commence par',
					endsWith: 'Se termine par',
					filterOoo: 'Valeur ...',
					more: 'plusieurs',
					to: 'à',
					of: 'sur',
					next: 'Suivant',
					last: 'Dernier',
					first: 'Premier',
					previous: 'Précédent',
					loadingOoo: 'Chargement ...'
				},
				components: Object.assign(
					VGCellRender.init()
				),
				columnDefs: this.columnDefs,
                overlayLoadingTemplate:'<span class="overlay-loading-template">'+this.overlayLoadingText+'</span>',
                overlayNoRowsTemplate:'<span class="overlay-no-rows-template">'+this.overlayNoRowsText+'</span>'
			}
        };
    },
    computed:{
        getDefaultHeight:function(){
            if(this.$vgutils.isMobile()){
                return "height:95%;";
            }else{
                return "height:"+this.defaultHeight;
            }

        },
        getIdTableau:function(){
            let idTableau = null;
            if(this.$vgutils.isMobile()){
                idTableau = this.idTableau + "-mobile";
            }else{
                idTableau = this.idTableau;
            }
            return idTableau;
        }

    },
    methods: {
        handleCloseAgGridColModal: function(){
            this.showToggleAgGridColModal = false;
            this.$emit("modal-show-hide-columns-closed", true);
        },
        exportXLS: function(datas){
            let excludeCellrenderers = datas.detail.excludeCellrenderers ? datas.detail.excludeCellrenderers : [];
            if (datas.detail.idTableau == this.getIdTableau) {
                let filename = this.exportFileName?this.exportFileName: this.getIdTableau;
                var params = {
                    allColumns: false,
                    onlySelected: false,
                    suppressQuotes: false,
                    fileName: filename,
                    columnSeparator: ',',
                    processCellCallback: function(cell) {
                        let node = cell.node;
                        if(excludeCellrenderers.indexOf(cell.column.colDef.field) == 1){
                            return cell.value;
                        }
                        let value = cell.value;
                        if(cell.column.colDef.cellRenderer) {
                            value = cell.column.colDef.cellRenderer(node).textContent;
                            if(value){
                                value = value.trimStart();
                                value = value.trimEnd();
                            }
                        }
                        return value;
                    }
                };

                let csvData = this.localeGridOptions.api.getDataAsCsv(params);
                this.TagGridMixins_convertCsvToXlsx(csvData, filename);
            }
        },
        exportCSV: function(datas){
            let excludeCellrenderers = datas.detail.excludeCellrenderers ? datas.detail.excludeCellrenderers : [];
            if (datas.detail.idTableau == this.getIdTableau) {
                var params = {
                    allColumns: false,
                    onlySelected: false,
                    suppressQuotes: false,
                    fileName: this.exportFileName?this.exportFileName+".csv":'verifgood.csv',
                    columnSeparator: ',',
                    processCellCallback: function(cell) {
                        let node = cell.node;
                        if(excludeCellrenderers.indexOf(cell.column.colDef.field) == 1){
                            return cell.value;
                        }
                        let value = cell.value;
                        if(cell.column.colDef.cellRenderer) {
                            // if cell.column.colDef.cellRenderer(node) is node an html element 
                            // then we can get the textContent
                            if(cell.column.colDef.cellRenderer(node) instanceof HTMLElement){
                                value = cell.column.colDef.cellRenderer(node).textContent;
                            }else{
                                value = cell.column.colDef.cellRenderer(node);
                            }
                            if(value){
                                // trim only if not a number
                                if(isNaN(value)){
                                    value = value.trimStart();
                                    value = value.trimEnd();
                                }
                            }
                        }
                        return value;
                    }
                };
                this.localeGridOptions.api.exportDataAsCsv(params);
            }
        },
        showColumn: function(column){
            try{
                this.localeGridOptions.columnApi.setColumnVisible(column.colId?column.colId:column.field, true);
            }catch(err){
                new TagGridExceptions('components:TagGrid:gererColonne', err);
            }
        },
        hideColumn: function(column){
            try{
                this.localeGridOptions.columnApi.setColumnVisible(column.colId?column.colId:column.field, false);
            }catch(err){
                new TagGridExceptions('components:TagGrid:gererColonne', err);
            }
        },

        initialiserAgGrid: function() {
            //// //console.log("initialiserAgGrid");
            let idTableau = this.getIdTableau;

            try{
                var tableau = this.$refs[idTableau];
                // //console.log("ag-grid_init : ",this.idTableau, this.localeGridOptions, this.agGridInstance);
                if(!this.agGridInstance){
                    this.agGridInstance = new agGrid.Grid(tableau, this.localeGridOptions);
				}else{
                    this.agGridInstance = null;
                    this.agGridInstance = new agGrid.Grid(tableau, this.localeGridOptions);
				}
				this.$emit("input", this.localeGridOptions);

				this.$emit("rendered", null);

            }catch(err){
                new TagGridExceptions('components:TagGrid:initialiserAgGrid', err);
            }

            // A revoir :
            // On redimensionne les colonnes automatiquement.
            // Les colonnes sont ajustées de manière à ce qu'elles apparaissent toutes à l'écran.
            // Peut poser des soucis sur des petits écrans.
            // this.localeGridOptions.api.sizeColumnsToFit();
        },
        getExternalFilters: function( newFilters ){
            try{
                var lastFilterIndex = newFilters.length;
                //;
                var externalFilters = [];
                for( var index = 0 ; index < lastFilterIndex ; ++index ){
                    var columnFilter = newFilters[index].field ? this.localeGridOptions.api.getFilterInstance( newFilters[index].field ) : this.localeGridOptions.api.getFilterInstance( newFilters[index].name );
                    //// //console.log(newFilters[index]);
                    var isEnableForFiltering = !newFilters[index].isExternable;
                    if( !( columnFilter && isEnableForFiltering ) ){
                        externalFilters.push( newFilters[index] );
                    }

                }
            }catch(err){
                new TagGridExceptions('components:TagGrid:getExternalFilters',err);
            }
            return externalFilters;
        },
        //ATTENTION A RECODER TROP GROSSE CETTE FONCTION
        filtrer: function( newFilters ) {
			// //console.log("filter aggrid");

            try{

                if(!newFilters || (newFilters && newFilters.length==0)){
					// //console.log("filter aggrid !newFilters || (newFilters && newFilters.length==0)");
                    return null;
                };
                var lastFilterIndex = newFilters.length;
                var externalFilters = this.getExternalFilters( newFilters );

                if( externalFilters.length ){
                    this.$emit( 'ag-grid-before-filtering', {"externalFilters": externalFilters,"activeAggridOptions":this.localeGridOptions} );
                }

                if( !lastFilterIndex ){
                    this.localeGridOptions.api.setRowData( this.localeGridOptions.rowData );
                }
				for( var index = 0 ; index < lastFilterIndex ; ++index ){
					var columnFilter = null;
					if(newFilters[index].field){
                        columnFilter = this.localeGridOptions.api.getFilterInstance( newFilters[index].field );
                    }else{
                        columnFilter = this.localeGridOptions.api.getFilterInstance( newFilters[index].name );
                    }
					//var columnFilter = newFilters[index].field ? this.localeGridOptions.api.getFilterInstance( newFilters[index].field ) : this.localeGridOptions.api.getFilterInstance( newFilters[index].name );
                    var isEnableForFiltering = !newFilters[index].isExternable;

                    // //console.log("columnFilter "+newFilters[index].field,columnFilter, newFilters);
                    if( isEnableForFiltering ){

                        var value = newFilters[index].value || newFilters[index].defaultValue;
                        var filterType = newFilters[index].defaultFilterType || 'contains';
                        var component = newFilters[index].component;

						if( filterType == 'quickSearchFilter' ){
							this.localeGridOptions.api.setQuickFilter(value);

                        }else if( this.isDateRange(component) && columnFilter){
							//// //console.log( newFilters[index].name + "/" + filterType + "/" + value + "/" + component);
							columnFilter.setModel({
								type:'inRange',
								dateFrom:this.getStartDate(value)+"",
								dateTo:this.getEndDate(value)+""
							});

                        }else  if( columnFilter && value && value != "" ){
							columnFilter.setType( filterType );
							columnFilter.setFilter( value );
						}else if( columnFilter ){

							columnFilter.setFilter( null );
						}else{
							// //console.log("!COLUMN FILTER");
						}
                    }else{

                    }
                }

                this.localeGridOptions.api.onFilterChanged();
				this.$emit("rows-count", this.localeGridOptions.api.rowModel.rowsToDisplay.length);
				this.$forceUpdate();

            }catch(err){
                new TagGridExceptions('components:TagGrid:filtrer',err);
            }
        },
        isDateRange: function(component){
            return component && component == 'date-range-picker' ? true:false;
        },
        getStartDate:function(interval){
            if(!interval) return;
            var startDate = interval[0];
            return Moment(startDate).format('YYYY-MM-DD');
        },
        getEndDate:function(interval){
            if(!interval) return;
            var endDate = interval[1];
            //// //console.log(Moment(endDate).format('YYYY-MM-DD'));
            return Moment(endDate).format('YYYY-MM-DD');
        },
		updateRows: function(datas){
			//// //console.log("before ag-grid-updaterows");
			this.$emit('ag-grid-updaterows', datas);
		},
        getState:function(){
            //alert("get state");
            //return JSON.parse(localStorage.getItem(this.getIdTableau));
            return this.$storage.get(this.getIdTableau);
        },
        notEmptyState:function(state){
            return state && state.length > 0;
        },
        restoreColumnState:function(){
            var state = this.getState();
            this.notEmptyState(state) ? this.localeGridOptions.columnApi.setColumnState(state) : null ;
           //alert("state column restored");
            this.$forceUpdate();
        },
        saveState:function(){
            //alert("save state");
            var gridState = this.localeGridOptions.columnApi.getColumnState();
            //stocker le resultat du save state pour linstance de tableau en cours
            //localStorage.setItem(this.getIdTableau, JSON.stringify(gridState) );
            this.$storage.set(this.getIdTableau, gridState);
        },
        initListeners: function(){
            try{
                var that = this;
				//// //console.log("LOCALE", this.localeGridOptions);
                //this.localeGridOptions.api.sizeColumnsToFit();
				this.localeGridOptions.api.addEventListener("firstDataRendered", (e)=>{
                    console.log("firstDataRendered", e);
                    this.localeGridOptions.api.hideOverlay();
				});
				this.localeGridOptions.api.addEventListener("filterChanged", function(e){
                    let filters = that.TagGridMixins_normalize(that.localeGridOptions.api.getFilterModel(),"metadatas", that.localeGridOptions);
                    that.$emit("filter-changed",filters);
				});
                this.localeGridOptions.api.addEventListener("gridSizeChanged", function(){
                    //that.localeGridOptions.api.sizeColumnsToFit();
                });
                this.localeGridOptions.api.addEventListener("displayedColumnsChanged", function(){
                    //that.localeGridOptions.api.sizeColumnsToFit();
                });
                this.localeGridOptions.api.addEventListener('rowDoubleClicked', function(data){
                    that.$emit('ag-dbl-click',data);
                });
				this.localeGridOptions.api.addEventListener('cellContextMenu', function(data){
					//e.preventDefault();
                    that.$emit('cell-context-menu', data);
                });
                this.localeGridOptions.api.addEventListener('rowClicked', function(data){
                    that.$emit('ag-click',data);
                });
                this.localeGridOptions.api.addEventListener("cellClicked", function(event){
                    ////console.log("--------------cellClicked", event);
                });
                this.localeGridOptions.api.addEventListener('selectionChanged', function(data){
                    //// //console.log("selectionChanged");
                    if(!that.isMobile()){
                        that.$emit('ag-multiple-selection-row-changed', that.localeGridOptions);
                    }
                });
                this.localeGridOptions.api.addEventListener('columnMoved',function(data){
                   that.saveState();
                });
                this.localeGridOptions.api.addEventListener('columnVisible',function(data){
                    that.saveState();
                    that.autosize();
                });
                this.localeGridOptions.api.addEventListener('columnResized',function(data){
                    that.saveState();
                });
                this.localeGridOptions.api.addEventListener('pinnedRowDataChanged',function(data){
                    that.saveState();
                });
                this.localeGridOptions.api.addEventListener('cellContextMenu', function(data){
                    //// //console.log("event cellContextMenu",data);
                    that.$emit("ag-long-click", data);
                });

				this.localeGridOptions.api.addEventListener('rowValueChanged', function(data){
                    //// //console.log("event cellContextMenu",data);
                    that.$emit("row-value-changed", data);
                });
				this.localeGridOptions.api.addEventListener('paginationChanged', function(data){
                    //// //console.log("event cellContextMenu",data);
                    that.$emit("pagination-changed", data);
                });
                this.$eventbus.$on("tag-grid-updaterows", function(data){
                    //// //console.log("tag-grid-updaterows");
                    //// //console.log(data);
                    that.updateRows(data);
                });
                this.$eventbus.$on("ag-grid-removerows", function(data){
                    //// //console.log("addEventListener ag-grid-removerows");
                    //// //console.log(data);
                    var selectedNodes = that.localeGridOptions.api.getSelectedNodes();
                    that.localeGridOptions.api.removeItems(selectedNodes);
                });
                this.localeGridOptions.api.addEventListener("rowDataChanged", function(event){
                    //console.log("--------------rowDataChanged");
                });
				this.localeGridOptions.api.addEventListener("rowDataUpdated", (event)=>{
                    this.$forceUpdate();
                });
                window.addEventListener("gestionColonnesEvent", () => {
                    this.showToggleAgGridColModal = true;
                });
                window.addEventListener("gestionColonnesEventId", (datas) => {
                    // //console.log(datas);
                    if (datas.detail.idTableau == this.getIdTableau) {
                        this.showToggleAgGridColModal = true;
                    }
                });
                window.addEventListener("askForXLS", this.exportXLS);
                window.addEventListener("askForCSV", this.exportCSV);
            }catch(err){
                new TagGridExceptions('components:TagGrid:initListeners',err);
            }

        },
        //@description retourne les filtre de la page courante, la récupération se fait grace à la route
        //si la route c'est / recensement alors il récupère les filtre contenant l'instance recensement
		//ATTENTION SI UNE URL A UN CHEMIN GENRE CATEGORIE/EQUIPEMENT et quon veut refresh un tableau avec instance categorie-mobile-search
		//SI le string Equipements n'est pas dans linstance du filtre ca ne marche pas
        /**
        * @deprecated
        */
        getFiltersForCurrentPage: function( filters ){
            try{
                var currentPath = window.location.pathname.split('/');
                var view = currentPath[currentPath.length-1];
                var result = filters ? filters.filter( current => current.instance.indexOf( view ) != -1 ) : null;
            }catch(err){
                new TagGridExceptions('components:TagGrid:initListeners',err);
            }
            return result;
        },
        autosize : function(){
            let visibleColumn = this.columnDefs.map((columnDef)=>{
                columnDef.hide == true
            });
            // rustine collé ici c'est pas parce quil y a moins de 10 colonnes
            // que ça ne rempli pas le tableau
            if(visibleColumn.length < 11) this.localeGridOptions.api.sizeColumnsToFit();
            if(this.$vgutils.isMobile()) this.localeGridOptions.api.sizeColumnsToFit();
        },
        /**
        * Add column colId base on fieldname attribute foreach columDef.
        */
        addColumnsColId:function(){
            this.localeGridOptions.columnDefs.forEach((columnDef, i) => {
                if(!columnDef.colId){
                    this.localeGridOptions.columnDefs[i].colId = columnDef.field;
                }
            });
        },
        // check if the device is a mobile
        isMobile(){
            if( navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
            ){
                return true;
            }
                return false;
        }
    },

	created: function(){
        this.localeGridOptions = Object.assign(this.options, this.value);
        if(this.deltaRowDataMode){
            this.addColumnsColId();
            this.localeGridOptions.deltaRowDataMode = true;
            this.options.getRowNodeId = function(data) {return data.id};
        }
		this.localeGridOptions.onGridReady = ()=>{
            if((!this.rowData || this.rowData.length==0) && this.showLoadingOverlay) this.localeGridOptions.api.showLoadingOverlay();
            this.restoreColumnState();
            this.autosize();
			this.$emit("on-grid-ready", null);
        }
        this.localeGridOptions.columnDefs = this.TagGridMixins_columnDefSplitpath(this.localeGridOptions.columnDefs,this.pathSplitKey);
	},
    mounted: function() {
        this.initialiserAgGrid();
		this.initListeners();
    },
    beforeDestroy:function(){
        window.removeEventListener("askForXLS", this.exportXLS);
        window.removeEventListener("askForCSV", this.exportCSV);
    },
    watch:{
        isModalShowHideColumnsOpened: function(value){
            this.showToggleAgGridColModal = value;
        },
        isExportXLS: function(value){
            if(value) this.exportXLS();
        },
        /**
        * @deprecated
        */
        filters: {
            handler: function ( newFilters ){
				//// //console.log("-+------newFilters------+-");
                // //console.log(newFilters);
                var activeFilters = this.getFiltersForCurrentPage( newFilters );
				// //console.log("ACTIVE", activeFilters);
                ////;
                //// //console.log( "activeFilters -------------------" );
                //// //console.log( activeFilters );
                this.filtrer( activeFilters );
             },
            //deep: true // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! JAI ENLEVER CA
        },
		rowData: {
			handler: function(datas){
				console.log("ROWDATA WATCHER", datas);
				if(this.localeGridOptions.api) {
                    this.localeGridOptions.api.deselectAll();
					this.localeGridOptions.api.setRowData(null);
					this.localeGridOptions.api.setRowData(datas);
					this.$emit("grid-feeded", null);
				}else{
					this.localeGridOptions.rowData = datas;
				}
			},
            deep: true
		},
        showLoadingOverlay: {
            handler: function(isShow){
                if(isShow) this.localeGridOptions.api.showLoadingOverlay();
            }
        }
    }
};

</script>

<style lang="scss" >
    .tag-grid{
        .ag-grid{
            height: 100%;
            .ag-root-wrapper{
                min-height: 200px;
            }
        }
        width: 100%;
    }
    @media only screen and (max-width: 768px){
        .tag-grid{
            .ag-grid{
                /*height: 90%;*/
                .ag-root-wrapper{
                    min-height: 100px;
                }
            }
        }
    }
    .ag-theme-balham .ag-cell-inline-editing{
        height:100% !important;
        border-radius:0px !important;
    }
    .ag-theme-balham .ag-cell-inline-editing select{
        height:100% !important;
        width:100% !important;
        border-radius:0px;
    }
    .ag-theme-balham .ag-cell-inline-editing input{
		height:100% !important;
        width:100% !important;
        border-radius:0px;
    }

    .vg-desktop-tag-grid{
        height:90%;
    }

	/* CUSTOMIZE row focus */
	.ag-row-focus:not(.ag-row-selected){
		background-color: #ebf3f9 !important;
		border-style: none;
		/*-webkit-user-select:text; -moz-user-select:text; -ms-user-select:text; user-select:text;*/
	}
    /* CUSTOMIZE row focus */
	.ag-row-focus{
		z-index: 0 !important;
	}
    .ag-theme-balham .ag-header-cell-resize{
        z-index: 0 !important;
    }

	.ag-theme-balham .ag-cell .vg-synthese-cell{
		line-height: normal !important;
	}
  .chbox-TagGrid{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    .el-checkbox__label{
      font-size: 15px !important;
      font-family: Open sans;
      font-weight: 400;
    }
    .el-checkbox__inner{
      width: 17px;
      height: 17px;
      margin-right: 15px;
    }
    .el-checkbox__inner::after{
      height: 8px;
      left: 5px;
    }
  }


.overlay-loading-template{
    background-color:#35b1ea;
    color:white;
    border:none;
    padding:10px;
    font-size:14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.overlay-no-rows-template{
    background-color:white;
    color:#35b1ea;
    border:none;
    font-size:14px;
}

</style>
