<template>
    <vg-modal v-if="display=='modal'"
        :width="'75vw'"
        :title="$t('create-pieces', {etage: etage.libel_lieu})"
        @close="$emit('close')">
        <template #body>
            <span style="font-weight: bold;">{{ $t("parametrer-piece-a-creer") }}</span>
            <br>
            <br>
            <div style="display: flex;justify-content: flex-start;align-items: center;gap:2px;">
                <vg-input style="flex:3;"
                    isRequiredValue>
                    <vg-categorie-selector v-model="piece.categorie"
                        :attributeValue="null"
                        :clearable="false"
                        isGe
                        @input="onChangeCategorie"/>
                </vg-input>
                <vg-input style="flex:3;"><vg-service-selector v-model="piece.service"/></vg-input>
                <vg-input style="flex:1;" :placeholder="$t('racine-1')" v-model="piece.racine1"/>
                <vg-input style="flex:1;" :placeholder="$t('racine-2')" v-model="piece.racine2"/>
                <vg-input style="flex:1;" :placeholder="$t('prefix-auto-increment')" v-model="piece.prefixAutoIncrement"/>
                <vg-input style="flex:3;" :placeholder="$t('libel')" v-model="piece.libel"/>
                <vg-input style="flex:1;" :placeholder="$t('suffix-auto-increment')" v-model="piece.suffixAutoIncrement"/>
                <span style="flex:1;"></span>
            </div>
            <vg-checkbox v-model="isQrCodeEqualLibellePiece"
                :defaultChecked="isQrCodeEqualLibellePiece"
                :label="$t('checkbox-valeur-qrcode-egal-libelle')"
                @checked="isQrCodeEqualLibellePiece=true;"
                @unchecked="isQrCodeEqualLibellePiece=false;" />
            <div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
                <vg-button v-if="archivedPiece" type="grey" @click="discardUpdatePiece">{{ $t("btn-cancel") }}</vg-button>
                <vg-button :type="archivedPiece?'danger':'success'" :disabled="!piece.categorie || !piece.racine1 || !piece.racine2 || !piece.prefixAutoIncrement" 
                    @click="addPiece">{{ archivedPiece?$t("btn-update"):$t("btn-add") }}
                </vg-button>
            </div>
            <br>
            <br>
            <div style="display: flex;justify-content: flex-start;align-items: center;gap:5px;font-weight: bold;">
                <span style="flex:3;">{{ $t("categorie") }}</span>
                <span style="flex:3;">{{ $t("service") }}</span>
                <!--span style="flex:1;">{{ $t("racine-1") }}</span>
                <span style="flex:1;">{{ $t("racine-2") }}</span>
                <span style="flex:1;">{{ $t("prefix-auto-increment") }}</span-->
                <span style="flex:3;">{{ $t("libel") }}</span>
                <!--span style="flex:1;">{{ $t("suffix-auto-increment") }}</span-->
                <span style="flex:1;"></span>
            </div>
            <br>
            <div v-if="pieces.length==0" style="width: 100%;display: flex;justify-content: center;">
                <span>{{ $t("aucune-piece-a-creer") }}</span>
            </div>
            <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;gap:10px;width:100%;">
                <div style="display: flex;justify-content: flex-start;align-items: center;gap:2px;width:100%;" 
                    v-for="(p, index) in pieces" :key="'piece-'+index"
                    :class="{'row-piece-on-update': archivedPieceIndexInPieces && archivedPieceIndexInPieces!=-1}">
                    <span style="flex:3;">{{ p.categorie.libelleCatgorie }}</span>
                    <span style="flex:3;">{{ p.service }}</span>
                    <!--span style="flex:1;" :placeholder="$t('racine-1')">{{ p.racine1 }}</span>
                    <span style="flex:1;" :placeholder="$t('racine-2')">{{ p.racine2 }}</span>
                    <span style="flex:1;" :placeholder="$t('prefix-auto-increment')">{{ p.prefixAutoIncrement }}</span>
                    <span style="flex:3;" :placeholder="$t('libel')">{{ p.libel }}</span>
                    <span style="flex:1;" :placeholder="$t('suffix-auto-increment')">{{ p.suffixAutoIncrement }}</span-->
                    <span style="flex:3;">{{ p.racine1 }}-{{ p.racine2 }}{{ p.prefixAutoIncrement }}-{{ p.libel }} {{p.suffixAutoIncrement}}</span>
                    <div style="flex:1;">
                        <vg-button size="sm" type="grey" @click="updatePiece(p, index)"><i class="fas fa-pen"/></vg-button>
                        <vg-button size="sm" type="grey" @click="removePiece(p, index)"><i class="far fa-trash-alt"/></vg-button>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <vg-button type="default-success" :disabled="isLoading" @click="$emit('close')">{{ $t("btn-cancel") }}</vg-button>
            <vg-button type="success" :disabled="pieces.length==0 || isLoading" @click="onSave">{{ $t("btn-create", {nbPieces: pieces.length}) }}</vg-button>
        </template>
    </vg-modal>
    <div v-else-if="display=='form' && !multiple" style="width:100%;">
        <div style="display: flex;justify-content: flex-start;align-items: center;gap:2px;">
            <vg-input style="flex:3;"
                :title="$t('categorie')"
                isRequiredValue>
                <vg-categorie-selector v-model="piece.categorie"
                    :attributeValue="null"
                    :clearable="false"
                    isGe
                    @input="onChangeCategorie"/>
            </vg-input>
            <vg-input style="flex:3;"
                :title="$t('service')">
                <vg-service-selector v-model="piece.service" @input="emitChange"/>
            </vg-input>
            <vg-input style="flex:1;" :placeholder="$t('racine-1')" v-model="piece.racine1" @input="emitChange"
                :title="$t('racine-1')"/>
            <vg-input style="flex:1;" :placeholder="$t('racine-2')" v-model="piece.racine2" @input="emitChange"
                :title="$t('racine-2')"/>
            <vg-input style="flex:1;" :placeholder="$t('prefix-auto-increment')" v-model="piece.prefixAutoIncrement" @input="emitChange"
                :title="$t('prefix-auto-increment')"/>
            <vg-input style="flex:3;" :placeholder="$t('libel')" v-model="piece.libel" @input="emitChange"
                :title="$t('libel')"/>
            <vg-input style="flex:1;" :placeholder="$t('suffix-auto-increment')" v-model="piece.suffixAutoIncrement" @input="emitChange"
                :title="$t('suffix-auto-increment')"/>
            <div style="flex:2;color:#9e9da9;font-size:12px;display:flex;flex-direction:column;justify-content: center;align-items:center;">
                <vg-checkbox v-model="isQrCodeEqualLibellePiece"
                    :defaultChecked="isQrCodeEqualLibellePiece"
                    :label="$t('checkbox-valeur-qrcode-egal-libelle')"
                    @checked="isQrCodeEqualLibellePiece=true;emitChange();"
                    @unchecked="isQrCodeEqualLibellePiece=false;emitChange();" 
                    style="margin-left:10px;"/>
                <span v-if="displayQrCodeValue">{{$t("qr-code")}}: {{ codeUnValue }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import LieuMixins from "src/mixins/LieuMixins.js";

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgModal from "src/components/Vg/VgModal.vue";

import StringUtilities from 'src/utilities/StringUtilities.js';
import LieuxValidator from 'validators/LieuxValidator.js';

import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import { mapGetters } from 'vuex';

export default {
    name: 'vg-piece-form',
    mixins: [LieuMixins],
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "create-pieces": "Créer des pièces dans {etage}",
                "parametrer-piece-a-creer": "Paramétrer la pièce à créer",
                "btn-add": "Ajouter",
                "btn-update": "Modifier",
                "btn-cancel": "Annuler",
                "btn-create": "Créer {nbPieces} pièces",
                "categorie": "Catégorie",
                "service": "Service",
                "racine-1": "Racine 1",
                "racine-2": "Racine 2",
                "prefix-auto-increment": "NuméroAuto1",
                "libel": "Libellé",
                "suffix-auto-increment": "NuméroAuto2",
                "checkbox-valeur-qrcode-egal-libelle": "Valeur QrCode = libellé pièce ?",
                "aucune-piece-a-creer": "Aucune pièce à créer",
                "qr-code": "QrCode"
            },
            "en":{
                "create-pieces": "Create rooms in {etage}",
                "parametrer-piece-a-creer": "Set up the room to create",
                "btn-add": "Add",
                "btn-update": "Update",
                "btn-cancel": "Cancel",
                "btn-create": "Create {nbPieces} rooms",
                "categorie": "Category",
                "service": "Service",
                "racine-1": "Root 1",
                "racine-2": "Root 2",
                "prefix-auto-increment": "AutoNumber1",
                "libel": "Label",
                "suffix-auto-increment": "AutoNumber2",
                "checkbox-valeur-qrcode-egal-libelle": "QrCode value = room label?",
                "aucune-piece-a-creer": "No room to create",
                "qr-code": "QrCode"
            }
        }
    },
    components: {
        VgButton,
        VgInput,
        VgSelect,
        VgCheckbox,
        VgModal,
        VgCategorieSelector,
        VgServiceSelector
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        etage:{
            type: Object,
            default: null
        },
        id: {
            type: Number,
            default: null
        },
        skipVueXStorage: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        },
        /**
         * display modal | form
         */
        display:{
            type: String,
            default: "modal"
        },
        storeInPlanInteractifStore:{
            type: Boolean,
            default: false
        },
        displayQrCodeValue:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isQrCodeEqualLibellePiece: false,
            pieces: [],
            piece: {
                categorie: null,
                service: null,
                racine1: null,
                racine2: null,
                prefixAutoIncrement: null,
                libel: null,
                suffixAutoIncrement: 1,
                isQrCodeEqualLibellePiece: false
            },
            archivedPiece:null,
            archivedPieceIndexInPieces:null
        }
    },
    created(){
        this.initDefaultValues();
    },
    watch: {
        newPieceMarker:{
            handler: function(newvalue, oldvalue){
                console.log("NEW PIECE MARKER", newvalue, oldvalue);
                if(oldvalue && !newvalue){ 
                    this.nextPiece();
                    this.emitChange();
                }
            },
            deep: true
        }
    },
    computed:{
        ...mapGetters({
            newPieceMarker: "PlanInteractifStore/getNewPieceMarker"
        }),
        codeUnValue: function(){
            let libel = "";
            if(this.piece.racine1) libel += this.piece.racine1;
            if(this.piece.racine1 && this.piece.racine2) libel += "-"+this.piece.racine2;
            else if(!this.piece.racine1 && this.piece.racine2) libel += this.piece.racine2;
            if(this.piece.prefixAutoIncrement) libel += this.piece.prefixAutoIncrement;
            if(this.piece.libel) libel += "-"+this.piece.libel.replace("/", "-");
            if(this.piece.suffixAutoIncrement) libel += " "+this.piece.suffixAutoIncrement;
            let codeUn = this.isQrCodeEqualLibellePiece?libel:"VLGL"+moment().unix();
            if(this.piece.suffixAutoIncrement) codeUn += this.piece.suffixAutoIncrement;
            else codeUn += this.piece.prefixAutoIncrement;
            return codeUn;
        },
        formatedPiece: function(){
            let libel = "";
            if(this.piece.racine1) libel += this.piece.racine1;
            if(this.piece.racine1 && this.piece.racine2) libel += "-"+this.piece.racine2;
            else if(!this.piece.racine1 && this.piece.racine2) libel += this.piece.racine2;
            if(this.piece.prefixAutoIncrement) libel += this.piece.prefixAutoIncrement;
            if(this.piece.libel) libel += "-"+this.piece.libel.replace("/", "-");
            if(this.piece.suffixAutoIncrement) libel += " "+this.piece.suffixAutoIncrement;
            let codeUn = this.piece.isQrCodeEqualLibellePiece?libel:"VLGL"+moment().unix();
            if(this.piece.suffixAutoIncrement) codeUn += this.piece.suffixAutoIncrement;
            else codeUn += this.piece.prefixAutoIncrement;
            return {
                idLieuParent_id: this.etage.id,
                categorie: this.piece.categorie,
                type_lieu: "Piece",
                libel_lieu: libel,
                codeUn: codeUn,
                service: this.piece.service,
                etiquetter: false,
                userId: this.$app.appID
            };
        },
        formatedPieces: function(){
            return this.pieces.map((piece, index)=>{
                let libel = "";
                if(piece.racine1) libel += piece.racine1;
                if(piece.racine1 && piece.racine2) libel += "-"+piece.racine2;
                else if(!piece.racine1 && piece.racine2) libel += piece.racine2;
                if(piece.prefixAutoIncrement) libel += piece.prefixAutoIncrement;
                if(piece.libel) libel += "-"+piece.libel.replace("/", "-");
                if(piece.suffixAutoIncrement) libel += " "+piece.suffixAutoIncrement;
                return {
                    idLieuParent_id: this.etage.id,
                    categorie: piece.categorie,
                    type_lieu: "Piece",
                    libel_lieu: libel,
                    codeUn: piece.isQrCodeEqualLibellePiece?libel:"VLGL"+moment().unix()+index,
                    service: piece.service,
                    etiquetter: false,
                    userId: this.$app.appID
                };
            });
        },
        dernierNumeroPiece: function(){
            return this.pieces.at(-1).prefixAutoIncrement;
        },
        isFormValid: function(){
            return this.piece.categorie && this.piece.racine1 && this.piece.racine2 && this.piece.prefixAutoIncrement && this.piece.libel;
        }
    },
    methods: {
        storePieceInPlanInteractifStore: function(){
            this.$store.dispatch("PlanInteractifStore/setNewPieceMarker", Object.assign({}, this.formatedPiece, {
                coordX: null,
                coordY: null
            }));
        },
        emitChange: function(){
            if(!this.multiple && this.display=="form" && this.isFormValid) this.$emit("change", this.formatedPiece);
            if(!this.multiple && this.display=="form" && this.isFormValid && this.storeInPlanInteractifStore) this.storePieceInPlanInteractifStore();
        },
        onChangeCategorie: function(categorie){
            this.piece.libel = categorie.libelleCatgorie;
            this.emitChange();
        },
        initDefaultValues: function(){
            this.piece.prefixAutoIncrement = Number(this.$app.numeroInitialCreationLibelPiece)+1;
            this.piece.racine1 = this.$app.racineACreationLibelPiece?this.$app.racineACreationLibelPiece:StringUtilities.getAcronym(this.etage.path.split("/")[1]);
            this.piece.racine2 = this.$app.racineBCreationLibelPiece?this.$app.racineBCreationLibelPiece:"P";
        },
        nextPiece: function(){
            this.piece.prefixAutoIncrement++;
            if(this.piece.suffixAutoIncrement) this.piece.suffixAutoIncrement++;
        },
        addPiece: function(){
            if(this.archivedPiece){
                this.pieces[this.archivedPieceIndexInPieces] = Object.assign({}, {}, this.piece);
                this.discardUpdatePiece();
            }else{
                this.piece.isQrCodeEqualLibellePiece = this.isQrCodeEqualLibellePiece;
                this.pieces.push(Object.assign({}, {}, this.piece));
                this.nextPiece();
            }
        },
        removePiece: function(piece, index){
            if(this.archivedPiece) this.discardUpdatePiece();
            this.pieces.splice(index, 1);
        },
        updatePiece: function(piece, index){
            this.archivedPiece = Object.assign({}, {}, this.piece);
            this.archivedPieceIndexInPieces = index;
            this.piece = Object.assign({}, {}, piece);
        },
        discardUpdatePiece: function(){
            this.piece = Object.assign({}, {}, this.archivedPiece);
            this.archivedPieceIndexInPieces = null;
            this.archivedPiece = null;
        },
        onSave: function(){
            this.isLoading = true;
            this.LieuMixins_create(this.formatedPieces, {dernierNumeroPiece: this.dernierNumeroPiece}).then((pieces)=>{
                this.isLoading = false;
                this.$emit("save", pieces);
            });
        }
    }
}
</script>
<style lang="scss">
.archivedPieceIndexInPieces{
    background-color: whitesmoke;
}
</style>