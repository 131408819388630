<template>
    <!--
        j'ai fais un copié collé de la popup fournie par tui-calendar
        les classes doivent être conservées pour garder le style de la popup
    -->
    <div v-if="!$vgutils.isMobile()" class="tui-full-calendar-popup tui-full-calendar-popup-detail" :style="getPosition">
        <div v-if="calendar.id=='maintenance-ouverture' || calendar.id=='maintenance-fermeture' || calendar.id=='maintenance-affectation'"
            class="tui-full-calendar-popup-container">
            <div class="header">
                <div class="header-title">
                    <img class="icon" :src="schedule.raw.statut=='Resolue'?'static/assets/icone/maintenance-verte.png':'static/assets/icone/maintenance-rouge.png'"/>
                    <span>{{ $t("title-maintenance") }}</span>
                    <span>{{ schedule.raw.id }}</span>
                </div>
                <div class="popup-close-modal">
                    <img src="static/assets/icone/fermer.png" height="20" alt="header-button-close" @click="closePopup()" style="cursor:pointer;"/>
                </div>
            </div>
            <div class="content">
                <vg-fichesav-description :fm="schedule.raw" :showIcon="true" style="display:flex;align-items:center;" />
                <br>
                <div class="content-grid">
                    <span class="grid-title">{{ $t("plage-horaire") }}</span>
                    <div class="grid-value">
                        <span>{{ plageHoraireStart }} - {{ plageHoraireEnd }}</span>
                        <vg-button type="default-danger" size="xs"
                            @click="openUpdate">
                            {{ $t("update") }}
                        </vg-button>
                    </div>
                    <span class="grid-title">{{ $t("localisation") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.equipement.libel_lieu }}</span>
                    </div>
                    <span class="grid-title">{{ $t("affectation") }}</span>
                    <div class="grid-value" v-if="schedule.raw.affectation && schedule.raw.affectation.affectes">
                        <span v-for="affecte in schedule.raw.affectation.affectes" class="affecte">
                            {{ affecte.hasOwnProperty("name")?affecte.name:affecte.user_name }}
                        </span>
                    </div>
                    <div v-else class="grid-value">
                    </div>
                    <span class="grid-title">{{ $t("statut") }}</span>
                    <div class="grid-value">
                        <vg-fichesav-statut-viewer :fm="schedule.raw"/>
                    </div>
                    <span class="grid-title">{{ $t("urgence") }}</span>
                    <div class="grid-value">
                        {{ schedule.raw.urgence?$t("oui"):$t("non") }}
                    </div>
                    <span class="grid-title">{{ $t("bloquant") }}</span>
                    <div class="grid-value">
                        {{ schedule.raw.isBloquant?$t("oui"):$t("non") }}
                    </div>
                </div>
            </div>
            <div class="footer">
                <vg-button :type="'default-link'"
                    :disabled="!can('MAINTENANCE.PAGE')"
                    @click="openMaintenance()">
                     {{$t("open-maintenance")}}
                </vg-button>
                <vg-button :type="'default-link'"
                    @click="openMaintenanceDetail()">
                     {{$t("show-more")}}
                </vg-button>
            </div>
        </div>
        <div v-else-if="calendar.id=='intervention-programmee'"
            class="tui-full-calendar-popup-container">
            <div class="header">
                <div class="header-title">
                    <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                        :color="schedule.raw.status=='intervention-prevue'?'red':'#45b348'"
                        :size="'md'" />
                    <img v-if="schedule.raw.contrat && schedule.raw.contrat.categorie && schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon" />
                    <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu.png" class="icon" />
                    <span>{{ $t("title-intervention-programmee") }}</span>
                </div>
                <div class="popup-close-modal">
                    <img src="static/assets/icone/fermer.png" height="20" alt="header-button-close" @click="closePopup()" style="cursor:pointer;"/>
                </div>
            </div>
            <div class="content">
                <div class="content-grid">
                    <span class="grid-title">{{ $t("libelle") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.intitule }}</span>
                    </div>
                    <span class="grid-title">{{ $t("plage-horaire") }}</span>
                    <div class="grid-value">
                        <span>{{ plageHoraireStart }} - {{ plageHoraireEnd }}</span>
                        <vg-button type="default-danger" size="xs" 
                            @click="openUpdate">
                            {{ $t("update") }}
                        </vg-button>
                    </div>
                    <span class="grid-title">{{ $t("affectation") }}</span>
                    <div class="grid-value">
                        {{ schedule.raw.tiers.name }}
                    </div>
                    <!--span class="grid-title">{{ $t("frequence") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.frequence }}</span>
                    </div-->
                    <span class="grid-title">{{ $t("contrat") }}</span>
                    <div class="grid-value">
                        <div>
                            <span v-if="schedule.raw.contrat_id && schedule.raw.contrat">{{ schedule.raw.contrat.name }}</span>
                            <span v-else>{{ $t("sans-contrat") }}</span>
                        </div>
                    </div>
                    <span class="grid-title" v-if="schedule.raw.contrat_id && schedule.raw.contrat">{{ $t("categorie") }}</span>
                    <div class="grid-value" v-if="schedule.raw.contrat_id && schedule.raw.contrat">
                        <div>
                            <img v-if="schedule.raw.contrat && schedule.raw.contrat.categorie && schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon" />
                            <span v-if="schedule.raw.contrat && schedule.raw.contrat.categorie">{{ schedule.raw.contrat.categorie.libelleCatgorie }}</span>
                        </div>
                    </div>
                    <span class="grid-title">{{ $t("statut") }}</span>
                    <div class="grid-value">
                        <span :class="schedule.raw.status">{{ $t("status-"+schedule.raw.status) }}</span>
                    </div>
                    <span class="grid-title" v-if="!schedule.raw.nbDocuments || schedule.raw.nbDocuments.length==0 || schedule.raw.nbDocuments==0">{{ $t("documents") }}</span>
                    <div class="grid-value" v-if="!schedule.raw.nbDocuments || schedule.raw.nbDocuments.length==0 || schedule.raw.nbDocuments==0">
                        <span style="color:red;"><i class="far fa-file no-documents"></i><span style="color: red;font-size:10px;margin-left:10px;">{{ $t("documents-manquants") }}</span></span>
                    </div>
                </div>
            </div>
            <div class="footer">
                <vg-button :type="'default-link'"
                    :disabled="!can('INTERVENTION.PAGE')"
                    @click="openIntervention()">
                     {{$t("open-intervention")}}
                </vg-button>
                <vg-button v-if="schedule.raw.status!='intervention-realisee'" 
                    :type="'default-link'"
                    :disabled="!can('INTERVENTION.VALIDATION')"
                    @click="openInterventionValidation()">
                     {{$t("validation")}}
                </vg-button>
            </div>
        </div>
        <div v-else-if="calendar.id=='tache-session'"
            class="tui-full-calendar-popup-container">
            <div class="header">
                <div class="header-title">
                    <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                        :color="tacheSessionIconColor"
                        :size="'md'" 
                        :key="'popup-detail-'+schedule.raw.id+'-'+schedule.raw.start"/>
                    <img v-if="schedule.raw.tache.categorie_icon" :src="schedule.raw.tache.categorie_icon" class="icon" />
                    <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu.png" class="icon" />
                    <span>{{ $t("title-tache-session") }}</span>
                </div>
                <div class="popup-close-modal">
                    <img src="static/assets/icone/fermer.png" height="20" alt="header-button-close" @click="closePopup()" style="cursor:pointer;"/>
                </div>
            </div>
            <div class="content">
                <div class="content-grid">
                    <span class="grid-title">{{ $t("libelle-tache") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.tache.libel_tache }}</span>
                    </div>
                    <span class="grid-title">{{ $t("plage-horaire") }}</span>
                    <div class="grid-value">
                        <span>{{ plageHoraireStart }} - {{ plageHoraireEnd }}</span>
                        <vg-button type="default-danger" size="xs"
                            @click="openUpdate">
                            {{ $t("update") }}
                        </vg-button>
                    </div>
                    <span class="grid-title">{{ $t("affectation") }}</span>
                    <div class="grid-value" v-if="schedule.raw.affectes">
                        <span v-for="affecte in schedule.raw.affectes" class="affecte">
                            {{ affecte.affectationusertache_user_name }}
                        </span>
                    </div>
                    <div v-else class="grid-value">
                    </div>
                    <span class="grid-title" v-if="schedule.raw.recurrence">{{ $t("frequence") }}</span>
                    <div class="grid-value" v-if="schedule.raw.recurrence">
                        <span>{{ $t("tous-les-n-jours", {nJours: schedule.raw.recurrence}) }}</span>
                    </div>
                    <span class="grid-title">{{ $t("categorie") }}</span>
                    <div class="grid-value">
                        <div>
                            <img v-if="schedule.raw.tache.categorie_icon" :src="schedule.raw.tache.categorie_icon" class="icon" />
                            <span v-if="schedule.raw.tache">{{ schedule.raw.tache.libelleCatgorie }}</span>
                        </div>
                    </div>
                    <span class="grid-title" v-if="schedule.raw.progression || isCurrentSession">{{ $t("resume-progression") }}</span>
                    <div v-if="schedule.raw.tache && isCurrentSession" class="grid-value">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;gap:5px;width:100%;">
                            <div style="display:flex;justify-content: space-between;gap:5px;align-items: center;width:100%;">
                                <vg-progress v-model="schedule.raw.tache.nbEquipementChecked"
                                    :total="schedule.raw.tache.nbEquipementToCheck"
                                    percentage 
                                    :key="'current-'+schedule.raw.id+'-'+schedule.raw.tache.id+'-'+schedule.raw.start" />
                                <span style="white-space:nowrap;">{{ schedule.raw.tache.nbEquipementChecked }} / {{ schedule.raw.tache.nbEquipementToCheck }}</span>
                            </div>
                            <small v-if="schedule.raw.tache && schedule.raw.tache.periodicite!=1">{{$t("du")}} {{ getDateDebut }} {{ $t("au") }} {{ getDateFin }}</small>
                            <small v-else-if="schedule.raw.tache && schedule.raw.tache.periodicite==1">{{$t("le")}} {{ getDateDebut }}</small>
                        </div>
                    </div>
                    <div v-else-if="schedule.raw.progression" class="grid-value">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;gap:5px;width:100%;">
                            <div style="display:flex;justify-content: space-between;gap:5px;align-items: center;width:100%;">
                                <vg-progress v-model="schedule.raw.progression.verified"
                                    :total="schedule.raw.progression.total"
                                    percentage 
                                    :key="'current-'+schedule.raw.id+'-'+schedule.raw.tache.id+'-'+schedule.raw.progression.dateDebut" />
                                <span style="white-space:nowrap;">{{ schedule.raw.progression.verified }} / {{ schedule.raw.progression.total }}</span>
                            </div>
                            <small v-if="schedule.raw.tache && schedule.raw.tache.periodicite!=1">{{$t("du")}} {{ getDateDebut }} {{ $t("au") }} {{ getDateFin }}</small>
                            <small v-else-if="schedule.raw.tache && schedule.raw.tache.periodicite==1">{{$t("le")}} {{ getDateDebut }}</small>
                        </div>
                    </div>
                    <!--div v-else class="grid-value">
                        <span>{{ $t("aucune-verification-effectuee") }}</span>
                    </div-->
                </div>
            </div>
            <div class="footer">
                <vg-button v-if="!isScheduleDansProgressionFuture && schedule.raw.tache.nbEquipementChecked!=0"
                    :type="'default-link'"
                    :disabled="!can('VERIFICATIONS.PAGE')"
                    @click="openVerifications()">
                     {{$t("show-verifications")}}
                </vg-button>
                <vg-button v-if="isCurrentSession"
                    :type="'default-link'"
                    :disabled="!can('PROGRESSION.PAGE')"
                    @click="openProgression()">
                     {{$t("show-progression")}}
                </vg-button>
                <vg-button v-if="can('CHECKS.PAGE')"
                    :type="'default-link'"
                    :disabled="!isCurrentSession"
                    @click="openCheck()">
                    {{$t("check-"+schedule.raw.tache.type_tache)}}
                    <small v-if="!isCurrentSession && !isScheduleDansProgressionFuture">({{ $t("session-depassee") }})</small>
                </vg-button>
            </div>
        </div>
        <vg-fichesav-modal v-if="modalFm"
            :idMaintenance="schedule.raw.id"
            @close="modalFm=false" />
        <vg-intervention-form v-if="showInterventionUpdate"
            isPeriodique
            :defaultStatut="'intervention-prevue'"
            @close="showInterventionUpdate=false;"
            @save="onSaveInterventionUpdate"/>
        <vg-intervention-validation v-if="showInterventionValidation"
            v-model="schedule.raw"
            @close="showInterventionValidation=false;"
            @save="onSaveInterventionValidation" />
    </div>
    <vg-modal v-else
        @close="closePopup()">
        <template #header>
            <div v-if="calendar.id=='maintenance-ouverture' || calendar.id=='maintenance-fermeture' || calendar.id=='maintenance-affectation'"
                class="header-title">
                <img class="icon" :src="schedule.raw.statut=='Resolue'?'static/assets/icone/maintenance-verte.png':'static/assets/icone/maintenance-rouge.png'"/>
                <span>{{ $t("title-maintenance") }}</span>
                <span>{{ schedule.raw.id }}</span>
            </div>
            <div v-else-if="calendar.id=='intervention-programmee'"
                class="header-title">
                <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                    :color="schedule.raw.status=='intervention-prevue'?'red':'#45b348'"
                    :size="'xs'" />
                <img v-if="schedule.raw.contrat && schedule.raw.contrat.categorie && schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon" />
                <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu.png" class="icon" />
                <span>{{ $t("title-intervention-programmee") }}</span>
            </div>
            <div v-else-if="calendar.id=='tache-session'"
                class="header-title">
                <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                    :color="tacheSessionIconColor"
                    :size="'xs'" 
                    :key="'popup-detail-'+schedule.raw.id+'-'+schedule.raw.start"/>
                <img v-if="schedule.raw.tache.categorie_icon" :src="schedule.raw.tache.categorie_icon" class="icon" />
                <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu.png" class="icon" />
                <span>{{ $t("title-tache-session") }}</span>
            </div>
        </template>
        <template #body>
            <div v-if="calendar.id=='maintenance-ouverture' || calendar.id=='maintenance-fermeture' || calendar.id=='maintenance-affectation'"
                class="content">
                <vg-fichesav-description :fm="schedule.raw" :showIcon="true" />
                <br>
                <div class="content-grid">
                    <span class="grid-title">{{ $t("plage-horaire") }}</span>
                    <div class="grid-value">
                        <span>{{ plageHoraireStart }} - {{ plageHoraireEnd }}</span>
                        <vg-button type="default-danger" size="xs"
                            @click="openUpdate">
                            {{ $t("update") }}
                        </vg-button>
                    </div>
                    <span class="grid-title">{{ $t("localisation") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.equipement.libel_lieu }}</span>
                    </div>
                    <span class="grid-title">{{ $t("affectation") }}</span>
                    <div class="grid-value" v-if="schedule.raw.affectation && schedule.raw.affectation.affectes">
                        <span v-for="affecte in schedule.raw.affectation.affectes" class="affecte">
                            {{ affecte.hasOwnProperty("name")?affecte.name:affecte.user_name }}
                        </span>
                    </div>
                    <div v-else class="grid-value">
                    </div>
                    <span class="grid-title">{{ $t("statut") }}</span>
                    <div class="grid-value">
                        <vg-fichesav-statut-viewer :fm="schedule.raw"/>
                    </div>
                    <span class="grid-title">{{ $t("urgence") }}</span>
                    <div class="grid-value">
                        {{ schedule.raw.urgence?$t("oui"):$t("non") }}
                    </div>
                    <span class="grid-title">{{ $t("bloquant") }}</span>
                    <div class="grid-value">
                        {{ schedule.raw.isBloquant?$t("oui"):$t("non") }}
                    </div>
                </div>
            </div>
            <div v-else-if="calendar.id=='intervention-programmee'"
                class="content">
                <div class="content-grid">
                    <span class="grid-title">{{ $t("libelle") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.intitule }}</span>
                    </div>
                    <span class="grid-title">{{ $t("plage-horaire") }}</span>
                    <div class="grid-value">
                        <span>{{ plageHoraireStart }} - {{ plageHoraireEnd }}</span>
                        <vg-button type="default-danger" size="xs"
                            @click="openUpdate">
                            {{ $t("update") }}
                        </vg-button>
                    </div>
                    <span class="grid-title">{{ $t("affectation") }}</span>
                    <div class="grid-value">
                        {{ schedule.raw.tiers.name }}
                    </div>
                    <!--span class="grid-title">{{ $t("frequence") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.frequence }}</span>
                    </div-->
                    <span class="grid-title">{{ $t("contrat") }}</span>
                    <div class="grid-value">
                        <div>
                            <span v-if="schedule.raw.contrat_id && schedule.raw.contrat">{{ schedule.raw.contrat.name }}</span>
                            <span v-else>{{ $t("sans-contrat") }}</span>
                        </div>
                    </div>
                    <span class="grid-title">{{ $t("categorie") }}</span>
                    <div class="grid-value">
                        <img v-if="schedule.raw.contrat && schedule.raw.contrat.categorie && schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon" />
                        <span v-if="schedule.raw.contrat && schedule.raw.contrat.categorie">{{ schedule.raw.contrat.categorie.libelleCatgorie }}</span>
                    </div>
                    <span class="grid-title">{{ $t("statut") }}</span>
                    <div class="grid-value">
                        <span :class="schedule.raw.status">{{ $t("status-"+schedule.raw.status) }}</span>
                    </div>
                </div>
            </div>
            <div v-else-if="calendar.id=='tache-session'"
                class="content">
                <div class="content-grid">
                    <span class="grid-title">{{ $t("libelle-tache") }}</span>
                    <div class="grid-value">
                        <span>{{ schedule.raw.tache.libel_tache }}</span>
                    </div>
                    <span class="grid-title">{{ $t("plage-horaire") }}</span>
                    <div class="grid-value">
                        <span>{{ plageHoraireStart }} - {{ plageHoraireEnd }}</span>
                        <vg-button type="default-danger" size="xs"
                            @click="openUpdate">
                            {{ $t("update") }}
                        </vg-button>
                    </div>
                    <span class="grid-title">{{ $t("affectation") }}</span>
                    <div class="grid-value" v-if="schedule.raw.affectes">
                        <span v-for="affecte in schedule.raw.affectes" class="affecte">
                            {{ affecte.affectationusertache_user_name }}
                        </span>
                    </div>
                    <div v-else class="grid-value">
                    </div>
                    <span class="grid-title" v-if="schedule.raw.recurrence">{{ $t("frequence") }}</span>
                    <div class="grid-value" v-if="schedule.raw.recurrence">
                        <span>{{ $t("tous-les-n-jours", {nJours: schedule.raw.recurrence}) }}</span>
                    </div>
                    <span class="grid-title">{{ $t("categorie") }}</span>
                    <div class="grid-value">
                        <div>
                            <img v-if="schedule.raw.tache.categorie_icon" :src="schedule.raw.tache.categorie_icon" class="icon" />
                            <span v-if="schedule.raw.tache">{{ schedule.raw.tache.libelleCatgorie }}</span>
                        </div>
                    </div>
                    <span class="grid-title" v-if="schedule.raw.progression || isCurrentSession">{{ $t("resume-progression") }}</span>
                    <div v-if="schedule.raw.tache && isCurrentSession" class="grid-value">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;gap:5px;width:100%;">
                            <div style="display:flex;justify-content: space-between;gap:5px;align-items: center;width:100%;">
                                <vg-progress v-model="schedule.raw.tache.nbEquipementChecked"
                                    :total="schedule.raw.tache.nbEquipementToCheck"
                                    percentage 
                                    :key="'current-'+schedule.raw.id+'-'+schedule.raw.tache.id+'-'+schedule.raw.tache.dateDebut" />
                                <span style="white-space:nowrap;">{{ schedule.raw.tache.nbEquipementChecked }} / {{ schedule.raw.tache.nbEquipementToCheck }}</span>
                            </div>
                            <small v-if="schedule.raw.tache && schedule.raw.tache.periodicite!=1">{{$t("du")}} {{ getDateDebut }} {{ $t("au") }} {{ getDateFin }}</small>
                            <small v-else-if="schedule.raw.tache && schedule.raw.tache.periodicite==1">{{$t("le")}} {{ getDateDebut }}</small>
                        </div>
                    </div>
                    <div v-else-if="schedule.raw.progression" class="grid-value">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;gap:5px;width:100%;">
                            <div style="display:flex;justify-content: space-between;gap:5px;align-items: center;width:100%;">
                                <vg-progress v-model="schedule.raw.progression.verified"
                                    :total="schedule.raw.progression.total"
                                    percentage 
                                    :key="'current-'+schedule.raw.id+'-'+schedule.raw.tache.id+'-'+schedule.raw.progression.dateDebut" />
                                <span style="white-space:nowrap;">{{ schedule.raw.progression.verified }} / {{ schedule.raw.progression.total }}</span>
                            </div>
                            <small v-if="schedule.raw.tache && schedule.raw.tache.periodicite!=1">{{$t("du")}} {{ getDateDebut }} {{ $t("au") }} {{ getDateFin }}</small>
                            <small v-else-if="schedule.raw.tache && schedule.raw.tache.periodicite==1">{{$t("le")}} {{ getDateDebut }}</small>
                        </div>
                    </div>
                    <!--div v-else class="grid-value">
                        <span>{{ $t("aucune-verification-effectuee") }}</span>
                    </div-->
                </div>
            </div>

            <vg-fichesav-modal v-if="modalFm"
                :idMaintenance="schedule.raw.id"
                @close="modalFm=false" />
            <vg-intervention-form v-if="showInterventionUpdate"
                isPeriodique
                :defaultStatut="'intervention-prevue'"
                @close="showInterventionUpdate=false;"
                @save="onSaveIntervention"/>
            <vg-intervention-validation v-if="showInterventionValidation"
                v-model="schedule.raw"
                @close="showInterventionValidation=false;"
                @save="onSaveValidation" />
        </template>
        <template #footer>                
            <vg-button v-if="calendar.id=='maintenance-ouverture' || calendar.id=='maintenance-fermeture' || calendar.id=='maintenance-affectation'"
                :type="'default-link'"
                :disabled="!can('MAINTENANCE.PAGE')"
                @click="openMaintenance()">
                {{$t("open-maintenance")}}
            </vg-button>
            <vg-button v-if="calendar.id=='maintenance-ouverture' || calendar.id=='maintenance-fermeture' || calendar.id=='maintenance-affectation'"
                :type="'default-link'"
                @click="openMaintenanceDetail()">
                {{$t("show-more")}}
            </vg-button>
            <vg-button v-if="calendar.id=='intervention-programmee' && can('INTERVENTION.PAGE')"
                :type="'default-link'"
                :disabled="!can('INTERVENTION.PAGE')"
                @click="openIntervention()">
                {{$t("open-intervention")}}
            </vg-button>
            <vg-button v-if="calendar.id=='intervention-programmee' && schedule.raw.status!='intervention-realisee'" 
                :type="'default-link'"
                :disabled="!can('INTERVENTION.VALIDATION')"
                @click="openInterventionValidation()">
                {{$t("validation")}}
            </vg-button>
            <vg-button v-if="calendar.id=='tache-session' && isCurrentSession"
                :type="'default-link'"
                :disabled="!can('PROGRESSION.PAGE')"
                @click="openProgression()">
                {{$t("show-progression")}}
            </vg-button>
            <vg-button v-else-if="calendar.id=='tache-session' && (!isScheduleDansProgressionFuture && schedule.raw.tache.nbEquipementChecked!=0)"
                :type="'default-link'"
                :disabled="!can('VERIFICATIONS.PAGE')"
                @click="openVerifications()">
                {{$t("show-verifications")}}
            </vg-button>
            <vg-button v-if="calendar.id=='tache-session' && can('CHECKS.PAGE')"
                :type="'default-link'"
                :disabled="!isCurrentSession"
                @click="openCheck()">
                {{$t("check-"+schedule.raw.tache.type_tache)}}
                <small v-if="!isCurrentSession && !isScheduleDansProgressionFuture">({{ $t("session-depassee") }})</small>
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgIcon from "src/components/Vg/VgIcon.vue";
    import VgProgress from "src/components/Vg/VgProgress.vue";
    import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import VgFichesavStatutViewer from "src/components/Vg/FicheSAV/VgFichesavStatutViewer.vue";
    import VgVerificationModalViewer from "src/components/Vg/Verification/VgVerificationModalViewer.vue";
    import VgFichesavModal from 'src/components/Vg/FicheSAV/VgFichesavModal.vue';
    import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
    import VgInterventionValidation from "src/components/Vg/Interventions/VgInterventionValidation.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";

    export default Vue.extend({
        name: "popup-detail",
		props: {
            /**
            * schedule
            */
			value: {
				type: Object,
				required: true
			},
            calendar: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            }
		},
        filters:{
            onlyDate: function(timestamp) {
                return moment(timestamp).format("DD/MM/YY");
            },
            hour: function(timestamp) {
	            return moment(timestamp).format("HH:mm");
	        },
            site: function(path){
                var splitPath = path.split("/");
                return splitPath[1];
            }
        },
        data: function() {
            return {
                schedule: this.value,
                modalFm:false,
                showInterventionValidation: false,
                showInterventionUpdate: false
            };
        },
        components: {
            VgButton,
            VgIcon,
            VgProgress,
            VgFichesavDescription,
            VgLieuPathViewer,
            VgFichesavStatutViewer,
            VgVerificationModalViewer,
            VgFichesavModal,
            VgDatetimeViewer,
            VgInterventionForm,
            VgInterventionValidation
        },
        mixins:[],
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-maintenance": "Fiche de maintenance curative",
            "title-intervention-programmee": "Intervention tiers programmée",
            "title-tache-session": "Session tâche récurrente",
            "show-more": "Voir plus de détails",
            "open-maintenance": "Accéder fiche",
            "plage-horaire": "Plage horaire",
            "localisation": "Localisation",
            "affectation": "Affectation",
            "statut": "Statut",
            "documents": "Documents attachés",
            "urgence": "Urgence",
            "bloquant": "Bloquant",
            "libelle": "Libellé",
            "frequence": "Fréquence",
            "categorie": "Catégorie",
            "contrat": "Contrat",
            "open-intervention": "Accéder fiche intervention",
            "validation": "Validation",
            "libelle-tache": "Libellé tâche",
            "date-butoir": "A finir avant le",
            "resume-progression": "Résumé progression",
            "show-progression": "Détail progression",
            "show-verifications": "Voir vérifications",
            "session-depassee": "Session dépassée",
            "session-non-commencee": "Session non commencée",
            "check-Verification_equipement": "Check équipement",
            "check-Relever_compteur": "Check compteur",
            "check-Relever_Grandeur_physique": "Check grandeur physique",
            "check-Verification_Lieu": "Check lieu",
            "update": "Modifier",
            "status-intervention-realisee": "Réalisée",
            "status-intervention-prevue": "Prévue",
            "oui": "Oui",
            "non": "Non",
            "tous-les-n-jours": "Tous les {nJours} jours",
            "aucun-equipement-lieu-associe": "Aucun équipement/lieu associé",
            "aucune-verification-effectuee": "Aucune vérification effectuée",
            "sans-contrat": "Sans contrat",
            "du": "du",
            "au": "au",
            "le": "le",
            "documents-manquants": "Documents manquants"
        },
        "en": {
            "title-maintenance": "Corrective maintenance",
            "title-intervention-programmee": "Scheduled third-party intervention",
            "title-tache-session": "Recurring task session",
            "show-more": "Show more details",
            "open-maintenance": "Access maintenance",
            "plage-horaire": "Time slot",
            "localisation": "Location",
            "affectation": "Assignment",
            "statut": "Status",
            "documents": "Attached documents",
            "urgence": "Urgency",
            "bloquant": "Blocking",
            "libelle": "Label",
            "frequence": "Frequency",
            "categorie": "Category",
            "contrat": "Contract",
            "open-intervention": "Access intervention",
            "validation": "Validation",
            "libelle-tache": "Task label",
            "date-butoir": "Deadline date",
            "resume-progression": "Progress summary",
            "show-progression": "Show progress",
            "show-verifications": "Show verifications",
            "session-depassee": "Session expired",
            "session-non-commencee": "Session not started",
            "check-verification_equipement": "Equipment check",
            "check-relever_compteur": "Meter reading check",
            "check-relever_grandeur_physique": "Physical quantity measurement check",
            "check-verification_lieu": "Location check",
            "update": "Modify",
            "status-intervention-realisee": "Completed",
            "status-intervention-prevue": "Scheduled",
            "oui": "Yes",
            "non": "No",
            "tous-les-n-jours": "Every {nDays} days",
            "aucun-equipement-lieu-associe": "No equipment/location associated",
            "aucune-verification-effectuee": "No verification performed",
            "sans-contrat": "Without contract",
            "du": "from",
            "au": "to",
            "le": "",
            "documents-manquants": "Missing documents"
        }
    }
},
        watch: {
            value: {
                handler: function(val){
                    this.schedule = val;
                },
                deep: true
            }
        },
        methods:{
            openUpdate: function(){
                this.$emit("open-update", {
                    start: this.schedule.start,
                    end: this.schedule.end,
                    schedule: this.schedule
                });
            },
            openProgression: function(){
                if(this.can("PROGRESSION.PAGE")) this.$router.push({ name: '_verification_progression_tache', params: { tacheId: this.schedule.raw.tache.id} });
            },
            openVerifications: function(){
                if(this.can("VERIFICATIONS.PAGE")) this.$emit("open-verifications", this.schedule);
            },
            openIntervention: function(){
                if(this.can("INTERVENTION.PAGE")) this.$router.push({ name: '_intervention', params: { id: this.schedule.raw.id, datas: this.schedule.raw } });
            },
            openTache: function(){
                if(this.can("TACHES.PAGE")) this.$router.push({ name: '_taches', params: { id: this.schedule.raw.id, datas: this.schedule.raw } });
            },
            openCheck: function(){
                if(this.can("CHECKS.PAGE")){
                    if(this.schedule.raw.tache.type_tache=="Verification_equipement") {
                        if(this.schedule.raw.tache.isScanRequired==1) this.$router.push({name: "_process_verification_tache", params: { type: "equipement", tacheId: this.schedule.raw.tache_id }});
                        else this.$router.push({ name: '_verification_progression_tache', params: { tacheId: this.schedule.raw.tache.id} });
                    }else if(this.schedule.raw.tache.type_tache=="Relever_compteur") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "compteur", tacheId: this.schedule.raw.tache_id }});
                    }else if(this.schedule.raw.tache.type_tache=="Relever_Grandeur_physique") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "grandeur-physique", tacheId: this.schedule.raw.tache_id }});
                    }else if(this.schedule.raw.tache.type_tache=="Verification_Lieu") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "lieux", tacheId: this.schedule.raw.tache_id }});
                    }
                }
            },
            openInterventionUpdate: function(){
                this.$store.dispatch("InterventionsStore/setSelectedItem", this.schedule.raw);
                this.showInterventionUpdate = true;
            },
            openInterventionValidation: function(){
                this.$store.dispatch("InterventionsStore/setSelectedItem", this.schedule.raw);
                this.showInterventionValidation = true;
            },
            onSaveInterventionUpdate: function(){
                this.showInterventionUpdate = false;
                this.$store.dispatch("InterventionsStore/setSelectedItem", null);
                this.$emit("after-update-schedule", this.schedule);
            },
            onSaveInterventionValidation: function(){
                this.showInterventionValidation = false;
                this.$store.dispatch("InterventionsStore/setSelectedItem", null);
                this.$emit("after-update-schedule", this.schedule);
            },
            openMaintenanceDetail: function(){
                //console.log("OPEN DETAIL", this.schedule.raw, this.schedule.raw.id);
                this.$emit("open-schedule", {schedule: this.schedule, calendar: this.calendar});
                this.modalFm = true
            },
            openMaintenance: function(){
                if(this.can("MAINTENANCE.PAGE")) this.$router.push({ name: '_maintenance', params: { id: this.schedule.raw.id } });
            },
            closePopup: function(){
                this.$emit("close-popup", {schedule: this.schedule, calendar: this.calendar});
            }
        },
        computed: {
            getDateDebut: function(){
                if(this.schedule.raw.progression && this.schedule.raw.progression.dateDebut) return moment(this.schedule.raw.progression.dateDebut).format("DD/MM/YYYY");
                else if(this.isCurrentSession && this.schedule.raw.tache) return  moment(this.schedule.raw.tache.dateprochaineVerif).subtract(parseInt(this.schedule.raw.tache.periodicite), "days").format("DD/MM/YYYY");
                else return null;
            },
            getDateFin: function(){
                if(this.schedule.raw.progression && this.schedule.raw.progression.dateFin) return moment(this.schedule.raw.progression.dateFin).format("DD/MM/YYYY");
                else if(this.isCurrentSession && this.schedule.raw.tache) return  moment(this.schedule.raw.tache.dateprochaineVerif).format("DD/MM/YYYY");
                else return null;
            },
            tacheSessionIconColor: function(){
                if(this.isCurrentSession){
                    if(this.schedule.raw.tache){
                        if(this.schedule.raw.tache.nbEquipementChecked==this.schedule.raw.tache.nbEquipementToCheck) return "#3baa36";
                        else return "red";
                    }else return "#059cff";
                }else if(this.schedule.raw.progression){
                    if(this.schedule.raw.progression.verified==this.schedule.raw.progression.total) return "#3baa36";
                    else return "red";
                }else return "#059cff";
            },
            isCurrentSession: function(){
                if(this.schedule.raw.tache){
                    let scheduleDateStart = moment(this.schedule.start._date).format("YYYY-MM-DD");
                    let tacheProgressionDateDebut = moment(this.schedule.raw.tache.dateprochaineVerif).subtract(parseInt(this.schedule.raw.tache.periodicite), "days").format("YYYY-MM-DD");
                    let tacheProchaineVerif = moment(this.schedule.raw.tache.dateprochaineVerif).format("YYYY-MM-DD");
                    return scheduleDateStart>=tacheProgressionDateDebut && scheduleDateStart<tacheProchaineVerif;
                }else return false;
            },
            isBetweenProgression: function(){
                if(this.schedule.raw.progression){
                    let scheduleDateStart = moment(this.schedule.start._date).format("YYYY-MM-DD");
                    let tacheProgressionDateDebut = moment(this.schedule.raw.progression.dateDebut).format("YYYY-MM-DD");
                    let tacheProchaineVerif = moment(this.schedule.raw.progression.dateFin).format("YYYY-MM-DD");
                    return scheduleDateStart>=tacheProgressionDateDebut && scheduleDateStart<tacheProchaineVerif;
                }else return false;
            },
            isScheduleDansProgressionFuture: function(){
                if(this.schedule.raw.tache){
                    let scheduleDateStart = moment(this.schedule.start._date).format("YYYY-MM-DD");
                    let tacheProchaineVerif = moment(this.schedule.raw.tache.dateprochaineVerif).format("YYYY-MM-DD");
                    return scheduleDateStart>=tacheProchaineVerif;
                }else return false;
            },
            isCategorieLieu: function(){
                if(this.calendar.id=='intervention-programmee') return this.schedule.raw.contrat && this.schedule.raw.contrat.categorie && (this.schedule.raw.contrat.categorie.isGe==1 || this.schedule.raw.contrat.categorie.isGe=="1");
                else if(this.calendar.id=='tache-session') return this.schedule.raw.tache && (this.schedule.raw.tache.categorie_isGe==1 || this.schedule.raw.tache.categorie_isGe=="1");
                else return false;
            },
            getDateButoir: function(){
                return moment(this.schedule.raw.tache.dateprochaineVerif).format("DD/MM/YYYY");
            },
            plageHoraireStart: function(){
                if(this.schedule.start._date) return moment(this.schedule.start._date).format("DD/MM/YYYY HH:mm");
                else return moment(this.schedule.start).format("DD/MM/YYYY HH:mm");
            },
            plageHoraireEnd: function(){
                let startDate = moment(this.schedule.start._date?this.schedule.start._date:this.schedule.start).format("DD/MM/YYYY");
                let endDate = moment(this.schedule.end._date?this.schedule.end._date:this.schedule.end).format("DD/MM/YYYY");
                if(startDate==endDate) return moment(this.schedule.end._date?this.schedule.end._date:this.schedule.end).format("HH:mm");
                else return moment(this.schedule.end._date?this.schedule.end._date:this.schedule.end).format("DD/MM/YYYY HH:mm");
            },
            getPosition: function(){
                //console.log("GET POSITION", this.event, this.event.x, this.event.y);
                let event = this.event;
                var heightScreen = window.innerHeight;
                //console.log(heightScreen);
                var toptPos = 0;
                if (event.pageY >= heightScreen-300) {
                    toptPos = event.pageY - 330
                }
                else{
                    toptPos = event.pageY + 30;
                }
                var leftPos = event.pageX - 150;
                //console.log(toptPos ,leftPos);
                return "position:absolute !important;top:"+toptPos+"px !important;left:"+leftPos+"px !important;";
            }
        }
    });
</script>

<style lang="scss" scoped>
.tui-full-calendar-popup-detail{
    z-index: 1003;
    min-width: 350px !important;
}
.tui-full-calendar-popup-container{
    padding:0;
    min-width: 350px !important;
    font-size: 12px;
    .header{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #d7d7d7;
        padding: 10px 20px;
    }
    .content{
        padding: 10px 20px;
    }
    .footer{
        background-color: #d7d7d7;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px 20px;
    }
}
.header-title{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    .icon{
        width: 20px;
        height: 20px;
    }
}
.content-grid{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    .grid-title{
        color: #d1d1d1;
    }
    .grid-value{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        .intervention-prevue{
            color: red;
        }
        .intervention-realisee{
            color: #45b348;
        }
        .icon{
            width: 15px;
            height: 15px;
        }
        .affecte{
            
        }
    }
}
</style>
