var LandingPageSelector = /** @class */ (function () {
    function LandingPageSelector() {
    }
    LandingPageSelector.prototype.getLandingPage = function (user) {
        switch (user.role) {
            case "ROLE_BASIC":
                return "/demandeintervention";
            case "ROLE_BASIC_VERIFICATEUR":
                return "/demandeintervention";
            case "ROLE_CHEF_EQUIPE":
                return "/lancement";
            default:
                return "/maintenances";
        }
    };
    return LandingPageSelector;
}());
export default LandingPageSelector;
