import { render, staticRenderFns } from "./VgCalendar.vue?vue&type=template&id=122e80ce&scoped=true&"
import script from "./VgCalendar.vue?vue&type=script&lang=js&"
export * from "./VgCalendar.vue?vue&type=script&lang=js&"
import style0 from "./VgCalendar.vue?vue&type=style&index=0&id=122e80ce&prod&lang=css&"
import style1 from "./VgCalendar.vue?vue&type=style&index=1&id=122e80ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122e80ce",
  null
  
)

export default component.exports