<template>
    <div class="vg-body-login" v-app-loader="isCheckingCredentials">
        <div class="login-screen">
            <div class="content-login-page">

                    <!--div style="display:flex;justify-content:center;"><vg-files-logo-viewer /></div-->
                    <div>
                        <form onsubmit="return false;" v-on:keyup.enter="logIn">
                            <div class="login-img">
                                <img src="static/assets/icone/new-logo-verifgood-jaune-gris.png" alt="logo-Verifgood-jaune">
                            </div>
                            <div class="login-form" >
                                <vg-invalid-login v-if="showInvalidLogin" />
                                <input id="email" type="email" class="form-control login-input" v-model="email" :placeholder="$t('email')" required>
                                <div class="login-input-password">
                                    <input id="password" :type="passwordType" class="form-control login-input" v-model="password" :placeholder="$t('password')" required>
                                    <span class="input_suffix" @click="checked">
                                        <span class="input_suffix-inner">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                    </span>
                                </div>
                                <!--input type="checkbox" @click="checked" /> <span style="font-size:12px;">{{$t("show-password")}}</span-->
                            </div>
                            <div class="login-footer">
                                <vg-button v-if="$auth0.isAuthenticated()" type="primary" @click="auth" style="margin-right: 10px;">Login Auth0</vg-button>
                                <vg-button v-if="$auth0.isAuthenticated()" type="primary" @click="logout" style="margin-right: 10px;">Logout Auth0</vg-button>
                                <vg-button v-if="$auth0.isAuthenticated()" type="primary" @click="getUserInfo" style="margin-right: 10px;">get user</vg-button>
                                <vg-button id="login_button"  type="success"
                                    :disabled="(email && email.length==0) || (password && password.length==0)"
                                    @click="logIn">
                                    {{ $t('desktop.log-btn') }}
                                </vg-button>
                            </div>

                        </form>

                    </div>
                    <vg-form-reset-password></vg-form-reset-password>
            </div>
            <br>
            <br>
            <br>
            <div class="credits">
                <div class="logos">
                    <img src="static/assets/credits/logo_ue.png" :alt="$t('logo-ue')" :title="$t('logo-ue')" style="margin-right: 20px;">
                    <img src="static/assets/credits/logo_region_reunion.png" :alt="$t('logo-region-reunion')" :title="$t('logo-region-reunion')">
                </div>
                <span class="text">{{$t("financement-ue-region-reunion")}}</span>
            </div>
        </div>
        <img v-show="false" v-if="accountLogoUrl" :src="accountLogoUrl"/>
        <vg-fin-demo-modal v-if="showFinDemoModal"
            @close="showFinDemoModal=false;" />
    </div>
</template>
<script>
import personalParameters from 'src/mixins/personnalParameters';
import pubnubMixins from 'src/mixins/pubnubMixins';
import UserGuidingMixins from 'src/mixins/UserGuidingMixins.js';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgFormResetPassword from 'src/components/Vg/Utils/VgFormResetPassword.vue';
import VgFilesLogoViewer from 'src/components/Vg/Files/VgFilesLogoViewer.vue';
import VgFinDemoModal from 'src/components/Vg/Utils/VgFinDemoModal.vue';
import VgInvalidLogin from 'src/components/Vg/Utils/VgInvalidLogin.vue';
import { Auth0Client } from '@auth0/auth0-spa-js';

export default {
    name: 'login',
    components:{
        VgButton,
        VgFormResetPassword,
        VgFilesLogoViewer,
        VgFinDemoModal,
        VgInvalidLogin
    },
    mixins:[personalParameters, pubnubMixins, UserGuidingMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "financement-ue-region-reunion": "Le projet conception et expérimentation de l’outil Verifgood est cofinancé par l’Union Européenne et la Région Réunion",
            "logo-ue": "Union Européenne",
            "logo-region-reunion": "Région Réunion",
            "email": "Email",
            "password": "Mot de Passe",
            "desktop": {
                "log-btn": "Se connecter",
                "feedback-error-login": "Le login ou mot de passe est incorrecte"
            },
            "show-password": "Montrer le mot de passe"
        },
        "en": {
            "desktop": {
                "log-btn": "Sign in",
                "feedback-error-login": "Login or password incorrect"
            },
            "email": "Email",
            "password": "Password",
            "financement-ue-region-reunion": "The design and experimentation project of the Verifgood tool is co-financed by the European Union and the Region Réunion ",
            "logo-ue": "European union",
            "logo-region-reunion": "Région Réunion",
            "show-password": "Show password"
        },
        "th": {
            "desktop": {
                "log-btn": "ลงชื่อเข้าใช้งาน",
                "feedback-error-login": "การเข้าสู่ระบบหรือรหัสผ่านไม่ถูกต้อง"
            },
            "financement-ue-region-reunion": "โครงการออกแบบและการทดลองของเครื่องมือ Verifgood ได้รับความช่วยเหลือทางการเงินโดยสหภาพยุโรปและภูมิภาครียูเนี่ยน ",
            "logo-ue": "สหภาพยุโรป",
            "logo-region-reunion": "ภูมิภาครียูเนี่ยน"
        }
    }
},
    data:function() {
        return {

            email: "",
            password: "",
            isCheckingCredentials:false,
            user: {
                idUser: null,
                appID: null,
                oAuthKey: null,
                restrictionsite: null
            },
            endpointlogin: '/api/remote_logins',
            showPassword: false,
            showFinDemoModal: false,
            showInvalidLogin: false
        };
    },
    computed: {
        passwordType: function(){
            return this.showPassword?"text":"password";
        }
    },
    methods: {
        checked: function( isChecked ) {
            this.showPassword = !this.showPassword;
        },
        /**
        * @param String storageKey
        * @param String data
        */
        saveInSessionStorage: function(storageKey, data){
            window.sessionStorage.setItem(storageKey, data);
        },
        redirect: function(user){
            if(!user)return false;
            if(user.role=="ROLE_BASIC") this.personalParameters_setLandingPage("/demandeintervention");
            else if(user.role=="ROLE_CHEF_EQUIPE") this.personalParameters_setLandingPage("/lancement");
            else this.personalParameters_setLandingPage("_maintenances");
            var fallbackPage = localStorage.getItem('personalParameters_fallbackpage');
            if(fallbackPage == "/verification/activites"){  // HACK pour virer la 1st page fil verif en maintenance
                fallbackPage = this.personalParameters_getDefaultLandingPage();
                localStorage.setItem("personalParameters_fallbackpage", fallbackPage);
            }
            var key = this.personalParameters_getlandingPage();
            if(fallbackPage == "/maintenance") this.personalParameters_setLandingPage("_maintenances");
            if(key=="/maintenance") this.personalParameters_setLandingPage("_maintenances");
            if(key == "/verification/activites"){  // HACK pour virer la 1st page fil verif en maintenance
                key = this.personalParameters_getDefaultLandingPage();
                this.personalParameters_setLandingPage(key);
            }
            if( fallbackPage !== null && fallbackPage != "" && fallbackPage !="/"){
                if(user.role=="ROLE_BASIC"){
                    fallbackPage = this.personalParameters_getDefaultLandingPage("ROLE_BASIC");
                }else if(user.role=="ROLE_CHEF_EQUIPE"){
                    fallbackPage = this.personalParameters_getDefaultLandingPage("ROLE_CHEF_EQUIPE");
                }
                this.$router.push(fallbackPage);
            }else{
                if(!key){
                    key = this.personalParameters_getDefaultLandingPage();
                }
                //ATTENTION replication code
                //window.history.pushState("", "", key);
                if(user.role=="ROLE_BASIC"){
                    key = this.personalParameters_getDefaultLandingPage("ROLE_BASIC");
                }else if(user.role=="ROLE_CHEF_EQUIPE"){
                    key = this.personalParameters_getDefaultLandingPage("ROLE_CHEF_EQUIPE");
                }
            }
            //window.history.pushState("", "", key);
			if(user.role=="ROLE_BASIC"){
				key = this.personalParameters_getDefaultLandingPage("ROLE_BASIC");
			}else if(user.role=="ROLE_CHEF_EQUIPE"){
				key = this.personalParameters_getDefaultLandingPage("ROLE_CHEF_EQUIPE");
			}
            if( (window.location.pathname == "" || window.location.pathname == "/" || window.location.pathname == "/login") ){

                if(user.role=="ROLE_BASIC") {
                    this.$router.push("/demandeintervention");
                }else if(user.role=="ROLE_CHEF_EQUIPE"){
                    this.$router.push("/lancement")
                }else{
                    this.$router.push("/maintenances")
                }
            }
            //location.reload();
            localStorage.removeItem('personalParameters_fallbackpage');
        },

        /**
         * @param integer hours
         * @return string
         */
        setSessionExpirationDate(hours){
            let now = moment().add(hours, 'h');
            return now.format();
        },
        /**
        * Get user session.
        *
        * @return Promise
        */
        getUserSession:function(){
            return new Promise((resolve, reject) => {
                let query = {
                    metadatas: {"directives":[],"filters":[]},
                };
                this.$rc.get("/api/user/auth/infos", query,
                    (datas, meta) => {
        				resolve(datas);
        			},
                    (err) => {
        				reject(err);
        			}
                );
            });
        },
        formatUser:function(response){
            return Object.assign({}, response, {
                idUser: response.user,
                fullname: response.prenom && response.prenom.length!=0?response.nom+" "+response.prenom:response.nom,
                role: response.role[0],
                isSupervisor: response.role[0]=="ROLE_ADMIN" && response.isFmClosedBySuperviseur,
                underSupervisor: response.role[0]=="ROLE_TECHNICIEN" && response.isFmClosedBySuperviseur,
                __fconf: JSON.stringify(response.__fconf)
            });
        },
        checkCookieAuth:function(){
            this.getUserSession().then((response)=>{
                this.isCheckingCredentials = false;
                if(response.message = "VALID_LOGIN"){
                    this.initAccount(response);
                }
            }).catch((err)=>{
                this.isCheckingCredentials = false;
            });
        },
        /**
         * @param string token
         * @return {Promise}
         */
        checkAuthToken:function(token){
            return new Promise((resolve, reject) => {
                let query = {
                    token: token,
                };
                this.$rc.get("/api/auth/verify", query,
                    (datas, meta) => {
                        this.isCheckingCredentials = false;
        				resolve(datas);
        			},
                    (err) => {
                        console.warn("auth error",err)
                        this.isCheckingCredentials = false;
        				reject();
        			}
                );
            });
        },
        getAccountLogo: function(){
            this.VgFilesMixins_getBase64Logo();
        },
        initAccount: function(response){
            let user = this.formatUser(response);
            this.saveInSessionStorage("user", JSON.stringify(user));
            this.saveInSessionStorage("exclude_categorie_tags", user["exclude_categorie_tags"]);
            this.saveInSessionStorage("exclude_categorie_tags_user_parameter_id", user["exclude_categorie_tags_user_parameter_id"]);
            localStorage.setItem('_expired_at', this.setSessionExpirationDate(10));
            this.VgFilesMixins_initializeFirebase();
			this.VgFilesMixins_connectToFirebase().then(()=>{
                this.getAccountLogo();
                this.pubnubMixins_connect(user);
                //this.UserGuidingMixins_identify(user);
                this.isCheckingCredentials=false;
                this.redirect(user);
            });
        },
        logIn: function(){
                if( !this.email.length ) return false;
                if( !this.password.length ) return false;
                this.isCheckingCredentials=true;
                var rc = this.$rc;
                rc.setDispatchResponse(false);
                var query = {
                    'email': this.email,
                    'password': this.password
                };
                rc.post("/api/remote_logins", query, (response)=> {
                    /*console.log("*** LOGIN ***");
                    console.log(JSON.stringify(response));*/
                    if( response && response.user ){
                        this.initAccount(response);
                    }else if( response && !response.user ){
                        if(response.message == "INVALID_LOGIN") alert(this.$t("desktop.feedback-error-login"));
                        this.isCheckingCredentials=false;
                    }else{  // !response
                        this.isCheckingCredentials=false;
                    }
                }, (err)=>{
                    //console.log(err);
                    this.showInvalidLogin = true;
                    this.isCheckingCredentials=false;
                });
                return false;

        },
        auth(){
            this.$auth0.loginWithRedirect({
                appState: {
                    targetPath: "/auth_",
                }
            });
        },
        logout(){
            this.$auth0.logout(    {
                logoutParams: {
                    returnTo: window.location.origin
                }
            })
        },
        getUserInfo(){
            this.$auth0.getUser().then((user)=>{
                console.log({user});
            });
        }
    },
    mounted(){
        
    },
    created:function(){
        //this.$auth0.handleRedirectCallback();
        window.addEventListener('RestClientError',(data)=>{
            if(data.detail == "DEMO_IS_OVER"){
                this.$message(this.$t("demo-is-over"),{type:"warning",timeout:10000});
                this.showFinDemoModal = true;
            }
        });
        // un hack pas fiable. il faut vraiment vérifié l'authentification est valide
        // avant de redirigé
        if(this.$route.query.token){
            this.checkAuthToken(this.$route.query.token).then((response)=>{
                if(!response) return false;
                this.initAccount(response);
            });
        }else{
            this.checkCookieAuth();
        }
        let isLogin = window.sessionStorage.getItem("user");
        if(isLogin) this.redirect(isLogin);
    }

};
</script>
<style lang="scss" src="./Login/Login.scss">

</style>
