<template>
      <vg-app-layout
         :title="pageTitle"
         :icon="$vgutils.isMobile()?'cle_blanc.png':'fiche-de-maintenance.png'"
         @save-filters="FiltersMixins_saveFilters(agfilters)"
         :colorheader="getHeaderColor"
         :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
         :filtersTitle="$t('modal-filters-title')"
         @action-export="handleActionExport"
         @remove-filters="onRemoveFilters"
         @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters"
         
         >
         <template v-slot:action-button-item >
             <li @click="openRapportPrinter">{{$t("generer-rapport")}}</li>
         </template>

         <template v-slot:search>
             <vg-text-filter
                 v-model="searchQuery">
             </vg-text-filter>
         </template>
         <template #header-top-right-pre>
             <img src="/static/assets/icone/mobile/scanner.png" width="28" height="28" @click="showScanner=true;"/>
         </template>

         <template #panel-filters-attributs>
             <vg-input>
                 <vg-checkbox
                     :inputValue="agfilters.field_urgence.value"
                     :defaultChecked="agfilters.field_urgence.value"
                     @checked="agfilters.field_urgence.value=true"
                     @unchecked="agfilters.field_urgence.value=false">
                     <img src="static/assets/icone/urgence.png" style="width:30px;height: 30px;"/>
                     <span>{{$t('urgence')}}</span>
                 </vg-checkbox>
             </vg-input>
             <br>
             <vg-input>
                 <vg-checkbox
                     :inputValue="agfilters.field_isBloquant.value"
                     :defaultChecked="agfilters.field_isBloquant.value"
                     @checked="agfilters.field_isBloquant.value=true"
                     @unchecked="agfilters.field_isBloquant.value=false">
                     <img src="static/assets/icone/maintenance-bloquante-red.svg" style="width:30px;height: 30px;"/>
                     <span>{{$t('bloquantes')}}</span>
                 </vg-checkbox>
             </vg-input>
             <br>
             <vg-input
                 :placeholder="$t('corps-detat')"
                 :title="$t('corps-detat')">
                 <vg-corps-detat-selector
                     v-model="agfilters.field_corpsDetat.value"
                     @change="handleChangeCorpsDetat" />
             </vg-input>
             <br>
             <vg-input
                 :placeholder="$t('affectes')"
                 :title="$t('affectes')">
                    <vg-users-selector v-model="agfilters.field_user_affecte.value" />
                    <vg-tiers-selector v-model="agfilters.field_tier_affecte.value" />
             </vg-input>
             <br>
             <vg-filter-maintenance-origine  @input="handleChangeOriginFilter"/>
         </template>
         <template #panel-filters-localisation>
             <vg-filter-site
                v-model="agfilters.field_path.value">
            </vg-filter-site>
         </template>
         <template #panel-widget-lieux>
             <vg-tree-lieux v-model="agfilters.field_path.value"/>
         </template>

         <template v-slot:primary>
            <div id="maintenance-action-btn" v-show="getSelectedMaintenances.length && !$vgutils.isMobile()">
                <vg-button :type="'default'" @click="showClotureModal=true;">
                    {{$t("cloturer")}}
                </vg-button>

                <vg-button :type="'default'" @click="handlePriseEnCompte">
                    {{$t("prendre-en-compte")}}
                </vg-button>

                <vg-button :type="'default'" @click="handleMiseEnAttente">
                    {{$t("mettre-en-attente")}}
                </vg-button>

                <!--vg-button :type="'default'" @click="handleRetourAFaire">
                    {{$t("retour-a-faire")}}
                </vg-button-->
                <vg-button :type="'default-danger'" @click="openDialogBoxConfirmationSuppression"
                    v-show="getSelectedMaintenances.length == 1" >
                    {{$t("supprimer")}}
                </vg-button>
            </div>

            <div v-if="$vgutils.isMobile() && showInfo" style="width:100%;color:white;display:flex;justify-content:space-between;background-color:#059cff;padding:5px 0;">
                <span style="width:90%;text-align:center;">{{$t("info-long-click-mobile")}}</span>
                <div @click="onCloseInfo()" style="text-align:center;width:10%;"><i class="fas fa-times"></i></div>
            </div>
            <vg-tabs class="hidden-print"
                v-model="focusedTab"
              :tabs="fmStatusTabs"
              :color="'jaune'"
              @tabClicked="handleTabClick">
            </vg-tabs>
            <vg-maintenance-timeline v-if="$vgutils.isMobile() && focusedTab && focusedTab=='mesAffectationsPlanifiees'"
                :agfilters="agfilters"
                mesAffectations>
            </vg-maintenance-timeline>
            <vg-fichesav-table v-else-if="$vgutils.isMobile() && focusedTab && focusedTab=='mesAffectationsNonPlanifiees'"
                :searchQuery="searchQuery"
                :filters="maintenanceFilters"
                :counterName="getFocusedTabCounterName"
                :page="'maintenances'" />
            <vg-fichesav-table v-else
                :searchQuery="searchQuery"
                :filters="maintenanceFilters"
                :showColumnsFinances="showColumnsFinances"
                :counterName="getFocusedTabCounterName"
                :page="'maintenances'" />
         </template>

         <template v-slot:pagination>
         </template>

         <template #secondary-filters>
             <vg-button v-show="showColumnsFinances" @click="showColumnsFinances=!showColumnsFinances"  >
                 {{$t("cacher-couts")}}
             </vg-button>
             <vg-button v-show="!showColumnsFinances" @click="showColumnsFinances=!showColumnsFinances"  >
                 {{$t("afficher-couts")}}
             </vg-button>
             <vg-input :title="$t('filter-Date')" >
                <vg-filter-interval-date
                    :defaultStartDate="agfilters.field_dateOuvertureSAV.value?agfilters.field_dateOuvertureSAV.value[0]:null"
                    :defaultEndDate="agfilters.field_dateOuvertureSAV.value?agfilters.field_dateOuvertureSAV.value[1]:null"
                   @change="handleFilterIntervalDate" />
             </vg-input>
             <vg-collapse :title="$t('filtres-complementaires')"
                :killContentOnCollapse="false">
                <template #content>
                    <vg-filter-maintenance-origine  @input="handleChangeOriginFilter"/>
                    <vg-typologie-maintenance-filter v-show="hasAtLeastOneTypologieMaintenance" v-model="agfilters.field_typologie.value"/>
                    <vg-filter-site v-model="agfilters.field_path.value" />
                    <vg-filter-service v-model="agfilters.field_service.value" />
                    <vg-input
                        :placeholder="$t('corps-detat')"
                        :title="$t('corps-detat')">
                        <vg-corps-detat-selector
                            v-model="agfilters.field_corpsDetat.value" 
                            @change="handleChangeCorpsDetat" />
                    </vg-input>
                    <vg-input
                        :placeholder="$t('affectes')"
                        :title="$t('affectes')">
                            <vg-users-selector v-model="agfilters.field_user_affecte.value" />
                            <vg-tiers-selector v-model="agfilters.field_tier_affecte.value" />
                    </vg-input>
                </template>
             </vg-collapse>
             <br>
         </template>
         <template #secondary-widget>
             <vg-tree-lieux v-model="agfilters.field_path.value" />
         </template>

         <dialog-secured-action
           v-if="isModalConfirmDeleteOpened"
           :valid-response="getSelectedMaintenances.length"
           @save="handleSupprimerMaintenance"
           @close="isModalConfirmDeleteOpened = false"
         >
           <template v-slot:header-title>
             {{ $t("user-form-confirm-suppression") }}
           </template>
         </dialog-secured-action>

         <vg-modal v-if="showScanner"
            :title="$t('scanner-une-etiquette')"
            @close="showScanner=false;">
            <template #body>
                <zxing-scanner
                    :isDemo="false"
                    @scanSuccess="onScannSuccess" />
            </template>
            <template #footer>
                <vg-button @click="showScanner=false;">
                    {{$t('close')}}
                </vg-button>
            </template>
         </vg-modal>

         <vg-maintenances-cloture-form v-if="showClotureModal"
            @close="showClotureModal=false;"
            @save="onSaveClotures"/>

      </vg-app-layout>
</template>

<script>
    import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
    import LieuMixins from "src/mixins/LieuMixins.js";
    import FiltersMixins from "src/mixins/FiltersMixins.js";

    import VgFichesavTable from 'src/components/Vg/FicheSAV/VgFichesavTable.vue';
    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgFilterIntervalDate from "src/components/Vg/FicheSAV/VgFilterIntervalDate.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
    import VgGroup from "src/components/Vg/VgGroup.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import Metadatas from "src/services/Metadatas.js";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgMaintenanceTimeline from 'src/components/Vg/FicheSAV/VgMaintenanceTimeline.vue';
    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgTypologieMaintenanceFilter from "src/components/Vg/Filters/VgTypologieMaintenanceFilter.vue";
    import VgCorpsDetatSelector from "src/components/Vg/Selectors/VgCorpsDetatSelector.vue";
    import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';
    import VgUsersSelector from 'src/components/Vg/Selectors/VgUsersSelector.vue';
    import VgTiersSelector from 'src/components/Vg/Selectors/VgTiersSelector.vue';
    import VgFilterMaintenanceOrigine from "src/components/Vg/Filters/VgFilterMaintenanceOrigine.vue";
    import VgMaintenancesClotureForm from "src/components/Vg/Forms/VgMaintenancesClotureForm.vue";
    import zxingScanner from "src/components/QrCode/Zxing/zxingScanner.vue";

    import { mapGetters } from 'vuex';
    export default {
        name: 'maintenances',
        components: {
            VgFichesavTable,
            VgTabs,
            VgTextFilter,
            VgFilterIntervalDate,
            VgInput,
            VgSelect,
            VgCollapse,
            VgCheckbox,
            VgTreeLieux,
            VgGroup,
            VgButton,
            VgModal,
            DialogSecuredAction,
            VgMaintenanceTimeline,
            VgFilterSite,
            VgCorpsDetatSelector,
            VgTypologieMaintenanceFilter,
            zxingScanner,
            VgFilterService,
            VgUsersSelector,
            VgTiersSelector,
            VgFilterMaintenanceOrigine,
            VgMaintenancesClotureForm
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "encours": "en cours",
            "resolue": "clôturées",
            "corps-detat": "Filtrage par corps d'état",
            "affectes": "Fiche affectée à",
            "urgent": "urgentes",
            "bloquantes": "bloquantes",
            "urgence": "urgence",
            "retourAFaire": "retours à faire",
            "relance": "relance",
            "supervisor": "A valider",
            "non-affecte": "non affectées",
            "mes-affectations": "Mes affectations",
            "mes-affectations-planifiees": "Planifiées",
            "mes-affectations-non-planifiees": "Non planifiées",
            "prendre-en-compte": "Prendre en compte",
            "retour-a-faire": "Retour à faire",
            "mettre-en-attente": "Mettre en attente",
            "supprimer": "Supprimer",
            "cloturer": "Clôturer",
            "toutes": "toutes",
            "filter-Site": "Site",
            "filter-Date": "intervalle dates",
            "filtres": "Filtres",
            "page-title": "Fiches curatives",
            "generer-rapport": "Imprimer rapport",
            "user-form-confirm-suppression": "Vous allez supprimer des fiches cette action est irréversible.",
            "afficher-couts": "Afficher les coûts",
            "cacher-couts": "Cacher les coûts",
            "liste-maintenances": "Liste-fiches-curatives",
            "info-long-click-mobile": "Nouveau: clic long ouvre les actions",
            "modal-filters-title": "Filtrer les fiches curatives",
            "close": "Fermer",
            "filtrage-sauvegarde": "Les filtres ont étés mémorisés",
            "scanner-une-etiquette": "Scanner une étiquette",
            "filtres-complementaires": "Filtres complémentaires"
        },
        "en": {
            "encours": "pending",
            "resolue": "closed",
            "corps-detat": "Filtered by technical field",
            "affectes": "Sheet assigned to",
            "urgent": "urgent",
            "bloquantes": "blocking",
            "retourAFaire": "Feedback",
            "relance": "reminder",
            "supervisor": "To validate",
            "non-affecte": "Not assigned",
            "mes-affectations": "My assignments",
            "mes-affectations-planifiees": "Planned",
            "mes-affectations-non-planifiees": "Unplanned",
            "toutes": "all",
            "filter-Site": "Site",
            "filter-Date": "date interval",
            "filtres": "Filters",
            "page-title": "Curative sheets",
            "generer-rapport": "Print report",
            "user-form-confirm-suppression": "Are you sure to delete permanently these sheets.",
            "prendre-en-compte": "Consider",
            "retour-a-faire": "Require a Feedback",
            "mettre-en-attente": "Put on hold",
            "supprimer": "Delete",
            "cloturer": "Resolve",
            "afficher-couts": "Display cost columns",
            "cacher-couts": "Hide cost columns",
            "urgence": "Emergency",
            "liste-maintenances": "Curative-sheets-list",
            "info-long-click-mobile": "New: long click opens actions",
            "modal-filters-title": "Filter curative sheets",
            "close": "Close",
            "filtrage-sauvegarde": "Filters has been saved",
            "scanner-une-etiquette": "Scan a label",
            "filtres-complementaires": "Additional filters"
        }
    }
},
		mixins:[
            MaintenanceMixins,
            LieuMixins,
            FiltersMixins
        ],
        props:{
            openScanner: {
                type: Boolean,
                default: false
            },
            defaultFilters: {
                type: Object,
                default: function(){
                    return {};
                }
            }
        },
        data: function() {
            return {
    			searchQuery: "",
                focusedTab: null,
                isModalConfirmDeleteOpened:false,
                agfilters: {
                    id: { attr:"fm.id",colId: "id", value: null, action:"contains", openParenthesis: true },
                    libelEquipement: { attr:"eq.libel_equipement",colId: "libel_equipement", value: null, action:"contains", logicalOperator: "OR" },
                    libelComposant: { attr:"comp.libelComposant",colId: "libelComposant", value: null, action:"contains", logicalOperator: "OR" },
                    userNomAffecte: { attr:"fuaffecte.nom",colId: "nom_affecte", value: null, action:"contains", logicalOperator: "OR" },
                    userPrenomAffecte: { attr:"fuaffecte.prenom",colId: "prenom_affecte", value: null, action:"contains", logicalOperator: "OR" },
                    tiersAffecte: { attr:"ti.name",colId: "tiers_name", value: null, action:"contains", logicalOperator: "OR" },
                    commentaireClient: { attr:"fm.commentaireClient",colId: "commentaireClient", value: null, action:"contains", logicalOperator: "OR", closeParenthesis: true },
                    field_typologie:{attr:"fm.typologie",colId: "typologie", value: null, action:"equals"},
                    field_origin:{attr:"fm.origin",colId: "origin", value: null, action:"equals"},
                    field_service:{attr:"l.service",colId: "service", value: null, action:"equals"},
                    field_path: { attr:"l.path",colId: "path", value: null, action:"contains" },
                    field_corpsDetat: { attr:"ce.id",colId: "ce.id", value: null, action:"equals" },
                    field_user_affecte: {attr:"affu.user_id", value: null, action:"equals"},
                    field_tier_affecte: {attr:"afft.tiers_id", value: null, action:"equals"},
                    field_statut: { attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains" },
                    field_dateOuvertureSAV: { attr:"fm.dateOuvertureSAV",colId: "dateOuvertureSAV", value: null, action:"between" }, // value = [start,end]
                    field_dateFermetureSAV: { attr:"fm.dateFermetureSAV",colId: "dateFermetureSAV", value: null, action:"between" },
                    field_urgence : {attr:"fm.urgence",colId: "urgence", value:false, action:"equals"},
                    field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                    field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:false, action:"equals"},
                    field_relance: {attr:"fm.isRelance",colId: "relance", value:false, action:"equals"},
                    field_affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                    field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                    field_codeEquipement: {attr: "eq.qrCode", value: null, action: "equals", openParenthesis: true},
                    field_codeLieu: {attr: "l.codeUn", value: null, action: "equals", closeParenthesis: true, logicalOperator: "OR"}
                },
                showColumnsFinances:false,
                showInfo: true,
                showScanner: this.openScanner,
                showClotureModal: false
            }
        },
        watch:{
            agfilters:{
                handler:function(nfilters){
                    // à améliorer je fais ça pour le tableau des maintenances qui attend un tableau en entrée
                    // je ne peut pas lui injecter directement agFilters dans sa property filters
                    //this.maintenanceFilters = Object.values(nfilters);
                    this.FiltersMixins_saveFilters(nfilters);
                },
                deep:true
            },
            searchQuery:{
                handler: function(query){
                    if (query != null && query.length) {
                        let searchQuery = query.split(" ").join('","');
                        this.agfilters.id.value = searchQuery;
                        this.agfilters.libelEquipement.value = searchQuery;
                        this.agfilters.libelComposant.value = searchQuery;
                        this.agfilters.userNomAffecte.value = searchQuery;
                        this.agfilters.userPrenomAffecte.value = searchQuery;
                        this.agfilters.tiersAffecte.value = searchQuery;
                        this.agfilters.commentaireClient.value = searchQuery;
                    }else {
                        this.agfilters.id.value = null;
                        this.agfilters.libelEquipement.value = null;
                        this.agfilters.libelComposant.value = null;
                        this.agfilters.userNomAffecte.value = null;
                        this.agfilters.userPrenomAffecte.value = null;
                        this.agfilters.tiersAffecte.value = null;
                        this.agfilters.commentaireClient.value = null;
                    }
                    this.FiltersMixins_saveSearchQuery(query);
                }
            }
        },
        methods:{
            onSaveClotures: function(datas){
                this.showClotureModal = false;
                let idMaintenances = datas.map((data)=>data.id);
                let modifiedMaintenances = [];
                let copyMaintenances = [...this.getMaintenances];
                copyMaintenances.forEach((maintenance, index)=>{
                    if(idMaintenances.indexOf(maintenance.id) != -1){
                        modifiedMaintenances.push(Object.assign({}, maintenance, {
                            statut: "Resolue"
                        }));
                        modifiedMaintenances[index].statut = "Resolue";
                        this.$store.dispatch("MaintenancesStore/deleteMaintenance", maintenance.id);
                    }
                });
                this.$store.dispatch("MaintenancesStore/decrementCounter", {name:"En_cours",qty:this.getSelectedMaintenances.length});
                this.$store.dispatch("MaintenancesStore/incrementCounter", {name:"Resolue",qty:this.getSelectedMaintenances.length});
                this.$store.dispatch("MaintenancesStore/setMaintenances", maintenances);
                this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
            },
            deleteGlobalFilters: function(){
                let filtersName = Object.keys(this.agfilters);
                let filters = {};
                let filter = {};
                filtersName.forEach((name)=>{
                    filter = Object.assign({}, {}, this.agfilters[name]);
                    if(name.startsWith("global_")) filter.value = null;
                    filters[name] = Object.assign({}, {}, filter);
                });
                this.agfilters = filters;
                this.FiltersMixins_saveFilters(this.agfilters);
            },
            onGlobalFiltersChange: function(filters){
                this.onRemoveFilters();
                this.agfilters = Object.assign({}, this.agfilters, filters);
            },
            onScannSuccess: function(code){
                this.agfilters.field_codeEquipement.value = code;
                this.agfilters.field_codeLieu.value = code;
                this.showScanner = false;
            },
            onRemoveFilters: function(){
                this.pageFilters.forEach((filterName)=>{
                    if(filterName!="urgence") this.agfilters[filterName].value = null;
                    else this.agfilters[filterName].value = false;
                });
                this.agfilters.field_dateOuvertureSAV.value = null;
                this.agfilters.field_dateFermetureSAV.value = null;
                this.agfilters.field_codeEquipement.value = null;
                this.agfilters.field_codeLieu.value = null;
                this.agfilters.field_typologie.value = null;
                this.agfilters.field_corpsDetat.value = null;
                this.agfilters.field_user_affecte.value = null;
                this.agfilters.field_tier_affecte.value = null;
                this.searchQuery = null;
                this.FiltersMixins_deleteStoredCurrentFilters();
            },
            onCloseInfo: function(){
                this.showInfo = !this.showInfo;
                localStorage.setItem("maintenance-mobile-show-info", this.showInfo);
            },
            openRapportPrinter: function(){
                this.$router.push({ name: '_maintenances_print' })
                /*var event = new CustomEvent("askGenerateReport", {
                  detail: {"idTableau":"vg-fichesav-table"}
                });
                window.dispatchEvent(event);*/
            },
            handleTabClick: function(e){
                this.agfilters = Object.assign(this.agfilters, e.filters);
                this.FiltersMixins_saveDefaultTab(e.name);
                this.$store.dispatch("MaintenancesStore/setMaintenances", []);
    		},
            handleFilterIntervalDate:function(e){
                if(e.startDate && e.endDate){
                    this.agfilters["field_"+e.targetedColumn].value = [e.startDate,e.endDate];
                }else{
                    this.agfilters.field_dateOuvertureSAV.value = null;
                    this.agfilters.field_dateFermetureSAV.value = null;
                }
            },
            handleActionExport:function(e){
                let metadatas = new Metadatas();
                metadatas.setFilters(this.agfilters);
                switch (e.name) {
                    case "askForXLS":
                        this.MaintenanceMixins_getFile(metadatas,this.$t("liste-maintenances"),"xlsx").then((datas)=>{});
                        break;
                    case "askForCSV":
                        this.MaintenanceMixins_getFile(metadatas,this.$t("liste-maintenances"),"csv").then((datas)=>{});
                        break;
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                            "detail": {"idTableau":"vg-fichesav-table"}
                        }));
                        break;
                    default:
                }

            },
            handleRetourAFaire: function(){
                let maintenances = this.getSelectedMaintenances;
                this.MaintenanceMixins_setStatusMaintenances(maintenances,"retourAFaire").then((datas)=>{
                    let idMaintenances = datas.map((data)=>{
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance)=>{
                        if(idMaintenances.indexOf(maintenance["id"]) != -1){
                            maintenance["isRetourAFaire"] = true;
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", {name:"isRetourAFaire",qty:this.getSelectedMaintenances.length});
                    this.$store.dispatch("MaintenancesStore/setMaintenances", maintenances);
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                });

            },
            handleMiseEnAttente: function(){
                this.MaintenanceMixins_mettreEnAttenteMaintenances(this.getSelectedMaintenances).then(()=>{
                    let selectedMaintenances = this.getSelectedMaintenances;
                    let idMaintenances = selectedMaintenances.map((data)=>{
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance)=>{
                        if(idMaintenances.indexOf(maintenance["id"]) != -1){
                            maintenance["statut"] = "en_attente";
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", {name:"en_attente",qty:this.getSelectedMaintenances.length});
                });
            },
            handlePriseEnCompte: function(){
                this.MaintenanceMixins_prendreEnCompteMaintenances(this.getSelectedMaintenances).then(()=>{
                    let selectedMaintenances = this.getSelectedMaintenances;
                    let idMaintenances = selectedMaintenances.map((data)=>{
                        return data["id"];
                    });
                    let maintenances = this.getMaintenances.map((maintenance)=>{
                        if(idMaintenances.indexOf(maintenance["id"]) != -1){
                            maintenance["statut"] = "prise_en_compte";
                        }
                        return maintenance;
                    });
                    this.$store.dispatch("MaintenancesStore/incrementCounter", {name:"prise_en_compte",qty:this.getSelectedMaintenances.length});
                });
            },
            // END MUST BE REFACTORIZED
            openDialogBoxConfirmationSuppression: function(){
                this.isModalConfirmDeleteOpened = true;
            },
            handleSupprimerMaintenance: function(){
                let maintenance = this.getSelectedMaintenances[0];
                this.MaintenanceMixins_delete(maintenance).then(()=>{
                    this.isModalConfirmDeleteOpened = false;
                    this.$store.dispatch("MaintenancesStore/selectMaintenances", []);
                    this.$store.dispatch("MaintenancesStore/decrementCounter", {name:"En_cours",qty:this.getSelectedMaintenances.length});
                    this.$store.dispatch("MaintenancesStore/decrementCounter", {name:"all",qty:this.getSelectedMaintenances.length});
                    // il faut decrement aussi sur les onglets qui concerne la maintenance qui est delete
                });
            },
            handleChangeOriginFilter: function(e){
                this.agfilters.field_origin = e.filters.field_origin;
            }

        },
		created: function(){
            if(typeof(localStorage.getItem("maintenance-mobile-show-info"))=="string") this.showInfo = localStorage.getItem("maintenance-mobile-show-info")=="true";
            if(this.$app.role=="ROLE_SOUS_TRAITANT"){
                this.handleTabClick(this.mesAffectationsTab);
                this.$forceUpdate();
            }
            this.FiltersMixins_page_name = "maintenances";
            let filters = this.FiltersMixins_getStoredFilters();
            filters.field_dateOuvertureSAV.value = null;
            filters.field_dateFermetureSAV.value = null;
            this.agfilters = Object.assign(this.agfilters, this.defaultFilters, filters);
            this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
            if(this.FiltersMixins_getDefaultFocusedTab()) this.focusedTab = this.FiltersMixins_getDefaultFocusedTab();
		},
		mounted: function(){
            
		},
        computed:{
            ...mapGetters({
                  getSelectedMaintenances: 'MaintenancesStore/getSelectedMaintenances',
                  getMaintenances: 'MaintenancesStore/getMaintenances',
                  getMaintenancesCounters: 'MaintenancesStore/getMaintenancesCounters',
                  getMaintenancesAffectees: 'MaintenancesStore/getMaintenancesAffectees',
                  hasAtLeastOneTypologieMaintenance: "TypologiesMaintenanceStore/hasAtLeastOneTypologieMaintenance"

            }),
            pageFilters: function(){
                return ["field_path", "field_corpsDetat", "field_user_affecte", "field_tier_affecte", "field_codeEquipement", "field_typologie"];
            },
            activeFilters: function(){
                let filtersNames = this.pageFilters;
                let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
                let nFilters = 0;
                if(this.agfilters.field_dateOuvertureSAV.value || this.agfilters.field_dateFermetureSAV.value) nFilters += 1;
                if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
                return activeFilters.length + nFilters;
            },
            getFocusedTabCounterName: function(){
                let focusedTab = this.fmStatusTabs.find((t)=>t.name==this.focusedTab);
                if(focusedTab) return focusedTab.counterName;
                else return "En_cours";
            },
            maintenanceFilters: function(){
                return Object.values(this.agfilters);
            },
            /*getMesAffectations:function(){
                if(this.$app.role=="ROLE_SOUS_TRAITANT" && this.$app.tiers_id) return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByTiersId"](this.$app.tiers_id);
                else return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByIdUser"](this.$app.idUser);
            },*/
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
            },
            fmStatusTabs: function(){
                let tabs = [];
                // jutilise le isMobile pour séparer les tabs car ordonnencement
                // des tabs entre desktop et mobile sont différentes
                if(!this.$vgutils.isMobile()){
                    if(this.can("MAINTENANCES.TAB_EN_COURS")){
                        tabs.push({label: this.$t("encours"), name:"encours", counterName: "En_cours", counter: this.getMaintenancesCounters["En_cours"], filters:
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //field_affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}

                            }
                        });
                    }
                    if(this.$app.handleFmBloquante && this.can("MAINTENANCES.TAB_BLOQUANTES") ){
                        tabs.push({label: this.$t("bloquantes"), img: "static/assets/icone/maintenance-bloquante-red.svg", name:"bloquante",counterName: "bloquantes", isNotMobile:true, counter: this.getMaintenancesCounters["bloquantes"], filters:
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:true, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //field_affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_URGENTES") ){
                        tabs.push({label: this.$t("urgent"), name:"urgence",counterName: "urgence", isNotMobile:true, counter: this.getMaintenancesCounters["urgence"], filters:
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:true, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_NON_AFFECTES") ){
                        tabs.push({label: this.$t("non-affecte"), name:"nonAffecte",counterName: "nonAffectes", isNotMobile:true, counter: this.getMaintenancesCounters["nonAffectes"], filters:

                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_MES_AFFECTATIONS") ){
                        tabs.push({label: this.$t("mes-affectations"), name:"mesAffectations", counterName: "mesAffectations", counter: this.getMaintenancesCounters["mesAffectations"], filters:
                             // cas partiulier l'objet affecté arrive en dehors de la requête
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"is_not_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_RETOUR_A_FAIRE") && this.$app.isFmRetourAFaire ){
                        tabs.push({label: this.$t("retourAFaire"), name:"retourAFaire",counterName: "retourAFaire", isNotMobile:true, counter: this.getMaintenancesCounters["isRetourAFaire"], filters:

                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:true, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_RELANCES") ){
                        tabs.push({label: this.$t("relance"), name:"relance", counterName: "isRelance", counter: this.getMaintenancesCounters["isRelance"], filters:

                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:true, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_SUPERVISOR") && this.$app.isFmClosedBySuperviseur){
                        tabs.push({label: this.$t("supervisor"), name:"supervisor",counterName: "Supervisor", isNotMobile:true, counter: this.getMaintenancesCounters["Supervisor"], filters: {
                            field_statut: {attr:"fm.statut",colId: "statut", value:"Supervisor", action:"equals"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }});
                    }
                    if(this.can("MAINTENANCES.TAB_RESOLUES") ){
                        tabs.push({label: this.$t("resolue"), name:"resolue",counterName: "Resolue", isNotMobile:true, counter: this.getMaintenancesCounters["Resolue"], filters: {
                            field_statut: {attr:"fm.statut",colId: "statut", value:"Resolue", action:"equals"},
                            field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                            field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                            field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                            field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                            //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                            field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                        }});
                    }
                    if(this.can("MAINTENANCES.TAB_TOUTES") ){
                        tabs.push({label: this.$t("toutes"), name:"toutes",counterName: "all", isNotMobile:true, counter: this.getMaintenancesCounters["all"], filters:
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:null, action:"equals"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                            }
                        });
                    }
                }else{
                    if(this.can("MAINTENANCES.TAB_EN_COURS")){
                        tabs.push({label: this.$t("encours"), name:"encours", counterName: "En_cours", counter: this.getMaintenancesCounters["En_cours"], filters:
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                                field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                                field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_MES_AFFECTATIONS") ){
                        tabs.push({label: this.$t("mes-affectations-planifiees"), name:"mesAffectationsPlanifiees", counterName: "mesAffectations", filters:
                             // cas partiulier l'objet affecté arrive en dehors de la requête
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"is_not_null"},
                                field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                                field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_MES_AFFECTATIONS") ){
                        tabs.push({label: this.$t("mes-affectations-non-planifiees"), name:"mesAffectationsNonPlanifiees", counter: this.getMaintenancesCounters["mesAffectationsNonPlanifiees"], counterName: "mesAffectationsNonPlanifiees", filters:
                             // cas partiulier l'objet affecté arrive en dehors de la requête
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"is_not_null"},
                                field_affectationStartNull: {attr: "aff.start", value: 1, action: "is_null"},
                                field_affectationEndNull: {attr: "aff.end", value: 1, action: "is_null"}
                            }
                        });
                    }
                    if(this.$app.handleFmBloquante && this.can("MAINTENANCES.TAB_BLOQUANTES") ){
                        tabs.push({label: this.$t("bloquantes"), img: "static/assets/icone/maintenance-bloquante-red.svg", name:"bloquante",counterName: "bloquantes", isNotMobile:true, counter: this.getMaintenancesCounters["bloquantes"], filters:
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:true, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                                field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                                field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                            }
                        });
                    }
                    if(this.can("MAINTENANCES.TAB_URGENTES") ){
                        tabs.push({label: this.$t("urgent"), name:"urgence",counterName: "urgence", isNotMobile:false, counter: this.getMaintenancesCounters["urgence"], filters:
                            {
                                field_statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                                field_urgence : {attr:"fm.urgence",colId: "urgence", value:true, action:"equals"},
                                field_isBloquant : {attr:"fm.isBloquant",colId: "isBloquant", value:null, action:"equals"},
                                field_retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                                field_relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                                //affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                                field_mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"},
                                field_affectationStartNull: {attr: "aff.start", value: null, action: "is_null"},
                                field_affectationEndNull: {attr: "aff.end", value: null, action: "is_null"}
                            }
                        });
                    }
                }


                return tabs;
    		},
            mesAffectationsTab:function(){
                return this.fmStatusTabs.map((tab)=>{
                    return tab.name == "mesAffectations" ? tab : null;
                })[0];
            },
            hasActiveGlobalFilters: function(){
                let filters = this.agfilters;
                for (let filterName in filters) {
                    if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                        return true;
                    }
                }
                return false;
            },
            pageTitle: function(){
                let pageTitle = this.$t("page-title");
                if(this.agfilters.field_typologie.value){
                    pageTitle = this.agfilters.field_typologie.value;
                }
                return pageTitle;
            }
        },



    };
</script>
<style lang="scss">

</style>
