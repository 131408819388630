<template >
    <transition name="fade">
      <div id="snackbar" :class="{'success': type=='success', 'warning': type=='warning', 'info': type=='info', 'top':position == 'top', 'reconnection': type=='reconnection'}">
          <i v-if="type=='success'" class="fas fa-check" style="margin-right:5px;"></i>
          <i v-else-if="type=='warning'" class="fas fa-exclamation" style="margin-right:5px;"></i>
          <i v-else-if="type=='info'" class="fas fa-info" style="margin-right:5px;"></i>
          <i v-else-if="type=='reconnection'" class="fas fa-terminal" style="margin-right:5px;"></i>
          <slot>{{message}}</slot>
      </div>
    </transition>

</template>

<script>
export default {
    name: "vg-snackbar",
    props:{
        message:{
            type:String,
            default:null
        },
        /**
        * durée d'affichage en ms
        */
        timeout: {
            type: Number,
            default: 3000
        },
        /**
        * type = warning success info
        */
        type: {
            type: String,
            default: "success"
        },
        position: {
            type: String,
            default: null
        }
    },
    methods:{
        close:function() {
            this.$emit("close");
            // destroy the vue listeners, etc
            this.$destroy();

            // remove the element from the DOM
            this.$el.parentNode.removeChild(this.$el);
        }
    },
    mounted:function(){
        // After 3 seconds, remove the show class from DIV
        setTimeout(()=>{ this.close() }, this.timeout);
    }
}
</script>

<style lang="scss" scoped>
    /* The snackbar - position it at the bottom and in the middle of the screen */
    #snackbar {
      min-width: 250px; /* Set a default minimum width */
      margin-left: -125px; /* Divide value of min-width by 2 */
      text-align: center; /* Centered text */
      border-radius: 2px; /* Rounded borders */
      padding: 5px 10px; /* Padding */
      position: fixed; /* Sit on top of the screen */
      left: 50%; /* Center the snackbar */
      bottom: 30px; /* 30px from the bottom */
      font-size: 12px;
    }
    .top{
        bottom: 80px !important; /* 30px from the top */
    }
    .success{
        background-color: #50b659;
        color: white;
        border: 1px solid white;
    }
    .warning{
        background-color: #f36f3a;
        color: white;
        border: 1px solid white;
    }
    .info{
        background-color: #059cff;
        color: white;
        border: 1px solid white;
    }
    .reconnection{
        background-color: #f36f3a;
        color: white;
        border: 1px solid white;
    }

    @media screen and (max-width: 991.98px) {
        #snackbar {
            width: 99% !important;
            left: 0;
            margin-left: 0;
            bottom: 5px;
        }
    }
</style>
