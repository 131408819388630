<template >
	<vg-app-layout
        :title="$t('title')"
        :icon="'date.png'"
		:isActionButtonDisplay="true"
		:colorheader="getHeaderColor"
		:isLoadingState="isLoadingState">
		<template #create-button>
			<vg-users-selector v-if="!$vgutils.isMobile()"
				style="min-width: 20vw;"
				:multiple="true"
				:placeholder="$t('select-user')"
				@input="onChangeUsersSelector"/>
		</template>
		<template #action-button>
			<span>{{$t("synchroniser-votre-agenda")}}</span>
			<vg-button type="info" class="sync-btn"><img src="static/assets/integration-google.png" class="calendar-icon"/> {{$t("calendar-google")}}</vg-button>
			<vg-button type="info" class="sync-btn"><img src="static/assets/integration-microsoft.png" class="calendar-icon"/> {{$t("calendar-microsoft")}}</vg-button>
		</template>
		<template v-slot:header-top-left-complement>
			<vg-select
				class="select-tableau-view"
			    v-model="view"
			    :options="typeMaintenance"
				:attributeValue="'value'"
			    :attributeLabel="'label'"
				:clearable="false">
			</vg-select>
		</template>
		<template #panel-filters>
			<vg-input :title="$t('type-maintenance')">
				<vg-select
					v-model="view"
					:options="typeMaintenance"
					:attributeValue="'value'"
					:attributeLabel="'label'"
					clearable>
				</vg-select>
			</vg-input>
			<vg-calendar-filters 
                v-model="getCalendars" />
			<hr>
			<vg-filter-tiers @input="onChangeFiltreTiers" />
			<vg-filter-site
                :multiple="true"
                :display="'modal'"
                :isAllSitesSelected="true"
                @input="onChangeFiltreSites">
            </vg-filter-site>
		</template>
		<template v-slot:body>
			<vg-users-selector v-if="$vgutils.isMobile()"
				style="margin-bottom: 10px;"
				:multiple="true"
				:placeholder="$t('select-user')"
				@input="onChangeUsersSelector"/>
			<vg-calendar ref="vg-calendar"
				:showLeftPanel="!$vgutils.isMobile()"
				style="width:100%;"
				:isReadOnly="isReadOnlyCalendar"
				v-model="dateRange"
				:calendars="getCalendars"
				:calendarEvents="calendarEvents"
				@change-date="onChangeDate"
				@change-filter-sites="onChangeFiltreSites"
				@change-filter-tiers="onChangeFiltreTiers"
				@before-update-schedule="onBeforeUpdateSchedule"
				@after-update-schedule="onUpdateSchedule"
				@open-update="onBeforeUpdateSchedule" />
			<vg-fichesav-affectation v-if="showAffectationModal"
				:defaultStartDate="defaultAffectationDate.start"
				:defaultEndDate="defaultAffectationDate.end"
				@close="showAffectationModal=false;"
				@save="onSaveAffectation"/>
			<vg-intervention-form v-if="showInterventionModal"
				isPeriodique
				:defaultStatut="'intervention-prevue'"
				:defaultDateDebut="defaultAffectationDate.start"
				:defaultDateFin="defaultAffectationDate.end"
				@close="showInterventionModal=false;"
				@save="onSaveAffectation"/>
			<vg-affectation-tache-form v-if="showAffectationTacheModal"
				:affectation="focusedEvent"
				:defaultStartDate="defaultAffectationDate.start"
				:defaultEndDate="defaultAffectationDate.end"
				:affectationDateStart="focusedEventStart"
				:affectationDateEnd="focusedEventEnd"
				@close="showAffectationTacheModal=false;"
				@save="onSaveAffectation"/>
		</template>

	</vg-app-layout>

</template>

<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgCalendar from "src/components/Vg/VgCalendar.vue";
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgCollapse from "src/components/Vg/VgCollapse.vue";

	import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";

	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import InterventionsMixins from "src/mixins/InterventionsMixins.js";
	import ContratsMixins from "src/mixins/ContratsMixins.js";
	import VerificationMixins from "src/mixins/VerificationMixins.js";
	import CalendarMixins from "src/mixins/CalendarMixins.js";

	import VgStatistiquesMaintenanceDashboard from "src/components/Vg/Statistiques/VgStatistiquesMaintenanceDashboard.vue";
	import VgStatistiquesVerificationDashboard from "src/components/Vg/Statistiques/VgStatistiquesVerificationDashboard.vue";
	import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";

	import VgFichesavAffectation from "src/components/Vg/FicheSAV/VgFichesavAffectation.vue";
	import VgAffectationTacheForm from "src/components/Vg/Forms/VgAffectationTacheForm.vue";
	import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
	import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
	import VgCalendarFilters from "src/components/Vg/Calendar/VgCalendarFilters.vue";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';
import moment from "moment";

    export default {
        name: 'calendrier',
		mixins: [ MaintenanceMixins, InterventionsMixins, ContratsMixins, VerificationMixins, CalendarMixins ],
        components: {
			VgCalendar,
			VgInput,
			VgCollapse,
			VgButton,
			VgSelect,
			VgStatistiquesMaintenanceDashboard,
			VgStatistiquesVerificationDashboard,
			VgFilterSite,
			VgUsersSelector,
			VgFichesavAffectation,
			VgAffectationTacheForm,
			VgInterventionForm,
			VgFilterTiers,
			VgCalendarFilters
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Calendrier curatif",
            "calendar-fm-ouverture": "Fiche curative (date ouverture)",
            "calendar-fm-fermeture": "Fiche curative (date fermeture)",
            "calendar-fm-affectation": "Fiche curative (date programmée)",
            "calendar-intervention-tiers-programmee": "Intervention tiers programmée",
			"calendar-tache-session": "Session tâche récurrente",
            "curative-preventive": "Curative / préventive",
			"section-curatif": "Curatif",
			"section-preventif": "Préventif",
            "curative": "Curative",
            "preventive": "Préventive",
			"synchroniser-votre-agenda": "Synchroniser votre agenda :",
			"calendar-google": "Google (bientôt)",
			"calendar-microsoft": "Microsoft (bientôt)",
			"type-maintenance": "Type maintenance",
			"select-user": "Voir tous les utilisateurs"
        },
        "en": {
            "title": "Maintenance calendar",
            "calendar-fm-ouverture": "Curative sheet (opening date)",
            "calendar-fm-fermeture": "Curative sheet (closing date)",
            "calendar-fm-affectation": "Curative sheet (schedule date)",
            "calendar-intervention-tiers-programmee": "Scheduled third-party intervention",
			"calendar-tache-session": "Recurrent task session",
            "curative-preventive": "Curative / preventive",
			"section-curatif": "Curative",
			"section-preventif": "Preventive",
            "curative": "Curative",
            "preventive": "Preventive",
			"synchroniser-votre-agenda": "Sync your calendar",
			"calendar-google": "Google (soon)",
			"calendar-microsoft": "Microsoft (soon)",
			"type-maintenance": "Maintenance type",
			"select-user": "Show all users"
        }
    }
},
        data: function() {
            return {
				isLoadingState: false,
				dateRange: {
					start: moment().startOf('month').format("YYYY-MM-DD"),
					end: moment().endOf('month').format("YYYY-MM-DD")
				},
				view: "curative-preventive",//"curative", // curative || preventive
				calendarEvents: [],
				calendarsMaintenanceCurative: [
					{
						id: "maintenance-affectation",
						name: this.$t("calendar-fm-affectation"),
						checked: true,
						color: "white",
						bgColor: "#3095C9",
						dragBgColor: "#3095C9",
						borderColor: "transparent",
						section: this.$t("section-curatif")
					},
					{
						id: "maintenance-ouverture",
						name: this.$t("calendar-fm-ouverture"),
						checked: false,
						color: "white",
						bgColor: "#3095C9",
						dragBgColor: "#3095C9",
						borderColor: "transparent",
						section: this.$t("section-curatif")
					},
					{
						id: "maintenance-fermeture",
						name: this.$t("calendar-fm-fermeture"),
						checked: false,
						color: "white",
						bgColor: "#3095C9", //f45112
						dragBgColor: "#3095C9",
						borderColor: "transparent",
						section: this.$t("section-curatif")
					}
				],
				calendarsMaintenancePreventive: [
					{
						id: "intervention-programmee",
						name: this.$t("calendar-intervention-tiers-programmee"),
						checked: true,
						color: "white",
						bgColor: "#908070",
						dragBgColor: "#908070",
						borderColor: "transparent",
						section: this.$t("section-preventif")
					},
					{
						id: "tache-session",
						name: this.$t("calendar-tache-session"),
						checked: true,
						color: "white",
						bgColor: "#4F56A6",
						dragBgColor: "#4F56A6",
						borderColor: "transparent",
						section: this.$t("section-preventif")
					}
				],
				typeMaintenance: [
					{label:this.$t("curative-preventive"),value:"curative-preventive"},
					{label:this.$t("curative"),value:"curative"},
					{label:this.$t("preventive"),value:"preventive"}
				],
				showAffectationModal: false,
				showInterventionModal: false,
				showAffectationTacheModal: false,
				defaultAffectationDate:{
					start: null,
					end: null
				},
				focusedEvent: null,
				focusedEventStart: null,
				focusedEventEnd: null,
				filtreTiers: null,
				filtreSites: null,
				filtreAffectes: null
			};
        },
        computed: {
			...mapGetters({
				sites: "LieuxStore/getSites"
            }),
			isReadOnlyCalendar: function(){
				return !this.can("CALENDRIER.UPDATE");
			},
			dateRangeMonth: function(){
				return {
					start: moment(this.dateRange.start).startOf('month').format('YYYY-MM-DD'),
					end: moment(this.dateRange.end).endOf('month').format('YYYY-MM-DD')
				};
			},
			getCalendars: function(){
				let calendars = null;
				switch (this.view) {
					case "curative-preventive":
						calendars = this.calendarsMaintenanceCurative.concat(this.calendarsMaintenancePreventive);
						break;
					case "curative":
						calendars = this.calendarsMaintenanceCurative
						break;
					default:
						calendars = this.calendarsMaintenancePreventive
						break;
				}
				return calendars;
			},
			getHeaderColor: function(){
				return this.$vgutils.isMobile() ? this.$root.vgPurple : 'white';
			},
			getRestrictionSites: function(){
				if(this.filtreSites) return this.filtreSites;
				else return this.$app.restrictionsite;
			}
        },
		watch:{
			view:function(value){
				this.fetch();
			}
		},
        methods: {
			onUpdateSchedule: function(schedule){
				this.fetch();
			},
			onSaveAffectation: function(){
				this.$store.dispatch("MaintenancesStore/setSelectedMaintenance", null);
				this.$store.dispatch("ContratsStore/setSelectedItem", null);
				this.$store.dispatch("InterventionsStore/setSelectedItem", null);
				this.defaultAffectationDate = {
					start: null,
					end: null
				};
				this.showAffectationModal = false;
				this.showInterventionModal = false;
				this.showAffectationTacheModal = false;
				this.fetch();
			},
			onBeforeUpdateSchedule: function(event){
				console.log("BEFORE UPDATE SCHEDULE", event);
				let dateStart = moment(event.start._date).format("YYYY-MM-DD HH:mm");
				let dateEnd = moment(event.end._date).format("YYYY-MM-DD HH:mm");
				if(event.schedule.calendarId=="maintenance-affectation"){
					this.$store.dispatch("MaintenancesStore/setMaintenance", event.schedule.raw);
					this.defaultAffectationDate = {
						start: dateStart,
						end: dateEnd
					};
					this.showAffectationModal = true;
				}else if(event.schedule.calendarId=="intervention-programmee"){
					this.$store.dispatch("InterventionsStore/setSelectedItem", event.schedule.raw);
					this.defaultAffectationDate = {
						start: dateStart,
						end: dateEnd
					};
					this.showInterventionModal = true;
				}else if(event.schedule.calendarId=="tache-session"){
					this.defaultAffectationDate = {
						start: dateStart,
						end: dateEnd
					};
					this.focusedEvent = event.schedule.raw;
					this.focusedEventStart = event.schedule.start._date;
					this.focusedEventEnd = event.schedule.end._date;
					this.showAffectationTacheModal = true;
				}
			},
			onChangeUsersSelector: function(users){
				this.filtreAffectes = [...users];
				this.fetch();
			},
			onChangeFiltreSites: function(sites){
				this.filtreSites = [...sites];
				this.fetch();
			},
			onChangeFiltreTiers: function(idTiers){
				this.filtreTiers = idTiers;
				this.fetch();
			},
			fetch: function(){
				this.isLoadingState = true;
				this.calendarEvents = [];
				let dateStart = moment(this.dateRange.start).subtract(1, "week").format("YYYY-MM-DD");
				let dateEnd = moment(this.dateRange.end).add(1, "week").format("YYYY-MM-DD");
				this.CalendarMixins_get(dateStart, dateEnd, this.getRestrictionSites, this.filtreTiers, this.filtreAffectes).then((datas)=>{
					this.calendarEvents = datas.events;
					this.isLoadingState = false;
				});
			},
			onChangeDate: function(dateRange){
				let currentDateMonthStart = this.dateRangeMonth.start;
				this.dateRange = dateRange;
				if(moment(dateRange.start).startOf('month').format('YYYY-MM-DD')!=currentDateMonthStart) this.fetch();
			}
        },
		created: function(){
			this.fetch();
		},
        mounted: function(){

        }
    };
</script>
<style lang="scss">

.select-tableau-view{
	margin-left: 30px;
	font-size: 18px;
	min-width: 200px;
}
</style>
<style lang="scss" scoped>
.bloc-statistiques{
	border: 1px solid #ccc;
	width: 100%;
	height: 100%;
	padding: 20px 0;
}
.sync-btn{
	border-radius: 15px;
}
.calendar-icon{
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-right: 5px;
}
</style>
