<template lang="html">
    <vg-modal
        :title="$t('title')"
        @close="$emit('close')">
        <template #body>
            <form id="tache-affectation-form"
                ref="tache-affectation-form"
                onSubmit="return false;">
                <vg-input :title="$t('libel-tache')" inline>
                    <span>{{ affectation.tache.libel_tache }}</span>
                </vg-input>
                <vg-input v-model="localAffectation.start" 
                    :title="$t('date-debut')" :inputType="'datetime-local'" isRequiredValue inline
                    @input="onChangeDateDebut"/>
                <vg-input v-model="localAffectation.end" 
                    :title="$t('date-fin')" :inputType="'datetime-local'" isRequiredValue inline/>
                <vg-input :title="$t('affectes')" inline >
                    <vg-users-selector v-model="localAffectation.affectes"
                        multiple
                        defaultTemplate="viewer"
                        @fetch-success="onUsersFetchSuccess"/>
                </vg-input>
                <input
                  v-show="false"
                  ref="tache-affectation-submit"
                  type="submit" >
            </form>
        </template>
        <template #footer>
            <vg-button
                :type="'default-danger'"
                @click="$emit('close')">
                {{$t("cancel")}}
            </vg-button>
            <vg-button
                :type="'danger'"
                :disabled="isLoading"
                @click="submitForm">
                {{$t("save")}}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
import TacheUsersMixins from "src/mixins/TacheUsersMixins.js";
import AffectationsMixins from "src/mixins/AffectationsMixins.js";
export default {
    name:'vg-affectation-tache-form',
    mixins:[TacheUsersMixins, AffectationsMixins],
    components:{
        VgButton,
        VgInput,
        VgUsersSelector
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Session tâche récurrente",
            "date-debut": "Date début",
            "date-fin": "Date fin",
            "libel-tache": "Libel tâche",
            "affectes": "Affectés",
            "cancel": "Annuler",
            "save": "Modifier"
        },
        "en": {
            "title": "Recurrent task session",
            "date-debut": "Start date",
            "date-fin": "End date",
            "libel-tache": "Label",
            "affectes": "Assigned",
            "cancel": "Cancel",
            "save": "Update"
        }
    }
},
    props:{
        affectation: {
            type: Object
        },
        defaultStartDate: {
            type: String,
            default: null
        },
        defaultEndDate: {
            type: String,
            default: null
        },
        affectationDateStart:{
            type: String,
            default: null
        },
        affectationDateEnd:{
            type: String,
            default: null
        }
    },
    data:function(){
        return {
            localAffectation: {
                start: this.defaultStartDate,
                end: this.defaultEndDate,
                tache_id: this.affectation.tache_id,
                originAffectation_id: this.affectation.originAffectation_id,
                affectes: []
            },
            isAnUpdateForm: false,
            isLoading: false
        }
    },
    created: function(){
        console.log("CREATED VG AFF TACHE FORM", this.localAffectation);
        if(this.localAffectation.hasOwnProperty("originAffectation_id") && this.localAffectation.originAffectation_id) this.isAnUpdateForm = true;
        else this.isAnUpdateForm = false;
    },
    computed:{
        
    },
    methods:{
        onChangeDateDebut: function(){
            let defaultAffectationMinutes = 60*60;
            if(this.defaultStartDate && this.defaultEndDate) defaultAffectationMinutes = moment(this.defaultEndDate).diff(moment(this.defaultStartDate), "minutes");
            this.localAffectation.end = moment(this.localAffectation.start).add(defaultAffectationMinutes, "minutes").format("YYYY-MM-DD HH:mm");
        },
        onUsersFetchSuccess: function(){
            console.log("FETCH SUCCESS", this.affectation.affectes);
            if(this.affectation.hasOwnProperty("affectes") && this.affectation.affectes) this.localAffectation.affectes = [...this.affectation.affectes.map((affecte)=>affecte.affectationusertache_user_id)];
        },
        /**
         * Verification formulaire.
         */
        submitForm:function(){
            this.isLoading = true;
            this.$refs['tache-affectation-submit'].click();
            let form = this.$refs['tache-affectation-form'];
            if (form.checkValidity()) {
                let affectation = Object.assign({}, this.affectation, {
                    start: this.affectationDateStart,
                    end: this.affectationDateEnd
                });
                this.AffectationsMixins_copieAffectationTache(affectation, this.localAffectation.start, this.localAffectation.end, this.localAffectation.affectes).then((datas)=>{
                    console.log("AFTER COPIE AFFECTATION TACHE", datas);
                    this.$emit("save");
                });
            }
        }
    }

}
</script>

<style lang="css" scoped>
</style>