<template>
    <vg-modal
        :title="$t('deplacement-title')"
        @close="close">
        <template #body>
            <form id="deplacements-equipement-form"
                ref="deplacements-equipements-form"
                onSubmit="return false;">

                <div style="height:200px;overflow: auto;" v-show="lieuDestination" >
                    <div v-for="equipement in selectedEquipements" :key="equipement.id" style="display:flex;justify-content: space-between;margin-bottom: 5px;">
                        <vg-equipement-viewer
                            :equipement="equipement"
                        ></vg-equipement-viewer>

                        <vg-lieu-path-viewer v-model="equipement.path">
                        </vg-lieu-path-viewer>
                    </div>
                </div>
                <span v-if="!lieuDestination"> <h4>{{$t('definir-destination')}}</h4> </span>
                <span v-else > {{$t('destination')}}<b> <vg-lieu-path-viewer v-model="lieuDestination.path" onlyPiece></vg-lieu-path-viewer> </b></span>

                <div v-if="!lieuDestination" style="display:flex;justify-content:flex-start;align-items:center;gap:10px;margin-bottom:10px;">
                    <vg-button
                        :type="'info'"
                        @click="showTreeLieu=!showTreeLieu">
                        {{showTreeLieu?$t("show-list-lieux"):$t("show-tree-lieux")}}
                    </vg-button>
                    <vg-button
                        type="grey"
                        @click="openPlansInteractifs=true;">
                        <i class="far fa-map"></i> <span>{{ $t("btn-plans-interactifs") }}</span>
                    </vg-button>
                    <vg-button
                        type="grey"
                        @click="openTiroir=true;">
                        <i class="far fa-map"></i> <span>{{ $t("btn-plans-pdf") }}</span>
                    </vg-button>
                </div>
                <vg-tree-lieux v-if="!lieuDestination && showTreeLieu"
                    :readOnly="true"
                    @select-piece="onClickLieu" />
                <div v-else-if="!lieuDestination && !showTreeLieu">
                    <vg-text-filter @input="onInputSearchPiece" />
                    <vg-filter-site v-model="agfiltersPieces.path.value"
                        style="margin: 10px 0;" />
                    <vg-filter-service v-model="agfiltersPieces.service.value"
                        style="margin: 10px 0;" />
                    <vg-lieux-table
                        :id="'vg-lieux-deplacement-table'"
                        :filters="agfiltersPieces"
                        paginate
                        isDemandeIntervention
                        @select-piece="onClickLieu" />
                </div>

                <input
                v-show="false"
                ref="equipement-submit"
                type="submit"
                >
            </form>

            <vg-etages-plans-interactifs-modal v-if="openPlansInteractifs"
                @close="openPlansInteractifs=false;" />

            <vg-files-plans v-if="openTiroir"
                @close="openTiroir = false" />

        </template>
        <template #footer>
            <vg-button v-if="isAnUpdateForm && isUserIsAuthorize"
                :type="'danger'"
                @click="isModalConfirmDeleteOpened=true">
                {{$t("delete")}}
            </vg-button>
            <vg-button
                :type="'default'"
                @click="close()">
                {{$t("cancel")}}
            </vg-button>
            <vg-button
                :type="'success'"
                :disabled="!lieuDestination"
                @click="handleSave">
                {{$t("save")}}
            </vg-button>
        </template>
    </vg-modal>
</template>
<script>

import DeplacementsEquipements from "src/mixins/DeplacementsEquipementsMixins.js";

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

import VgFilterSite from 'src/components/Vg/Filters/VgFilterSite.vue';
import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';

import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
import VgLieuxTable from "src/components/Vg/Lieu/VgLieuxTable.vue";

import VgEtagesPlansInteractifsModal from "src/components/Vg/Lieu/VgEtagesPlansInteractifsModal.vue";
import VgFilesPlans from "src/components/Vg/Files/VgFilesPlans.vue";

import { mapGetters } from 'vuex';

export default {
    name: 'vg-deplacements-equipements-form',
    components:{
        VgButton,
        VgInput,
        VgSelect,
        VgCheckbox,
        VgCollapse,
        VgTextFilter,
        VgTreeLieux,
        VgLieuPathViewer,
        VgEquipementViewer,
        VgFilterSite,
        VgFilterService,
        VgLieuxTable,
        VgEtagesPlansInteractifsModal,
        VgFilesPlans
    },
    mixins: [DeplacementsEquipements],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "deplacement-title": "Déplacer des équipements",
            "definir-destination": "Veuillez selectionner la destination des équipements à déplacer.",
            "destination": "Ces équipements seront déplacés vers : ",
            "equipements-suivant-deplacer": "Equipements sélectionnés",
            "save": "Confirmer déplacement",
            "show-list-lieux": "Basculer vue liste",
            "show-tree-lieux": "Basculer vue arborescence",
            "btn-plans-interactifs": "Plans interactifs",
            "btn-plans-pdf": "Plans PDF",
            "cancel": "Annuler"
        },
        "en": {
            "deplacement-title": "Relocate equipments",
            "definir-destination": "Please target equipment's destination",
            "destination": "These equipments will be relocated to : ",
            "equipements-suivant-deplacer": "Selected equipments",
            "save": "Confirm relocation",
            "show-list-lieux": "See list view",
            "show-tree-lieux": "See tree view",
            "btn-plans-interactifs": "Interactive level plans",
            "btn-plans-pdf": "PDF level plans",
            "cancel": "Cancel"
        }
    }
},
    props: {},
    data: function() {
        return {
            lieuDestination:null,
            showTreeLieu: true,
            agfiltersPieces: {
				type_lieu: {attr: "l.type_lieu", value: "Piece", action: "equals"},
				path: {attr: "l.path", value: null, action: "contains"},
				service: {attr: "l.service", value: null, action: "equals"},
				libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", openParenthesis: true},
				codeTrois: {attr: "l.codeTrois", value: null, action: "contains", logicalOperator: "OR"},
				codeDeux: {attr: "l.codeDeux", value: null, action: "contains", logicalOperator: "OR"},
				servicetext: {attr: "l.service", value: null, action: "contains", closeParenthesis: true, logicalOperator: "OR"}
			},
            openPlansInteractifs: false,
            openTiroir: false
        };
    },
    watch: {},
    created: function(){},
    mounted: function(){},
    computed:{
        ...mapGetters({
            selectedEquipements: "EquipementsStore/getSelectedItems"
        })

    },
    methods: {
        onInputSearchPiece: function(input){
            this.agfiltersPieces.libel_lieu.value = input;
			this.agfiltersPieces.codeTrois.value = input;
			this.agfiltersPieces.codeDeux.value = input;
			this.agfiltersPieces.servicetext.value = input;
        },
        close:function(){
            this.$emit("close");
        },
        onClickLieu: function(lieu){
            this.lieuDestination = lieu;
		},
        handleSave:function(){
            let deplacements = [];
            this.selectedEquipements.forEach((equipment, i) => {
                deplacements.push(this.formatEquipments(equipment));
            });
            this.DeplacementsEquipementsMixins_createDeplacementsEquipements(deplacements).then(()=>{
                this.$emit("save");
            });
        },
        formatEquipments:function(equipement){
            return {
                "equipement_id": equipement.id,
                "commentaire": "",
                "fromLieu_id": equipement.idLieu,
                "toLieu_id": this.lieuDestination.id,
                "user_id": this.$app.idUser,
                "userId": this.$app.appID,
                "origin_path":equipement.path,
                "destination_path":this.lieuDestination.path
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    table,
    td {
        border: 1px solid #333;
    }

    thead,
    tfoot {
        background-color: #333;
        color: #fff;
    }
</style>
<docs>

    ### vg-equipement-form
    Create|Update|Delete equipement

    #### basic usage

    ```html static
        <vg-equipement-form v-if="equipement || isEquipementFormDisplayed"
            v-model="equipement"
            @close="isEquipementFormDisplayed=false;equipement=null;"
            @created="isEquipementFormDisplayed=false;fetch()"
            @updated="isEquipementFormDisplayed=false;fetch()"
            @deleted="isEquipementFormDisplayed=false;fetch()"
        >
        </vg-equipement-form>
    ```
</docs>
