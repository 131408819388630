<template>
    <div class="vg-bon-de-commande-table">
        <tag-grid v-if="!showCards"
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="bonsDeCommande"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick" />
        <div v-else class="bons-de-commande-cards">
            <span v-if="showLoadingOverlay" style="color:#35b1ea;">{{ $t("overlay-loading-text") }}</span>
            <span v-if="!showLoadingOverlay && (!bonsDeCommande || bonsDeCommande.length==0)" style="color:#35b1ea;">{{ $t("overlay-no-rows-text") }}</span>
            <div v-for="(bonDeCommande,index) in bonsDeCommande" :key="'bon-de-commande-'+index"
                :class="['bon-de-commande-card', {'bon-de-commande-card-selected': focusedBonDeCommande && focusedBonDeCommande.id==bonDeCommande.id}]"
                @click="handleClick({data: bonDeCommande})">
                <b>{{ bonDeCommande.numero }}</b> 
                <span>{{ $t("created-on") }} </span><vg-datetime-viewer v-model="bonDeCommande.dateCreation"/> 
                <br>
                <span>{{ $t("fournisseur") }}: {{ bonDeCommande.fournisseur.name }}</span>
                <br>
                <span v-if="bonDeCommande.siteDemandeur">{{ $t("site") }}: {{ bonDeCommande.siteDemandeur.libel_lieu }}</span>
                <br>
                <span v-if="bonDeCommande.serviceDemandeur">{{ $t("service") }}: {{ bonDeCommande.serviceDemandeur }}</span> 
                <br>
                <div style="display: flex;justify-content: flex-start;align-items: center;gap: 20px;">
                    <span>{{ $t("montant-ht") }}: <vg-input-cost v-model="bonDeCommande.montantHT" /></span>
                    <span style="margin-left:20px;">{{ $t("montant-ttc") }}: <vg-input-cost v-model="bonDeCommande.montantTTC" /></span>
                </div>
            </div>
        </div>
        <vg-pagination v-if="paginate"
            :totalItems="counters.filtered"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange"
        />
    </div>
</template>
<script>
import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";
import BonDeCommandeItemsMixins from "src/mixins/BonDeCommandeItemsMixins.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgPagination from "src/components/Vg/VgPagination.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgInputCost from "src/components/Vg/VgInputCost.vue";

import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
import BonDeCommandeActionsCellRender from "src/components/Vg/TagGrid/BonDeCommandeActionsCellRender.vue";
import BonDeCommandeEntiteCellRender from "src/components/Vg/TagGrid/BonDeCommandeEntiteCellRender.vue";
import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
import BonDeCommandeStatutCellRender from "src/components/Vg/TagGrid/BonDeCommandeStatutCellRender.vue";
import BonDeCommandeStatutLivraisonCellRender from "src/components/Vg/TagGrid/BonDeCommandeStatutLivraisonCellRender.vue";
import BonDeCommandeFichesCurativesAttacheesCellRender from "src/components/Vg/TagGrid/BonDeCommandeFichesCurativesAttacheesCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-bons-de-commande-table',
    components:{
        TagGrid,
        VgPagination,
        VgDatetimeViewer,
        VgInputCost
    },
    mixins:[BonsDeCommandeMixins, BonDeCommandeItemsMixins],
    props: {
        id:{
            type: String,
            default: "bons-de-commande-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        showCards: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "overlay-loading-text": "Chargement des bons de commande...",
                "overlay-no-rows-text": "Aucun bon de commande correspondant au filtrage",
                "header-numero": "N° BC",
                "header-action": "Action",
                "header-createur": "Demandeur",
                "header-site": "Site",
                "header-service": "Service",
                "header-entite": "Entité qui passe commande",
                "header-fournisseur": "Fournisseur",
                "header-montant-ht": "Montant HT",
                "header-montant-tva": "Montant TVA",
                "header-montant-ttc": "Montant TTC",
                "header-date-creation": "Date création",
                "header-statut": "Statut BC",
                "header-documents": "Documents",
                "header-commentaire": "Commentaire",
                "header-statut-livraison": "Statut livraison",
                "header-statut-paiement": "Statut paiement",
                "header-commentaire": "Commentaire",
                "header-validations": "Validateur(s)",
                "modal-title-confirm-clonage": "Confirmer le clonage du bon de commande n°",
                "montant-ht": "Montant HT",
                "montant-ttc": "Montant TTC",
                "created-on": "Créé le",
                "fournisseur": "Fournisseur",
                "site": "Site demandeur",
                "service": "Service demandeur",
                "header-fiches-curatives-attachees": "Fiches curatives attachées",
                "header-reserves-attachees": "Réserves attachées"
            },
            "en": {
                "overlay-loading-text": "Loading purchase orders...",
                "overlay-no-rows-text": "No purchase order matching filtering",
                "header-numero": "N° PO",
                "header-action": "Action",
                "header-createur": "Applicant",
                "header-site": "Site",
                "header-service": "Service",
                "header-entite": "Ordering entity",
                "header-fournisseur": "Supplier",
                "header-montant-ht": "Amount excl. VAT",
                "header-montant-tva": "Amount VAT",
                "header-montant-ttc": "Amount incl. VAT",
                "header-date-creation": "Creation date",
                "header-statut": "Status PO",
                "header-documents": "Documents",
                "header-commentaire": "Comment",
                "header-statut-livraison": "Delivery status",
                "header-statut-paiement": "Payment status",
                "header-commentaire": "Comment",
                "header-validations": "Validator(s)",
                "modal-title-confirm-clonage": "Confirm cloning of purchase order No.",
                "montant-ht": "Amount excl. VAT",
                "montant-ttc": "Amount incl. VAT",
                "created-on": "Created on",
                "fournisseur": "Supplier",
                "site": "Requester site",
                "service": "Requester service",
                "header-fiches-curatives-attachees": "Attached curative sheets",
                "header-reserves-attachees": "Attached reserves"
            }
        }
    },
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
                {
                    headerName: this.$t("header-numero"),
                    width: 120,
                    field: 'numero',
                    colId: 'numero',
                    cellRenderer: (params) => new IconNameCellRender({propsData: {dataColumn: params.data.numero, isMainColumn: true}}).$mount().$el,

                },
                {
                    headerName: this.$t("header-action"),
                    width: 90,
                    cellRenderer: (params) => new BonDeCommandeActionsCellRender({propsData: {dataColumn: params.data}})
                        .$mount()
                        .$on("clone", this.onCloneBonDeCommande)
                        .$el
                },
                {
                    headerName: this.$t("header-entite"),
                    width: 150,
                    field: 'bonsDeCommandeEntite_id',
                    colId: 'bonsDeCommandeEntite_id',
                    cellRenderer: (params) => new BonDeCommandeEntiteCellRender({propsData: {params: params}}).$mount().$el,

                },
                {
                    headerName: this.$t("header-createur"),
                    width: 150,
                    field: 'createur.nom',
                    cellRenderer: (params)=>params.data.createur?params.data.createur.nom+" "+params.data.createur.prenom:""
                },
                {
                    headerName: this.$t("header-site"),
                    width: 150,
                    field: 'siteDemandeur.libel_lieu'

                },
                {
                    headerName: this.$t("header-service"),
                    width: 150,
                    field: 'serviceDemandeur',
                },
                {
                    headerName: this.$t("header-fournisseur"),
                    width: 150,
                    field: 'fournisseur_id',
                    colId: 'fournisseur_id',
                    cellRenderer: (params) => new IconNameCellRender({propsData: {icon: "static/assets/icone/circle/tiers-cercle.png", dataColumn: params.data.fournisseur.name, isMainColumn: false, route: { router: this.$router, name: '_tiers_id', params: {id: params.data.fournisseur.uid } }}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-montant-ht"),
                    width: 120,
                    field: 'montantHT',
                    colId: 'montantHT',
                    cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.montantHT}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-montant-tva"),
                    width: 120,
                    field: 'montantTVA',
                    colId: 'montantTVA',
                    hide: true,
                    cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.montantTVA}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-montant-ttc"),
                    width: 120,
                    field: 'montantTTC',
                    colId: 'montantTTC',
                    hide: true,
                    cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.montantTTC}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-date-creation"),
                    width: 120,
                    field: 'dateCreation',
                    colId: 'dateCreation',
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateCreation, hideTime: true}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-statut"),
                    width: 150,
                    field: 'statut',
                    colId: 'statut',
                    cellRenderer: (params) => new BonDeCommandeStatutCellRender({propsData: {value: params.data}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-validations"),
                    width: 150,
                    field: 'validations',
                    cellRenderer: (params) => {
                        if(params.data.validations && params.data.validations.length>0){
                            return params.data.validations.map((validation)=>validation.validateur.nom+" "+validation.validateur.prenom).filter((np, index, self)=>self.indexOf(np)===index).join(", ");
                        }
                    }
                },
                {
                    headerName: this.$t("header-documents"),
                    width: 90,
                    hide: true
                },
                {
                    headerName: this.$t("header-statut-livraison"),
                    width: 150,
                    field: 'statutLivraison',
                    colId: 'statutLivraison',
                    cellRenderer: (params) => new BonDeCommandeStatutLivraisonCellRender({propsData: {value: params.data}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-statut-paiement"),
                    width: 150,
                    field: 'statutPaiement',
                    colId: 'statutPaiement',
                    hide: true
                },
                {
                    headerName: this.$t("header-commentaire"),
                    width: 200,
                    field: 'commentaire',
                    colId: 'commentaire'
                },
                {
                    headerName: this.$t("header-fiches-curatives-attachees"),
                    width: 150,
                    field: 'operations',
                    colId: "fiches-curatives",
                    cellRenderer: (params) => new BonDeCommandeFichesCurativesAttacheesCellRender({propsData: {value: params.data}})
                        .$mount()
                        .$on("open-maintenance", this.onOpenMaintenance)
                        .$el,
                },{
                    headerName: this.$t("header-reserves-attachees"),
                    width: 150,
                    field: 'operations',
                    colId: "reserves",
                    cellRenderer: (params) => new BonDeCommandeFichesCurativesAttacheesCellRender({propsData: {value: params.data, isReserve: true}})
                        .$mount()
                        .$on("open-maintenance", this.onOpenReserve)
                        .$el,
                },
            ],
            metadatas: new Metadatas(),
            focusedBonDeCommande: null
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            bonsDeCommande: 'BonsDeCommandeStore/getCollection',
            counters: 'BonsDeCommandeStore/getCounters'
        }),
    },
    methods:{
        onOpenMaintenance: function(idMaintenance){
            this.$router.push({ name: '_maintenance', params: { id: idMaintenance }});
        },
        onOpenReserve: function(idReserve){
            this.$router.push({ name: '_reserve', params: { id: idReserve }});
        },
        onCloneBonDeCommande: function(bonDeCommande){
            let bonDeCommandeClone = this.BonsDeCommandeMixins_clone(bonDeCommande);
            let metadatas = new Metadatas();
            metadatas.setFilters({
                bonDeCommande_id: {attr: "bci.bonDeCommande_id", value: bonDeCommande.id, action: "equals"}
            });
            this.BonDeCommandeItemsMixins_getClones(metadatas).then((bonDeCommandeItemsClones)=>{
                bonDeCommandeClone.items = bonDeCommandeItemsClones;
                this.$emit("create-clone", bonDeCommandeClone);
            });
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.filters);
            this.BonsDeCommandeMixins_getBonsDeCommande(this.metadatas).then((datas)=>{
                console.log("GET BC", datas);
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.focusedBonDeCommande = row.data;
            this.$emit("row-click",row);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    }
};
</script>
<style lang="scss" scoped>
.bons-de-commande-cards{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    .bon-de-commande-card{
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid whitesmoke;
    }
    .bon-de-commande-card:hover{
        cursor: pointer;
        background-color: whitesmoke;
    }
    .bon-de-commande-card-selected{
        background-color: whitesmoke;
    }
}
</style>
