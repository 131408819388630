<template>
  <div>
    <vg-modal
      v-show="!isModalConfirmDeleteOpened"
      :width="'75vw'"
      class="vg-lieu-modal"
      @close="$emit('close')"
    >
        <template #header>
            <div v-if="isAnUpdateLieuForm" class="header-inline">
                <img :src="getIcon" class="icon"/> 
                <span>{{$t("lieu-form-update")}}</span>
                <b>{{$t(getTypeLieu)}}</b> 
                <span>{{$t("dans")}}</span>
                <vg-lieu-path-viewer v-model="lieu.path"
                    :showTooltip="false"
                    :type="getTypeLieu"
                    onlyPath />
            </div>
            <div v-else-if="!isAnUpdateLieuForm && !isPiece" class="header-inline">
                <img :src="getIcon" class="icon"/> 
                <span>{{$t("lieu-form-add")}}</span>
                <b>{{$t(getTypeLieu)}}</b> 
                <span>{{$t("dans")}}</span>
                <vg-lieu-path-viewer v-model="lieu.path"
                    :showTooltip="false"
                    :type="getTypeLieu"
                    onlyPath />
            </div>
            <div v-else class="header-inline">
                <img :src="getIcon" class="icon"/> 
                <span>{{$t("lieu-form-add-pieces")}}</span>
                <vg-lieu-path-viewer v-model="lieu.path"
                    :showTooltip="false"
                    :type="getTypeLieu"
                    onlyPath />
            </div>
        </template>
      <template #body>
          <form
          ref="lieu-form"
          onSubmit="return false;"
          id="lieu-form"
          v-if="isAnUpdateLieuForm || getTypeLieu!='Piece'" >
            <div class="single-create">
                  <vg-input
                    v-model="lieu.libel_lieu"
                    :title="$t('libel-lieu')"
                    :is-required-value="true"
                  />
                  <vg-input
                    v-if="getTypeLieu=='Site' && !isClient"
                    :title="$t('famille')"
                    :is-required-value="false"
                  >
                    <div style="display:flex;align-items:center;">
                        <vg-lieu-famille-selector v-if="!showNewFamille"
                            v-model="lieu.famille">
                        </vg-lieu-famille-selector>
                        <vg-input v-else
                            v-model="lieu.famille">
                        </vg-input>
                        <vg-button
                            :type="'default-info'"
                            :size="'sm'"
                            style="margin-left:10px;"
                            @click="showNewFamille=!showNewFamille;">
                            <i class="fas fa-plus" v-if="!showNewFamille"></i>
                            <i class="fas fa-eraser" v-else></i>
                        </vg-button>
                    </div>
                  </vg-input>
                  <vg-input v-if="getTypeLieu=='Batiment'"
                    v-model="lieu.lienFichierIfc"
                    :title="$t('lien-fichier-ifc')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.typeErp"
                    :title="$t('type-erp')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.categorieErp"
                    :title="$t('categorie-erp')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.capaciteAccueil"
                    :title="$t('capacite-accueil')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.periodiciteCommissionSecurite"
                    inputType="number"
                    :title="$t('periodicite-commission-securite')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.dateProchaineCommissionDeSecurite"
                    inputType="date"
                    :title="$t('date-prochaine-commission-de-securite')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.dateMiseEnService"
                    inputType="date"
                    :title="$t('date-mise-en-service')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.surfaceHorsOeuvreBrut"
                    inputType="number"
                    :title="$t('shob')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.surfaceParcelle"
                    inputType="number"
                    :title="$t('surface-parcelle')"
                    :is-required-value="false"
                  />

                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.referenceCadastrale"
                    :title="$t('reference-cadastrale')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.adresse"
                    :title="$t('adresse')"
                    input-type="textarea"
                    :is-required-value="false"
                  />
                  <vg-checkbox v-if="getTypeLieu=='Site'"
                    :defaultChecked="isCreatePieceGenerique"
                    :label="$t('creer-piece-generique')"
                    @checked="isCreatePieceGenerique=true;"
                    @unchecked="isCreatePieceGenerique=false;"/>
                  <vg-input
                    v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.varx"
                    :title="$t('latitude')"
                    :is-required-value="false"
                  />
                  <vg-input
                    v-if="getTypeLieu=='Site' && !isClient"
                    v-model="lieu.vary"
                    :title="$t('longitude')"
                    :is-required-value="false"
                  />
                  
                  <vg-input v-if="isPiece && lieu.equipement_categorie_id"
                      :title="$t('categorie')"
                      :is-required-value="true">
                      <vg-categorie-selector v-model="lieu.equipement_categorie_id"
                        :clearable="false"
                        isGe
                        style="width:100%;"
                        />
                  </vg-input>
                  <vg-input v-else-if="isPiece && lieu.categorie_id"
                      :title="$t('categorie')"
                      :is-required-value="true">
                      <vg-categorie-selector v-model="lieu.categorie_id"
                        :clearable="false"
                        isGe
                        style="width:100%;"
                        />
                  </vg-input>
                  <vg-input v-if="getTypeLieu=='Piece'"
                    v-model="lieu.codeTrois"
                    :title="$t('fonction')"
                    :is-required-value="false"
                  />
                  <vg-input v-if="getTypeLieu=='Piece'"
                    :title="$t('service')"
                    :is-required-value="false" >
                    <vg-service-selector
                      v-model="lieu.service"/>
                  </vg-input>
                    <vg-input
                        v-if="getTypeLieu=='Piece'"
                        v-model="lieu.surfaceSol"
                        :title="$t('surface-sol')"
                        :is-required-value="false">
                    </vg-input>
                    <vg-input
                        v-if="getTypeLieu=='Piece'"
                        v-model="lieu.surfaceMur"
                        :title="$t('surface-mur')"
                        :is-required-value="false">
                    </vg-input>
                    <vg-input
                        v-if="getTypeLieu=='Piece'"
                        v-model="lieu.revetementSol"
                        :title="$t('revetement-sol')"
                        :is-required-value="false">
                    </vg-input>
                    <vg-input
                        v-if="getTypeLieu=='Piece'"
                        v-model="lieu.revetementMur"
                        :title="$t('revetement-mur')"
                        :is-required-value="false">
                    </vg-input>
                    <vg-input
                        v-if="getTypeLieu=='Piece'"
                        v-model="lieu.revetementPlafond"
                        :title="$t('revetement-plafond')"
                        :is-required-value="false">
                    </vg-input>

            </div>
            <input
              v-show="false"
              ref="lieu-submit"
              type="submit"
            >
        </form>
        <vg-pieces-form
            class="multiple-create" v-else style="height:50vh;gap: 4px;"
            v-model="lieux"
            :etage="parent"
            multiple
        >
        </vg-pieces-form>

      </template>
      <template #footer>
        <vg-button
          type="default"
          @click="$emit('close')"
        >
          {{ $t("lieu-form-cancel") }}
        </vg-button>

        <vg-button
          v-if="isAnUpdateLieuForm && $app.role == 'ROLE_ADMIN' && getTypeLieu!='Organisation'"
          type="danger"
          @click="isModalConfirmDeleteOpened=true;"
        >
          {{ $t("lieu-form-delete") }}
        </vg-button>

        <vg-button
          v-if="isAnUpdateLieuForm"
          type="default-danger"
          @click="submitForm"
          :disabled="isDisabledUi"
        >
          {{ $t("lieu-form-save") }}
        </vg-button>

        <vg-button
          v-if="!isAnUpdateLieuForm"
          type="success"
          @click="submitForm"
          :disabled="isDisabledUi"
        >
          {{ $t("lieu-form-save") }}
        </vg-button>
      </template>
    </vg-modal>
    <dialog-secured-action
      v-if="isModalConfirmDeleteOpened"
      :valid-response="$t('message-validation-suppression-lieu')"
      :isAuthorized="isDeleteIsAuthorized"
      @save="handleDeleteLieu"
      @close="isModalConfirmDeleteOpened = false"
    >
      <template v-if="isDeleteIsAuthorized" v-slot:header-title>
        {{ $t("lieu-form-confirm-suppression") }} <b>{{ lieu.libel_lieu }}</b> ?
      </template>
      <template v-else v-slot:header-title>
        {{ $t("lieu-form-unauthorize-title") }} <b>{{ lieu.libel_lieu }}</b>.
      </template>
      <template v-slot:infos>
        {{ $t("warning-message") }}
      </template>
      <template v-slot:unauthorize-message>
        <b>{{ lieu.libel_lieu }}</b> {{ $t("lieu-form-unauthorize-message", {nbEquipement: lieu.nbEquipement}) }}.
      </template>
    </dialog-secured-action>
  </div>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins.js";
import CategorieMixins from "src/mixins/CategorieMixins.js";


import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgPiecesForm from "src/components/Vg/Forms/VgPiecesForm.vue";

import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgLieuFamilleSelector from "src/components/Vg/Selectors/VgLieuFamilleSelector.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgExcelGridEditor from 'src/components/Vg/ExcelGridEditor/VgExcelGridEditor.vue';
import StringUtilities from 'src/utilities/StringUtilities.js';
import LieuxValidator from 'validators/LieuxValidator.js';
import Metadatas from "src/services/Metadatas";

export default {
    name: 'vg-lieu-form',
    components:{
        VgButton,
        VgInput,
        VgSelect,
        VgCheckbox,
        DialogSecuredAction,
        VgLieuFamilleSelector,
        VgServiceSelector,
        VgCategorieSelector,
        VgLieuPathViewer,
        VgExcelGridEditor,
        VgPiecesForm
    },
    mixins: [ LieuMixins, CategorieMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "lieu-form-cancel": "Annuler",
            "lieu-form-save": "Enregistrer",
            "lieu-form-update": "Modification",
            "lieu-form-delete": "Supprimer",
            "lieu-form-add": "Ajouter",
            "dans": "dans",
            "lieu-form-add-pieces": "Ajouter des pièces dans ",
            "Site": "Site",
            "Batiment": "Batiment",
            "Etage": "Etage",
            "lieu-form-lieu": "Lieu",
            "lieu-form-confirm-suppression": "Confirmer la suppression",
            "lieu-form-unauthorize-title": "Impossible de supprimer",
            "lieu-form-unauthorize-message": "contient {nbEquipement} équipement(s)",
            "libel-lieu": "Libellé du lieu",
            "adresse": "Adresse",
            "fonction": "Fonction",
            "service": "Service",
            "categorie": "Catégorie",
            "add-piece": "Saisir une autre pièce",
            "remove-piece": "Enlever cette pièce",
            "famille": "Famille",
            "type-erp": "Type ERP",
            "categorie-erp": "Categorie ERP",
            "capacite-accueil": "Capacité d'accueil",
            "periodicite-commission-securite": "Périodicité commission de sécurité",
            "date-prochaine-commission-de-securite": "Prochaine date commission de sécurité",
            "date-mise-en-service": "Date de mise en service",
            "shob": "Surface Hors Oeuvre Brut (SHOB)",
            "surface-parcelle": "Surface de la parcelle",
            "reference-cadastrale": "Reference cadastrale",
            "revetement-sol": "Revêtement sol",
            "revetement-mur": "Revêtement mur",
            "revetement-plafond": "Revêtement plafond",
            "surface-sol": "Surface sol (m²)",
            "surface-mur": "Surface murs (m²)",
            "lien-fichier-ifc": "Lien complet vers fichier ifc",
            "creer-piece-generique": "Créer pièce générique",
            "message-validation-suppression-lieu":"Valider la suppression",
            "warning-message":"Attention la suppression de ce lieu entrainera la suppression des équipements, interventions, fiches curatives contrats etc... qui y sont attachés cette action est irréversible",
            "prefixA" : "Racine 1",
            "prefixB" : "Racine 2",
            "libelLieu":"Texte libellé",
            "derniersNumeroConnu" : "Numéro Auto.",
            "liste_piece_a_creer": "Liste des pièces à créer",
            "aucune_piece_a_creer":"Aucune pièce à créer, veuillez en ajouter au moins une en utilisant le formulaire d'ajout.",
            "pas_de_pieces_dans_la_liste":"Aucune pièce dans la liste, veuillez en ajouter au moins une en utilisant le formulaire ci-dessous.",
            "categorie_manquante":"Veuillez renseigner la catégorie de la pièce",
            "libellieu_manquant":"Veuillez renseigner le libellé de la pièce"
        },
        "en": {
            "lieu-form-cancel": "Cancel",
            "lieu-form-save": "Save",
            "lieu-form-update": "Update",
            "lieu-form-delete": "Delete",
            "lieu-form-add": "Add",
            "dans": "In",
            "lieu-form-add-pieces": "Add room in",
            "Site": "Site",
            "Batiment": "Building",
            "Etage": "Floor",
            "lieu-form-lieu": "Lieu",
            "lieu-form-confirm-suppression": "Confirm removal",
            "lieu-form-unauthorize-title": "Unathorized removal",
            "lieu-form-unauthorize-message": "Contains {nbEquipement} equipment(s)",
            "libel-lieu": "Location name",
            "adresse": "Address",
            "fonction": "Position",
            "service": "Service",
            "categorie": "Location category",
            "add-piece": "Add room",
            "remove-piece": "Remove room",
            "famille": "Family",
            "type-erp": "ERP type",
            "categorie-erp": "ERP category",
            "capacite-accueil": "Accomodation capacity",
            "periodicite-commission-securite": "Safety committee frequency",
            "date-prochaine-commission-de-securite": "Safety committee next date",
            "date-mise-en-service": "Date of commissionning",
            "shob": "Gross floor area",
            "surface-parcelle": "Plot surface",
            "surface-sol": "Floor area (m²)",
            "surface-mur": "Walls area (m²)",
            "revetement-sol": "Floor covering",
            "revetement-mur": "Walls covering",
            "revetement-plafond": "Ceiling covering",
            "reference-cadastrale": "Castadral reference",
            "lien-fichier-ifc": "Link to ifc file",
            "creer-piece-generique": "Create generic room",
            "message-validation-suppression-lieu":"confirm deletion",
            "warning-message":"Attention la suppression de ce lieu entrainera la suppression des équipements, interventions, fiches curatives contrats etc... qui y sont attachés cette action est irréversible"
        },
        "th": {
            "lieu-form-cancel": "ยกเลิก",
            "lieu-form-save": "บันทึก",
            "lieu-form-update": "อัปเดต",
            "lieu-form-delete": "ลบ",
            "lieu-form-add": "เพิ่ม",
            "dans": "ใน",
            "lieu-form-add-pieces": "เพิ่มห้องใน",
            "Site": "หน้างาน",
            "Batiment": "สิ่งปลูกสร้าง",
            "Etage": "พื้น",
            "lieu-form-lieu": "Lieu",
            "lieu-form-confirm-suppression": "ยืนยันการลบ",
            "lieu-form-unauthorize-title": "การลบที่ไม่ได้รับอนุญาต",
            "lieu-form-unauthorize-message": "มีอุปกรณ์ {nbEquipement} ชิ้น",
            "libel-lieu": "ชื่อที่ตั้ง",
            "adresse": "ที่อยู่",
            "fonction": "ตำแหน่ง",
            "service": "บริการ",
            "categorie": "หมวดหมู่ที่ตั้ง",
            "add-piece": "เพิ่มห้อง",
            "remove-piece": "ลบห้อง"
        }
    }
},
    props: {
        /**
        * @model
        */
        value:{
            type: Object,
            default:function(){
                return {};
            }
        },
        id: {
            type: Number | String,
            default: null
        },
        skipVueXStorage:{
            type: Boolean,
            default: false
        },
        /**
        * parent du lieu à créer
        */
        parent: {
            type: Object,
            default: null
        },
        /**
        * site créé pour un tiers type client dans le cadre d'un compte prestataire
        */
        isClient: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            lieu:{
                libel_lieu: null,
                type_lieu: this.getTypeLieu,
                adresse: null,
                codeTrois: null,
                service: null,
                vary: null,
                varx: null,
                prefixA:null,
                derniersNumeroConnu : null,
                prefixB: null,
                numAISuffix:null
            },
            selectedCategorie:null,
            libel_lieu: null,
            lieux: [],
            isModalConfirmDeleteOpened:false,
            isDisabledUi:false,
            showNewFamille: false,
            organisation: null,
            isCreatePieceGenerique: this.isClient
        };
    },
    created: function(){
        if(this.skipVueXStorage && this.id){ 
            this.fetchLieu();
        }else{
            if(!this.isAnUpdateLieuForm && this.isPiece){
                this.lieu.userId = this.$app["appID"];
                this.lieu.derniersNumeroConnu = 100;
                this.lieu.prefixB = "P";
                this.lieu.numAISuffix = 1;
                let path = this.parent?this.parent.path:this.value.path;
                this.lieu.prefixA = StringUtilities.getAcronym(path.split("/")[1]);
            }
            this.lieu = Object.assign({}, this.lieu, this.value);
            if(this.$app.account_type=="prestataire" && this.isClient && !this.isAnUpdateLieuForm){
                this.fetchOrganisation();
                this.getTypeLieu = "Site";
            }
        }
    },
    mounted:function(){

    },
    computed:{
        isDeleteIsAuthorized: function(){
            return !(this.lieu.nbEquipement && this.lieu.nbEquipement!=0);
        },
        hasChildren: function(){
            return this.value && this.value.children && this.value.children.length!=0;
        },
        isAnUpdateLieuForm:function(){
            if(this.id) return true;
            else if(this.value) return this.value.hasOwnProperty('id') && this.value.id;
            else return false;
        },
        isClientSite: function(){
            return this.$app.account_type=="prestataire" && this.isClient && !this.isAnUpdateLieuForm && this.organisation;
        },
        getTypeLieu: function(){
            if(this.id){
                return this.lieu ? this.lieu.type_lieu : "Piece";
            }else if(this.isClientSite){
                return "Site";
            }else if(this.parent){
                if(this.parent.type_lieu=="Organisation") return "Site";
                else if(this.parent.type_lieu=="Site") return "Batiment";
                else if(this.parent.type_lieu=="Batiment") return "Etage";
                else if(this.parent.type_lieu=="Etage") return "Piece";
            }else if(this.value && this.value.hasOwnProperty("id")){
                if(this.value.type_lieu=="Organisation") return "Organisation";
                else if(this.value.type_lieu=="Site") return "Site";
                else if(this.value.type_lieu=="Batiment") return "Batiment";
                else if(this.value.type_lieu=="Etage") return "Etage";
                else if(this.value.type_lieu=="Piece") return "Piece";
            }else return "Organisation";
        },
        isPiece: function(){
            return (this.parent && this.parent.type_lieu=="Etage") || (this.value && this.value.type_lieu=="Piece") || (this.lieu && this.lieu.type_lieu=="Piece");
        },
        getIcon: function(){
            if(this.isClientSite){
                return "/static/assets/icone/lieux/site-mauve.png";
            }else if(this.parent){
                if(this.parent.type_lieu=="Organisation") return "/static/assets/icone/lieux/site-mauve.png";
                else if(this.parent.type_lieu=="Site") return "/static/assets/icone/lieux/batiment-mauve.png";
                else if(this.parent.type_lieu=="Batiment") return "/static/assets/icone/lieux/etage-mauve.png";
                else if(this.parent.type_lieu=="Etage") return "/static/assets/icone/lieux/piece-mauve.png";
            }else if(this.value && this.value.hasOwnProperty("id")){
                if(this.value.type_lieu=="Organisation") return "/static/assets/icone/lieux/organisation-mauve.png";
                else if(this.value.type_lieu=="Site") return "/static/assets/icone/lieux/site-mauve.png";
                else if(this.value.type_lieu=="Batiment") return "/static/assets/icone/lieux/batiment-mauve.png";
                else if(this.value.type_lieu=="Etage") return "/static/assets/icone/lieux/etage-mauve.png";
                else if(this.value.type_lieu=="Piece") return "/static/assets/icone/lieux/piece-mauve.png";
            }
        },
        isValidPieces: function(){
            if( !this.isAnUpdateLieuForm && this.isPiece ) return this.lieux.every((lieu)=>lieu.libel_lieu && lieu.categorie_id);
            else return true;
        },
        getPatternLibelLieu: function(){
            let brothers = this.parent && this.parent.children?this.parent.children.map((child)=>child.libel_lieu):[];
            return brothers.length!=0?"^((?!(/|"+brothers.join("|")+")).)+$":"^((?!(/)).)+$";
        }
    },
    methods: {
        fetchLieu: function(){
            this.LieuMixins_getLieu(this.id, {skipVueXStorage: this.skipVueXStorage}).then((lieu)=>{
                this.lieu = lieu;
            });
        },
        fetchOrganisation: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters({type_lieu: {attr:"l.type_lieu", value:"Organisation", action: "equals"}});
            this.LieuMixins_getLieux(metadatas).then((datas)=>{
                if(datas.lieux) this.organisation = datas.lieux[0];
            });
        },
        onDuplicateCategorie: function(lieu, index){
            this.lieux = this.lieux.map((item,indexItem)=>{
                if(indexItem>=index) item.categorie_id = lieu.categorie_id;
            });
        },
        onDuplicateService: function(lieu, index){
            this.lieux = this.lieux.map((item,indexItem)=>{
                if(indexItem>=index) item.service = lieu.service;
            });
        },
        /*onAddPiece: function(piece){
            let validator = new LieuxValidator();
            let validation = validator.validatePiece(piece);
            if(!validation.success){
                alert(this.$t(validation.code));
                return;
            }
            this.lieux.push(Object.assign({categorie: {id: null}},this.lieu));
            this.lieu.derniersNumeroConnu++;
            this.lieu.numAISuffix++;
            console.log(this.lieux);
        },*/
        /*onRemovePiece: function(lieu, index){
            this.lieux.splice(index, 1);
        },*/
        /*
        * @event updated
        * @event close
        *
        */
        handleUpdateLieu:function(){
            let lieu = {
                id: this.lieu.id,
                libel_lieu: this.lieu.libel_lieu.replaceAll('/', '-'),
                type_lieu: this.getTypeLieu,
                adresse: this.lieu.adresse,
                codeTrois: this.lieu.codeTrois,
                service: this.lieu.service,
                userId: this.$app.appID,
                typeErp: this.lieu.typeErp,
                categorieErp: this.lieu.categorieErp,
                referenceCadastrale: this.lieu.referenceCadastrale,
                dateMiseEnService: this.lieu.dateMiseEnService,
                dateProchaineCommissionDeSecurite: this.lieu.dateProchaineCommissionDeSecurite,
                periodiciteCommissionSecurite: this.lieu.periodiciteCommissionSecurite,
                surfaceHorsOeuvreBrut: this.lieu.surfaceHorsOeuvreBrut,
                capaciteAccueil: this.lieu.capaciteAccueil,
                surfaceParcelle: this.lieu.surfaceParcelle,
                lienFichierIfc: this.lieu.lienFichierIfc,
                varx: this.lieu.vary,
                vary: this.lieu.varx,
                famille: this.lieu.famille
            };
            if(this.isPiece || this.lieu.categorie_id || this.lieu.equipement_categorie_id) lieu.categorie = {id: this.lieu.hasOwnProperty("categorie_id") && this.lieu.categorie_id?this.lieu.categorie_id:this.lieu.equipement_categorie_id};
            this.LieuMixins_update(lieu).then((lieu)=>{
                if(!this.skipVueXStorage) this.$store.dispatch("LieuxStore/setSelectedItem", lieu);
                if(this.getTypeLieu=="Organisation") window.location.reload();
                this.$emit("updated",lieu);
                //this.$emit("close");
            });
        },
        createPieceGenerique: function(lieux){
            // @TODO create piece generique
            this.LieuMixins_createPieceGenerique(lieux[0].id).then((piece)=>{
                this.$emit("created", lieux);
            });
        },
        /*
        * @event created
        * @event close
        *
        */
        handleCreateLieu:function(){
            this.lieu.idLieuParent_id = this.isClientSite?this.organisation.id:this.parent.id;
            this.lieu.type_lieu = this.getTypeLieu;
            this.lieu.libel_lieu = this.lieu.libel_lieu.replaceAll('/', '-');
            delete this.lieu.categorie_id;
            delete this.lieu.prefixA;
            delete this.lieu.prefixB;
            delete this.lieu.derniersNumeroConnu;
            delete this.lieu.numAISuffix;
            this.LieuMixins_create([this.lieu]).then((lieu)=>{
                if(this.isCreatePieceGenerique) this.createPieceGenerique(lieu);
                else this.$emit("created",lieu);
                //this.$emit("close");
            });
        },
        /*
        * @event created
        * @event close
        *
        */
        handleCreatePiece:function(){
            if(!this.lieux.length) {
                alert(this.$t("aucune_piece_a_creer"));
                this.isDisabledUi = false;
                return;
            }
            let lieuxValidator = new LieuxValidator();
            let invalidLieuxIndex = lieuxValidator.getInvalidIndexes(this.lieux);
            invalidLieuxIndex.forEach((index)=>{
                this.lieux[index].isInvalid = true;
            });
            if(invalidLieuxIndex.length) return;
            let lieux = this.lieux.map((lieu)=>{
                lieu.idLieuParent_id = this.parent.id;
                lieu.type_lieu = "Piece";
                lieu.categorie = {id: lieu.categorie_id};
                let libel_lieu = "";
                lieu.prefixA ? libel_lieu += lieu.prefixA : "";
                lieu.prefixB ? libel_lieu += "-" + lieu.prefixB : "";
                lieu.derniersNumeroConnu ? libel_lieu += lieu.derniersNumeroConnu : "";
                libel_lieu += "-"+lieu.libel_lieu;
                lieu.numAISuffix ? libel_lieu += " " + lieu.numAISuffix : "";
                lieu.libel_lieu = libel_lieu;
                lieu.libel_lieu = lieu.libel_lieu.replaceAll('/', '-');
                delete lieu.categorie_id;
                delete lieu.prefixA;
                delete lieu.prefixB;
                delete lieu.derniersNumeroConnu;
                delete lieu.numAISuffix;
                delete lieu.isInvalid;
                return lieu;
            });
            this.LieuMixins_create(lieux).then((lieux)=>{
                this.$emit("created", lieux);
                //this.$emit("close");
            });
        },
        /*
        * @event deleted
        * @event close
        *
        */
        handleDeleteLieu:function(){
            this.isDisabledUi = true;
            this.LieuMixins_delete(this.lieu).then((lieu)=>{
                this.$emit("deleted",lieu);
                //this.$emit("close");
                this.isModalConfirmDeleteOpened = false;
                window.location.reload();
            });
        },
        submitForm:function(){
            this.isDisabledUi = true;
            let isValidForm = false;
            if(this.isAnUpdateLieuForm || this.getTypeLieu!='Piece'){
                this.$refs['lieu-submit'].click();
                let form = this.$refs['lieu-form'];
                isValidForm = form.checkValidity()
            }
            else{
                isValidForm = true;
            }
            if(isValidForm && this.isValidPieces){
                if(this.isAnUpdateLieuForm){
                    this.handleUpdateLieu();
                }else if(!this.isPiece){
                    this.handleCreateLieu();
                }else{
                    this.handleCreatePiece();
                }
            }else{
                this.isDisabledUi = false;
            }
            return true;

        },
        handleChangeCategorie: function(categorie){
            this.selectedCategorie = categorie;
            this.lieu.libel_lieu = categorie.libelleCatgorie;
        }
    }
};
</script>
<style lang="scss">
.vg-lieu-modal{
    .modal-body{
        min-height: 300px;
    }
}
</style>
<style lang="scss" scoped>
.not-valid-lieu{
    border: 1px solid red;
}
.header-inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
    .icon{
        width: 30px;
        height: 30px;
    }
    .multiple-create{
        width: 100%;
        >div{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            >*{
                margin:0 5px;
            }
            .vg-v-select{
                width: 200px;
            }
        }

    }
</style>
<docs>

    ### vg-lieu-form
    Create|Update|Delete lieu

    #### basic usage

    ```html static
        <vg-lieu-form v-if="lieu || islieuFormDisplayed"
            v-model="lieu"
            @close="isUserFormDisplayed=false;lieu=null;"
            @created="isUserFormDisplayed=false;fetch()"
            @updated="isUserFormDisplayed=false;fetch()"
            @deleted="isUserFormDisplayed=false;fetch()"
        >
        </vg-lieu-form>
    ```
    ### Contrat Lieu object
    ```js
    var lieu = {

    }
    ```
</docs>
