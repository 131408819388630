<template>
    <div>
        <vg-modal
            :width="'400px'"
            :title="$t('title-modal')"
            @close="$emit('close')">
            <template #body>
                <div class="btn-section">
                    <vg-button v-if="maintenance.statut!='Resolue' && maintenance.statut!='prise_en_compte'"
                        :type="'info'"
                        @click="handleClickPriseEnCompte">
                        {{$t("btn-prendre-en-compte")}}
                    </vg-button>
                    <vg-button
                        :type="'info'"
                        @click="openModalOperation('manuelle')">
                        {{$t("btn-commenter")}}
                    </vg-button>
                    <vg-button v-if="maintenance.statut!='Resolue'"
                        :type="'info'"
                        @click="showAffectationModal=true;">
                        {{$t("btn-affecter")}}
                    </vg-button>
                    <vg-button v-if="maintenance.statut!='Resolue'"
                        :type="'info'"
                        @click="showCloseMaintenance=true;">
                        {{$t("btn-cloturer")}}
                    </vg-button>
                    <vg-button v-else
                        :type="'info'"
                        :disabled="isLoading"
                        @click="reopen">
                        {{$t("btn-reouvrir")}}
                    </vg-button>
                </div>
                <br>
                <vg-collapse :title="$t('collapse-gestion-tiers')"
                    :collapseByDefault="false">
                    <template #content>
                        <div class="btn-section">
                            <vg-button
                                :type="'info'"
                                @click="showModalCreateIntervention=true;">
                                {{$t("btn-enregistrer-bon-intervention")}}
                            </vg-button>
                            <vg-button v-if="maintenance.statut!='Resolue'"
                                :type="'info'"
                                @click="openModalMail('demande-devis')">
                                {{$t("btn-demander-devis-email")}}
                            </vg-button>
                        </div>
                    </template>
                </vg-collapse>
                <br>
                <vg-collapse :title="$t('collapse-gestion-interne')"
                    :collapseByDefault="false">
                    <template #content>
                        <div class="btn-section">
                            <vg-button v-show="can('MAINTENANCE.CONSOMMATION')"
                                :type="'info'"
                                @click="showModalConsommations = true">
                                {{$t("btn-gerer-pieces-detachees")}}
                            </vg-button>
                            <vg-button v-show="can('MAINTENANCE.BON_DE_COMMANDE')"
                                :type="'info'"
                                @click="showModalBonsDeCommande = true">
                                {{$t("btn-attacher-bon-de-commande")}}
                            </vg-button>
                            <vg-button v-if="maintenance.statut!='Resolue' && maintenance.equipement.isGroupEqp=='0'"
                                :type="'info'"
                                @click="showModalRemplacementEquipement = true">
                                {{$t("btn-remplacer-equipement")}} <vg-new-badge negativeColor/>
                            </vg-button>
                            <vg-button v-if="maintenance.statut!='Resolue'"
                                :type="'info'"
                                @click="openModalOperation('tache')">
                                {{$t("btn-definir-sous-action")}}
                            </vg-button>
                            <vg-button v-if="maintenance.statut!='Resolue'"
                                :type="'info'"
                                @click="openModalOperation('a_prevoir')">
                                {{$t("btn-definir-materiel-a-prevoir")}}
                            </vg-button>
                            <vg-button v-if="maintenance.isRetourAFaire && !maintenance.isRetourFait"
                                :type="'info'"
                                @click="showModalRetourFait = true;">
                                {{$t("btn-faire-un-retour")}}
                            </vg-button>
                            <vg-button
                                :type="'info'"
                                @click="openPrintMaintenance">
                                {{$t("btn-imprimer")}}
                            </vg-button>
                            <vg-button
                                :type="'info'">
                                {{$t("btn-envoyer-resume-email")}}
                            </vg-button>
                            <vg-button v-if="maintenance.statut!='Resolue' && maintenance.statut!='en_attente'"
                                :type="'info'"
                                @click="handleClickEnAttente">
                                {{$t("btn-mettre-en-attente")}}
                            </vg-button>
                            <vg-button v-if="maintenance.statut!='Resolue' && maintenance.statut=='en_attente'"
                                :type="'info'"
                                @click="handleClickPriseEnCompte">
                                {{$t("enlever-de-attente")}}
                            </vg-button>
                        </div>
                    </template>
                </vg-collapse>
            </template>
            <template #footer>
                <vg-checkbox :label="$t('checkbox-fermer-apres-premiere-action')"
                    :inputValue="closeAfterFirstAction"
                    :defaultChecked="closeAfterFirstAction"
                    @checked="onCloseAfterFirstAction(true)"
                    @unchecked="onCloseAfterFirstAction(false)"/>
                <vg-button :type="'default-info'" @click="$emit('close')">{{$t("btn-fermer")}} </vg-button>
            </template>
        </vg-modal>

        <vg-operation-create v-if="showModalOperation"
            :flag="operationFlag"
            :idMaintenance="maintenance.id"
            @close="showModalOperation = false"
            @save="showModalOperation = false;emitClose();" />

        <vg-consommations-form v-if="showModalConsommations"
            @close="showModalConsommations=false;"
            @save="onSaveConsommations" />

        <vg-fichesav-bon-de-commande v-if="showModalBonsDeCommande"
            @close="showModalBonsDeCommande=false;"
            @save="showModalBonsDeCommande=false;emitClose();" />

        <vg-consommable-form v-if="showModalNewConsommable"
            :isSaveUnable="ConsommablesMixins_isSaveDisabled"
            @close="showModalNewConsommable=false;showModalConsommations=true;"
            @save="showModalNewConsommable=false;showModalConsommations=true;" />

        <vg-fichesav-emails-helper v-if="showModalSendMail"
            @close="showModalSendMail=false;"
            :emailToSend="templateMail" />

        <vg-intervention-form v-if="showModalCreateIntervention"
            :idMaintenance="maintenance.id"
            :maintenance="maintenance"
            :isPonctuelle="true"
            :idTiers="getTiersId"
            @close="handleCloseInterventionModal"
            @save="handleCloseInterventionModal" />

        <vg-operation-retour-fait v-if="showModalRetourFait"
            @close="showModalRetourFait=false"
            @save="handleSaveRetourFait" />

        <vg-equipement-remplacement-form v-if="showModalRemplacementEquipement"
            :equipement="maintenance.equipement"
            :maintenance="maintenance"
            isRemplacement
            @close="showModalRemplacementEquipement=false;"
            @save="showModalRemplacementEquipement=false;"/>

        <fichesav-close v-if="showCloseMaintenance"
            :target="maintenance"
            :showWorkingTime="!($app.isSupervisor && maintenance.statut=='Resolue')"
            @close="showCloseMaintenance=false;"
            @cancel="showCloseMaintenance=false;"
            @confirm="cloturer"
            @open-new-consommable="handleOpenNewConsommable()"
            v-model="rapportCloture" />

        <vg-fichesav-affectation v-if="showAffectationModal"
           @close="showAffectationModal=false;"
           @save="showAffectationModal=false;emitClose();" />

    </div>

</template>

<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import VgNewBadge from 'src/components/Vg/Utils/VgNewBadge.vue';
import VgOperationCreate from "src/components/Vg/Operation/VgOperationCreate.vue";
import VgConsommableForm from 'src/components/Vg/Forms/VgConsommableForm.vue';
import ConsommablesMixins from 'src/mixins/ConsommablesMixins.js';
import MaintenanceMixins from 'src/mixins/MaintenanceMixins.js';
import VgInterventionForm from 'src/components/Vg/Forms/VgInterventionForm.vue';
import VgOperationRetourFait from "src/components/Vg/Operation/VgOperationRetourFait.vue";
import VgFichesavEmailsHelper from "src/components/Vg/FicheSAV/VgFichesavEmailsHelper.vue";
import FichesavClose from 'src/components/Vg/FicheSAV/FichesavClose.vue';
import VgFichesavAffectation from 'src/components/Vg/FicheSAV/VgFichesavAffectation.vue';
import VgFichesavBonDeCommande from 'src/components/Vg/FicheSAV/VgFichesavBonDeCommande.vue';
import VgConsommationsForm from 'src/components/Vg/Forms/VgConsommationsForm.vue';
import VgEquipementRemplacementForm from 'src/components/Vg/Forms/VgEquipementRemplacementForm.vue';

import { mapGetters } from 'vuex';
export default {
    name:"vg-fichesav-management",
    components: {
        VgButton,
        VgCollapse,
        VgCheckbox,
        VgNewBadge,
        VgOperationCreate,
        VgOperationRetourFait,
        VgConsommableForm,
        VgInterventionForm,
        VgFichesavEmailsHelper,
        FichesavClose,
        VgFichesavAffectation,
        VgFichesavBonDeCommande,
        VgConsommationsForm,
        VgEquipementRemplacementForm
    },
    mixins:[
        ConsommablesMixins,
        MaintenanceMixins
    ],
    props:{
        /** 
         * @TODO si skipVueXStorage==true fetchMaintenance here et passer à tous les composants enfants à ce composant
         * */
        skipVueXStorage: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-modal": "Actions fiche curative",
            "btn-prendre-en-compte": "Prendre en compte",
            "btn-commenter": "Commenter",
            "btn-affecter": "Affecter",
            "btn-cloturer": "Clôturer",
            "btn-reouvrir": "Ré-ouvrir",
            "collapse-gestion-tiers": "Gestion tiers",
            "btn-enregistrer-bon-intervention": "Enregistrer intervention tiers",
            "btn-demander-devis-email": "Demander un devis par email",
            "collapse-gestion-interne": "Gestion interne",
            "btn-gerer-pieces-detachees": "Gérer pièces détachées",
            "btn-attacher-bon-de-commande": "Attacher un bon de commande",
            "btn-remplacer-equipement": "Remplacer équipement",
            "btn-definir-sous-action": "Définir une sous-action fiche curative",
            "btn-definir-materiel-a-prevoir": "Définir un matériel à prévoir fiche curative",
            "btn-faire-un-retour": "Faire un retour",
            "btn-imprimer": "Imprimer la fiche curative",
            "btn-envoyer-resume-email": "Envoyer résumé par email (Bientôt)",
            "btn-mettre-en-attente": "Mettre en attente",
            "checkbox-fermer-apres-premiere-action": "Fermer après 1ère action",
            "enlever-de-attente": "Enlever de l'attente",
            "btn-fermer": "FERMER",
            "ajout-operation-consommation": "Consommation"
        },
        "en": {
            "title-modal": "Curative sheet actions",
            "btn-prendre-en-compte": "Take into account",
            "btn-commenter": "Comment",
            "btn-affecter": "Assign",
            "btn-cloturer": "Resolve",
            "btn-reouvrir": "Re-open",
            "collapse-gestion-tiers": "Manage supplier",
            "btn-enregistrer-bon-intervention": "Save third party intervention",
            "btn-demander-devis-email": "Request a quote by email",
            "collapse-gestion-interne": "Internal management",
            "btn-gerer-pieces-detachees": "Manage spare part",
            "btn-attacher-bon-de-commande": "Attach a purchase order",
            "btn-remplacer-equipement": "Replace equipment",
            "btn-definir-sous-action": "Defined sub-action",
            "btn-definir-materiel-a-prevoir": "Defined a material to provide",
            "btn-faire-un-retour": "Return to requester",
            "btn-imprimer": "Print",
            "btn-envoyer-resume-email": "Send summary by email (Soon)",
            "btn-mettre-en-attente": "Put on hold",
            "checkbox-fermer-apres-premiere-action": "Close after 1st action",
            "enlever-de-attente": "Unhold",
            "btn-fermer": "CLOSE"
        }
    }
},
    data: function() {
        return {
            consommables:null,
            showModalOperation:false,
            showModalConsommations:false,
            showModalBonsDeCommande:false,
            showModalNewConsommable:false,
            showModalSendMail:false,
            showModalCreateIntervention: false,
            showModalRetourFait: false,
            showCloseMaintenance: false,
            showAffectationModal: false,
            templateMail:null,
            operationFlag: null,
            closeAfterFirstAction: false,
            rapportCloture: null,
            showModalRemplacementEquipement: false,
            isLoading: false
        };
    },
    created: function(){
        if(localStorage.getItem("maintenance-modal-management-close-after-1st-action")) this.closeAfterFirstAction = localStorage.getItem("maintenance-modal-management-close-after-1st-action");
    },
    computed:{
        ...mapGetters({
            maintenance: 'MaintenancesStore/getSelectedMaintenance'
        }),
        getTiersId: function(){
            if(this.maintenance.affectation && this.maintenance.affectation.affectes && this.maintenance.affectation.affectes.length!=0){
                let tiers = this.maintenance.affectation.affectes.find((aff)=>aff.hasOwnProperty("tiers_id"));
                return tiers?tiers.tiers_id:null;
            }else return null;
        }
    },
    methods:{
        onSaveConsommations: function(){
            this.showModalConsommations = false;
            this.emitClose();
        },
        openPrintMaintenance: function(){
            this.$router.push({ name: '_maintenance_print', params: { id: this.maintenance.id }});
        },
        onCloseAfterFirstAction: function(close){
            localStorage.setItem("maintenance-modal-management-close-after-1st-action", close);
            this.closeAfterFirstAction = close;
        },
        openModalOperation: function(flag){
            this.operationFlag = flag;
            this.showModalOperation = true;
        },
        handleSaveSendEmail: function(){
            this.showModalSendMail = false;
            this.emitClose();
        },
        handleCloseInterventionModal: function(){
            this.showModalCreateIntervention = false;
        },
        handleSaveRetourFait: function(commentaire){
            this.showModalRetourFait = false;
            this.MaintenanceMixins_postMaintenanceOperations(this.maintenance.id, [{
                operation: commentaire,
                flag: "retourFait",
                dateOperation: moment().format("YYYY-MM-DD HH:mm:ss"),
                idUser: this.$app.idUser
            }]).then((data)=>{
                this.emitClose();
            })
        },
        openModalMail: function(template){
            this.showModalSendMail = true;
            this.templateMail = template;
        },
        handleClickPriseEnCompte: function(){
            this.MaintenanceMixins_prendreEnCompteMaintenance(this.maintenance).then(()=>{
                this.emitClose();
            });
        },
        handleClickEnAttente: function(){
            this.MaintenanceMixins_mettreEnAttenteMaintenance(this.maintenance).then(()=>{
                this.emitClose();
            });
        },
        handleOpenNewConsommable: function(){
            this.showModalConsommations = false;
            this.showModalNewConsommable = true;
        },
        calculCoutTotalConsommable: function(consommations){
            var coutConsos = 0;
            consommations.forEach((conso)=>coutConsos+=Number(conso.cout)*Number(conso.quantite));
            return {
                coutConsommations: Number(this.maintenance.coutConsommations) + Number(coutConsos),
                coutTotal: Number(this.maintenance.coutTotal) + Number(coutConsos)
            };
        },
        updateRowsForConsommable: function(rows){
            this.$emit("update-row-consommable", rows);
        },
        cloturer: function(data) {
            this.showCloseMaintenance = false;
            let workingTime = data.workingTime ? data.workingTime : null;
            var query = {
                id: this.maintenance.id,
                dateFermetureSAV: moment().format("YYYY-MM-DD HH:mm:ss"),
                statut: this.$app.underSupervisor ? "Supervisor" : "Resolue",
                operation: this.rapportCloture,
                userId: this.$app.appID,
                idUser: this.$app.idUser,
                workingTime: workingTime
            };
            //if(data && data.workingTime) query.workingTime = data.workingTime;
            this.MaintenanceMixins_resolveMaintenance(query).then(()=>{
                this.$store.dispatch("MaintenancesStore/updateMaintenance", query);
                if(this.$router.app._route.name!="_maintenances") this.$emit("back-page");//this.$router.push({ name: '_maintenances'});
                else this.emitClose();
            });
        },
        reopen:function(){
            this.isLoading = true;
            this.MaintenanceMixins_reopenMaintenances(this.maintenance.id).then(()=>{
                this.isLoading = false;
                this.$store.dispatch("MaintenancesStore/updateMaintenance", {"id":this.maintenance.id, "statut":"En_cours"});
                this.$emit('close', null);
            });
        },
        emitClose: function(){
            if(this.closeAfterFirstAction) this.$emit('close', null);
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-section{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    button{
        width:100%;
        margin:7px 0;
        height:35px;
    }
}

    .title-fichesav-manangement{
        font-size: 16px;
    }
    .section{
        margin-bottom:10px;
        .name-section{
            font-size: 13px;
            color: #a2a2a2;
            width: 100%;
            height: 25px;
            .name{
                float: left;
                height: 100%;
                width: 40%;
                font-size: 12px;
            }
            .separation{
                width: calc(100% - 40%);
                float: left;
                height: 30%;
                border-bottom: 1px solid #a2a2a2;
            }

        }
        .vg-button-management{
            color: white;
            background-color: #0080C5;
            border: 1px solid #0080C5;
            width: 100%;
            font-size: 14px;
            text-align: left;
            margin-bottom:3px;
            .tag-button{
                font-size: 10px;
                margin-left: 3px;
            }
            img,i{
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }
        }

    }
    .vg-button-management-close{
        color: #0080C5 !important;
        border: 1px solid #0080C5 !important;
        font-size: 14px !important;

    }
</style>