<template>
    <div id="interventions" class="full-height">
        <vg-app-layout
            :title="$t('page-title')"
            :icon="getIconIntervention"
            :colorheader="getHeaderColor"
            :activeFilters="activeFilters"
            :globalFilters="hasActiveGlobalFilters"
            :filtersTitle="$t('modal-filters-title')"
            @remove-filters="onRemoveFilters"
            @action-export="handleActionExport"
            @global-filters-remove="deleteGlobalFilters">

            <template v-slot:create-button>
                <vg-button type="success"
                    @click="isInterventionSansContrat=true;showCreateInterventionModal=true;">
                    {{$t("create-intervention-sans-contrat")}}
                </vg-button>
                <vg-button type="success"
                    @click="isInterventionSansContrat=false;showCreateInterventionModal=true;">
                    {{$t("create-intervention-avec-contrat")}}
                </vg-button>
            </template>

            <template #search>
                <vg-text-filter v-if="!isVueCalendrier"
                    v-model="searchQuery">
                </vg-text-filter>
            </template>

            <template #panel-filters-attributs v-if="!isVueCalendrier">
                <vg-input :title="$t('filtre-date-previsionnelle-debut')">
                    <vg-date-picker-shortcuts
                        key="filter-date-previsionnelle-debut-date-picker-shortcuts"
                        previousDatesPresets
                        opens="right"
                        :appendToBody="true"
                        :defaultStartDate="agFilters.field_datePrevisionnelleDebut.value?agFilters.field_datePrevisionnelleDebut.value[0]:null"
                        :defaultEndDate="agFilters.field_datePrevisionnelleDebut.value?agFilters.field_datePrevisionnelleDebut.value[1]:null"
                        @select="onChangeDateIntervalle"
                        @finish-selection="onChangeDateIntervalle" />
                </vg-input>
                <vg-input :title="$t('filtre-status')">
                    <vg-intervention-statut-selector v-model="agFilters.field_status.value"
                        :clearable="true">
                    </vg-intervention-statut-selector>
                </vg-input>
                <vg-input v-if="isUserIsNotSousTraitant" :title="$t('filtre-tiers')">
                    <vg-tiers-selector
                        v-model="agFilters.field_tiers_id.value">
                    </vg-tiers-selector>
                </vg-input>
            </template>
            <template #panel-filters-attributs v-else>
                <vg-calendar-picker
                    @focused-date="onFocusedDate">
                </vg-calendar-picker>
            </template>

            <template v-slot:panel-menu-more>
                <vg-checkbox
                    :inputValue="isVueCalendrier"
                    :defaultChecked="isVueCalendrier"
                    :label="$t('menu-more-calendrier')"
                    @checked="onCheckVueCalendrier"
                    @unchecked="onUncheckVueCalendrier">
                </vg-checkbox>
                <br>
                <vg-button
                    :type="'success'"
                    @click="showCreateInterventionModal=true;">
                    {{$t("create-intervention")}}
                </vg-button>
            </template>

            <template #primary>
                <vg-tabs v-model="focusedTab" 
                    :tabs="tabs"
                    @tabClicked="onTabChange"/>
                <vg-interventions-table
                    :agFilters="agFilters"
                    isPeriodiques
                    @row-click="handleClickRow"
                    @row-dbl-click="handleDoubleClickRow" />
            </template>
            <template #secondary-filters>
                <vg-input :title="$t('filtre-date-previsionnelle-debut')" v-if="focusedTab!='sans-contrat'">
                    <vg-date-picker-shortcuts
                        key="filter-date-previsionnelle-debut-date-picker-shortcuts"
                        previousDatesPresets
                        opens="left"
                        :appendToBody="true"
                        :defaultStartDate="agFilters.field_datePrevisionnelleDebut.value?agFilters.field_datePrevisionnelleDebut.value[0]:null"
                        :defaultEndDate="agFilters.field_datePrevisionnelleDebut.value?agFilters.field_datePrevisionnelleDebut.value[1]:null"
                        @select="onChangeDateIntervalle"
                        @finish-selection="onChangeDateIntervalle" />
                </vg-input>
                <vg-input :title="$t('filtre-documents-manquants')">
                    <vg-checkbox
                        :inputValue="isOnlyRealiseesAucunDocument"
                        :defaultChecked="isOnlyRealiseesAucunDocument"
                        @checked="onShowOnlyRealiseesAucunDocument(true)"
                        @unchecked="onShowOnlyRealiseesAucunDocument(false)" />
                </vg-input>
                <vg-input :title="$t('filtre-contrat')" v-if="focusedTab!='sans-contrat'">
                    <vg-contrat-selector v-model="agFilters.field_contrat.value" />
                </vg-input>
                <vg-input :title="$t('filtre-status')" v-if="focusedTab!='non-validees' && focusedTab!='validees'">
                    <vg-intervention-statut-selector v-model="agFilters.field_status.value" :clearable="true" />
                </vg-input>
                <vg-input :title="$t('filtre-tiers')" v-if="isUserIsNotSousTraitant">
                    <vg-tiers-selector v-model="agFilters.field_tiers_id.value" />
                </vg-input>
                <vg-filter-site
                    :attributeValue="null"
                    @input="onChangeFiltreSite" />
            </template>
            <template #secondary-widget>
                <vg-tree-lieux
                    v-model="agFilters.field_path.value"/>
            </template>
            <vg-intervention-form v-if="showCreateInterventionModal"
                :idTiers="$app.tiers_id"
                :isPeriodique="true"
                :defaultStatut="isInterventionSansContrat?'intervention-realisee':'intervention-prevue'"
                :isSansContrat="isInterventionSansContrat"
                :canBeCreatedWithoutContrat="!isInterventionSansContrat"
                @close="showCreateInterventionModal=false;"
                @save="showCreateInterventionModal=false;" />
        </vg-app-layout>
    </div>
</template>

<script>
    import InterventionsMixins from "src/mixins/InterventionsMixins.js";

    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgGroup from "src/components/Vg/VgGroup.vue";
    import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
    import VgCalendarPicker from "src/components/Vg/VgCalendarPicker.vue";
    import VgInterventionsTable from "src/components/Vg/Interventions/VgInterventionsTable.vue";
    import VgInterventionStatutSelector from "src/components/Vg/Interventions/VgInterventionStatutSelector.vue";
    import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
    import VgContratSelector from "src/components/Vg/Selectors/VgContratSelector.vue";
    import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
    import FiltersMixins from 'src/mixins/FiltersMixins';

    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
    export default {
        name: 'interventions',
        components: {

            VgTabs,
            VgTextFilter,
            VgInput,
            VgSelect,
            VgButton,
            VgCheckbox,
            VgDatePickerShortcuts,
            VgCalendarPicker,
            VgInterventionsTable,
            VgInterventionStatutSelector,
            VgTiersSelector,
            VgContratSelector,
            VgInterventionForm,
            VgTreeLieux,
            VgFilterSite,
            VgGroup
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Historique interventions tiers",
            "menu-more-calendrier": "Vue calendrier",
            "create-intervention-avec-contrat": "Programmer intervention tiers préventive",
            "create-intervention-sans-contrat": "Créer intervention tiers préventive (sans contrat)",
            "filtres": "Filtres",
            "filtre-date-previsionnelle-debut": "Date prévisionnelle début",
            "filtre-status": "Statut",
            "filtre-tiers": "Tiers",
            "filtre-contrat": "Contrat",
            "modal-filters-title": "Filtrer les interventions",
            "tab-tous":"Tous",
            "tab-non-validees":"Non validées",
            "tab-non-validees-a-aujourdhui":"Non validées à aujourd'hui",
            "tab-validees":"Validées",
            "tab-sans-contrat":"Sans contrat",
            "tab-avec-contrat":"Avec contrat",
            "filtre-documents-manquants": "Voir uniquement interventions réalisées documents manquants"
        },
        "en": {
            "page-title": "Third party intervention history",
            "menu-more-calendrier": "Calendar view",
            "filtres": "Filters",
            "filtre-date-previsionnelle-debut": "Estimated start date",
            "filtre-status": "Status",
            "filtre-tiers": "Third parties",
            "filtre-contrat": "Contract",
            "modal-filters-title": "Filter interventions",
            "tab-tous":"All",
            "tab-non-validees":"Not validated",
            "tab-non-validees-a-aujourdhui":"Not validated today",
            "tab-validees":"Validated",
            "tab-sans-contrat":"Without contract",
            "tab-avec-contrat":"With contract"
        }
    }
},
		mixins:[
            InterventionsMixins,
            FiltersMixins
        ],
        props:{
            defaultFilters:{
                type: Object,
                default: function(){
                    return {};
                }
            }
        },
        data: function() {
            return {
                searchQuery: "",
    			interventionLocalFilters: null,
                sites:[],
                agFilters: {
                    numeroIntervention: {attr: "i.numeroIntervention", value: null, action: "contains", openParenthesis: true},
                    intitule: {attr: "i.intitule", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
                    field_datePrevisionnelleDebut: { attr:"i.datePrevisionnelleDebut",colId: "datePrevisionnelleDebut", value: null, action:"between" },
                    field_status: { attr:"i.status",colId: "status", value: null, action:"equals" },
                    field_contrat: { attr:"c.id",colId: "c.id", value: null, action:"equals" },
                    field_tiers_id: { attr:"t.id",colId: "tiers_id", value: this.$app.role=="ROLE_SOUS_TRAITANT"?this.$app.tiers_id:null, action:"equals" },
                    field_path: {attr: "l.path", colId: "path", value: null, action:"start_with", openParenthesis: true},
                    field_site_id: {attr: "i.site_id", colId: "site_id", value: null, action:"equals", closeParenthesis: true, logicalOperator: "OR" },
                    field_documents_manquants: {attr: "docs.nbDocuments", colId:"nbDocuments", value: null, action: "is_null"}
                },
                isVueCalendrier: false, // affichage vue calendrier || vue filtres
                showCreateInterventionModal: false,
                isInterventionSansContrat: false,
                focusedTab: "tous",
                isOnlyRealiseesAucunDocument: false
            }
        },
        watch:{
            agFilters:{
                handler:function(nfilters){
                    //console.log("AG FILTERS", nfilters);
                },
                deep:true
            },
            searchQuery:{
                handler: function(query){
                    //console.log("SEARCH QUERY", query);
                    this.agFilters.numeroIntervention.value = query;
                    this.agFilters.intitule.value = query;
                    this.FiltersMixins_saveSearchQuery(query);

                }
            }
        },
        methods:{
            onShowOnlyRealiseesAucunDocument: function(isShow){
                this.isOnlyRealiseesAucunDocument = isShow;
                this.agFilters.field_documents_manquants.value = isShow?1:null;
                this.agFilters.field_status.value = isShow?"intervention-realisee":null;
            },
            onChangeDateIntervalle: function(dt){
                this.agFilters.field_datePrevisionnelleDebut.value = [dt.startDate, dt.endDate];
            },
            onTabChange: function(tab){
                this.onRemoveFilters();
                this.agFilters = Object.assign({}, this.agFilters, tab.filters);
                this.FiltersMixins_saveDefaultTab(tab.name);
            },
            deleteGlobalFilters: function(){
                let filtersName = Object.keys(this.agFilters);
                let filters = {};
                let filter = {};
                filtersName.forEach((name)=>{
                    filter = Object.assign({}, {}, this.agFilters[name]);
                    if(name.startsWith("global_")) filter.value = null;
                    filters[name] = Object.assign({}, {}, filter);
                });
                this.agFilters = filters;
            },
            onChangeFiltreSite: function(site){
                if(site){
                    this.agFilters.field_path.value = site.path;
                    this.agFilters.field_site_id.value = site.id;
                }else{
                    this.agFilters.field_path.value = null;
                    this.agFilters.field_site_id.value = null;
                }
            },
            onRemoveFilters: function(){
                this.pageFilters.forEach((filterName)=>{
                    if(filterName=="field_tiers_id") this.agFilters[filterName].value = this.$app.role=="ROLE_SOUS_TRAITANT"?this.$app.tiers_id:null;
                    else this.agFilters[filterName].value = null;
                });
                this.agFilters.field_documents_manquants.value = null;
                this.isOnlyRealiseesAucunDocument = false;
                this.searchQuery = null;
            },
            handleActionExport:function(e){
                switch (e.name) {
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                            "detail": {"idTableau":"vg-interventions-table"}
                        }));
                        break;
                    default:
                        window.dispatchEvent(new CustomEvent(e.name, {
                                "detail": {"idTableau":"vg-interventions-table","excludeCellrenderers":["statut"]}
                            }));
                        break;
                }
            },
            gotToIntervention: function(intervention){
                this.$router.push({ name: '_intervention', params: { id: intervention.id, datas: intervention } });
            },
            handleClickRow:function(e){
                if(this.$vgutils.isMobile()) this.gotToIntervention(e);
            },
            handleDoubleClickRow:function(e){
                this.gotToIntervention(e);
            },
            onCheckVueCalendrier: function(e){
                this.isVueCalendrier = true;
                this.$storage.set("intervention-is-vue-calendrier", true);
            },
            onUncheckVueCalendrier: function(e){
                this.isVueCalendrier = false;
                this.$storage.set("intervention-is-vue-calendrier", false);
            },
            onFocusedDate: function(date){
                this.agFilters.field_datePrevisionnelleDebut.value = date;
            },
            getCounters: function(tabName){
                if(this.counters){
                    if(this.focusedTab==tabName) return this.counters.filtered+"/"+this.counters[tabName];
                    else return this.counters[tabName];
                }else{
                    return "-";
                }
            }
        },
		created: function(){
            this.FiltersMixins_page_name = "interventions";
            this.isVueCalendrier = this.$storage.get("intervention-is-vue-calendrier") || false;
            if(this.$app.tiers_id) this.agFilters.field_tiers_id.value = this.$app.tiers_id;
            this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
            this.agFilters = Object.assign({}, this.agFilters, this.defaultFilters);
            if(this.FiltersMixins_getDefaultFocusedTab()) this.focusedTab = this.FiltersMixins_getDefaultFocusedTab();
		},
		mounted: function(){

		},
        computed:{
            ...mapGetters({
                counters: "InterventionsStore/getCounters"
            }),
            pageFilters: function(){
                return ["field_datePrevisionnelleDebut", "field_status", "field_tiers_id", "field_contrat", "field_path"];
            },
            activeFilters: function(){
                let filtersNames = this.pageFilters;
                let activeFilters = filtersNames.filter((filterName)=>this.agFilters[filterName].value!=null && (this.agFilters[filterName].value && this.agFilters[filterName].value.length!=0));
                let nFilters = 0;
                if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
                return activeFilters.length + nFilters;
            },
            getIconIntervention: function(){
                return this.$vgutils.isMobile()?"intervention_blanc.png":"intervention.png";
            },
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#66cc00' : 'white';
            },
            isUserIsNotSousTraitant: function(){
                return this.$app.role!="ROLE_SOUS_TRAITANT";
            },
            hasActiveGlobalFilters: function(){
                let filters = this.agFilters;
                for (let filterName in filters) {
                    if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                        return true;
                    }
                }
                return false;
            },
            tabs: function(){
                return [
                    {label: this.$t("tab-tous"), name: "tous", counter: this.getCounters("all"), filters: {
                        tab_idContrat: {attr:"i.contrat_id", value: null, action: "is_not_null"},
                        tab_status: {attr:"i.status", value: null, action: "equals"},
                        tab_datePrevisionnelleDebut: {attr:"i.datePrevisionnelleDebut", value: null, action: "less_than"}
                    }},
                    {label: this.$t("tab-non-validees-a-aujourdhui"), name: "non-validees-a-ajourdhui", counter: this.getCounters("non-validees-a-aujourdhui"), filters: {
                        tab_idContrat: {attr:"i.contrat_id", value: null, action: "is_not_null"},
                        tab_status: {attr:"i.status", value: "intervention-prevue", action: "equals"},
                        tab_datePrevisionnelleDebut: {attr:"i.datePrevisionnelleDebut", value: moment().format("YYYY-MM-DD 23:59:59"), action: "less_than"}
                    }},
                    {label: this.$t("tab-non-validees"), name: "non-validees", counter: this.getCounters("non-validees"), filters: {
                        tab_idContrat: {attr:"i.contrat_id", value: null, action: "is_not_null"},
                        tab_status: {attr:"i.status", value: "intervention-prevue", action: "equals"},
                        tab_datePrevisionnelleDebut: {attr:"i.datePrevisionnelleDebut", value: null, action: "less_than"}
                    }},
                    {label: this.$t("tab-validees"), name: "validees", counter: this.getCounters("validees"), filters: {
                        tab_idContrat: {attr:"i.contrat_id", value: null, action: "is_not_null"},
                        tab_status: {attr:"i.status", value: "intervention-realisee", action: "equals"},
                        tab_datePrevisionnelleDebut: {attr:"i.datePrevisionnelleDebut", value: null, action: "less_than"}
                    }},
                    {label: this.$t("tab-sans-contrat"), name: "sans-contrat", counter: this.getCounters("sans-contrat"), filters: {
                        tab_idContrat: {attr:"i.contrat_id", value: "1", action: "is_null"},
                        tab_status: {attr:"i.status", value: null, action: "equals"},
                        tab_datePrevisionnelleDebut: {attr:"i.datePrevisionnelleDebut", value: null, action: "less_than"}
                    }},
                    {label: this.$t("tab-avec-contrat"), name: "avec-contrat", counter: this.getCounters("avec-contrat"), filters: {
                        tab_idContrat: {attr:"i.contrat_id", value: "1", action: "is_not_null"},
                        tab_status: {attr:"i.status", value: null, action: "equals"},
                        tab_datePrevisionnelleDebut: {attr:"i.datePrevisionnelleDebut", value: null, action: "less_than"}
                    }},
                ];
            }
        }


    };
</script>
<style lang="scss" scoped>

</style>