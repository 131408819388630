export default class DateUtilities {
    static getMinutesBetweenDatesExcludingWeekends(startDate, endDate) {
        console.log("GET MINUTES BETWEEN EXCLUDING WEEKENDS", startDate, endDate);
        let start = moment(startDate);
        let end = moment(endDate);
        let totalMinutes = 0;
        
        while (start.isBefore(end)) {
            // Si ce n'est ni un samedi ni un dimanche
            if (start.day() !== 6 && start.day() !== 0) totalMinutes += 24 * 60;  // Ajoute 1440 minutes par jour (24 heures * 60 minutes)
            // Avancer au jour suivant
            start.add(1, 'day');
        }
        
        // Ajuster pour les minutes restantes de la journée finale
        if (end.day() !== 6 && end.day() !== 0) {
            totalMinutes += end.diff(start, 'minutes');
        }
        
        return totalMinutes;
    }
}