<template >
    <vg-app-layout v-if="bonDeCommande"
        :backButtonPopoverText="$t('back-page')"
        :title="$t('page-title')"
        :isZoomLayout="true"
        :isActionButtonDisplay="false"
        :isLoadingState="isLoading"
        @backpage="retourPage">
        <template v-slot:header-top-left-complement>
            <span style="font-weight:bold;margin-left:10px;">{{bonDeCommande.numero}} {{bonDeCommande.fournisseur.name}}</span>
            <vg-bon-de-commande-statut-viewer v-model="bonDeCommande"
                style="margin-right:20px;font-size:20px;"/>
        </template>
        <template #create-button>
            <vg-button
                type="default-danger"
                @click="onClickUpdate">
                {{$t("update")}}
            </vg-button>
        </template>

        <!--template v-if="!isModeSaisirLivraison" #create-button>
            <vg-button type="danger" @click="onPrintBC">{{$t("print")}}</vg-button>
        </template-->

        <template v-slot:primary>
            <div>
                <vg-collapse :title="$t('collapse-title-entete')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #pretitle>
                        
                    </template>
                    <template v-slot:content>
                        <!-- numero -->
                        <!-- service demandeur -->
                        <!-- site demandeur -->
                        <!-- fournisseur -->
                        <!-- date creation -->
                        <!-- createur -->
                        <!-- commentaire -->
                        <vg-libelle-editable :label="$t('label-numero')" >
                            <template v-slot:read>
                               <span>{{bonDeCommande.numero}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable v-if="bonDeCommande.bonsDeCommandeEntite_id" :label="$t('label-entite')" >
                            <template v-slot:read>
                               <span>{{bonDeCommande.bonsDeCommandeEntite.name}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('label-service-demandeur')" >
                            <template v-slot:read>
                               <span>{{bonDeCommande.serviceDemandeur}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('label-site-demandeur')" >
                            <template v-slot:read>
                               <span v-if="bonDeCommande.siteDemandeur_id && bonDeCommande.siteDemandeur">{{bonDeCommande.siteDemandeur.libel_lieu}}</span>
                               <span v-if="bonDeCommande.demandeur">{{bonDeCommande.demandeur}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('label-fournisseur')" >
                            <template v-slot:read>
                               <span style="font-weight:bold;font-size:16px;">{{bonDeCommande.fournisseur.name}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('label-date-creation')" >
                            <template v-slot:read>
                               <vg-datetime-viewer v-model="bonDeCommande.dateCreation"/>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('label-createur')" >
                            <template v-slot:read>
                               <span>{{bonDeCommande.createur.nom}} {{bonDeCommande.createur.prenom}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('label-commentaire')" >
                            <template v-slot:read>
                               <vg-text-multiligne v-model="bonDeCommande.commentaire"/>
                               <vg-checkbox style="margin-top:5px;" :defaultChecked="defaultCheckedCommentairePdf"
                                    @checked="addCommentaireDansPdf(true)"
                                    @unchecked="addCommentaireDansPdf(false)">
                                    {{ $t("add-commentaire-in-pdf") }}
                                </vg-checkbox>
                            </template>
                        </vg-libelle-editable>
                    </template>
                </vg-collapse>

                <vg-collapse v-if="!isModeSaisirLivraison" :title="$t('collapse-title-statuts')"
                    :collapseByDefault="false"
                    :type="'section'"
                    >

                    <template v-slot:content>
                        <!-- statut bon de commande -->
                        <!-- statut paiement facture -->
                        <!-- statut livraison -->
                        <vg-libelle-editable :label="$t('label-statut')" >
                            <template v-slot:read>
                               <vg-bon-de-commande-statut-viewer v-model="bonDeCommande" />
                            </template>
                        </vg-libelle-editable>
                        <!--vg-libelle-editable :label="$t('label-statut-paiement')" >
                            <template v-slot:read>
                               <span>{{bonDeCommande.statutPaiement}}</span>
                            </template>
                        </vg-libelle-editable-->
                        <vg-libelle-editable :label="$t('label-statut-livraison')" >
                            <template v-slot:read>
                               <vg-bon-de-commande-statut-livraison-viewer v-model="bonDeCommande"/>
                            </template>
                        </vg-libelle-editable>
                    </template>
                </vg-collapse>
                <vg-collapse v-if="bonDeCommande.operations && bonDeCommande.operations.length!=0 && bonDeCommandeOperationsFichesCuratives.length!=0" :title="$t('collapse-title-fiches-curatives')"
                    :collapseByDefault="false"
                    :type="'section'">

                    <template #content>
                        <vg-libelle-editable :label="$t('fiches-curatives-numero')" >
                            <template #read>
                                <a v-for="(operation, index) in bonDeCommandeOperationsFichesCuratives" :key="'fiche-curative-'+index"
                                    class="fiche-curative-link"
                                    @click="openMaintenance(operation.ficheSav_id)">
                                    {{ $t("numero", {numero: operation.ficheSav_id}) }}
                                </a>
                            </template>
                        </vg-libelle-editable>
                        
                    </template>
                </vg-collapse>
                <vg-collapse v-if="bonDeCommande.operations && bonDeCommande.operations.length!=0 && bonDeCommandeOperationsReserves.length!=0" :title="$t('collapse-title-reserves')"
                    :collapseByDefault="false"
                    :type="'section'">

                    <template #content>
                        <vg-libelle-editable :label="$t('reserves-numero')" >
                            <template #read>
                                <a v-for="(operation, index) in bonDeCommandeOperationsReserves" :key="'fiche-curative-'+index"
                                    class="fiche-curative-link"
                                    @click="openReserve(operation.ficheSav_id)">
                                    {{ $t("numero", {numero: operation.ficheSav_id}) }}
                                </a>
                            </template>
                        </vg-libelle-editable>
                        
                    </template>
                </vg-collapse>

                <vg-collapse v-if="!isModeSaisirLivraison" :title="$t('collapse-title-documents')"
                    :collapseByDefault="false"
                    :type="'section'"
                    >
                    <template v-slot:pretitle>
                        <vg-button
                            size="md"
                            type="default-round"
                            @click="isUploaderVisible=!isUploaderVisible;">
                            <i class="fas fa-plus"></i>
                        </vg-button>
                    </template>
                    <template v-slot:content>

                        <div class="composants__documents-attaches inherit">
                            <vg-files-uploader v-if="isUploaderVisible"
                                :tagsList="VgFilesMixins.tags"
                                @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;">
                            </vg-files-uploader>
                            <vg-files-viewer-list-section
                                :files="VgFilesMixins_filesListSection"
                                @delete="VgFilesMixins_fetchFilesByUid()">
                            </vg-files-viewer-list-section>
                        </div>

                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('collapse-title-actions')"
                    :collapseByDefault="false"
                    :type="'section'"
                    >

                    <template v-slot:content>
                        <div v-if="!isModeSaisirLivraison" class="container-actions">
                            <div class="container-actions-btn">
                                <vg-button v-if="!showUpdateItems"
                                    :type="bonDeCommande.statut=='draft'?'danger':'grey'"
                                    :disabled="bonDeCommande.statut!='draft'"
                                    @click="showUpdateItems=true;">
                                    {{$t("btn-modifier")}}
                                </vg-button>
                                <vg-button v-else
                                    :type="'danger'"
                                    :disabled="bonDeCommande.statut!='draft'"
                                    @click="showUpdateItems=false;">
                                    {{$t("btn-quitter")}}
                                </vg-button>
                                <div class="container-action-arrow">
                                    <i class="fas fa-arrow-down"></i>
                                </div>
                                <vg-button
                                    :type="bonDeCommande.statut=='draft'?'danger':'grey'"
                                    :disabled="bonDeCommande.statut!='draft'"
                                    @click="demanderValidation">
                                    {{$t("btn-demander-la-validation")}}
                                </vg-button>
                                <div class="container-action-arrow">
                                    <i class="fas fa-arrow-down"></i>
                                </div>
                                <vg-button
                                    :type="isBtnValiderVisible?'danger':'grey'"
                                    :disabled="!isBtnValiderVisible"
                                    @click="validerBonDeCommande">
                                    {{$t("btn-valider")}}
                                </vg-button>
                                <div class="container-action-arrow">
                                    <i class="fas fa-arrow-down"></i>
                                </div>
                                <vg-button
                                    :type="bonDeCommande.statut=='validation-complete'?'danger':'grey'"
                                    :disabled="bonDeCommande.statut!='validation-complete'"
                                    @click="envoyerBonDeCommande">
                                    {{$t("btn-envoyer-la-commande")}}
                                </vg-button>
                                <div class="container-action-arrow">
                                    <i class="fas fa-arrow-down"></i>
                                </div>
                                <vg-button
                                    :type="bonDeCommande.statut=='sended' || bonDeCommande.statut=='partially-delivered' || bonDeCommande.statut=='skip-sending'?'danger':'grey'"
                                    :disabled="bonDeCommande.statut!='sended' && bonDeCommande.statut!='partially-delivered' && bonDeCommande.statut!='skip-sending'"
                                    @click="saisirLivraison">
                                    {{$t("btn-saisir-livraison")}}
                                </vg-button>
                            </div>
                            <div class="container-actions-infos">
                                <span class="container-action-info">
                                    {{$t("action-info-modifier")}}
                                </span>
                                <span class="container-action-info">
                                    {{$t("action-info-demander-validation")}}
                                </span>
                                <span class="container-action-info">
                                    {{$t("action-info-valider")}}
                                </span>
                                <span class="container-action-info">
                                    {{$t("action-info-envoyer-commande")}}
                                </span>
                                <span class="container-action-info">
                                    {{$t("action-info-saisir-livraison")}}
                                </span>
                            </div>
                        </div>
                        <div v-else class="container-actions-btn-livraison">
                            <vg-button :type="'default-danger'"
                                @click="isModeSaisirLivraison=false;">
                                {{$t("btn-annuler")}}
                            </vg-button>
                            <vg-button :type="'danger'"
                                @click="showLivraisonTotaleConfirmModal=true">
                                {{$t("btn-livraison-totale")}}
                            </vg-button>
                            <vg-button :type="'danger'"
                                @click="onSaveLivraison">
                                {{$t("btn-valider-livraison")}}
                            </vg-button>
                        </div>
                        <div>
                            <br>
                            <vg-button v-if="can('BONS_DE_COMMANDE.DELETE') && (bonDeCommande.statut=='draft' || bonDeCommande.statut=='demande-validation')" 
                                type="default-danger" style="width:50%;" @click="isModalConfirmDeleteOpened=true;">
                                <i class="fas fa-trash-alt"></i> {{ $t("delete-bc") }}
                            </vg-button>
                            <vg-button v-if="can('BONS_DE_COMMANDE.CANCEL') && bonDeCommande.statut!='canceled' && bonDeCommande.statut!='delivered' && bonDeCommande.statut!='partially-delivered'" 
                                type="default-danger" style="width:50%;margin-top: 10px;" @click="isModalConfirmCancelOpened=true;">
                                {{ $t("cancel-bc") }}
                            </vg-button>
                            <dialog-secured-action v-if="isModalConfirmDeleteOpened"
                                :valid-response="'1'"
                                @save="onDeleteBC"
                                @close="isModalConfirmDeleteOpened=false;">
                                <template v-slot:header-title>
                                    {{ $t("confirm-delete-bc") }} {{ bonDeCommande.numero }} ?
                                </template>
                            </dialog-secured-action>
                            <dialog-secured-action v-if="isModalConfirmCancelOpened"
                                :valid-response="'1'"
                                @save="onCancelBC"
                                @close="isModalConfirmCancelOpened=false;">
                                <template v-slot:header-title>
                                    {{ $t("confirm-cancel-bc") }} {{ bonDeCommande.numero }} ?
                                </template>
                                <template #objective>
                                    {{ $t("pour-annuler") }}
                                </template>
                            </dialog-secured-action>
                        </div>
                    </template>
                </vg-collapse>
                <vg-bon-de-commande-form v-if="showUpdateModal"
                    v-model="bonDeCommande"
                    @close="showUpdateModal=false;"
                    @save="showUpdateModal=false;"/>
                <vg-bon-de-commande-demande-validation v-if="showDemandeValidation"
                    @close="showDemandeValidation=false;"
                    @save="showDemandeValidation=false;"/>
                <vg-bon-de-commande-validation v-if="showValidation"
                    @close="showValidation=false;"
                    @save="showValidation=false;"/>
                <vg-bon-de-commande-envoi-commande v-if="showEnvoi"
                    :base64Logo="base64Logo"
                    @close="showEnvoi=false;"
                    @save="showEnvoi=false;"/>
                <vg-modal v-if="showLivraisonTotaleConfirmModal"
                    :title="$t('livraison-totale-confirm-modal-title')"
                    @close="showLivraisonTotaleConfirmModal=false;">
                    <template #footer>
                        <vg-button :type="'default-success'" @click="showLivraisonTotaleConfirmModal=false;">
                            {{$t("btn-non")}}
                        </vg-button>
                        <vg-button :type="'danger'" @click="onSaveLivraisonTotale">
                            {{$t("btn-oui")}}
                        </vg-button>
                    </template>
                </vg-modal>

            </div>
        </template>

        <template v-slot:secondary>
            <vg-tabs v-if="!isModeSaisirLivraison" v-model="focusedTab"
                :tabs="tabs"
                :color="'gris'"
                :type="'pane'" 
                style="width:100%;margin-bottom: 20px;"/>
            <span v-else style="font-size:16px;color:#35b1ea;">
                {{$t("livraison-du-bc")}}
            </span>
            <hr v-if="isModeSaisirLivraison" style="border-color:#35b1ea !important;">
            <vg-collapse v-if="focusedTab=='details-commande' || isModeSaisirLivraison"
                :title="$t('collapse-title-details-commande')"
                :collapseByDefault="false"
                :type="'section'">
                <template v-slot:content>
                    <vg-bon-de-commande-items-table v-if="!showUpdateItems"
                        :idBonDeCommande="bonDeCommande.id"
                        :isLivraison="isModeSaisirLivraison"
                        @livraison-change="onLivraisonChange"/>
                    <vg-bon-de-commande-items-form v-else 
                        v-model="bonDeCommandeItems"
                        @is-loading="isLoading=!isLoading" />
                    <br>
                    <hr>
                    <div class="bc-total">
                        <div class="bc-total-item">
                            <span class="bc-total-item-title">{{$t("bc-total-ht")}}</span>
                            <vg-input v-model="bonDeCommande.montantHT"
                                :inputType="'financial'"
                                disabled/>
                        </div>
                        <div class="bc-total-item">
                            <span class="bc-total-item-title">{{$t("bc-total-tva")}}</span>
                            <vg-input v-model="bonDeCommande.montantTVA"
                                :inputType="'financial'"
                                disabled/>
                        </div>
                        <div class="bc-total-item">
                            <span class="bc-total-item-title">{{$t("bc-total-ttc")}}</span>
                            <vg-input v-model="bonDeCommande.montantTTC"
                                :inputType="'financial'"
                                disabled/>
                        </div>
                    </div>
                </template>
            </vg-collapse>
            <vg-collapse v-if="bonDeCommande && focusedTab=='details-commande' || isModeSaisirLivraison"
                :title="$t('collapse-title-historique')"
                :collapseByDefault="false"
                :type="'section'">
                <template #content>
                    <vg-bon-de-commande-historique-table
                        :idBonDeCommande="bonDeCommande.id"/>
                </template>
            </vg-collapse>

            <div v-if="focusedTab=='preview-pdf-a-imprimer'" style="height: calc(100% - 80px);">
                <vg-bon-de-commande-pdf-viewer />
            </div>
        </template>

        <template v-slot:footer>

        </template>

        <template v-slot:panel-menu-more>

        </template>
    </vg-app-layout>
</template>
<script>

import BonsDeCommandeMixins from 'src/mixins/BonsDeCommandeMixins.js';
import BonDeCommandeItemsMixins from 'src/mixins/BonDeCommandeItemsMixins.js';
import GroupeValidateursMixins from 'src/mixins/GroupeValidateursMixins.js';

import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgDatetimeViewer from 'src/components/Vg/VgDatetimeViewer.vue';
import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgModal from "src/components/Vg/VgModal.vue";
import VgTextMultiligne from "src/components/Vg/VgTextMultiligne.vue";

import VgBonDeCommandeStatutViewer from 'src/components/Vg/BonDeCommande/VgBonDeCommandeStatutViewer.vue';
import VgBonDeCommandeStatutLivraisonViewer from 'src/components/Vg/BonDeCommande/VgBonDeCommandeStatutLivraisonViewer.vue';
import VgBonDeCommandeItemsTable from 'src/components/Vg/BonDeCommandeItem/VgBonDeCommandeItemsTable.vue';
import VgBonDeCommandePdfViewer from 'src/components/Vg/BonDeCommande/VgBonDeCommandePdfViewer.vue';

import VgBonDeCommandeDemandeValidation from 'src/components/Vg/BonDeCommande/VgBonDeCommandeDemandeValidation.vue';
import VgBonDeCommandeValidation from 'src/components/Vg/BonDeCommande/VgBonDeCommandeValidation.vue';
import VgBonDeCommandeEnvoiCommande from 'src/components/Vg/BonDeCommande/VgBonDeCommandeEnvoiCommande.vue';

import VgBonDeCommandeHistoriqueTable from 'src/components/Vg/BonDeCommande/VgBonDeCommandeHistoriqueTable.vue';

import VgBonDeCommandeForm from 'src/components/Vg/Forms/VgBonDeCommandeForm.vue';
import VgBonDeCommandeItemsForm from 'src/components/Vg/Forms/VgBonDeCommandeItemsForm.vue';

import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

export default {
    name: 'bon-de-commande',
    i18n:    { "locale":navigator.language,
"messages": {
    "fr": {
        "update": "Modifier",
        "page-title": "Bon de commande",
        "back-page": "Retour page précédente",
        "tab-title-details-commande": "Détails bon de commande",
        "tab-title-preview-pdf-a-imprimer": "Prévisualisation PDF",
        "collapse-title-details-commande": "Détails commande",
        "collapse-title-historique": "Historique bon de commande",
        "collapse-title-entete": "Entête bon de commande",
        "collapse-title-statuts": "Statuts",
        "collapse-title-documents": "Documents attachés",
        "collapse-title-actions": "Actions / Avancement",
        "label-numero": "n° bon de commande",
        "label-entite": "Entité qui passe commande",
        "label-service-demandeur": "Service demandeur",
        "label-site-demandeur": "Site demandeur",
        "label-fournisseur": "Fournisseur",
        "label-date-creation": "Date création",
        "label-createur": "Créateur",
        "label-commentaire": "Commentaire",
        "label-statut": "Statut bon de commande",
        "label-statut-paiement": "Statut paiement facture",
        "label-statut-livraison": "Statut livraison",
        "btn-modifier": "Modifier détails",
        "action-info-modifier": "Le bon de commande est en cours de création vous pouvez encore le modifier",
        "btn-quitter": "Quitter modification",
        "btn-demander-la-validation": "Demander la validation",
        "action-info-demander-validation": "Lorsque la demande de validation est effective la modification du bon de commande n'est plus possible",
        "btn-valider": "Valider",
        "action-info-valider": "Seuls les utilisateurs autorisés peuvent valider",
        "btn-envoyer-la-commande": "Envoyer la commande",
        "action-info-envoyer-commande": "Le bon de commande sera envoyé par email au fournisseur",
        "btn-saisir-livraison": "Saisir livraison",
        "action-info-saisir-livraison": "La saisie partielle ou totale interagira avec les quantités du stock (page consommable)",
        "bc-total-ht": "Total HT",
        "bc-total-tva": "Total TVA",
        "bc-total-ttc": "Total TTC",
        "print": "Imprimer",
        "livraison-du-bc": "Livraison du bon de commande",
        "btn-annuler": "Annuler",
        "btn-livraison-totale": "Livraison totale",
        "btn-valider-livraison": "Valider livraison",
        "livraison-totale-confirm-modal-title": "Confirmer livraison totale du bon de commande",
        "btn-oui": "Oui",
        "btn-non": "Non",
        "confirm-delete-bc": "Supprimer définitivement le bon de commande n°",
        "confirm-cancel-bc": "Annuler le bon de commande n°",
        "pour-annuler": "pour annuler le bon de commande.",
        "delete-bc": "Supprimer bon de commande",
        "add-commentaire-in-pdf": "Ajouter ce commentaire sur le PDF",
        "cancel-bc": "Annuler bon de commande",
        "collapse-title-fiches-curatives": "Fiches curatives attachées",
        "fiches-curatives-numero": "Fiches curatives",
        "collapse-title-reserves": "Réserves attachées",
        "reserves-numero": "Réserve",
        "numero": "N° {numero}"
    },
    "en":{
        "update": "Modify",
        "page-title": "Purchase Order",
        "back-page": "Back to Previous Page",
        "tab-title-details-commande": "Order Details",
        "tab-title-preview-pdf-a-imprimer": "PDF Preview",
        "collapse-title-details-commande": "Order Details",
        "collapse-title-historique": "Order History",
        "collapse-title-entete": "Order Header",
        "collapse-title-statuts": "Status",
        "collapse-title-documents": "Attached Documents",
        "collapse-title-actions": "Actions / Progress",
        "label-numero": "Order Number",
        "label-entite": "Entity Placing the Order",
        "label-service-demandeur": "Requesting Department",
        "label-site-demandeur": "Requesting Site",
        "label-fournisseur": "Supplier",
        "label-date-creation": "Creation Date",
        "label-createur": "Creator",
        "label-commentaire": "Comment",
        "label-statut": "Order Status",
        "label-statut-paiement": "Invoice Payment Status",
        "label-statut-livraison": "Delivery Status",
        "btn-modifier": "Edit Details",
        "action-info-modifier": "The purchase order is currently being created, and you can still edit it.",
        "btn-quitter": "Exit Editing",
        "btn-demander-la-validation": "Request Validation",
        "action-info-demander-validation": "Once the validation request is submitted, editing of the purchase order is no longer possible.",
        "btn-valider": "Validate",
        "action-info-valider": "Only authorized users can validate.",
        "btn-envoyer-la-commande": "Send Order",
        "action-info-envoyer-commande": "The purchase order will be sent to the supplier via email.",
        "btn-saisir-livraison": "Enter Delivery",
        "action-info-saisir-livraison": "Partial or complete entry will interact with stock quantities (consumable page).",
        "bc-total-ht": "Total (Excl. Tax)",
        "bc-total-tva": "Total VAT",
        "bc-total-ttc": "Total (Incl. Tax)",
        "print": "Print",
        "livraison-du-bc": "Delivery of the Purchase Order",
        "btn-annuler": "Cancel",
        "btn-livraison-totale": "Complete Delivery",
        "btn-valider-livraison": "Validate Delivery",
        "livraison-totale-confirm-modal-title": "Confirm Complete Delivery of Purchase Order",
        "btn-oui": "Yes",
        "btn-non": "No",
        "confirm-delete-bc": "Permanently delete purchase order number",
        "confirm-cancel-bc": "Cancel purchase order number",
        "pour-annuler": "to cancel purchase order.",
        "delete-bc": "Delete purchase order",
        "add-commentaire-in-pdf": "Add this comment to the PDF",
        "cancel-bc": "Cancel purchase order",
        "collapse-title-fiches-curatives": "Attached curative sheets",
        "fiches-curatives-numero": "Curative sheets",
        "collapse-title-reserves": "Attached reserves",
        "reserves-numero": "Reserve",
        "numero": "N° {numero}"
    }
}
},
    mixins:[
        BonsDeCommandeMixins, BonDeCommandeItemsMixins, GroupeValidateursMixins
    ],
    components:{
        VgCollapse,
        VgCheckbox,
        VgTabs,
        VgDatetimeViewer,
        VgLibelleEditable,
        VgButton,
        VgInput,
        VgModal,
        VgTextMultiligne,
        VgFilesViewerListSection,
        VgFilesUploader,
        VgBonDeCommandeStatutViewer,
        VgBonDeCommandeStatutLivraisonViewer,
        VgBonDeCommandeItemsTable,
        VgBonDeCommandePdfViewer,
        VgBonDeCommandeForm,
        VgBonDeCommandeItemsForm,
        VgBonDeCommandeDemandeValidation,
        VgBonDeCommandeValidation,
        VgBonDeCommandeEnvoiCommande,
        VgBonDeCommandeHistoriqueTable,
        DialogSecuredAction
    },
    props: {},
    data: function() {
        return {
            isUploaderVisible: false,
            focusedTab: "details-commande",
            isModeSaisirLivraison: false,
            showUpdateModal: false,
            showUpdateItems: false,
            showValidation: false,
            showDemandeValidation: false,
            showEnvoi: false,
            showLivraisonTotaleConfirmModal: false,
            bonDeCommandeItemsLivraison: null,
            consommablesStocksLivraison: null,
            base64Logo: null,
            isModalConfirmDeleteOpened: false,
            isModalConfirmCancelOpened: false,
            isLoading: false,
            defaultCheckedCommentairePdf: false
        };
    },
    methods: {
        openMaintenance: function(idMaintenance){
            this.$router.push({ name: '_maintenance', params: { id: idMaintenance }});
        },
        openReserve: function(idReserve){
            this.$router.push({ name: '_reserve', params: { id: idReserve }});
        },
        addCommentaireDansPdf: function(isAdding){
            this.defaultCheckedCommentairePdf = isAdding;
            localStorage.setItem("bon-de-commande-commentaire-dans-pdf", isAdding);
        },
        onDeleteBC: function(){
            this.isLoading = true;
            this.BonsDeCommandeMixins_delete(this.bonDeCommande).then(()=>{
                this.retourPage();
            });
        },
        onCancelBC: function(){
            this.isLoading = true;
            this.BonsDeCommandeMixins_cancel(this.bonDeCommande).then(()=>{
                this.retourPage();
            });
        },
        onClickUpdate: function(){
            this.showUpdateModal = true;
        },
        demanderValidation: function(){
            this.showDemandeValidation = true;
        },
        validerBonDeCommande: function(){
            this.showValidation = true;
        },
        envoyerBonDeCommande: function(){
            this.showEnvoi = true;
        },
        saisirLivraison: function(){
            this.isModeSaisirLivraison = true;
        },
        onLivraisonChange: function(datas){
            this.bonDeCommandeItemsLivraison = datas.bonDeCommandeItems;
            this.consommablesStocksLivraison = datas.consommablesStocks;
        },
        onSaveLivraison: function(){
            this.isLoading = true;
            this.BonsDeCommandeMixins_livraison(this.bonDeCommande, this.bonDeCommandeItemsLivraison, Object.values(this.consommablesStocksLivraison), this.depotDefault).then((datas)=>{
                this.bonDeCommandeItemsLivraison = null;
                this.consommablesStocksLivraison = null;
                this.isModeSaisirLivraison = false;
                this.isLoading = false;
            });
        },
        onSaveLivraisonTotale: function(){
            this.isLoading = true;
            this.BonsDeCommandeMixins_livraisonTotale(this.bonDeCommande, this.bonDeCommandeItems, this.depotDefault).then((datas)=>{
                this.bonDeCommandeItemsLivraison = null;
                this.consommablesStocksLivraison = null;
                this.showLivraisonTotaleConfirmModal = false;
                this.isModeSaisirLivraison = false;
                this.isLoading = false;
            });
        },
        retourPage: function(){
            this.$router.go(-1);
            this.$store.dispatch("BonsDeCommandeStore/deleteSelectedItem");
            this.$store.dispatch("BonDeCommandeItemsStore/clear");
            this.$store.dispatch("BonsDeCommandeHistoriquesStore/clear");
        },
        fetchBonDeCommande: function(){
            this.BonsDeCommandeMixins_getBonDeCommande(this.$route.params.id).then((datas)=>{
                this.VgFilesMixins_init(datas.uid, "bon-de-commande");
                this.$store.dispatch("BonsDeCommandeStore/setSelectedItem", datas);
                if(this.bonDeCommande.bonsDeCommandeEntite_id && this.bonDeCommande.bonsDeCommandeEntite && this.bonDeCommande.bonsDeCommandeEntite.logo) this.saveBonDeCommandeEntiteLogo();
            });
        },
        fetchValidateurs: function(){
            let metadatas = new Metadatas();
            this.GroupeValidateursMixins_getGroupeValidateurs(metadatas).then((datas)=>{
                console.log("AFTER FETCH VALIDATEURS", datas);
            });
        },
        saveBonDeCommandeEntiteLogo: function(){
            let logo = this.bonDeCommande.bonsDeCommandeEntite.logo;
            this.VgFilesMixins_getBase64Document(logo).then((base64Logo)=>{
                this.base64Logo = base64Logo;
            });
        },
        onPrintBC: function(){
            console.log("onPrintBC");
        },
        getLocalStorageValues: function(){
            if(localStorage.getItem("bon-de-commande-commentaire-dans-pdf")) this.defaultCheckedCommentairePdf = localStorage.getItem("bon-de-commande-commentaire-dans-pdf")==="true";
        },
    },
    computed: {
        ...mapGetters({
            bonDeCommande: 'BonsDeCommandeStore/getSelectedItem',
            bonDeCommandeItems: 'BonDeCommandeItemsStore/getCollection',
            groupeValidateurs: "GroupeValidateursStore/getCollection",
            depotDefault: "StocksStore/getDepotDefault",
            depotFlottant: "StocksStore/getDepotFlottant"
        }),
        tabs:function(){
            return [
                {label: this.$t("tab-title-details-commande"), name:"details-commande"},
                {label: this.$t("tab-title-preview-pdf-a-imprimer"), name:"preview-pdf-a-imprimer"},
            ];
        },
        isCurrentUserValidateur: function(){
            let idCurrentUser = this.$app.idUser;
            let groupeValidateursDuCurrentUser = this.groupeValidateurs.filter((groupe)=>{
                return groupe.validateurs.findIndex((validateur)=>{
                    return validateur.validateur_id==idCurrentUser;
                })!=-1;
            });
            return groupeValidateursDuCurrentUser.length!=0;
        },
        isValidationsCurrentUserFaites: function(){
            let idCurrentUser = this.$app.idUser;
            let groupeValidateursDuCurrentUser = this.groupeValidateurs.filter((groupe)=>{
                return groupe.validateurs.findIndex((validateur)=>{
                    return validateur.validateur_id==idCurrentUser;
                })!=-1;
            });
            let validationsDuCurrentUserFaites = this.bonDeCommande.validations?this.bonDeCommande.validations.filter((validation)=>{
                return validation.validateur_id==idCurrentUser;
            }):[];
            return validationsDuCurrentUserFaites.length==groupeValidateursDuCurrentUser.length;
        },
        isBtnValiderVisible: function(){
            return (this.bonDeCommande.statut=='demande-validation' || this.bonDeCommande.statut=='validation-partielle') && this.isCurrentUserValidateur && !this.isValidationsCurrentUserFaites;
        },
        bonDeCommandeOperationsFichesCuratives: function(){
            if(this.bonDeCommande.operations && this.bonDeCommande.operations.length!=0) return this.bonDeCommande.operations.filter((operation)=>operation.ficheSav_isReserve==false);
            else return [];
        },
        bonDeCommandeOperationsReserves: function(){
            if(this.bonDeCommande.operations && this.bonDeCommande.operations.length!=0) return this.bonDeCommande.operations.filter((operation)=>operation.ficheSav_isReserve==true);
            else return [];
        }
    },
    created: function(){
        this.fetchBonDeCommande();
        this.fetchValidateurs();
        this.getLocalStorageValues();
    }
};

</script>

<style lang="scss" scoped>
.container-actions{
display: flex;
justify-content: space-between;
align-items: center;
gap: 10px;
.container-actions-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    >button{
        width: 100%;
    }
}
.container-actions-infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    .container-action-info{
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width:100%;
    }
    >span{
        font-size: 12px;
        color: #BBBCBE;
        line-height: 1;
    }
}
.container-action-arrow{
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

}
.container-actions-btn-livraison{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
>button{
    width: 50%;
    margin: 10px 0;
}
}
.bc-total{
display:flex;
flex-direction:column;
align-items: flex-end;
margin-right: 6%;
.bc-total-item{
    display:flex;
    justify-content:space-between;
    align-items: baseline;
    .bc-total-item-title{
        width: 100px;
        color: #9e9da9;
        font-family: "Open Sans";
        font-size: 0.85em;
    }
}
}
.fiche-curative-link{
color: #3999ff;
margin-right: 10px;
}
.fiche-curative-link:hover{
cursor: pointer;
}
</style>