<template>
    <div id="progression" class="full-height">
        <vg-app-layout
            :title="getTitle"
            :icon="getIconPage"
            :colorheader="getHeaderBackgroundColor"
            :isZoomLayout="targetedTacheState != null"
            :activeFilters="activeFilters"
            :filtersTitle="$t('modal-filters-title')"
            @remove-filters="onRemoveFilters"
            @backpage="backPage()" >


           <!-- <action-menu-aggrid slot="search"></action-menu-aggrid> -->
           <template #panel-filters-attributs v-if="!targetedTacheState">
               <vg-input :title="$t('mes-assignations')" >
                   <vg-checkbox
                       :defaultChecked="isMesAffectationsTaches"
                       @checked="HandleGetMyAssignement"
                       @unchecked="HandleGetAllAssignement">
                   </vg-checkbox>
               </vg-input>
               <br>
           </template>
           <template #panel-filters-localisation v-if="!targetedTacheState">
               <vg-filter-site
                    v-model="agfilters.path.value"
               >
               </vg-filter-site>
           </template>
           <template #search>
               <vg-text-filter
                   v-model="searchQuery">
               </vg-text-filter>
           </template>
           <template v-slot:action-button-item>
               <li @click="openModalTacheAffectation">{{$t("progressionVerif.mobile.affectation-tache")}}</li>
           </template>

            <template #primary v-if="!$vgutils.isMobile() && !targetedTacheState">
                <tag-grid
                    :idTableau="'progression-tableau-desktop'"
                    :isModalShowHideColumnsOpened="TagGridMixins.isModalShowHideColumnsOpened"
                    :isExportXLS="TagGridMixins.isExportXLS"
                    v-model="gridOptions"
                    :columnDefs="columnDefs"
                    :rowData="stateVerif"
        			:showLoadingOverlay="showProgressionLoadingOverlay"
        			:overlayLoadingText="$t('progression-overlay-loading-text')"
        			:overlayNoRowsText="$t('progression-overlay-no-rows-text')"
                    @ag-click="setTargetedTache"
                    @modal-show-hide-columns-closed="TagGridMixins_modalShowHideColumnsClosed"
                    @export-xls-done="TagGridMixins_exportXLSDone" />
            </template>

            <template #body v-else-if="!$vgutils.isMobile() && targetedTacheState">
                <div style="display:flex;flex-direction:column;width:100%;">
                    <div>
                        <h4><b>{{ $t("progressionVerif.mobile.reste-a-faire") }}</b></h4>

                        <div>
                            <b>{{displayEquipementTache.length}}</b>{{ $t("progressionVerif.mobile.zoom-equipRest") }} {{ $t("progressionVerif.mobile.avantLe") }} <b>{{targetedTacheState.date_butoire | dateEU}}</b>
                        </div>
                        <span id="temps-restant">{{ $t("progressionVerif.mobile.reste") }} <b>{{targetedTacheState.date_butoire | timeFromGivenValueToNow}}</b></span>
                    </div>
                    <div style="margin-bottom:10px;">
                        <vg-text-filter v-model="searchQueryZoom"></vg-text-filter>
                        <vg-select v-model="agfiltersZoom.path.value"
                            class="select-filter-lieu"
                            :options="pieceDesEquipementAverifier"
                            :placeholder="$t('progressionVerif.mobile.filtre-lieu')">
                        </vg-select>
                    </div>
                    <tag-grid
                        defaultHeight="600px"
                        :idTableau="'zoom-progression-tableau-mobile'"
                        v-model="gridOptionsZoom"
                        :columnDefs="columnDefsZoom"
                        :rowData="equipmentToCheck"
            			:showLoadingOverlay="showEquipementsLoadingOverlay"
            			:overlayLoadingText="$t('equipements-overlay-loading-text')"
            			:overlayNoRowsText="$t('equipements-overlay-no-rows-text')"
                        @ag-click="handleCheckSelectedEquipement"
                        @on-grid-ready="restoreFilters" />
                </div>
            </template>

            <template #body v-else-if="$vgutils.isMobile()">
                <tag-grid v-show="!targetedTacheState"
                    :idTableau="'progression-tableau'"
                    :isModalShowHideColumnsOpened="TagGridMixins.isModalShowHideColumnsOpened"
                    :isExportXLS="TagGridMixins.isExportXLS"
                    v-model="gridOptions"
                    :columnDefs="columnDefs"
                    :rowData="stateVerif"
        			:showLoadingOverlay="showProgressionLoadingOverlay"
        			:overlayLoadingText="$t('progression-overlay-loading-text')"
        			:overlayNoRowsText="$t('progression-overlay-no-rows-text')"
                    @ag-click="setTargetedTache" />

                <div class="zoom-progression" v-if="targetedTacheState" >

                    <div id="zoom-progression">
                        <div class="entete-Dashboard">
                            <div class="QrCode">
                                <vg-button class="vg-button-scan" @click="goToCheckEquipement"><img src="static/assets/icone/inventaire.png" height="30">   </vg-button>
                            </div>
                            <div class="info-entete">
                                <h4><b>{{ $t("progressionVerif.mobile.reste-a-faire") }}</b></h4>

                                <div>
                                    <b>{{displayEquipementTache.length}}</b>{{ $t("progressionVerif.mobile.zoom-equipRest") }} {{ $t("progressionVerif.mobile.avantLe") }} <b>{{targetedTacheState.date_butoire | dateEU}}</b>
                                </div>
                                <span id="temps-restant">{{ $t("progressionVerif.mobile.reste") }} <b>{{targetedTacheState.date_butoire | timeFromGivenValueToNow}}</b></span>
                            </div>

                        </div>
                        <div>
                            <vg-text-filter v-model="searchQueryZoom"></vg-text-filter>
                            <vg-select v-model="agfiltersZoom.path.value"
                                class="select-filter-lieu"
                                :options="pieceDesEquipementAverifier"
                                :placeholder="$t('progressionVerif.mobile.filtre-lieu')">
                            </vg-select>
                        </div>
                        <div class="separateur"></div>
                    </div>
                    <tag-grid
                        :idTableau="'zoom-progression-tableau-mobile'"
                        v-model="gridOptionsZoom"
                        :columnDefs="columnDefsZoom"
                        :rowData="equipmentToCheck"
            			:showLoadingOverlay="showEquipementsLoadingOverlay"
            			:overlayLoadingText="$t('equipements-overlay-loading-text')"
            			:overlayNoRowsText="$t('equipements-overlay-no-rows-text')"
                        @ag-click="handleCheckSelectedEquipement"
                        @on-grid-ready="restoreFilters" />
                </div>

            </template>

            <template #secondary-filters>
                <vg-input :title="$t('mes-assignations')" >
                    <vg-checkbox
                        :defaultChecked="isMesAffectationsTaches "
                        @checked="HandleGetMyAssignement"
                        @unchecked="HandleGetAllAssignement">
                    </vg-checkbox>
                </vg-input>
                <br>
                <vg-input :title="$t('progressionVerif.mobile.filtre-categorie')" >
                    <vg-select
                        v-model="filterCategorieQuery"
                        :options="categories"
                        :attributeLabel="'libelleCatgorie'"
                        :attributeValue="'libelleCatgorie'">
                        <template v-slot="{option}">
                            <div style="display:flex;justify-content:space-between;align-items:center;width:100%;padding:5px 0;">
                                <div >
                                    <img v-if="option.icon" :src="option.icon" height="20" width="20" style="margin-right:10px;" />
                                    <span>{{option.libelleCatgorie}}</span>
                                </div>
                                <span v-if="option.isGe!='1'" style="border:1px solid lightgray;color:lightgray;padding:0 2px;font-size:9px;">{{ $t('categorie-equipement') }}</span>
                                <span v-else style="border:1px solid lightgray;color:lightgray;padding:0 2px;font-size:9px;">{{ $t('categorie-lieu') }}</span>
                            </div>
                        </template>
                    </vg-select>
                </vg-input>
                <vg-filter-site
                    v-model="agfilters.path.value"
                >
                </vg-filter-site>
            </template>

            <vg-affectation-tache-users-form v-if="isTacheAffectationFormOpened"
                isModal
                @close="isTacheAffectationFormOpened=false" />

        </vg-app-layout>
    </div>
</template>
<script>


    import TagGrid from 'src/components/Grid/TagGrid.vue';
    import TagGridMixins from 'src/mixins/TagGridMixins.js';
    import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
    import ZoomDashboardCellRender from "src/components/Vg/TagGrid/ZoomDashboardCellRender.vue";
    import VgGroup from "src/components/Vg/VgGroup.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import DashboardCellRender from "src/components/Vg/TagGrid/DashboardCellRender.vue";
    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgAffectationTacheUsersForm from "src/components/Vg/Forms/VgAffectationTacheUsersForm.vue";
    import VerificationMixins from "src/mixins/VerificationMixins.js";
    import Metadatas from "src/services/Metadatas.js";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    const ICON_DESKTOP = "menu/progression.png";
    const ICON_MOBILE = "mobile/progression-des-verifications.png";
    import FiltersMixins from 'src/mixins/FiltersMixins';


export default {
    name: 'progression',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "progression-overlay-loading-text": "Chargement de la progression...",
    		"progression-overlay-no-rows-text": "Aucune tache correspondant au filtrage",
            "equipements-overlay-loading-text": "Chargement des équipements...",
    		"equipements-overlay-no-rows-text": "Aucun équipement correspondant au filtrage",
            "mes-assignations": "Voir uniquement mes affectations",
            "categorie-equipement": "Catégorie équipement",
            "categorie-lieu": "Catégorie pièce",
            "progressionVerif": {
                "mobile": {
                    "header-titre": "Progression",
                    "avantLe": "avant le",
                    "reste": "Reste",
                    "total": "Total de",
                    "zoom-equipRest": "équipement(s) ",
                    "feedback-a": "Vérifications Ok",
                    "reste-a-faire": "Reste à faire",
                    "requiert-scan": "Requiert un scan de l'équipement",
                    "filtre-lieu": "Sélectionner une pièce",
                    "filtre-categorie": "Filtrage par categorie",
                    "filtre-group": "Filtre",
                    "affectation-tache": "Affectation tâche"
                }
            },
            "modal-filters-title": "Filtrer la progression"
        },
        "en": {
            "mes-assignations": "Display only my assignements",
            "categorie-equipement": "Equipment category",
            "categorie-lieu": "Room category",
            "progressionVerif": {
                "mobile": {
                    "header-titre": "Progress",
                    "avantLe": "Before",
                    "reste": "Remaining :",
                    "total": "Total of",
                    "zoom-equipRest": "remaining equipments",
                    "feedback-a": "Verifications OK",
                    "reste-a-faire": "To do",
                    "requiert-scan": "This requires an equipment scan",
                    "filtre-lieu": "Select a room",
                    "filtre-categorie": "Filter by category",
                    "filtre-group": "Filter",
                    "affectation-tache": "Task assignment"
                }
            },
            "modal-filters-title": "Filter verifications progress"
        },
        "th": {
            "progressionVerif": {
                "mobile": {
                    "avantLe": "ก่อน",
                    "reste": "คงเหลือ :",
                    "total": "ยอดรวมของ",
                    "zoom-equipRest": "อุปกรณ์ที่คงเหลือ",
                    "feedback-a": "การตรวจสอบเรียบร้อย",
                    "reste-a-faire": "เพื่อทำ",
                    "requiert-scan": "จำเป็นต้องสแกนอุปกรณ์",
                    "filtre-lieu": "เลือกห้อง",
                    "filtre-group": "ตัวกรอง"
                }
            }
        }
    }
},
    components:{
        VgAffectationTacheUsersForm,
        TagGrid,
        VgTextFilter,
        DashboardCellRender,
        ZoomDashboardCellRender,
        ActionMenuAggrid,
        VgButton,
        VgSelect,
        VgInput,
        VgGroup,
        VgFilterSite,
        VgCheckbox
    },
    mixins:[
        TagGridMixins,
        VerificationMixins,
        FiltersMixins
    ],
    filters:{
        dateEU: function(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        timeFromGivenValueToNow:function( value ){
            moment.updateLocale('en', {
                relativeTime : {
                    future: "dans %s",
                    past:   "il y a %s",
                    s:  "secondes",
                    m:  "une minute",
                    mm: "%d minutes",
                    h:  "une heure",
                    hh: "%d heures",
                    d:  "un jour",
                    dd: "%d jours",
                    M:  "un mois",
                    MM: "%d mois",
                    y:  "un an",
                    yy: "%d ans"
                }
            });
            var time = moment(value).toNow(true);
            return time;
        }
    },
    data: function() {
        return {
            showProgressionLoadingOverlay: false,
            showEquipementsLoadingOverlay: false,
            metadatasTaches: new Metadatas(),
            isTacheAffectationFormOpened :false,
            stateVerif: null,
            equipmentToCheck: null,
            targetedTacheState: null,
            searchQuery: '',
            searchQueryZoom: '',
            filterCategorieQuery: '',
            categories: [],
            gridOptions: {
                headerHeight: 0,
                rowHeight: this.$vgutils.isMobile() ? 130 : 160
            },
            gridOptionsZoom: {
                headerHeight: 0,
                rowHeight: 100,
            },
            headerZoom: {
                retour: "Retour",
                title2: "",
                title1: "",
                etat: {
                    isButtonAble: false,
                }
            },
            columnDefs: [{
                    headerName: "Libelle tache",
                    width: 180,
                    field: 'libel_tache',
                    colId: 'libel_tache',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new DashboardCellRender({
                        propsData: {
                            params: params.data
                        }
                    }).$mount().$el,
                },
                {
                    headerName: "date_butoire",
                    width: 180,
                    field: 'date_butoire',
                    colId: 'date_butoire',
                    hide: true,
                },
                {
                    headerName: "libelleCatgorie",
                    width: 180,
                    field: 'libelleCatgorie',
                    colId: 'libelleCatgorie',
                    hide: true,
                },
            ],
            columnDefsZoom: [{
                    headerName: "Libelle tache",
                    field: 'libel_equipement',
                    colId: 'libel_equipement',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new ZoomDashboardCellRender({
                        propsData: {
                            params: params.data,
                            icon:this.targetedTacheState.icon
                        }
                    }).$mount().$el,
                },
                {
                    headerName: "id",
                    width: 180,
                    field: 'id',
                    colId: 'id',
                    hide: true,
                },
                {
                    headerName: "path",
                    width: 180,
                    field: 'path',
                    colId: 'path',
                    hide: true,
                },
                {
                    headerName: "qrCode",
                    width: 180,
                    field: 'qrCode',
                    colId: 'qrCode',
                    hide: true,
                },
                {
                    headerName: "isScanRequired",
                    width: 180,
                    field: 'isScanRequired',
                    colId: 'isScanRequired',
                    hide: true
                }
            ],
            agfilters: {
                "path": {"attr":"path", "colId": "path", "value": null, "action":"contains"},
                "nom": {"attr":"fu.nom", "colId": "nom", "value": null, "action":"contains"},
                "prenom": {"attr":"fu.prenom", "colId": "prenom", "value": null, "action":"contains"},
                "isActive": {"attr":"isActive","value":"0","action":"not_equals"}
            },
            agfiltersZoom: {
                "path": {"attr":"path", "colId": "path", "value": null, "action":"contains"}
            }
        };
    },
    mounted: function(){
        this.FiltersMixins_page_name = "progression";
        this.showProgressionLoadingOverlay = true;
        this.metadatasTaches.setFilters(this.agfilters);
        this.VerificationMixins_getProgression(null, this.metadatasTaches).then((datas)=>{
            console.log("DATAS PROGRESSION", datas);
            var categories = [];
            let indexCategorie = -1;
            datas.forEach(function(row){
                indexCategorie = row.libelleCatgorie?categories.findIndex((cat)=>cat.libelleCatgorie==row.libelleCatgorie):-1;
				if(row.libelleCatgorie && indexCategorie==-1) categories.push({libelleCatgorie: row.libelleCatgorie, icon: row.icon, isGe: row.categorie_isGe});
			});
            this.categories = categories.sort((c1, c2)=>c1.libelleCatgorie.localeCompare(c2.libelleCatgorie));
            this.stateVerif = this.setTaskProgression(datas);
            if(this.$route.params["tacheId"]){
                let tache = this.findTache(datas, this.$route.params["tacheId"]);
                this.setTargetedTache({data:tache})
            }
            this.showProgressionLoadingOverlay = false;
            this.$eventbus.$emit("loader-stop", null);
            this.restoreFilters();
        });
    },
    methods: {
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.agfilters.nom.value = null;
            this.agfilters.prenom.value = null;
            this.searchQuery = null;
            this.filterCategorieQuery = null;
        },
        backPage: function() {
            if(this.$route.params["tacheId"]){
                this.$router.go(-1);
            }else{
                window.location.reload();
            }
        },
        /**
         * @param array
         * @param integer tacheId
         * @return object
         */
        findTache:function(taches, tacheId){
            let foundTache = {};
            taches.forEach((tache, i) => {
                if(tache.id_tache == tacheId){
                    foundTache = tache;
                }
            });
            return foundTache;
        },
        goToZoomProgression:function(tacheId){
            this.$router.push({"name":"_verification_progression_tache",params:{"tacheId":tacheId}});
        },
        setTargetedTache: function(row) {
            if(this.$route.params["tacheId"]){
                if(this.$route.params["tacheId"] != row.data.tache_id){
                    this.goToZoomProgression(row.data.id_tache);
                }
            }else{
                this.goToZoomProgression(row.data.id_tache);
            }
            this.setTargetedTacheState(row.data);
        },
        setTargetedTacheState: function(tache) {
            this.showEquipementsLoadingOverlay = true;
            let site = this.agfilters.path.value;
            this.VerificationMixins_getTacheState(tache, site).then((datas)=>{
                this.targetedTacheState = tache;
                this.headerZoom.title1 = this.targetedTacheState.libel_tache;
                this.equipmentToCheck = datas;
                this.showEquipementsLoadingOverlay = false;
                this.$forceUpdate();
            });
        },
        setTaskProgression: function(taches) {
            taches.map((tache,index)=>{
                let done = parseInt(tache.total_done);
                let todo = parseInt(tache.total_todo);
                tache.pourcentToDo = Math.floor(done > todo?100:(done / todo) * 100);
                if(!todo) delete taches[index];
            });
            return taches;
        },
        goToCheckEquipement:function(){
            this.$router.push({ name: '_process_verification_equipement_selection', params: {type: "equipement"} })
        },
        handleCheckSelectedEquipement:function(row){
            if(1 != this.targetedTacheState.isScanRequired){
                this.$router.push({name:"_process_verification", params:{type:"equipement", equipementId:row.data.id, tacheId: this.targetedTacheState.id_tache} });
            }else{
                alert(this.$t('progressionVerif.mobile.requiert-scan'));
            }

        },
        restoreFilters: function(){
            this.agfilters = this.$storage.get("agFilters-progression.vue") || this.agfilters;
            this.agfiltersZoom = this.$storage.get("agFiltersZoom-progression.vue") || this.agfiltersZoom;
            this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
            this.searchQueryZoom = this.$storage.get("searchQueryZoom-progression.vue") || '';
        },
        openModalTacheAffectation: function(){
            this.isTacheAffectationFormOpened  = true;
        },
        HandleGetMyAssignement: function(e){
            this.agfilters.nom.value = this.$app.nom;
            this.agfilters.prenom.value = this.$app.prenom;
        },
        HandleGetAllAssignement: function(e){
            this.agfilters.nom.value = null;
            this.agfilters.prenom.value = null;
        }
    },
    computed: {
        pageFilters: function(){
            return ["path"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.filterCategorieQuery && this.filterCategorieQuery.length!=0) nFilters += 1;
            if(this.agfilters.nom.value || this.agfilters.prenom.value) nFilters += 1;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? this.$root.vgOrange : "";
        },
        getIconPage: function(){
            return this.$vgutils.isMobile() ? ICON_MOBILE : ICON_DESKTOP;
        },
        displayEquipementTache: function(){
            return this.equipmentToCheck;
        },
        pieceDesEquipementAverifier: function(){
            let pieces = this.displayEquipementTache.map((equipement)=>this.$vgutils.getPathEnd(equipement.path));
            pieces = pieces.filter((piece, index, self)=>self.indexOf(piece) == index);
            return pieces.sort();
        },
        isMesAffectationsTaches : function(){
            return this.agfilters.nom.value == null ? false : true;
        },
        getTitle: function(){
            let title = this.targetedTacheState ? this.targetedTacheState.libel_tache : this.$t('progressionVerif.mobile.header-titre');
            return title;
        }
    },
    watch:{
        searchQuery: {
			handler: function(query){
				this.TagGridMixins_setQuickFilter(this.gridOptions, query);
                this.FiltersMixins_saveSearchQuery(query);

			}
		},
        searchQueryZoom: {
			handler: function(query){
				this.TagGridMixins_setQuickFilter(this.gridOptionsZoom, query);
                this.$storage.set("searchQueryZoom-progression.vue", query);
			}
		},
        filterCategorieQuery: {
            handler: function(data) {
                var filters = [{ colId: "libelleCatgorie", value: data }];

                // //console.log("watch filters ",filters);
                this.TagGridMixins_setFilters(this.gridOptions, filters);
            },
            deep: true

        },
        agfilters:{
            handler: function(nfilters) {
                ////console.log("WATCH AG FILTERS", nfilters);
                this.showProgressionLoadingOverlay = true;
                const agfilters = Object.values(nfilters);
                this.TagGridMixins_setFilters(this.gridOptions, agfilters);
                this.$storage.set("agFilters-progression.vue",nfilters);
                this.metadatasTaches.setFilters(nfilters);
                this.VerificationMixins_getProgression(nfilters.path.value,this.metadatasTaches).then((datas)=>{
                    var categories = [];
                    datas.forEach(function(row){
    					if(row.libelleCatgorie && categories.indexOf(row.libelleCatgorie)==-1) categories.push(row.libelleCatgorie);
    				});
                    this.categories = categories;
                    this.stateVerif = this.setTaskProgression(datas);
                    this.showProgressionLoadingOverlay = false;
                });
            },
            deep: true
        },
        agfiltersZoom:{
            handler: function(nfilters) {
                ////console.log("WATCH AG FILTERS", nfilters);
                const agfilters = Object.values(nfilters);
                this.TagGridMixins_setFilters(this.gridOptionsZoom, agfilters);
                this.$storage.set("agFiltersZoom-progression.vue",nfilters);
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" >
@import "../../../style/setting.scss";
.dashVerif{
    margin: 5px 15px;
    overflow: auto;
    height: 90vh;
}
.zoom-progression{
    height: 100%;
    width: 100%;
}
.dashVerif-card{
    // height: 150px;
    margin: 10px 0;
}

.dashVerif-ProgressBar{
    .el-progress-bar{
        .el-progress-bar__outer{
            height: 25px !important;
            border-radius: inherit !important;

            .el-progress-bar__inner{
                background-color: rgb(26, 152, 0);
                border-radius: inherit;
                text-align: center;

            }
        }
    }
}
.dashVerif-card-body{
    // height: 50%;
    margin-top: 5px;
    .dashVerif-card-body-taskDone{
        color: $check;
    }
    .dashVerif-card-body-totalTask{
        text-align: right;
    }
}

// mise en forme du zoom progression

    // entête

        #zoom-progression {
            .select-filter-lieu{
                margin:7px 0;
            }
            #entete {
                $marginWidth: 30px;
                background-color: white;
                // left: -$marginWidth;
                min-height: 60px;
                padding: 10px;
                position: relative;
                // top: -15px;
                // width: calc(100% + 2*(#{$marginWidth}));
                #tache {
                    margin-bottom: 10px;
                    #libelle {
                        font-size: 17px;
                        font-weight: bold;
                        position: relative;
                        top: 4px;
                    }
                }
                #nombre-equipements-restants {
                    font-weight: bold;
                }
                #informations-temporelles {
                    margin-top: 4px;
                    #temps-restant {
                        float: right;
                    }
                }
            }
        }

    // corps

        .carte-equipement {
            background-color: white;
            border: 1px solid #AAAAAA;
            border-radius: 2px;
            font-size: 13px;
            margin: 0px 0px 10px 0px;
            padding: 8px;
            .libelle-equipement {
                font-weight: bold;
            }
            .container-localisation {
                height: 4px;
                .localisation {
                    float: right;
                }
            }
            .qr-code {
                margin-top: 10px;
            }
        }
        .dashboard-option-zoom{
            margin-left: 53%;
            height: 40px;
            width: 50%;
        }
        @media (max-width: 768px){
            #progression-tableau,#zoom-progression-tableau{
                .ag-cell{
                    padding:0;
                }
            }
        }
        #zoom-progression{
            .separateur{
                border-bottom: 1px solid #ff6928;
                margin-top: 10px;
                margin-bottom: 10px !important;
            }
        }

        .entete-Dashboard{
            height: 100px;
            color:#a2a2a2;
            b{
                color: black;
            }
            .QrCode{
                float: left;
                display: flex;
                height: 100%;
                width: 35%;
            }
            .info-entete{
                width: 65%;
                font-size: 12px;
                float: right;
                text-align: right;
            }
            .vg-button-scan{
                align-self: center;
                height: 57px;
                width: 57px;
                cursor: pointer;
                white-space: nowrap;
                -webkit-appearance: none;
                text-align: center;
                box-sizing: border-box;
                outline: none;
                transition: 0.1s;
                opacity: 0.9;
                background-color: white;
                border:1px solid #ff6928 !important;
                padding: 10px;
                padding-top: 7px;
                img{
                    margin:0 !important;
                }
                &:focus{
                    box-shadow: 0 1px #ffffff !important;
                }
                &:active{
                    box-shadow: 0 1px #ffffff !important;
                    background-color: rgba(#ff7a00, 0.19);
                }
            }
        }
</style>
