<template>
	<div class="vg-interventions-table vg-print-hide">
		<tag-grid
			:idTableau="idTableau"
	
			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="getRowDatas"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
	
			@grid-feeded="setDefaultFilter()"
			@ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
			@on-grid-ready="setDefaultFilter()"
			@ag-dbl-click="handleDoubleClickRow"
			@ag-click="handleClickRow" />
		<vg-pagination v-show="paginate"
			:totalItems="counters.filtered"
			:offset="offset"
			:limit="limit"
			@pagination-change="handlePaginationChange" />
		<!-- IMPRESSION -->
		<!--modal :size="'printable'" v-if="showMaintenancesRapport"  @close="showMaintenancesRapport=false">
			<template v-slot:body>
				<vg-reporting-prints
					class="vg-print-show"
					:idTableau="'vg-fiche-sav'"
					:data="getMaintenances"
					:columnDefs="columnDefs"
					:search="searchQuery"
					:allTitle="reportTitle?reportTitle:$t('report-title')">
				</vg-reporting-prints>
			</template>
		</modal-->
		<vg-intervention-validation v-if="showValidateIntervention"
			v-model="focusedIntervention"
			@close="showValidateIntervention=false;"
			@save="onSaveValidateIntervention">
		</vg-intervention-validation>
		<vg-intervention-documents-modal v-if="showInterventionDocument"
			v-model="focusedIntervention"
			@close="showInterventionDocument=false;"
			@after-upload-document="fetch();"/>
		<vg-reserves-modal v-if="showReservesModal"
			:width="'900px'"
			:intervention="focusedIntervention"
			@close="onCloseReservesModal">
			<template v-slot:title>
				<span>{{$t('modal-detail-reserves')}} <b>{{focusedIntervention.intitule}}</b></span>
			</template>
		</vg-reserves-modal>
	</div>
	</template>
	<script>
		import TagGrid from "src/components/Grid/TagGrid.vue";
	
		import TagGridMixins from 'src/mixins/TagGridMixins.js';
		import InterventionsMixins from "src/mixins/InterventionsMixins.js";
	
		import DatetimeIntervalleCellRender from "src/components/Vg/TagGrid/DatetimeIntervalleCellRender.vue";
		import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
		import FilesCellRender from "src/components/Vg/TagGrid/FilesCellRender.vue";
		import NbReservesNonLeveesCellRender from "src/components/Vg/TagGrid/NbReservesNonLeveesCellRender.vue";
		import StatutInterventionCellRender from "src/components/Vg/TagGrid/StatutInterventionCellRender.vue";
		import NumeroInterventionCellRender from "src/components/Vg/TagGrid/NumeroInterventionCellRender.vue";
		import InterventionNbDocumentsCellRender from "src/components/Vg/TagGrid/InterventionNbDocumentsCellRender.vue";
		import OuiNonCellRender from "src/components/Vg/TagGrid/OuiNonCellRender.vue";
		import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
		import InterventionCellRender from "src/components/Vg/TagGrid/InterventionCellRender.vue";
		import InterventionAffectesCellRender from "src/components/Vg/TagGrid/InterventionAffectesCellRender.vue";
	
		import VgReportingPrints from "src/components/Vg/TagGrid/VgReportingPrints.vue";
		import VgInterventionNumeroViewer from "src/components/Vg/Interventions/VgInterventionNumeroViewer.vue";
		import VgInterventionValidation from "src/components/Vg/Interventions/VgInterventionValidation.vue";
		import VgInterventionDocumentsModal from "src/components/Vg/Interventions/VgInterventionDocumentsModal.vue";
		import VgReservesModal from "src/components/Vg/Reserves/VgReservesModal.vue";
		import VgPagination from "src/components/Vg/VgPagination.vue";
	
	
		import Metadatas from "src/services/Metadatas.js";
	
		import { mapGetters } from 'vuex';
		export default {
			name: 'vg-interventions-table',
			props: {
				/**
				* liste des columns à hide (column colId)
				*/
				hiddenColumns: {
					type: Array,
					default: function(){
						return [];
					}
				},
				/**
				* liste des columns pined left (column colId)
				*/
				pinnedColumns: {
					type: Array,
					default: function(){
						return ["id", "commentaireClient", "piece"];
					}
				},
				/**
				* liste des columns non showable (column colId)
				*/
				notShowableColumns: {
					type: Array,
					default: function(){
						return [];
					}
				},
				/**
				* affiche le tableau en mode impression
				* default false
				*/
				showPrint : {
					type: Boolean,
					default: false
				},
				/**
				* recherche
				*/
				searchQuery: {
					type: String,
					default: ""
				},
				/**
				* filtres externes appliqué sur le tableau au fetch
				* agFilters: {
				*	path: { attr:"l.path",colId: "path", value: null, action:"contains" },
				* 	...
				* }
				*/
				agFilters: {
					type: Object,
					default: function(){
						return {};
					}
				},
				/**
				* filtres externes appliqué sur le tableau en local
				*/
				localFilters: {
					type: Array,
					default: function(){
						return [];
					}
				},
				/**
				* equipement_id equipement cible pour la liste des interventions
				*/
				equipementId: {
					type: String,
					default: null
				},
				/**
				* titre rapport impression
				*/
				reportTitle: {
					type: String,
					default: null
				},
				/**
				* idTableau default vg-interventions-table
				*/
				idTableau: {
					type: String,
					default: "vg-interventions-table"
				},
				/**
				* recupere uniquement les interventions periodiques
				*/
				isPeriodiques: {
					type: Boolean,
					default: false
				},
				/**
				* recupere uniquement les interventions ponctuelle
				*/
				isPonctuelles: {
					type: Boolean,
					default: false
				},
				equipement:{
					type: Object,
					default: function(){
						return null;
					}
				},
				paginate:{
					type: Boolean,
					default: true
				},
				offset: {
					type: Number,
					default: 0
				},
				limit: {
					type: Number,
					default: 25
				}
			},
			watch: {
				agFilters: {
					handler: function(newfilters){
						this.agfilters = Object.assign({}, {}, newfilters);
						this.reinitPagination();
						this.fetch();
					},
					deep: true
				},
				searchQuery: {
					handler: function(query){
						this.TagGridMixins_setQuickFilter(this.gridOptions, query);
					}
				},
				offset:{
					handler: function(val){
						this.reinitPagination();
						this.fetch();
					}
				},
				limit:{
					handler: function(val){
						this.reinitPagination();
						this.fetch();
					}
				}
			},
			mixins: [
				TagGridMixins,
				InterventionsMixins
			],
			components: {
				TagGrid,
				DatetimeIntervalleCellRender,
				DatetimeCellRender,
				FilesCellRender,
				NbReservesNonLeveesCellRender,
				StatutInterventionCellRender,
				NumeroInterventionCellRender,
				OuiNonCellRender,
				IconNameCellRender,
				InterventionCellRender,
				InterventionAffectesCellRender,
				VgReportingPrints,
	
				VgInterventionNumeroViewer,
				VgInterventionValidation,
				VgInterventionDocumentsModal,
				VgReservesModal,
				VgPagination
			},
			i18n:    { "locale":navigator.language,
		"messages": {
		"fr": {
			"overlay-loading-text": "Chargement des interventions...",
			"overlay-no-rows-text": "Aucune intervention correspondant au filtrage",
			"header-numero": "N° intervention",
			"header-intitule": "Intitulé",
			"header-site": "Site",
			"header-affectes": "Utilisateurs affectés",
			"header-commentaire": "Commentaire",
			"header-nb-reserves-non-levees": "Nb réserves non levées",
			"header-documents": "Documents",
			"header-nb-documents": "Nb documents",
			"header-date-prevue": "Date prévue",
			"header-date-effective": "Date effective",
			"header-rdv-confirme": "Rdv confirmé?",
			"header-cout": "Coût",
			"header-rate": "Note",
			"header-qui-a-valider": "Qui a validé",
			"header-date-validation": "Date validation",
			"header-status": "Statut",
			"header-restriction-site": "Restriction site",
			"header-maintenance": "N° fiche",
			"header-contrat": "Contrat",
			"header-tiers": "Tiers",
			"report-title": "Verifgood_rapport_interventions",
			"modal-validate-intervention-title": "Valider l'intervention",
			"modal-detail-reserves": "Réserves de l'intervention"
		},
		"en": {
			"overlay-loading-text": "Loading interventions...",
			"overlay-no-rows-text": "No intervention matching filtering",
			"header-numero": "Intervention N°",
			"header-intitule": "Title",
			"header-site": "Site",
			"header-affectes": "Assigned users",
			"header-commentaire": "Comment",
			"header-documents": "Documents",
			"header-nb-documents": "Nb documents",
			"header-date-prevue": "Expected Date",
			"header-date-effective": "Effective date",
			"header-rdv-confirme": "Meeting confirmed ?",
			"header-cout": "Cost",
			"header-rate": "Mark",
			"header-qui-a-valider": "Who validate",
			"header-date-validation": "Validation's date",
			"header-status": "Status",
			"header-restriction-site": "Location perimeter",
			"header-maintenance": "Sheet N°",
			"header-contrat": "Contract",
			"header-tiers": "Provider",
			"report-title": "Verifgood_rapport_interventions",
			"modal-validate-intervention-title": "Validate intervention",
			"header-nb-reserves-non-levees": "Number of reservation not lifted",
			"modal-detail-reserves": "Intervention reserves"
		},
		"th": {
			"header-numero": "การแทรกแซงหมายเลข",
			"header-intitule": "หัวข้อ",
			"header-commentaire": "ความคิดเห็น",
			"header-documents": "เอกสาร",
			"header-date-prevue": "วันที่คาดการณ์",
			"header-date-effective": "วันที่มีผล",
			"header-rdv-confirme": "ยืนยันการประชุมแล้ว ?",
			"header-cout": "ต้นทุน",
			"header-rate": "ทำเครื่องหมาย",
			"header-qui-a-valider": "ใครตรวจสอบ",
			"header-date-validation": "วันที่ตรวจสอบ",
			"header-status": "สถานะ",
			"header-restriction-site": "ขอบเขตที่ตั้ง",
			"header-maintenance": "แผ่นงานหมายเลข",
			"header-contrat": "สัญญา",
			"header-tiers": "ผู้ให้บริการ",
			"modal-validate-intervention-title": "ตรวจสอบการแทรกแซง"
			}
		}
	},
			data: function(){
				return {
					showLoadingOverlay: false,
					showReservesModal: false,
					showInterventionsRapport: false,
					showValidateIntervention: false,
					focusedIntervention: null,
					gridOptions: {
						rowHeight: this.$vgutils.isMobile() ? 90:40,
						isExternalFilterPresent: ()=>this.TagGridMixins_isExternalFilterPresent(),
						doesExternalFilterPass: (node)=>this.TagGridMixins_doesExternalFilterPass(node)
					},
					columnDefs: [
						{
							headerName: this.$t('header-numero'),
							field: 'numeroIntervention',
							colId: 'numeroIntervention',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new NumeroInterventionCellRender({propsData: {params: params}}).$mount().$el,
							width: 100,
							hide: this.columnIsHidden("numeroIntervention"),
							isVisible: this.columnIsNotShowable("numeroIntervention")
						},
						{
							headerName: this.$t('header-nb-documents'),
							field: 'nbDocuments',
							colId: 'nbDocuments',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new InterventionNbDocumentsCellRender({propsData: {params: params}})
								.$on("open-documents", this.onOpenDocuments)
								.$mount().$el,
							width: 90,
							hide: this.columnIsHidden("nbDocuments"),
							isVisible: this.columnIsNotShowable("nbDocuments")
						},
						{
							headerName: this.$t('header-status'),
							field: 'status',
							colId: 'status',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new StatutInterventionCellRender({propsData: {params: params}})
								.$on("valid-intervention", this.onShowValidIntervention)
								.$mount().$el,
							width: 150,
							hide: this.columnIsHidden("status"),
							isVisible: this.columnIsNotShowable("status")
						},
						{
							headerName: this.$t('header-rdv-confirme'),
							field: 'confirmed',
							colId: 'confirmed',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new OuiNonCellRender({propsData: {params: params, isValid: parseInt(params.data.confirmed)}}).$mount().$el,
							width: 80,
							hide: this.columnIsHidden("confirmed"),
							isVisible: this.columnIsNotShowable("confirmed")
						},
						{
							headerName: this.$t('header-date-prevue'),
							field: 'datePrevisionnelleDebut',
							colId: 'datePrevisionnelleDebut',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new DatetimeIntervalleCellRender({propsData: {start: params.data.datePrevisionnelleDebut, end: params.data.datePrevisionnelleFin}}).$mount().$el,
							width: 160,
							hide: this.columnIsHidden("datePrevue"),
							isVisible: this.columnIsNotShowable("datePrevue")
						},
						{
							headerName: this.$t('header-date-effective'),
							field: 'dateEffectiveDebut',
							colId: 'dateEffectiveDebut',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new DatetimeIntervalleCellRender({propsData: {start: params.data.dateEffectiveDebut, end: params.data.dateEffectiveFin}}).$mount().$el,
							width: 160,
							hide: this.columnIsHidden("dateEffective"),
							isVisible: this.columnIsNotShowable("dateEffective")
						},
						{
							headerName: this.$t('header-intitule'),
							field: 'intitule',
							colId: 'intitule',
							cellClass: ["vg-cell-ballam-theme"],
							width: 200,
							hide: this.columnIsHidden("intitule"),
							isVisible: this.columnIsNotShowable("intitule")
						},
						{
							headerName: this.$t('header-site'),
							field: 'site.libel_lieu',
							colId: 'site.libel_lieu',
							cellClass: ["vg-cell-ballam-theme"],
							width: 200,
							hide: this.columnIsHidden("site"),
							isVisible: this.columnIsNotShowable("site")
						},
						{
							headerName: this.$t('header-commentaire'),
							field: 'commentaire',
							colId: 'commentaire',
							cellClass: ["vg-cell-ballam-theme"],
							width: 200,
							hide: this.columnIsHidden("commentaire"),
							isVisible: this.columnIsNotShowable("commentaire")
						},
						{
							headerName: this.$t('header-nb-reserves-non-levees'),
							field: 'nbReservesNonLevees',
							colId: 'nbReservesNonLevees',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new NbReservesNonLeveesCellRender({propsData: {params: params}})
								.$on("show-reserves", this.onClickShowReserves)
								.$mount().$el,
							width: 160,
							hide: this.columnIsHidden("nbReservesNonLevees"),
							isVisible: this.columnIsNotShowable("nbReservesNonLevees")
						},
						{
							headerName: this.$t('header-documents'),
							field: 'documents',
							colId: 'documents',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new FilesCellRender({propsData: {files: params.data.documents}}).$mount().$el,
							width: 200,
							hide: this.columnIsHidden("documents"),
							isVisible: this.columnIsNotShowable("documents")
						},
						{
							headerName: this.$t('header-cout'),
							field: 'cout',
							colId: 'cout',
							cellClass: ["vg-cell-ballam-theme"],
							width: 80,
							hide: this.columnIsHidden("cout"),
							isVisible: this.columnIsNotShowable("cout")
						},
						{
							headerName: this.$t('header-rate'),
							field: 'rate',
							colId: 'rate',
							cellClass: ["vg-cell-ballam-theme"],
							width: 80,
							hide: this.columnIsHidden("rate"),
							isVisible: this.columnIsNotShowable("rate")
						},
						{
							headerName: this.$t('header-qui-a-valider'),
							field: 'validateur.nom',
							colId: 'validateur.nom',
							cellClass: ["vg-cell-ballam-theme"],
							width: 160,
							cellRenderer: (params) => params.data.validateur?params.data.validateur.nom+" "+params.data.validateur.prenom:"",
							hide: this.columnIsHidden("validateur.nom"),
							isVisible: this.columnIsNotShowable("validateur.nom")
						},
						{
							headerName: this.$t('header-date-validation'),
							field: 'dateValidation',
							colId: 'dateValidation',
							cellClass: ["vg-cell-ballam-theme"],
							width: 120,
							cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateValidation}}).$mount().$el,
							hide: this.columnIsHidden("dateValidation"),
							isVisible: this.columnIsNotShowable("dateValidation")
						},
						/*{
							headerName: this.$t('header-restriction-site'),
							field: 'restrictionsSite',
							colId: 'restrictionsSite',
							cellClass: ["vg-cell-ballam-theme"],
							width: 50,
							hide: this.columnIsHidden("restrictionsSite"),
							isVisible: this.columnIsNotShowable("restrictionsSite")
						},*/
						{
							headerName: this.$t('header-maintenance'),
							field: 'maintenance.id',
							colId: 'maintenance.id',
							cellClass: ["vg-cell-ballam-theme"],
							width: 80,
							cellRenderer: (params) => new IconNameCellRender({propsData: {
								dataColumn: params.data.maintenance?params.data.maintenance.id:null,
								isMainColumn: false,
								route: { router: this.$router, name: '_maintenance', params: {id: params.data.maintenance.id } }
							}}).$mount().$el,
							hide: this.columnIsHidden("maintenance.id"),
							isVisible: this.columnIsNotShowable("maintenance.id")
						},
						{
							headerName: this.$t('header-contrat'),
							field: 'contrat.name',
							colId: 'contrat.name',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new IconNameCellRender({propsData: {
								dataColumn: params.data.contrat_id?params.data.contrat.name:null,
								icon: "static/assets/icone/circle/contrat-cercle.png",
								isMainColumn: false,
								route: { router: this.$router, name: '_contrat_id', params: {id: params.data.contrat_id } }
							}}).$mount().$el,
							width: 200,
							hide: this.columnIsHidden("contrat.name"),
							isVisible: this.columnIsNotShowable("contrat.name")
						},
						{
							headerName: this.$t('header-tiers'),
							field: 'tiers.name',
							colId: 'tiers.name',
							cellClass: ["vg-cell-ballam-theme"],
							cellRenderer: (params) => new IconNameCellRender({propsData: {
								dataColumn: params.data.tiers_id?params.data.tiers.name:null,
								icon: "static/assets/icone/circle/tiers-cercle.png",
								isMainColumn: false
							}}).$mount().$el,
							width: 120,
							hide: this.columnIsHidden("tiers.name"),
							isVisible: this.columnIsNotShowable("tiers.name")
						}
					],
					metadatasInterventions: new Metadatas(),
					agfilters: this.agFilters,
					showInterventionDocument: false
				};
			},
			created: function(){
				if(this.$vgutils.isMobile()){
					this.columnDefs.map((columnDef)=>{
						columnDef.hide = true;
					})
					this.columnDefs.unshift({
						headerName: "",
						field:"",
						cellClass: ["vg-cell-mobile-card"],
						cellRenderer: (params) => new InterventionCellRender({propsData: {intervention: params.data}}).$mount().$el
					});
				}else if(this.$app.account_type=="prestataire"){
					let indexSite = this.columnDefs.findIndex((col)=>col.field=="site.libel_lieu");
					this.columnDefs.splice(indexSite, 0,{
						headerName: this.$t('header-affectes'),
						field: 'users',
						colId: 'users',
						cellClass: ["vg-cell-ballam-theme"],
						width: 200,
						cellRenderer: (params) => new InterventionAffectesCellRender({propsData: {
							affectes: params.data.users
						}}).$mount().$el,
						hide: this.columnIsHidden("users"),
						isVisible: this.columnIsNotShowable("users")
					});
				}
				this.metadatasInterventions.setLimit(this.offset, this.limit);
				this.fetch();
			},
			mounted: function(){
				window.addEventListener("askGenerateReport", this.generateReport);
			},
			methods: {
				onSaveValidateIntervention: function(){
					this.fetch();
					this.showValidateIntervention = false;
				},
				onShowValidIntervention: function(intervention){
					this.focusedIntervention = intervention;
					this.showValidateIntervention = true;
				},
				onOpenDocuments: function(intervention){
					this.focusedIntervention = intervention;
					this.showInterventionDocument = true;
				},
				generateReport: function(datas){
					if(datas.detail.idTableau==this.idTableau){
						this.showMaintenancesRapport = true;
					}
				},
				/**
				* column is hidden
				* @param String colId
				* @return Boolean
				*/
				columnIsHidden: function(colId){
					let hiddenInterventionsPonctuellesColumns = ["intitule", "nbReservesNonLevees", "confirmed", "datePrevue", "dateValidation", "validateur.nom", "contrat.name"];
					let hiddenInterventionsPeriodiquesColumns = ["cout", "maintenance.id"];
					if(this.isPonctuelles) return this.hiddenColumns.indexOf(colId)!=-1 || hiddenInterventionsPonctuellesColumns.includes(colId) ;
					if(this.isPeriodiques) return this.hiddenColumns.indexOf(colId)!=-1 || hiddenInterventionsPeriodiquesColumns.includes(colId) ;
					return this.hiddenColumns.indexOf(colId)!=-1;
				},
				/**
				* column is pinned to left
				* @param String colId
				* @return Boolean
				*/
				columnIsPinnedToLeft: function(colId){
					return this.pinnedColumns.indexOf(colId)!=-1;
				},
				/**
				* column is not showable
				* @param String colId
				* @return Boolean
				*/
				columnIsNotShowable: function(colId){
					let hiddenInterventionsPonctuellesColumns = ["intitule", "nbReservesNonLevees", "confirmed", "datePrevue", "dateValidation", "validateur.nom", "contrat.name"];
					let hiddenInterventionsPeriodiquesColumns = ["cout", "maintenance.id"];
					if(this.isPonctuelles) return !(this.notShowableColumns.indexOf(colId)!=-1) && !hiddenInterventionsPonctuellesColumns.includes(colId) ;
					if(this.isPeriodiques) return !(this.notShowableColumns.indexOf(colId)!=-1) && !hiddenInterventionsPeriodiquesColumns.includes(colId) ;
					return !(this.notShowableColumns.indexOf(colId)!=-1);
				},
				handlePaginationChange: function(pagination){
					this.metadatasInterventions.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
					this.fetch();
				},
				reinitPagination: function(){
					this.metadatasInterventions.setLimit(this.offset, this.limit);
				},
				fetch: function(){
					this.showLoadingOverlay = true;
					if(this.isPeriodiques) this.agfilters["fichesav_id"] = { attr: "i.fichesav_id", value: 1, action: "is_null"};
					if(this.isPonctuelles) this.agfilters["fichesav_id"] = { attr: "i.fichesav_id", value: 1, action: "is_not_null"};
					this.metadatasInterventions.setFilters(this.agfilters);
					console.log("THIS FILTERS", this.agfilters);
					this.InterventionsMixins_get(this.metadatasInterventions, this.equipementId, this.getSitePath).then((datas)=>{
						console.log("INTERVENTIONS", datas);
						this.showLoadingOverlay = false;
						this.$emit("fetch-success", {interventions: datas});
					});
				},
				setDefaultFilter: function(){
					if(this.localFilters) this.TagGridMixins_setFilters(this.gridOptions, this.localFilters);
					if(this.showPrint) this.gridOptions.domLayout= "forPrint";
					this.$emit("grid-feeded", this.gridOptions);
				},
				handleClickRow: function(e){
					this.$emit("row-click", e.data);
				},
				handleDoubleClickRow: function(e){
					this.$store.dispatch("InterventionsStore/setSelectedItem",e.data);
					this.$emit("row-dbl-click", e.data);
				},
				handleMultipleSelectionRowChanged: function(gridOptions){
					var selectedNodes = gridOptions.api.getSelectedNodes();
					let interventions = selectedNodes.map((node)=>{
						return node.data;
					});
					this.$store.dispatch("InterventionsStore/setSelectedItems", interventions);
				},
				onClickShowReserves: function(intervention){
					this.focusedIntervention = intervention;
					this.showReservesModal = true;
				},
				onCloseReservesModal: function(){
					this.focusedIntervention = null;
					this.showReservesModal = false;
				}
			},
			beforeDestroy:function(){
				window.removeEventListener("askGenerateReport", this.generateReport);
				this.$store.dispatch("InterventionsStore/clear");
			},
			computed:{
				...mapGetters({
					  getInterventions: 'InterventionsStore/getCollection',
					  getInterventionsPeriodiques: 'InterventionsStore/getPeriodiques',
					  getInterventionsPonctuelles: 'InterventionsStore/getPonctuelles',
					counters: "InterventionsStore/getCounters"
				}),
				getRowDatas: function(){
					if(this.isPeriodiques) return this.getInterventionsPeriodiques;
					if(this.isPonctuelles) return this.getInterventionsPonctuelles;
					return this.getInterventions;
				},
				getSitePath: function(){
					if(this.equipement && this.equipement.lieu && this.equipement.lieu.path){
						let path = this.equipement.lieu.path.split("/");
						return path[0]+"/"+path[1];
					}else return null;
				}
			}
		};
	</script>
	<style lang="scss" scoped>
	.vg-interventions-table{
		height: 90%;
		overflow-y: auto;
	}
	</style>