<template>
	<vg-modal v-if="!showBonDeCommandeCreateModal"
        @close="$emit('close')">
        <template #header>
			<span>{{$t("attacher-bon-de-commande", {numero: maintenance.id})}}</span>
        </template>
        <template #body>
			<span v-if="isLoading">{{ $t("loading-bons-de-commande") }}</span>
			<vg-text-filter v-model="searchQuery" style="margin-bottom:10px;"/>
			<vg-tiers-selector v-model="filterFournisseurId" style="margin-bottom:10px;"/>
			<vg-date-picker-shortcuts 
				previousDatesPresets 
				opens="right" 
				@select="onSelectDateIntervalle" />
			<vg-bons-de-commande-table 
				showCards 
				paginate 
				:filters="bonsDeCommandeFilters"
				@row-click="onBonDeCommandeClick" />
        </template>
        <template #footer>
            <vg-button @click="$emit('close')">
                {{$t("btn-cancel")}}
            </vg-button>
            <vg-button type="success"
				@click="showBonDeCommandeCreateModal=true;">
                {{$t("btn-create")}}
            </vg-button>
            <vg-button type="success"
				:disabled="!selectedBonDeCommande || isLoading"
				@click="attacherBonDeCommande(selectedBonDeCommande)">
                {{$t("btn-attacher")}}
            </vg-button>
        </template>
    </vg-modal>
	<vg-bon-de-commande-form v-else
		@close="showBonDeCommandeCreateModal=false;"
		@save="attacherBonDeCommande" />
</template>
<script>
	import VgModal from "src/components/Vg/VgModal.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";

	import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";

	import VgBonsDeCommandeTable from "src/components/Vg/BonDeCommande/VgBonsDeCommandeTable.vue";
	import VgBonDeCommandeForm from "src/components/Vg/Forms/VgBonDeCommandeForm.vue";

	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

    export default {
        name: 'vg-fichesav-bon-de-commande',
		components: {
			VgModal,
			VgButton,
			VgTextFilter,
			VgTiersSelector,
			VgDatePickerShortcuts,
			VgBonsDeCommandeTable,
			VgBonDeCommandeForm
		},
		mixins: [ MaintenanceMixins, BonsDeCommandeMixins ],
    	props: {
            
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "attacher-bon-de-commande": "Attacher un bon de commande à la fiche n°{numero}",
            "btn-create": "Créer bon de commande",
            "btn-cancel": "Annuler",
            "btn-attacher": "Attacher ce bon de commande",
			"loading-bons-de-commande": "Chargement des bons de commande en cours..."
        },
		"en": {
			"attacher-bon-de-commande": "Attach a purchase order to the curative sheet n°{numero}",
			"btn-create": "Create purchase order",
			"btn-cancel": "Cancel",
			"btn-attacher": "Attach this purchase order",
			"loading-bons-de-commande": "Loading purchase orders..."
		}
    }
},
        data: function() {
            return {
				selectedBonDeCommande: null,
				showBonDeCommandeCreateModal: false,
				isLoading: false,
				searchQuery: null,
				filterFournisseurId: null,
				filterDateIntervalle: null
            };
        },
		created: function(){
			
		},
		mounted: function(){

		},
		methods: {
			onSelectDateIntervalle: function(dateIntervalle){
				if(dateIntervalle) this.filterDateIntervalle = dateIntervalle;
				else this.filterDateIntervalle = null;
			},
			onBonDeCommandeClick: function(row){
				this.selectedBonDeCommande = Object.assign({},{},row.data);
			},
			attacherBonDeCommande: function(selectedBonDeCommande){
				this.isLoading = true;
				// @HERE attacher le bon de commande selectedBonDeCommande à la maintenance
				let operationBonDeCommande = {
					dateOperation: moment().format("YYYY-MM-DD HH:mm:ss"),
					operation: "Attacher bon de commande n°"+selectedBonDeCommande.numero,
					flag: "bon-de-commande",
					ficheSav_id: this.maintenance.id,
					bonDeCommande_id: selectedBonDeCommande.id,
					idUser: this.$app.idUser,
					bonDeCommande: selectedBonDeCommande
				};
				this.MaintenanceMixins_postOperations([operationBonDeCommande]).then((response)=>{
					this.isLoading = false;
					this.$emit("save");
				});
			},
		},
        computed: {
			...mapGetters({
                maintenance: 'MaintenancesStore/getSelectedMaintenance'
            }),
			bonsDeCommandeFilters: function(){
				let searchQuery = this.searchQuery && this.searchQuery.length!=0?this.searchQuery:null;
				return {
					textFilter_numero: {attr: "bc.numero", value: searchQuery, action: "contains", openParenthesis: true},
					textFilter_site: {attr: "l.path", value: searchQuery, action: "contains", logicalOperator: "OR"},
					textFilter_serviceDemandeur: {attr: "bc.serviceDemandeur", value: searchQuery, action: "contains", logicalOperator: "OR"},
					textFilter_demandeur: {attr: "bc.serviceDemandeur", value: searchQuery, action: "contains", logicalOperator: "OR"},
					textFilter_tiers_name: {attr: "t.name", value: searchQuery, action: "contains", logicalOperator: "OR", closeParenthesis: true},
					statut: {attr: "bc.statut", value: ["canceled", "rejected"], action: "not_equals"},
					idMaintenance: {attr: "bc.id", value: this.maintenance && this.maintenance.operations?this.maintenance.operations.filter((op)=>op.bonDeCommande_id).map((op)=>op.bonDeCommande_id):null, action: "not_equals"},
					field_fournisseur: {attr: "bc.fournisseur_id", value: this.filterFournisseurId, action: "equals"},
					field_dateCreation: {attr: "bc.dateCreation", value: this.filterDateIntervalle?[this.filterDateIntervalle.startDate, this.filterDateIntervalle.endDate]:null, action: "between"}
				};
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
