<template>
    <div v-if="value.operations && value.operations.length!=0 && operationsFiches.length!=0">
        <a v-for="(operation, index) in operationsFiches" :key="'fiche-curative-'+index"
            class="fiche-curative-link"
            @click="openMaintenance(operation.ficheSav_id)">
            {{ $t("numero", {numero: operation.ficheSav_id}) }}
        </a>
    </div>
    <div v-else></div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"bon-de-commande-fiches-curatives-attachees-cell-render",
        mixins:[LangMixins],
		props: {
            value: {
                type: Object
            },
            isReserve: {
                type: Boolean,
                default: false
            }
		},
        i18n:    { "locale":navigator.language,
            "messages": {
                "fr": {
                    "numero": "N°{numero}"
                },
                "en": {
                    "numero": "N°{numero}"
                }
            }
        },
        data: function(){
            return {

            };
        },
        computed: {
            operationsFiches: function(){
                return this.value.operations.filter((operation)=>operation.ficheSav_isReserve==this.isReserve);
            }
        },
        methods:{
            openMaintenance: function(idMaintenance){
                this.$emit("open-maintenance", idMaintenance);
            }
        }
    });
</script>

<style lang="scss" scoped>
.fiche-curative-link{
    color: #3999ff;
    margin-right: 10px;
}
.fiche-curative-link:hover{
    cursor: pointer;
}
</style>
