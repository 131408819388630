<template>
    <div class="vg-bons-de-sortie-table">
        <tag-grid
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="bonsDeSortie"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick" />
        <vg-pagination v-if="paginate"
            :totalItems="getTotalItems"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange" />
        <vg-bon-de-sortie-form v-if="showUpdateBonDeSortie"
            v-model="focusedBonDeSortie"
            @close="showUpdateBonDeSortie=false;"
            @update="showUpdateBonDeSortie=false;"/>
        <vg-bon-de-sortie-signature v-if="showSignatureBonDeSortie"
            v-model="focusedBonDeSortie"
            @close="showSignatureBonDeSortie=false;"
            @update="showSignatureBonDeSortie=false;"
            @save="showSignatureBonDeSortie=false;"/>
    </div>
</template>
<script>
import BonsDeSortieMixins from "src/mixins/BonsDeSortieMixins";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgPagination from "src/components/Vg/VgPagination.vue";

import VgBonDeSortieForm from "src/components/Vg/Forms/VgBonDeSortieForm.vue";
import VgBonDeSortieSignature from "src/components/Vg/BonsDeSortie/VgBonDeSortieSignature.vue";

import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import FileLinkCellRender from "src/components/Vg/TagGrid/FileLinkCellRender.vue";
import BonDeSortieCellRender from "src/components/Vg/TagGrid/BonDeSortieCellRender.vue";
import BonDeSortieActionsCellRender from "src/components/Vg/TagGrid/BonDeSortieActionsCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-bons-de-sortie-table',
    components:{
        TagGrid,
        VgPagination,
        VgBonDeSortieForm,
        VgBonDeSortieSignature
    },
    mixins:[ BonsDeSortieMixins ],
    props: {
        id:{
            type: String,
            default: "bons-de-sortie-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * affiche la lsite des consommables sous forme de tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
        "fr": {
            "overlay-loading-text": "Chargement des bons de sortie...",
            "overlay-no-rows-text": "Aucun bon de sortie correspondant au filtrage",
            "header-numero": "Numéro",
            "header-date-creation": "Date création",
            "header-createur": "Créateur",
            "header-signataire-donneur": "Signataire donneur",
            "header-signataire-receveur": "Signataire receveur",
            "header-commentaire": "Commentaire",
            "header-document": "Document",
            "header-actions": "Actions",
            "header-fiche-demande-consommables": "Fiche demande consommables"
        },
        "en": {
            "overlay-loading-text": "Loading exit vouchers...",
            "overlay-no-rows-text": "No exit voucher matching filtering",
            "header-numero": "Number",
            "header-date-creation": "Creation date",
            "header-createur": "Creator",
            "header-signataire-donneur": "Giver signatory",
            "header-signataire-receveur": "Receiver signatory",
            "header-commentaire": "Comment",
            "header-document": "Document",
            "header-actions": "Actions",
            "header-fiche-demande-consommables": "Consumables request sheet"
        }

        }
    },
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
				{
					headerName: this.$t("header-date-creation"),
					field: 'dateCreation',
					colId: 'dateCreation',
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateCreation}}).$mount().$el
				},
                {
					headerName: this.$t("header-createur"),
					field: 'createur_id',
					colId: 'createur_id',
                    cellRenderer: (params) => params.data.createur.nom+" "+params.data.createur.prenom
				},
                {
					headerName: this.$t("header-numero"),
					field: 'numero',
					colId: 'numero'
				},
				{
					headerName: this.$t("header-signataire-donneur"),
					field: 'signataireDonneur',
					colId: 'signataireDonneur'
				},
				{
					headerName: this.$t("header-signataire-receveur"),
					field: 'signataire',
					colId: 'signataire'
				},
				{
					headerName: this.$t("header-commentaire"),
					field: 'description',
					colId: 'description'
				},
				{
					headerName: this.$t("header-document"),
					field: 'document',
					colId: 'document',
                    cellRenderer: (params) => new FileLinkCellRender({propsData: {params: {data: params.data.document}}}).$mount().$el
				},
				{
					headerName: this.$t("header-fiche-demande-consommables"),
					field: 'ficheDemandeConsommable',
					colId: 'ficheDemandeConsommable',
                    cellRenderer: (params) => new IconNameCellRender({propsData: {dataColumn: params.data.ficheDemandeConsommable_id?params.data.ficheDemandeConsommable.numero:null,isMainColumn:false,
                        route: { router: this.$router, name: '_fichedemandeconsommables_id', params: {id: params.data.ficheDemandeConsommable_id } }}}).$mount().$el
				},
                {
                    headerName: this.$t("header-actions"),
                    cellRenderer: (params) => new BonDeSortieActionsCellRender({propsData: {data: params.data}})
                        .$on("update-commentaire", this.onUpdateCommentaire)
                        .$on("open-signer", this.onOpenSigner)
                        .$mount().$el,
                    width: 300
                }
            ],
            metadatas: new Metadatas(),
            showUpdateBonDeSortie: false,
            showSignatureBonDeSortie: false,
            focusedBonDeSortie: null
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                this.metadatas.setLimit(this.offset, this.limit);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            });
            this.columnDefs.unshift({
                headerName: "",
                field:"",
                cellClass: ["vg-cell-mobile-card"],
                cellRenderer: (params) => new BonDeSortieCellRender({propsData: {params: params}})
                    .$on("open-signer", this.onOpenSigner)
                    .$mount().$el
            });
        }
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            bonsDeSortie: 'BonsDeSortieStore/getCollection',
            counters: 'BonsDeSortieStore/getCounters'
        }),
        getTotalItems: function(){
            return this.counters.filtered;
        },
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.filters);
            this.BonsDeSortieMixins_getBonsDeSortie(this.metadatas).then((datas)=>{
                console.log("GET BONS DE SORTIE", datas);
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$emit("row-click",row);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        },
        onUpdateCommentaire: function(bonDeSortie){
            console.log("bonDeSortie", bonDeSortie);
            this.focusedBonDeSortie = Object.assign({}, {}, bonDeSortie);
            this.showUpdateBonDeSortie = true;
        },
        onOpenSigner: function(bonDeSortie){
            this.focusedBonDeSortie = Object.assign({}, {}, bonDeSortie);
            this.showSignatureBonDeSortie = true;
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-bons-de-sortie-table{
    height: 100%;
}
</style>