<template>
    <div class="vg-signature-pad-container">
        <canvas id="vg-signature-pad" ref="vg-signature-pad"/>
        <div class="footer">
            <vg-button type="grey" @click="$emit('close');">
                {{$t("cancel")}}
            </vg-button>
            <vg-button type="grey" @click="eraseSignature">
                {{$t("erase")}}
            </vg-button>
            <vg-button type="success" @click="save">
                {{$t("save")}}
            </vg-button>
        </div>
    </div>
</template>

<script>
// https://github.com/szimek/signature_pad
import VgButton from "src/components/Vg/VgButton.vue";
import SignaturePad from 'signature_pad';
export default {
  name: "vg-signature-pad",
    props: {
        /**
        * null=png image/jpeg image/svg+xml
        */
        extension:{
            type: String,
            default: null
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        scaleToDevicePixelRatio: {
            type: Boolean,
            default: () => true
        }
    },
    components:{
        VgButton
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "cancel": "Retour",
            "erase": "Effacer signature",
            "save": "Valider signature"
        }
    }
},
    data: function() {
        return {
            signaturePad: null
        };
    },
    watch:{

    },
    computed: {

    },
    methods:{
        eraseSignature: function(){
            this.signaturePad.clear();
        },
        save: function(){
            let signatureImage = this.signaturePad.toDataURL(this.extension);
            this.$emit("save", signatureImage);
        }
    },
    created: function(){

    },
    mounted: function(){
        this.signaturePad = new SignaturePad(
            document.getElementById("vg-signature-pad"),
            {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                penColor: 'rgb(0, 0, 0)'
            }
        );
    }
};
</script>

<style lang="scss" scoped>
.vg-signature-pad-container{
    width: 100%;
    height: 100%;
    #vg-signature-pad{
        border: 1px solid #ccc;
    }
    .footer{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
}
// media queries mobile
@media only screen and (max-width: 1200px){
    .vg-signature-pad-container{
        #vg-signature-pad{
            width: -webkit-fill-available !important;
        }
    }
}
</style>
