<template>
	<div class="vg-operations-viewer">
		<tag-grid v-if="!$vgutils.isMobile()"
			:idTableau="getIdTableau"
			v-model="gridOptions"
			:columnDefs="getColumnDefs"
			:rowData="getOperations"
			:defaultHeight="heightGrid"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')" />
		<div v-else style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;max-height: -webkit-fill-available;overflow-y: auto;">
			<operations-simple-cell-render v-for="operation in getOperations" :key="operation.id" 
				style="border-bottom: 1px solid whitesmoke;padding: 10px 0;"
				:operation="{data: operation}"
				:fm="fm?fm:operation.maintenance"
				@update="onUpdate" 
				@click="handleClickOperation" />
		</div>
		<vg-intervention-form v-if="showInterventionForm && focusedIntervention"
			:id="focusedIntervention.id"
			isPonctuelle
			skipVueXStorage
			@close="showInterventionForm=false;focusedIntervention=null;"
			@save="onSaveUpdateIntervention" />
		<vg-operation-modal v-if="showOperationModal"
			v-model="focusedOperation"
			:maintenance="fm"
			@close="showOperationModal=false"
			@after-update="onAfterUpdate"
			@after-delete="onAfterDelete">
		</vg-operation-modal>
		<vg-operation-commentaire-update v-if="showOperationCommentaireModal"
			v-model="focusedOperation"
			@close="showOperationCommentaireModal=false;"
			@save="showOperationCommentaireModal=false;"/>
		<dialog-secured-action v-if="showConfirmDeleteConsommation"
			:validResponse="'1'"
            @save="onDeleteConsommation"
            @close="showConfirmDeleteConsommation=false;">
            <template v-slot:header-title>
                {{$t("delete-consommation")}}
            </template>
        </dialog-secured-action>
		<vg-modal v-if="showInterventionDocuments"
			:title="$t('title-intervention-documents')"
			@close="closeInterventionDocuments">
			<template #body>
				<vg-files-viewer-list-section
					:files="VgFilesMixins_filesListSection"
					:collapseByDefault="false" />
			</template>
			<template #footer>
				<vg-button type="default" @click="closeInterventionDocuments">{{$t("close")}}</vg-button>
			</template>
		</vg-modal>
	</div>
</template>
<script>
	import TagGrid from "src/components/Grid/TagGrid.vue";
	import OperationsSimpleCellRender from "src/components/Vg/TagGrid/OperationsSimpleCellRender.vue";
	import OperationTacheAPrevoirCellRender from "src/components/Vg/TagGrid/OperationTacheAPrevoirCellRender.vue";
	import DateOperationCellRender from "src/components/Vg/TagGrid/DateOperationCellRender.vue";
	import FlagOperationCellRender from "src/components/Vg/TagGrid/FlagOperationCellRender.vue";
	import OperationCellRender from "src/components/Vg/TagGrid/OperationCellRender.vue";
	import DescriptionFmCellRender from "src/components/Vg/TagGrid/DescriptionFmCellRender.vue";
	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import ActionsOperationCellRender from "src/components/Vg/TagGrid/ActionsOperationCellRender.vue";
	import ThumbnailCellRender from "src/components/Vg/TagGrid/ThumbnailCellRender.vue";
	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
	import TagGridMixins from 'src/mixins/TagGridMixins.js';

	import VgModal from 'src/components/Vg/VgModal.vue';
	import VgButton from 'src/components/Vg/VgButton.vue';
	import VgOperationModal from 'src/components/Vg/Operation/VgOperationModal.vue';
	import VgOperationCommentaireUpdate from 'src/components/Vg/Operation/VgOperationCommentaireUpdate.vue';
	import VgInterventionForm from 'src/components/Vg/Forms/VgInterventionForm.vue';
    import DialogSecuredAction from "src/components/Vg/DialogSecuredAction.vue";
	import AffectationFmCellRender from "src/components/Vg/TagGrid/AffectationFmCellRender.vue";
	import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";

	import OperationMixins from "src/mixins/OperationMixins.js";
	import VgFilesMixins from "src/components/Vg/Files/VgFilesMixins.js";
    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

	export default {
        name: 'vg-operations-viewer',
		i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
		"overlay-loading-text": "Chargement des opérations...",
		"overlay-no-rows-text": "Aucune opération correspondant au filtrage",
        "header-date": "Date",
        "header-tag": "Etiquette",
        "header-operation": "Opération",
        "header-photo": "Photo",
        "header-actions": "Actions",
        "header-maintenance-id": "N° fiche",
        "header-maintenance-description": "Description",
        "header-maintenance-affectation": "Affectation",
        "operation-modal-title": "Opération",
        "delete-consommation": "Voulez-vous supprimer cette consommation?",
        "aucune-affectation": "Aucune affectation",
		"title-intervention-documents": "Documents de l'intervention",
		"close": "Fermer"
    },
    "en": {
		"overlay-loading-text": "Loading operations...",
		"overlay-no-rows-text": "No operation matching filtering",
        "header-date": "Date",
        "header-tag": "Label",
        "header-operation": "Operation",
        "header-photo": "Photo",
        "header-actions": "Actions",
        "header-maintenance-id": "File no.",
        "header-maintenance-description": "Description",
        "header-maintenance-affectation": "Assignment",
        "operation-modal-title": "Operation",
        "delete-consommation": "Do you want to delete this consumption?",
        "aucune-affectation": "No assignment",
		"title-intervention-documents": "Documents of the intervention",
		"close": "Close"
    },
    "th": {
        "header-date": "วันที่",
        "header-tag": "ฉลาก",
        "header-operation": "ปฏิบัติการ",
        "header-photo": "รูปภาพ",
        "header-actions": "ปฏิบัติการ"
        }
    }
},
		components:{
			TagGrid,
			DateOperationCellRender,
			FlagOperationCellRender,
			OperationCellRender,
			DescriptionFmCellRender,
			IconNameCellRender,
			ActionsOperationCellRender,
			ThumbnailCellRender,
			OperationsSimpleCellRender,
			OperationTacheAPrevoirCellRender,
			VgOperationModal,
			VgOperationCommentaireUpdate,
			DialogSecuredAction,
			AffectationFmCellRender,
			VgModal,
			VgButton,
			VgFilesViewerListSection,
			VgInterventionForm
		},
		mixins:[MaintenanceMixins, TagGridMixins, ConsommablesMixins, OperationMixins, VgFilesMixins],
    	props: {
			idTableau: {
				type: String,
				default: "vg-operations-viewer"
			},
			fm: {
				type: Object
			},
			oneColumnView:{
				type:Boolean,
				default:false
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau en local
			* @deprecated
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			agfilters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			flags:{
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* affiche le tableau verbeux ex pour page maintenance tache/à prévoir OU en mode normal ex pour page zoom maintenance
			*/
			verbose: {
				type: Boolean,
				default: false
			},
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
        },
        data: function() {
            return {
				showLoadingOverlay: false,
				gridOptions: {
					rowHeight: this.$vgutils.isMobile()?110:60,
				},
				heightGrid:"73vh",
				columnDefs: [
					{
						colId:"dateOperation",
						field:"dateOperation",
						headerName: this.$t("header-date"),
						cellRenderer: (params)=>new DateOperationCellRender({
	                        propsData: {
	                            params: params
	                        }
	                    }).$mount().$el,
						hide: this.columnIsHidden("dateOperation"),
						isVisible: this.columnIsNotShowable("dateOperation")
					},
					{
						colId:"flag",
						field:"flag",
						headerName: this.$t("header-tag"),
						cellRenderer: (params)=>new FlagOperationCellRender({
	                        propsData: {
	                            params: params
	                        }
	                    }).$mount().$el,
						hide: this.columnIsHidden("flag"),
						isVisible: this.columnIsNotShowable("flag")
					},
					{
						colId:"operation",
						field:"operation",
						headerName: this.$t("header-operation"),
						cellRenderer: (params)=>new OperationCellRender({
	                        propsData: {
	                            params: params,
	                            operation: params.data,
								fm: this.fm ? this.fm : params.data.maintenance
	                        }
	                    })
							.$on("update", this.onUpdate)
							.$mount().$el,
						width:400,
						hide: this.columnIsHidden("operation"),
						isVisible: this.columnIsNotShowable("operation")
					},
					{
						colId:"maintenance.id",
						field:"maintenance.id",
						headerName: this.$t("header-maintenance-id"),
						cellRenderer: (params)=> new IconNameCellRender({propsData: {
							dataColumn: params.data.maintenance.id,
							route: { router: this.$router, name: '_maintenance', params: {id: params.data.maintenance.id } }
						}}).$mount().$el,
						width:150,
						hide: this.columnIsHidden("maintenance.id"),
						isVisible: this.columnIsNotShowable("maintenance.id")
					},
					{
						colId:"maintenance",
						field:"maintenance",
						headerName: this.$t("header-maintenance-description"),
						cellRenderer: (params)=>new DescriptionFmCellRender({
	                        propsData: {
	                            params: {data: params.data.maintenance}
	                        }
	                    }).$mount().$el,
						width:400,
						hide: this.columnIsHidden("maintenance"),
						isVisible: this.columnIsNotShowable("maintenance")
					},
					{
						colId:"maintenance.affectation",
						field:"maintenance.affectation",
						headerName: this.$t("header-maintenance-affectation"),
						filterParams: {
							values: []
						},
						getQuickFilterText: (params)=>{
							var result = '';
							if(params.data.maintenance.affectation){
								params.data.maintenance.affectation.affectes.forEach((affecte)=>affecte.user_id?result+=' '+affecte.user_name:result+=' '+affecte.name);
							}
							return result;
						},
						tooltip: (params)=>{
							if(params.data.maintenance.affectation && params.data.maintenance.affectation.affectes){
								return ;
							}else{
								return this.$t("aucune-affectation");
							}
						},
						cellRenderer: (params) => new AffectationFmCellRender({propsData: {params: {data: params.data.maintenance}}}).$mount().$el,
						width:400,
						hide: this.columnIsHidden("maintenance.affectation"),
						isVisible: this.columnIsNotShowable("maintenance.affectation")
					},
					{
						colId:"action",
						field:"action",
						headerName: this.$t("header-actions"),
						cellRenderer: (params)=>new ActionsOperationCellRender({
	                        propsData: {
	                            params: params
	                        }
	                    })
							.$on("update", this.onEmitUpdate)
							.$on("update-commentaire", this.openCommenterModal)
							.$on("delete", this.handleDelete)
							.$on("consommable-show", this.handleShowConsommable)
							.$on("consommation-delete", this.handleConsommationDelete)
							.$on("intervention-show", this.handleShowIntervention)
							.$on("intervention-update", this.handleUpdateIntervention)
							.$on("intervention-show-documents", this.handleShowInterventionDocuments)
							.$on("intervention-reserve-show", this.onInterventionReserveShow)
							.$on("show-equipement", this.onShowEquipement)
							.$on("show-bon-de-commande", this.onShowBonDeCommande)
						.$mount().$el,
						hide: this.columnIsHidden("action"),
						isVisible: this.columnIsNotShowable("action")
					}
				],
				showOperationModal: false,
				focusedOperation: null,
				cellRenderMobile:{
					colId:"dateOperation",
					field:"dateOperation",
					cellRenderer: (params)=>new OperationsSimpleCellRender({
			            propsData: {
			                operation: params,
							fm: this.fm?this.fm:params.data.maintenance
			            }
			        })
						.$on("update", this.onUpdate)
						.$on("click", this.handleClickOperation)
					.$mount().$el
			    },
				showConfirmDeleteConsommation: false,
				showOperationCommentaireModal: false,
				metadatas: new Metadatas(),
				showInterventionDocuments: false,
				focusedIntervention: null,
				getInterventionsFiles: [],
				showInterventionForm: false
            };
        },
		watch: {
			localFilters: {
				handler: function(newfilters){

				},
				deep: true
			},
			agfilters: {
				handler: function(newfilters){
					this.fetch();
				},
				deep: true
			},
			searchQuery: {
				handler: function(query){
					console.log("SEARCH QUERY", query);
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);	// ATTENTION !! ne pourra plus de faire en local
				}
			}
		},
		created: function(){
			if(!this.fm) this.fetch();
		},
		methods: {
			onSaveUpdateIntervention: function(){
				this.showInterventionForm=false;
				this.focusedIntervention=null;
				this.$emit('after-update-operation');
			},
			/**
			* column is hidden
			* @param String colId
			* @return Boolean
			*/
			columnIsHidden: function(colId){
				if(this.verbose) return ["document_name"].includes(colId);
				return this.hiddenColumns.indexOf(colId)!=-1 || ["maintenance", "maintenance.id", "maintenance.affectation"].includes(colId);
			},
			/**
			* column is not showable
			* @param String colId
			* @return Boolean
			*/
			columnIsNotShowable: function(colId){
				if(this.verbose) return !["document_name"].includes(colId);
				return !(this.notShowableColumns.indexOf(colId)!=-1) && ["maintenance", "maintenance.id", "maintenance.affectation"].includes(colId);
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				if(this.flags && this.flags.length!=0) this.agfilters.flags = {attr: "op.flag", value: this.flags, action: "equals"};
				this.metadatas.setFilters(this.agfilters);
				this.OperationsMixins_get(this.metadatas).then((datas)=>{
					console.log("OPERATIONS", datas);
					this.showLoadingOverlay = false;
				});
			},
			onDeleteConsommation: function(){
				this.ConsommablesMixins_deleteConsommableMouvement(this.focusedOperation.consommableMouvement.id).then((data)=>{
					this.$emit("operation-delete", this.focusedOperation);
					this.focusedOperation = null;
					this.showConfirmDeleteConsommation = false;
				});
			},
			onAfterUpdate: function(operation){
				let indexOperation = this.operations.findIndex((op)=>op.id==operation.id);
				this.operations[indexOperation] = Object.assign(this.operations[indexOperation], operation);
				this.showOperationModal = false;
			},
			onAfterDelete: function(operation){
				this.operations.splice(this.operations.findIndex((op)=>op.id==operation.id), 1);
				this.showOperationModal = false;
			},
			handleClickOperation: function(operation){
				this.focusedOperation = operation;
				this.showOperationModal = true;
			},
			onUpdate: function(operation){
				console.log("ON UPDATE", operation);
				this.MaintenanceMixins_putOperation(operation).then((reponse)=>{
					console.log("UPDATE OPERATION AFTER", reponse);
					if(!this.value && !this.fm) this.fetch();
					this.$emit("after-update-operation", reponse);
				});
			},
			onEmitUpdate: function(operation){
				this.$emit("update", operation);
			},
			handleDelete: function(operation){
				this.$emit("delete", operation);
			},
			handleShowConsommable: function(operation){
				this.$emit("consommable-show", operation.consommable.id);
			},
			handleConsommationDelete: function(operation){
				this.focusedOperation = operation;
				this.showConfirmDeleteConsommation = true;
			},
			handleShowIntervention: function(operation){
				this.$emit("intervention-show", operation.tiers.intervention.id);
			},
			handleUpdateIntervention: function(operation){
				this.focusedIntervention = operation.tiers.intervention;
				this.showInterventionForm = true;
			},
			handleShowInterventionDocuments: function(operation){
				this.focusedIntervention = operation.tiers.intervention;
				this.showInterventionDocuments = true;
				this.VgFilesMixins_init(this.focusedIntervention.uid, "intervention");
			},
			closeInterventionDocuments: function(){
				this.showInterventionDocuments = false;
				this.focusedIntervention = null;
			},
			onInterventionReserveShow: function(){
				this.$emit("intervention-show", this.fm.intervention_id);
			},
			onShowEquipement: function(equipement){
				this.$router.push({ name: "_equipement_id", params: { id: equipement.id } });
			},
			onShowBonDeCommande: function(bonDeCommande){
				this.$router.push({ name: "_bon_de_commande", params: { id: bonDeCommande.id } });
			},
			goToMaintenance: function(maintenance){
				this.$router.push({ name: "_maintenance", params: { id: maintenance.id } });
			},
			openCommenterModal: function(operation){
				this.focusedOperation = operation;
				this.showOperationCommentaireModal = true;
			}
		},
        computed: {
			...mapGetters({
                operations: 'OperationsStore/getCollection'
            }),
			getOperations: function(){
				let filters = this.localFilters;
				if(filters && filters.length!=0){
					let flagFilter = filters.find((filter)=>filter.colId=="flag");	// pour l'instant je ne tiens compte uniqument du filtre flag
					return this.operations.filter((operation)=>{
						if(flagFilter.value && typeof(flagFilter.value)=="object") return flagFilter.value.includes(operation.flag);
						else if(flagFilter.value && typeof(flagFilter.value)=="string") return operation.flag==flagFilter.value;
						else return true;
					});
				}else return this.operations;
			},
			getIdTableau:function(){
				if (this.$vgutils.isMobile() || this.oneColumnView) {
					//this.idTableau = "tableau-operations-viewer-mobile";
					if(!this.verbose) this.heightGrid = "100px";
				}
				else{
					//this.idTableau = "tableau-operations-viewer";
					this.heightGrid = "73vh";
				}
				return this.idTableau;
			},
			getColumnDefs:function(){
				let rowHeight = 100;
				if(this.$vgutils.isMobile() && this.idTableau=="operations-tache-a-prevoir"){
					rowHeight = 130;
					this.columnDefs = this.TagGridMixins_replaceMobileColumn(this.gridOptions, this.columnDefs, {
						colId:"operation",
						field:"operation",
						cellClass: ["vg-cell-mobile-card"],
						cellRenderer: (params)=>new OperationTacheAPrevoirCellRender({
				            propsData: {
				                params: params,
								fm: this.fm?this.fm:params.data.maintenance
				            }
				        })
							.$on("update", this.onUpdate)
							.$on("click", this.handleClickOperation)
							.$on("go-to-maintenance", this.goToMaintenance)
							.$on("click-commenter", this.openCommenterModal)
						.$mount().$el
					}, rowHeight);
				}else if (this.$vgutils.isMobile() || this.oneColumnView) {
					this.columnDefs = this.TagGridMixins_replaceMobileColumn(this.gridOptions,this.columnDefs,this.cellRenderMobile, rowHeight);
				}
				return this.columnDefs;
			}
		}
    };

</script>
<style scoped lang="scss">
.vg-operations-viewer{
	height: inherit;
}
@media only screen and (max-width: 1200px){
    .vg-operations-viewer{
        height: -webkit-fill-available !important;
    }
}
</style>
