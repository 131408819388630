<template>
    <div class="vg-lieux-table">
        <tag-grid v-if="!isDemandeIntervention"
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="lieux"
            :pathSplitKey="'path'"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick"
            @ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
        />
        <div v-else class="vg-lieux-demande-intervention">
            <div v-if="showLoadingOverlay" class="loading-overlay">
                <span class="text">{{$t("overlay-loading-text")}}</span>
            </div>
            <div v-if="counters.filtered==0" class="loading-overlay">
                <span class="text">{{$t("overlay-no-rows-text")}}</span>
            </div>
            <lieu-demande-intervention-cell-render v-for="piece in lieux" :key="piece.id"
                :params="{data:piece}"
                @select-piece="onSelectPiece"
                @deselect-piece="onDeselectPiece"
            />
        </div>
        <vg-pagination v-if="paginate"
            :totalItems="counters?counters.filtered:0"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange"
        />
    </div>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgPagination from "src/components/Vg/VgPagination.vue";

import LibelLieuCellRender from "src/components/Vg/TagGrid/LibelLieuCellRender.vue";
import LieuMobileCellRender from "src/components/Vg/TagGrid/LieuMobileCellRender.vue";
import LieuDemandeInterventionCellRender from "src/components/Vg/TagGrid/LieuDemandeInterventionCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-lieux-table',
    components:{
        TagGrid,
        LibelLieuCellRender,
        LieuDemandeInterventionCellRender,
        VgPagination
    },
    mixins:[LieuMixins],
    props: {
        id:{
            type: String,
            default: "lieux-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return ["id", "commentaireClient", "piece"];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * applique le rendu du tableau pour la page DI
        */
        isDemandeIntervention: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des pièces...",
		"overlay-no-rows-text": "Aucune pièce correspondant au filtrage",
        "header-id": "Id",
        "header-service": "Service",
        "header-fonction": "Fonction",
        "header-intitule": "Intitulé",
        "header-categorie": "Catégorie",
        "header-path": "Emplacement",
        "header-qrcode": "Qr code",
        "header-statut": "Statut",
        "header-urgence": "Urgence",
        "header-affectation-recensement": "Affectation R",
        "header-date-prevue-recensement": "Date prévue R",
        "header-statut-recensement": "Statut recensement"
    },
    "en": {
        "overlay-loading-text": "Loading rooms...",
		"overlay-no-rows-text": "No room matching filtering",
        "header-id": "Id",
        "header-service": "Service",
        "header-fonction": "Function",
        "header-intitule": "Name",
        "header-categorie": "Category",
        "header-path": "Location",
        "Header_desktop_table_head_actualite_maintenance": "Maintenance news",
        "Header_desktop_table_head_actualite_verification": "Verification news",
        "header-qrcode": "QR code",
        "header-statut": "Status",
        "header-urgence": "Emergency",
        "header-affectation-recensement": "Inventory assignement",
        "header-date-prevue-recensement": "Scheduled inventory",
        "header-statut-recensement": "Inventory status"
    },
    "th": {
        "header-path": "ตำแหน่งที่ตั้ง",
        "header-service": "บริการ",
        "header-fonction": "ฟังก์ชัน",
        "header-intitule": "หัวเรื่อง",
        "header-categorie": "หมวดหมู่",
        "Header_desktop_table_head_actualite_maintenance": "ข่าวการซ่อมบำรุง",
        "Header_desktop_table_head_actualite_verification": "ข่าวการตรวจสอบ",
        "header-qrcode": "QR โค้ด",
        "header-statut": "สถานะ",
        "header-urgence": "ความเร่งด่วน"
        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                rowHeight: this.$vgutils.isMobile()?70:40,
                /*headerHeight: this.$vgutils.isMobile()||this.isDemandeIntervention?0:40,
                rowHeight: this.$vgutils.isMobile()||this.isDemandeIntervention?84:40*/
            },
            columnDefs: [
                {
                    headerName: '',
                    colId: 'checkbox',
                    width: 50,
                    maxWidth: 50,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    pinned: 'left',
                    isVisible: false
                },
                {
                    headerName: this.$t("header-id"),
                    width: 90,
                    field: 'id',
                    colId: 'id',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    hide: true,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                /*{
                    headerName: this.$t("header-path"),
                    width: 360,
                    field: 'path',
                    colId: 'l.path',
                    tooltipField: 'path',
                    cellClass: ["vg-cell-ballam-theme", "vg-important-cell"],
                    cellStyle: {
                        fontWeight: 'bold'
                    },
                    hide:true,
                    cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path,onlyPath:1}}).$mount().$el
                },*/
                {
                    headerName: this.$t("header-intitule"),
                    width: 360,
                    field: 'libel_lieu',
                    colId: 'libel_lieu',
                    tooltipField: 'path',
                    cellClass: ["vg-cell-ballam-theme", "vg-important-cell"],
                    cellStyle: {
                        fontWeight: 'bold'
                    },
                    cellRenderer: (params) => new LibelLieuCellRender({
                        propsData: {
                            icon: "static/assets/icone/circle/piece-cercle.png",
                            params: params
                        }
                    }).$mount().$el
                },
                {
                    headerName: this.$t("header-categorie"),
                    width: 180,
                    field: 'libelleCatgorie',
                    colId: 'c.libelleCatgorie',
                    suppressSizeToFit: false,
                    //headerComponent: headerRenderer,
                    cellClass: ["vg-cell-ballam-theme"]

                },
                {
                    headerName: this.$t("header-service"),
                    width: 180,
                    field: 'service',
                    colId: 'service',
                    //cellClass: 'text-open-sans-gras',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    //headerComponent: headerRenderer,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-fonction"),
                    width: 180,
                    field: 'codeTrois',
                    colId: 'fonction',
                    //headerComponent: headerRenderer,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-statut-recensement"),
                    field: 'statutRecensement',
                    colId: 'statutRecensement',
                    width: 150,
                    //headerComponent: headerRenderer,
                    //cellRenderer: dateCellRenderer,
                    cellClass: ["vg-cell-ballam-theme"],
                    hide: true,
                    filterParams: {
                        filterOptions: ["equals"],
                        caseSensitive: true
                    }
                },
                {
                    headerName: this.$t("header-affectation-recensement"),
                    field: 'affectationRecensement',
                    colId: 'affectationRecensement',
                    width: 100,
                    cellClass: ["vg-cell-ballam-theme"],
                    hide: true
                },
                {
                    headerName: this.$t("header-date-prevue-recensement"),
                    field: 'dateRecensementPrevu',
                    colId: 'dateRecensementPrevu',
                    width: 100,
                    cellClass: ["vg-cell-ballam-theme"],
                    hide: true
                },
                {
                    headerName: this.$t("header-qrcode"),
                    width: 180,
                    field: 'equipement_qrCode',
                    cellClass: 'text-open-sans-gras',
                    unSortIcon: true,
                    suppressSizeToFit: false,
                    //headerComponent: headerRenderer,
                    //cellRenderer: defaultCellRenderer,
                    cellClass: ["vg-cell-ballam-theme"],
                    hide: true
                },
                {
                    headerName: this.$t("header-statut"),
                    width: 180,
                    field: 'statut',
                    //headerComponent: headerRenderer,
                    //cellRenderer: statutCellRenderer,
                    cellClass: ["vg-cell-ballam-theme"],
                    hide: true
                },
                {
                    headerName: this.$t("header-urgence"),
                    width: 180,
                    field: 'urgence',
                    //headerComponent: headerRenderer,
                    //cellRenderer: defaultCellRenderer,
                    cellClass: ["vg-cell-ballam-theme"],
                    hide: true
                },
                {
                    headerName: this.$t("path"),
                    field: 'path',
                    cellClass: ["vg-cell-ballam-theme"],
                    isVisible: false,
                    hide: true
                }
            ],
            sites:[],
            metadatas: new Metadatas()
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if(this.isDemandeIntervention){
            this.columnDefs = this.transformColumnDefsForDemandeIntervention(this.columnDefs);
        }else if (this.$vgutils.isMobile()) {
            this.columnDefs = this.transformColumnDefsForMobile(this.columnDefs);
        }
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            lieux: 'LieuxStore/getCollection',
            counters: 'LieuxStore/getCounters'
        })
    },
    methods:{
        handleMultipleSelectionRowChanged: function(gridOptions){
            var selectedNodes = gridOptions.api.getSelectedNodes();
            let pieces = selectedNodes.map((node)=>{
                return node.data;
            });
            this.$store.dispatch("LieuxStore/setSelectedItems", pieces);
        },
        onSelectPiece: function(data){
            this.$emit("select-piece", data);
        },
        onDeselectPiece: function(data){
            this.$emit("deselect-piece", data);
        },
        /**
        *
        * @param {array} columnDefs before transformation
        * @return {array} columnDefs all column are hide except the first one with the cell render
        */
        transformColumnDefsForMobile:function(columnDefs){
            columnDefs.forEach((columnDef, i) => {
                columnDef.hide = true;
            });
            columnDefs.unshift({
                getQuickFilterText: (params) => params.data.path + " " + params.data.codeTrois + " " + params.data.codeDeux,
                cellRenderer: (params) => new LieuMobileCellRender({
                    propsData: {
                        params: params
                    }
                }).$mount().$el,
                cellClass: ["vg-cell-mobile-card"]
            });
            return columnDefs;
        },
        transformColumnDefsForDemandeIntervention:function(columnDefs){
            columnDefs.forEach((columnDef, i) => {
                columnDef.hide = true;
            });
            columnDefs.unshift({
                cellRenderer: (params) => new LieuDemandeInterventionCellRender({
                    propsData: {
                        params: params
                    }
                }).$mount().$el
            });
            return columnDefs;
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            console.log("FETCH", this.filters);
            this.metadatas.setFilters(this.filters);
            this.LieuMixins_getLieux(this.metadatas).then((datas)=>{
                this.$store.dispatch("LieuxStore/set", datas.lieux);
                this.$store.dispatch("LieuxStore/setCounters", datas.metas.counters);
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$emit("row-click",row);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-lieux-table{
    .vg-lieux-demande-intervention{
        height: 85%;
        width: 100%;
        overflow: auto;
    }
}
.loading-overlay{
    position: absolute;
    margin: auto;
    background-color: rgba(255,255,255,0.5);
    width: inherit;
    height: -webkit-fill-available;
    overflow: hidden;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .text{
        background-color:#35b1ea;
        color:white;
        border:none;
        padding:10px;
        font-size:14px;
        display: block;
    }
}
</style>
