<template>
    <div :class="'schedule-month-template schedule-template-'+calendar.id">
        <div v-if="calendar.id=='maintenance-ouverture' || calendar.id=='maintenance-fermeture'" 
            :class="['month-template', {'maintenance-open': schedule.raw.statut!='Resolue', 'maintenance-close': schedule.raw.statut=='Resolue'}]">
            <div class="title-container">
                <small>{{ getTime(schedule.start._date) }}</small>
                <vg-fichesav-description
                    :fm="schedule.raw"
                    :showIcon="false"/>
                <span>{{$t("dans")}}</span>
                <span>{{schedule.raw.equipement.libel_lieu}}</span>
            </div>
            <div class="icon-container">
                <img class="icon" src="static/assets/icone/wrench-white-light.png"/>
                <vg-icon v-if="schedule.raw.isGEP=='1' && schedule.raw.libelComposant && schedule.raw.iconComposant"
                    :url="schedule.raw.iconComposant" 
                    :color="'white'"
                    :size="'xxs'" />
                <vg-icon v-else-if="schedule.raw.equipement && schedule.raw.equipement.isGroupEqp=='1' && schedule.raw.composant && schedule.raw.composant.libelComposant && schedule.raw.composant.icon"
                    :url="schedule.raw.composant.icon" 
                    :color="'white'"
                    :size="'xxs'" />
                <vg-icon v-else-if="schedule.raw.equipement && schedule.raw.equipement.categorie_icon"
                    :url="schedule.raw.equipement.categorie_icon" 
                    :color="'white'"
                    :size="'xxs'" />
            </div>
        </div>

        <div v-else-if="calendar.id=='maintenance-affectation'"
            :class="['month-template', {'maintenance-open': schedule.raw.statut!='Resolue', 'maintenance-close': schedule.raw.statut=='Resolue'}]">
            <div class="title-container">
                <small>{{ getTime(schedule.start._date) }}</small>
                <vg-fichesav-description
                    :fm="schedule.raw"
                    :showIcon="false"/>
                <span>{{$t("dans")}}</span>
                <span>{{schedule.raw.equipement.libel_lieu}}</span>
            </div>
            <div class="icon-container">
                <img class="icon" src="static/assets/icone/wrench-white-light.png"/>
                <vg-icon v-if="schedule.raw.isGEP=='1' && schedule.raw.libelComposant && schedule.raw.iconComposant"
                    :url="schedule.raw.iconComposant" 
                    :color="'white'"
                    :size="'xxs'" />
                <vg-icon v-else-if="schedule.raw.equipement && schedule.raw.equipement.isGroupEqp=='1' && schedule.raw.composant && schedule.raw.composant.libelComposant && schedule.raw.composant.icon"
                    :url="schedule.raw.composant.icon" 
                    :color="'white'"
                    :size="'xxs'" />
                <vg-icon v-else-if="schedule.raw.equipement && schedule.raw.equipement.categorie_icon"
                    :url="schedule.raw.equipement.categorie_icon" 
                    :color="'white'"
                    :size="'xxs'" />
            </div>
        </div>

        <div v-else-if="calendar.id=='intervention-programmee' && schedule.raw.status=='intervention-prevue'" class="month-template schedule-intervention-prevue">
            <!--vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                :color="'red'"
                :size="'xxs'"
                :key="'icon-'+calendar.id+'-'+schedule.raw.id" />
            <img v-if="schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon-xxs" />
            <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu.png" class="icon-xxs" />
            <span class="schedule-hour">{{ getTime(schedule.start._date) }}</span>
            <span class="title">{{schedule.raw.intitule}}</span-->
            <div class="title-container">
                <small>{{ getTime(schedule.start._date) }}</small><span>{{schedule.raw.intitule}}</span>
            </div>
            <div class="icon-container">
                <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                    :color="'white'"
                    :size="'xxs'" />
                <img v-if="schedule.raw.contrat && schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon" />
                <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu-blanc.png" class="icon" />
            </div>
        </div>

        <div v-else-if="calendar.id=='intervention-programmee' && schedule.raw.status=='intervention-realisee'" class="month-template schedule-intervention-realisee">
            <div v-if="!schedule.raw.nbDocuments || schedule.raw.nbDocuments.length==0 || schedule.raw.nbDocuments==0"
                class="intervention-warning-aucun-document">
            </div>
            <!--vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                :color="'#45b348'"
                :size="'xxs'"
                :key="'icon-'+calendar.id+'-'+schedule.raw.id" />
            <img v-if="schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon-xxs" />
            <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu.png" class="icon-xxs" />
            <span class="schedule-hour">{{ getTime(schedule.start._date) }}</span>
            <span class="title">{{schedule.raw.intitule}}</span-->
            <div class="title-container">
                <small>{{ getTime(schedule.start._date) }}</small><span>{{schedule.raw.intitule}}</span>
            </div>
            <div class="icon-container">
                <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                    :color="'white'"
                    :size="'xxs'" />
                <img v-if="schedule.raw.contrat && schedule.raw.contrat.categorie.icon" :src="schedule.raw.contrat.categorie.icon" class="icon" />
                <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu-blanc.png" class="icon" />
            </div>
        </div>

        <div v-else-if="calendar.id=='tache-session'" :class="'month-template schedule-tache-session-'+tacheSessionColor">
            <!--vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                :color="tacheSessionIconColor"
                :size="'xxs'"
                :key="'icon-'+calendar.id+'-'+schedule.raw.id" />
            <img v-if="schedule.raw.tache.categorie_icon" :src="schedule.raw.tache.categorie_icon" class="icon-xxs" />
            <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu.png" class="icon-xxs" />
            <span class="schedule-hour">{{ getTime(schedule.start._date) }}</span>
            <span class="title">{{ schedule.raw.tache.libel_tache }}</span-->

            <span class="title-container">
                <small>{{ getTime(schedule.start._date) }}</small>
                <span>{{ schedule.raw.tache.libel_tache }}</span>
            </span>
            <div class="icon-container">
                <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                    :color="'white'"
                    :size="'xxs'" />
                <img v-if="schedule.raw.tache.categorie_icon" :src="schedule.raw.tache.categorie_icon" class="icon" />
                <img v-else-if="isCategorieLieu" src="static/assets/icone/lieu-blanc.png" class="icon" />
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
    import VgIcon from "src/components/Vg/VgIcon.vue";

    export default Vue.extend({
        name: "schedule-month-template",
		props: {
            /**
            * schedule
            */
			value: {
				type: Object,
				required: true
			},
            calendar: {
                type: Object,
                required: true
            }
		},
        data: function() {
            return {
                schedule: this.value
            };
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            
        }
    }
},
        filters:{
            getSite: function(path){
                return path.split("/")[1];
            }
        },
        components: {
            VgFichesavDescription,
            VgIcon
        },
        methods:{
            isAffectationInterne: function(){
                return this.schedule.raw.affectation.affectes.findIndex((aff)=>aff.roles)!=-1
            },
            getTime: function(dt){
                return moment(dt).format("HH:mm");
            }
        },
        computed: {
            tacheSessionColor: function(){
                if(this.isScheduleDansProgressionFuture) return "bleu";
                if(this.isCurrentSession){
                    if(this.schedule.raw.tache){
                        if(this.schedule.raw.tache.nbEquipementChecked==this.schedule.raw.tache.nbEquipementToCheck) return "vert";
                        else return "red";
                    }else return "bleu";
                }else if(this.schedule.raw.progression){
                    if(this.schedule.raw.progression.verified==this.schedule.raw.progression.total) return "vert";
                    else return "red";
                }else return "bleu";
            },
            isCurrentSession: function(){
                if(this.schedule.raw.tache){
                    let scheduleDateStart = moment(this.schedule.start._date).format("YYYY-MM-DD");
                    let tacheProgressionDateDebut = moment(this.schedule.raw.tache.dateprochaineVerif).subtract(parseInt(this.schedule.raw.tache.periodicite), "days").format("YYYY-MM-DD");
                    let tacheProchaineVerif = moment(this.schedule.raw.tache.dateprochaineVerif).format("YYYY-MM-DD");
                    return scheduleDateStart>=tacheProgressionDateDebut && scheduleDateStart<tacheProchaineVerif;
                }else return false;
            },
            isScheduleDansProgressionFuture: function(){
                if(this.schedule.raw.tache){
                    let scheduleDateStart = moment(this.schedule.start._date).format("YYYY-MM-DD");
                    let tacheProchaineVerif = moment(this.schedule.raw.tache.dateprochaineVerif).format("YYYY-MM-DD");
                    return scheduleDateStart>=tacheProchaineVerif;
                }else return false;
            },
            isCategorieLieu: function(){
                if(!this.schedule.raw.contrat) return false;
                if(this.calendar.id=='intervention-programmee') return this.schedule.raw.contrat.categorie.isGe==1 || this.schedule.raw.contrat.categorie.isGe=="1";
                else if(this.calendar.id=='tache-session') return this.schedule.raw.tache.categorie_isGe==1 || this.schedule.raw.tache.categorie_isGe=="1";
                else return false;
            },
            isInterventionPrevueIsNotValid: function(){
                return this.schedule.raw.status=="intervention-prevue" && moment(this.schedule.raw.datePrevisionnelleDebut).format("YYYY-MM-DD").localeCompare(moment().format("YYYY-MM-DD"))==-1;
            },
            start: function(){
                return moment(this.schedule.start._date).format("HH:mm");
            },
            end: function(){
                return moment(this.schedule.end._date).format("HH:mm");
            },
            isInterventionEffectiveValid: function(){
                return this.schedule.raw.dateEffectiveDebut && this.schedule.raw.echeance.id
                    && moment(this.schedule.raw.echeance.datePrevisionelle).format("YYYY-MM-DD")==moment(this.schedule.raw.dateIntervention).format("YYYY-MM-DD");
            },
            isInterventionEffectiveInValid: function(){
                return this.schedule.raw.echeance && this.schedule.raw.echeance.id
                    && moment(this.schedule.raw.echeance.datePrevisionelle).format("YYYY-MM-DD")!=moment(this.schedule.raw.dateIntervention).format("YYYY-MM-DD");
            },
            getDatePrevisionnelle: function(){
                return moment(this.schedule.raw.echeance.datePrevisionelle).format('DD/MM/YYYY');
            }
        }
    });
</script>

<style lang="scss" scoped>
.schedule-month-template{
    height: 25px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    .month-template{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 2px;
        overflow: hidden;
        flex-wrap: nowrap;    
        width: 100%;
        height:100%;
        font-weight: normal;
        font-size: 10px;
        .title-container{
            line-height: normal;
            padding: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            gap: 2px;
        }
        .icon-container{
            padding: 5px;
            display: flex;
            justify-content: flex-end;
            gap: 5px;
            .icon{
                width: 12px;
                height: 12px;
                filter: brightness(0) invert(1);
            }
        }
    }
}
.schedule-template-maintenance-ouverture{
    background-color: #3095C9 !important;
}
.schedule-template-maintenance-fermeture{
    background-color: #3095C9 !important;
    
}
.schedule-template-maintenance-affectation{
    background-color: #3095C9 !important;
    
}
.schedule-template-intervention-programmee{
    background-color: #908070 !important;
    
}
.schedule-template-tache-session{
    background-color: #4F56A6 !important;
    
}
$border-left-width: 4px;
.maintenance-open{
    border-left: $border-left-width solid red;
}
.maintenance-close{
    border-left: $border-left-width solid #3baa36;
}
.schedule-tache-session-bleu{
    border-left: $border-left-width solid #4F56A6;
}
.schedule-tache-session-red{
    border-left: $border-left-width solid red;
}
.schedule-tache-session-vert{
    border-left: $border-left-width solid #3baa36;
}
.schedule-intervention-prevue{
    border-left: $border-left-width solid red;
}
.schedule-intervention-realisee{
    border-left: $border-left-width solid #3baa36;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.intervention-warning-aucun-document{
    position: absolute;
    right: 5px;
    top: 5px;
    height:5px;
    width:5px;
    background-color: red;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
}
@media only screen and (max-width:800px){
    
}
</style>
